import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import classNames from './marketsPage.module.scss';
import tradeStream from '../../static/images/sidebarIcons/tradeStream.svg';
import searchIcon from '../../static/images/search.svg';
import fulllogo from '../../static/images/logos/defilogo.svg';
import ddown from '../../static/images/malls/ddown.svg';
import ddGrey from '../../static/images/icons/ddwonGrey.svg';
import { useShareTokensDetail } from '../../queryHooks';
import { SIDEBAR_FOOTERS, MARKET_ASSET_CLASSES } from '../../config/constants';
import { BankContext } from '../../context/Context';
import { GetSortOrder } from '../../utils/FunctionTools';
import MarketAssetDetail from '../../components/MarketAssetDetail';
import TerminalsSidebar from '../../components/TerminalsSidebar';
import LoginModal from '../../components/LoginModalNew';
import Layout from '../../Layout/Layout';
import MarketTableCrypto from './components/MarketTableCrypto';
import MarketTableForex from './components/MarketTableForex';
import MarketTableShares from './components/MarketTableShares';
import MarketTableBonds from './components/MarketTableBonds';
import MarketTableMoneyMarkets from './components/MarketTableMoneyMarkets';
import { BondContractDetails } from './components/BondContractDetails';
import { useMarketContext } from '../../context/MarketContext';
import CreateBond from '../../components/CreateBond';
import Bonds from '../../components/Product/Bonds';
import BondOverview from '../BondsOverview/BondOverview';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { useAppContextDetails } from '../../context/AppContext';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import Investments from './components/Investments';

import banner1 from '../../assets/images/DefiMarkets/banner1.png';
import banner2 from '../../assets/images/DefiMarkets/banner2.png';
import banner3 from '../../assets/images/DefiMarkets/banner3.png';
import banner4 from '../../assets/images/DefiMarkets/banner4.png';
import btc from '../../static/images/coin-small/btc1.svg';
import axios from 'axios';
import Staking from './components/Staking';

function MarketsPage() {
  const history = useHistory();
  const { assetClassName, coin } = useParams();
  const [assetClass, setAssetClass] = useState(MARKET_ASSET_CLASSES[0]);
  const [index, setIndex] = useState(2);
  const { email, sidebarCollapse, profileId, token, iceProfileId } =
    useContext(BankContext);
  const { appLogo } = useAppContextDetails();
  const {
    streamOpen,
    setStreamOpen,
    defiTab,
    setDefiTab,
    defiDropdown,
    setDefiDropdown,
    defiSelectedDropdown,
    setDefiSelectedDropdown,
    coinSelected,
    setCoinSelected,
    buyContract,
    setBuyContract,
    buyContractStep,
    setBuyContractStep,
    selectedAsset,
    setSelectedAsset,
    coinBalance,
    setCoinBalance,
    contractCost,
    setContractCost,
  } = useMarketContext();

  const [search, setSearch] = useState('');
  const [selectedSidebarMenu, setSelectedSidebarMenu] = useState('Bonds');
  const ref = useRef();
  const [contractValue, setcontractValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [updatedVaultBalance, setUpdatedVaultBalance] = useState([]);
  const [bondHash, setBondHash] = useState('');
  const [toast, setToast] = useState('');
  const { pathname } = useLocation();
  const [clicked, setClicked] = useState('');

  const { data: shareToken } = useShareTokensDetail(coin);

  useEffect(() => {
    const classSelected = MARKET_ASSET_CLASSES.filter(
      (asCls) => asCls.name === assetClassName
    );
    if (classSelected[0]) setAssetClass(classSelected[0]);
  }, [assetClassName]);

  useEffect(() => {
    if (assetClassName === 'shares' && shareToken?.token_profile_data) {
      setCoinSelected(shareToken?.token_profile_data);
    }
  }, [coin, shareToken, assetClassName]);

  useEffect(() => {
    ref.current.addEventListener('scroll', scrollHandle);
    return () => {
      ref.current.removeEventListener('scroll', scrollHandle);
    };
  }, []);

  useEffect(() => {
    if (pathname.includes('myContracts')) {
      setDefiTab('My Contracts');
    }
  }, [pathname]);

  const [active, setActive] = useState(false);
  const scrollHandle = (event) => {
    setActive(
      Boolean(
        event?.path[0]?.scrollTop >=
          event?.path[0].childNodes[1].childNodes[0].childNodes[1]?.offsetTop
      )
    );
  };

  function selectTable() {
    switch (assetClass?.name) {
      case 'crypto':
        return (
          <MarketTableCrypto
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'fiat':
        return (
          <MarketTableForex
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'shares':
        return (
          <MarketTableShares
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            search={search}
          />
        );
      case 'bonds':
        return (
          <MarketTableBonds
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'moneyMarkets':
        return (
          <MarketTableMoneyMarkets
            streamOpen={streamOpen}
            setCoinSelected={setCoinSelected}
            assetClass={assetClass}
            active={active}
            search={search}
          />
        );
      case 'stakingVaults':
        return <CreateBond />;
      case 'myContracts':
        return <Staking />;

      default:
        return '';
    }
  }

  const updatedBalance = () => {
    if (selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin) {
      let obj = {
        app_code: 'manifest',
        email,
        include_coins: [selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin],
      };
      axios
        .post(
          'https://comms.globalxchange.io/coin/vault/service/coins/get',
          obj
        )
        .then((response) => {
          let result = response?.data?.coins_data;
          // console.log(result, 'updatedBalance result');
          setLoading(false);
          setUpdatedVaultBalance(result);
          setBuyContractStep('step2');
        })
        .catch((error) => {
          console.log(error, 'updatedBalance error');
        });
    }
  };

  const buyStakingContract = () => {
    if (
      selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin &&
      selectedAsset?.custom_bonds?.[0]?.days &&
      selectedAsset?.custom_bonds?.[0]?.bond_template_id
    ) {
      let obj = {
        email,
        token,
        origin_app_code: 'manifest',
        profile_id: iceProfileId,
        coin: selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin,
        days: selectedAsset?.custom_bonds?.[0]?.days,
        num_of_bonds: contractValue,
        simulate: false,
        payCoin: selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin,
        stats: false,
        bond_type: 'custom',
        bond_template_id: selectedAsset?.custom_bonds?.[0]?.bond_template_id,
        debitfromApp: 'manifest',
        debitfromProfileId: profileId,
      };
      axios
        .post('https://comms.globalxchange.io/coin/iced/contract/create', obj)
        .then((response) => {
          let result = response?.data?.message;
          let hash = response?.data?.contract_id;
          if (result === 'Successfully Created the Iced contract') {
            updatedBalance();
            setBondHash(hash);
          } else {
            setLoading(false);
            setToast(result);
          }
        })
        .catch((error) => {
          console.log(error, 'buyStakingContract error');
        });
    }
  };

  const percentageCalc = (value) => {
    if (value == 50) {
      value = 0.5;
    } else {
      value = 1;
    }
    let balance = coinBalance?.coinValue;
    balance = value * balance;
    let contracts = balance / contractCost;
    if (contracts < 1) {
      contracts = 1;
      setcontractValue(contracts);
    } else {
      contracts = Math.floor(contracts);
      setcontractValue(contracts);
    }
  };

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  if (!email) {
    history.push('/');
  }

  return (
    <Layout active="market" className={classNames.layoutManagement} hideFooter>
      <div className={classNames.marketsPage}>
        <div className={classNames.pageWrap} ref={ref}>
          {toast && (
            <ToastMessage textOne={toast} onClose={() => setToast('')} />
          )}
          <div className={classNames.definavbar}>
            <div className={classNames.defiicon}>
              <img src={fulllogo} alt="" />
            </div>
            <div
              className={classNames.searchdefi}
              style={{ borderRadius: defiDropdown ? '5px 5px 0 0' : '' }}
            >
              {/* <div
                className={classNames.all}
                onClick={() => setDefiDropdown(!defiDropdown)}
              >
                <p>{defiSelectedDropdown}</p>
                <div
                  className={classNames.arroww}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={ddown}
                    alt=""
                    style={{ rotate: defiDropdown ? '180deg' : '' }}
                  />
                </div>
              </div> */}
              <div className={classNames.im}>
                <input
                  type="text"
                  placeholder="Ex. Bitcoin..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div
                  className={classNames.arroww}
                  style={{ cursor: 'pointer' }}
                >
                  <img src={searchIcon} alt="search" />
                </div>
              </div>
              <div
                className={classNames.dropDown}
                style={{
                  height: defiDropdown ? '' : '0',
                  overflow: defiDropdown ? '' : 'hidden',
                  display: defiDropdown ? '' : 'none',
                }}
              >
                <div className={classNames.eachSection}>
                  <div className={classNames.title}>Search By</div>
                  <div className={classNames.selectItems}>
                    <div
                      style={{
                        fontWeight:
                          defiSelectedDropdown == 'Asset' ? '900' : '',
                      }}
                      onClick={(event) =>
                        setDefiSelectedDropdown(event.target.innerText)
                      }
                    >
                      Asset
                    </div>
                    <div
                      style={{
                        fontWeight:
                          defiSelectedDropdown == 'Issuer' ? '900' : '',
                      }}
                      onClick={(event) =>
                        setDefiSelectedDropdown(event.target.innerText)
                      }
                    >
                      Issuer
                    </div>
                  </div>
                </div>
                <div className={classNames.eachSection}>
                  <div className={classNames.title}>Filter By</div>
                  <div className={classNames.selectItems}>
                    <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                      Contract Cost
                    </div>
                    <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                      Length
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.topValues}>
              <div className={classNames.eachTopValue}>
                <div className={classNames.font1}>Staking Rewards</div>
                <div className={classNames.font2}>$--</div>
              </div>
              <div className={classNames.eachTopValue} style={{ width: '31%' }}>
                <div className={classNames.font1}>MoneyMarket Earnings</div>
                <div className={classNames.font2}>$--</div>
              </div>
              <div className={classNames.eachTopValue}>
                <div className={classNames.font1}>Missed Rewards</div>
                <div className={classNames.font2}>$--</div>
              </div>
              <div className={classNames.monthlyTop}>
                <div>Monthly</div>
                <img src={ddGrey} alt="ddown" style={{ height: '7px' }} />
              </div>
            </div>
          </div>
          <div
            className={classNames.wspace}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div className={classNames.optionss} style={{ width: '60%' }}>
              {MARKET_ASSET_CLASSES.sort(GetSortOrder('marketOrder')).map(
                (assetClassCard, i) => (
                  <div
                    key={i}
                    className={`${classNames.assetClassCard} ${
                      classNames[assetClassCard === assetClass]
                    } ${classNames} ${classNames.eachoption}`}
                    onClick={() => {
                      if (assetClassCard.marketEnable) {
                        // console.log(assetClassCard.name, 'assetclass');
                        setDefiTab(assetClassCard.newName);
                        setAssetClass(assetClassCard);
                        setCoinSelected('');
                        history.push(`/market/${assetClassCard.name}`);
                      }
                    }}
                    style={{
                      borderBottom:
                        defiTab === assetClassCard.newName
                          ? '2px solid #137EFB'
                          : '',
                      fontWeight:
                        defiTab === assetClassCard.newName ? '600' : '',
                      opacity: assetClassCard.marketEnable ? '1' : '0.25',
                    }}
                  >
                    <div
                      style={{
                        cursor: assetClassCard.marketEnable
                          ? 'pointer'
                          : 'not-allowed',
                      }}
                    >
                      <p>{assetClassCard.newName}</p>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              className={classNames.explorer}
              onClick={() => {
                setDefiTab('');
                setCoinSelected('explorer');
                history.push(`/defi/explorer`);
              }}
            >
              Explorer
              <div
                style={{
                  display:
                    coinSelected == 'explorer' || coinSelected == 'defihash'
                      ? ''
                      : 'none',
                }}
              ></div>
            </div>
          </div>

          <div
            className={classNames.pageContent}
            style={{ position: 'relative' }}
          >
            {coinSelected == 'explorer' ? (
              <Bonds />
            ) : coinSelected == 'defihash' ? (
              <BondOverview />
            ) : coinSelected?.coinSymbol || coin ? (
              <MarketAssetDetail
                coin={coinSelected}
                coinSymbol={coin}
                onClose={() => {
                  setCoinSelected();
                  history.push(`/market/${assetClass.name}`);
                }}
                assetClass={assetClass}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClass?.name == 'stakingVaults' ? (
              selectTable()
            ) : assetClass?.name == 'myContracts' ? (
              <div
                className="list-component"
                style={{ padding: '2rem 0 0', width: '100%' }}
              >
                <Investments />
                {selectTable()}
              </div>
            ) : (
              <div className={classNames.tableArea}>
                <div className={classNames.topBlank}>
                  <div className={classNames.cardList}>
                    <div className={classNames.card}>
                      <img src={banner1} alt="banner1" />
                    </div>
                    <div className={classNames.card}>
                      <img src={banner2} alt="banner1" />
                    </div>
                    <div className={classNames.card}>
                      <img src={banner3} alt="banner1" />
                    </div>
                    <div className={classNames.card}>
                      <img src={banner4} alt="banner1" />
                    </div>
                  </div>
                </div>
                {selectTable()}
              </div>
            )}
            {buyContract && buyContractStep === 'step1' && (
              <div className={classNames.buyContractSidebar}>
                <div className={classNames.headerCont}>
                  <div className={classNames.headerTxt}>
                    Buy Staking Contract
                  </div>
                  <div className={classNames.subbTxtbox}>
                    <div className={classNames.divv}>
                      <img
                        src={coinBalance?.coinImage}
                        alt=""
                        style={{ width: '25px' }}
                      />
                      <div>{coinBalance?.coinSymbol} Balance</div>
                    </div>
                    <div className={classNames.divv1}>
                      {coinBalance?.coinValue}
                    </div>
                  </div>
                </div>
                <div className={classNames.percentageDiv}>
                  <div className={classNames.perTxt}>
                    Enter Percentage Of Balance
                  </div>
                  <div className={classNames.perBoxes}>
                    <div
                      className={classNames.box}
                      onClick={() => {
                        percentageCalc(50);
                        setClicked('50');
                      }}
                      style={{
                        background: clicked === '50' ? '#9FD6DF' : '#ffffff',
                        color: clicked === '50' ? '#ffffff' : '#18191d',
                      }}
                    >
                      50%
                    </div>
                    <div
                      className={classNames.box}
                      onClick={() => {
                        percentageCalc(100);
                        setClicked('100');
                      }}
                      style={{
                        background: clicked === '100' ? '#9FD6DF' : '#ffffff',
                        color: clicked === '100' ? '#ffffff' : '#18191d',
                      }}
                    >
                      100%
                    </div>
                    <div className={classNames.box}>
                      <input
                        type="number"
                        style={{
                          width: '100%',
                          height: '100%',
                          background: 'transparent',
                          border: 'none',
                          textAlign: 'center',
                        }}
                        placeholder="0.00%"
                        onChange={(event) => percentageCalc(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={classNames.orDiv}>
                  <div className={classNames.dash1}></div>
                  <div style={{ fontSize: '1.25rem', fontWeight: '600' }}>
                    OR
                  </div>
                  <div className={classNames.dash1}></div>
                </div>
                <div className={classNames.midCont}>
                  <div className={classNames.perTxt}>
                    How Many Contracts Do You Want To Buy?
                  </div>
                  <div className={classNames.subbTxtbox}>
                    <input
                      type="number"
                      placeholder="1"
                      value={contractValue}
                      onChange={(event) => {
                        if (event.target.value < 1) {
                          setcontractValue(1);
                        } else {
                          setcontractValue(event.target.value);
                        }
                      }}
                      style={{ width: '30%' }}
                    />
                    <div className={classNames.divv1}>
                      <input
                        type="number"
                        placeholder="0.01"
                        value={
                          selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]
                            ?.bondCost
                            ? contractValue *
                              selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]
                                ?.bondCost
                            : ''
                        }
                        style={{
                          textAlign: 'end',
                          fontWeight: '650',
                          fontSize: '1.25rem',
                        }}
                      />
                      <div style={{ fontWeight: '650', fontSize: '1.25rem' }}>
                        {selectedAsset?.custom_bonds?.[0]?.coinsData?.[0]?.coin}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames.buttonCont}
                  style={{ background: '#9FD6DF' }}
                  onClick={() => {
                    buyStakingContract();
                    setLoading(true);
                  }}
                >
                  Next Step
                </div>
              </div>
            )}
            {buyContract && buyContractStep === 'step2' && (
              <div className={classNames.buyContractSidebar}>
                <div className={classNames.headerCont}>
                  <div className={classNames.headerTxt}>
                    Purchase Successful
                  </div>
                  <div className={classNames.subbTxt}>
                    You Have Purchased {contractValue}{' '}
                    {updatedVaultBalance?.coinName} Staking Contracts
                  </div>
                </div>
                <div
                  className={classNames.midCont}
                  style={{ marginBottom: '17rem' }}
                >
                  <div style={{ fontWeight: '600', paddingBottom: '0.75rem' }}>
                    Updated {updatedVaultBalance?.coinSymbol} Vault Balance
                  </div>
                  <div className={classNames.amountValue}>
                    <div className={classNames.imgData}>
                      <img src={updatedVaultBalance?.coinImage} alt="" />{' '}
                      <span>{updatedVaultBalance?.coinSymbol}</span>
                    </div>
                    <input
                      type="number"
                      placeholder="0.0000"
                      value={updatedVaultBalance?.coinValue}
                    />
                  </div>
                </div>
                <div className={classNames.buttonCont1}>
                  <div
                    style={{ border: '1px solid #e7e7e7', color: '#18191d' }}
                  >
                    See All {updatedVaultBalance?.coinSymbol} Contracts
                  </div>
                  <div
                    style={{ background: '#9A4C97' }}
                    onClick={() => {
                      history.push(`/defi/explorer/${bondHash}`);
                    }}
                  >
                    See Contract Hash
                  </div>
                  <div style={{ background: '#9FD6DF' }}>
                    {updatedVaultBalance?.coinSymbol} Vault
                  </div>
                </div>
              </div>
            )}
            {buyContract && loading && (
              <div className={classNames.buyContractSidebar}>
                <div className="loadingIcon">
                  <LoadingAnimation icon={appLogo} width={200} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`${classNames.sidebarArea} ${
            classNames[Boolean(streamOpen)]
          }`}
          style={{ maxHeight: '100%' }}
        >
          <div
            className={classNames.sidebarContent}
            style={{ maxHeight: '100%' }}
          >
            {assetClassName === 'shares' && coin ? (
              <TerminalsSidebar
                shareToken={shareToken}
                streamOpen={streamOpen}
                setStreamOpen={setStreamOpen}
              />
            ) : assetClassName === 'bonds' &&
              selectedSidebarMenu === 'Bonds' ? (
              <BondContractDetails
                index={index}
                setIndex={setIndex}
                setSelectedSidebarMenu={setSelectedSidebarMenu}
              />
            ) : (
              <img
                className={classNames.fullLogo}
                src={SIDEBAR_FOOTERS[index].fullLogo}
                alt=""
              />
            )}
          </div>

          {(assetClassName === 'shares' && coin) ||
          (assetClassName === 'bonds' && selectedSidebarMenu === 'Bonds') ? (
            ''
          ) : (
            <div className={classNames.sidebarFooter}>
              {SIDEBAR_FOOTERS.map((menu, i) => (
                <div
                  className={`${classNames.footerMenu} ${
                    classNames[i === index]
                  } ${menu.disable && classNames.disable}`}
                  onClick={() => {
                    !menu.disable && setIndex(i);
                    setSelectedSidebarMenu(menu.label);
                  }}
                >
                  <img src={menu.icon} alt="" />
                  <span>{menu.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {loginModalOpen && (
          <LoginModal
            onClose={() => setLoginModalOpen(false)}
            onSuccess={() => setLoginModalOpen(false)}
          />
        )}
      </div>
    </Layout>
  );
}

export default MarketsPage;
