import React, { useContext, useState, useEffect } from 'react';
import styles from "./sidebar.module.scss"
import { useAppContextDetails } from '../../context/AppContext';
import close from './close.svg';
import { BankContext } from '../../context/Context';
import axios from 'axios';

const UpdateUsername = ({setPopular, setSelectedStep}) => {
    const {
        email,
        token,
        username,
        name,
        profileImg,
        copyToClipboard,
        linkname,
        setLinkname,
      } = useContext(BankContext);
    const [newUserName, setnewUserName] = useState('');
    const [newUserNameSidebar, setnewUserNameSidebar] = useState(false);
    const [usernameExist, setusernameExist] = useState(false);
    const [updatingUsername, setupdatingUsername] = useState(false)

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
          // console.log('Search term:', newUserName);
          if (newUserName) {
            usernameExistCheck(newUserName);
          } else {
            setusernameExist(false);
          }
          // Perform search or any other action with the debounced value of newUserName
        }, 50);
    
        return () => {
          clearTimeout(debounceTimer);
        };
      }, [newUserName]);
    
     
    
      function usernameExistCheck(username) {
        setusernameExist(false);
        axios
          .get(
            `https://comms.globalxchange.io/user/profile/data/get?username=${username}`
          )
          .then((response) => {
            if (!response?.data?.status || !response?.data?.count) {
              setusernameExist(true);
            } else {
              setusernameExist(false);
            }
            // console.log('username exist', response);
          })
          .catch((error) => {
            console.log('error checking username', error?.message);
          });
      }


  function usernameUpdate() {
    setupdatingUsername(true);
    axios
      .post('https://comms.globalxchange.io/user/details/update', {
        email: email,
        token: token,
        username: newUserName,
      })
      .then((response) => {
        // console.log(response, 'updated username');
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log('error checking username', error?.message);
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      });
  }

    return ( 
        <>
        <div className={styles.headTxt}>
                <div>Update Username</div>
                <div
                onClick={() => {
                    setPopular(false);
                    setSelectedStep(null);
                }}
                className="close-div"
                >
                <img src={close} alt="" />
                </div>
        </div>
        <div
                className={styles.editUsername}
            //   style={{ right: newUserNameSidebar ? '' : '-150%' }}
            >
        
                <div className={styles.otherFields}>
                <div>Current Username</div>
                <div>
                    <input
                    value={linkname ? linkname : ''}
                    style={{ pointerEvents: 'none' }}
                    />
                </div>
                </div>
                <div className={styles.otherFields}>
                <div>New Username</div>
                <div>
                    <input
                    placeholder="Enter Username"
                    type="text"
                    value={newUserName}
                    onChange={e => setnewUserName(e.target.value)}
                    />
                    <div
                    className={styles.usernameIndicator}
                    style={{ background: usernameExist ? '#57D6A1' : '' }}
                    ></div>
                </div>
                </div>
                <div className={styles.footer}>
                    <div
                    className={styles.submitBtn}
                    style={{
                        pointerEvents: usernameExist ? '' : 'none',
                        opacity: usernameExist ? '' : '0.4',
                        width:"100%"
                    }}
                    onClick={usernameUpdate}
                    >
                    Confirm Update
                    </div>
                </div>
        </div>
        </>
     );
}
 
export default UpdateUsername;