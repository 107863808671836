import React, { useEffect, useState, useContext } from 'react';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { buyProduct } from '../../pages/Malls/apidata';
import { useHistory } from 'react-router-dom';

const Step4 = () => {
  const [isloading, setisloading] = useState(true);
  const [isfinalstep, setIsfinalstep] = useState(false);
  const {
    mallCoindata,
    setMallCoinData,
    setBuy,
    mallselectedCoin,
    setMallSeclectedCoin,
    appLogo,
    index,
    setIndex,
  } = useAppContextDetails();
  const { email, profileId, token } = useContext(BankContext);
  const history = useHistory();

  useEffect(() => {
    let product = localStorage.getItem('retailerProduct');
    setIndex(JSON.parse(product));

    let obj = {
      email,
      token,
      product_id: index?.product_id,
      billing_method: 'monthly',
      pay_with_coin: mallselectedCoin?.coinSymbol,
      app_code: 'manifest',
      profile_id: profileId,
    };
    buyProduct(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        setIsfinalstep(true);
        setisloading(false);
      }
      //   setisloading(false);
    });
  }, []);

  return (
    <>
      {isloading ? (
        <div className="loader-anim">
          <LoadingAnimation icon={appLogo} width={200} />
          <div className="bottom-textt">Buying {index?.product_name}</div>
        </div>
      ) : (
        <>
          <div className="success-text">
            You Have Successfully Subscribed To {index?.product_name}
          </div>
          <div
            className="boxx"
            onClick={() => {
              history.push('/malls');
              setBuy('step1');
            }}
          >
            Continue Shopping
          </div>
          <div className="boxx" style={{ marginTop: '1.5rem' }}>
            See Subscriptions
          </div>
        </>
      )}
    </>
  );
};

export default Step4;
