import React, { useState, useContext, useEffect } from 'react';
import Layout from '../../Layout/Layout';
import './academy.scss';
import img1 from '../../static/images/malls/ham.svg';
import fulllogo from '../../static/images/sidebar-icons/trainingIcon.svg';
import ddown from '../../static/images/malls/ddown.svg';
import search from '../../static/images/malls/search.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Skeleton from 'react-loading-skeleton';
import Customers from './Customers';
import EducationContainer from '../../components/React_Education';
import Learn, {
  DetailedCoursePage,
  DetailedSectionsPage,
} from '../../components/Learn';

const AcademyPage = () => {
  const { academytab, setAcademytab } = useAppContextDetails();
  const [currentSubPage, setCurrentSubPage] = useState('main');

  return (
    <Layout active="learn" className="academy" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="academy-navbar">
          <div className="academy-icon">
            <img src={fulllogo} alt="" />
            Learn
          </div>
          <div className="search-academy">
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div className="academy-btn">Ask AI</div>
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '100%' }}>
            <div
              className={`each-option ${
                academytab === 'Overview' ? 'each-option-selected' : ''
              }`}
              onClick={() => setAcademytab('Overview')}
            >
              <div>
                <p>Overview</p>
              </div>
            </div>
            <div
              className={`each-option ${
                academytab === 'Library' ? 'each-option-selected' : ''
              }`}
              onClick={() => setAcademytab('Library')}
              style={{ pointerEvents: 'none' }}
            >
              <div>
                <p>Library</p>
              </div>
            </div>
            <div
              className={`each-option ${
                academytab === '1On1' ? 'each-option-selected' : ''
              }`}
              onClick={() => setAcademytab('1On1')}
              style={{ pointerEvents: 'none' }}
            >
              <div>
                <p>1 On 1</p>
              </div>
            </div>
          </div>
        </div>
        {currentSubPage == 'main' ? (
          <Learn setCurrentSubPage={setCurrentSubPage} />
        ) : currentSubPage == 'singlecoursepage' ? (
          <DetailedCoursePage setCurrentSubPage={setCurrentSubPage} />
        ) : // <MallProduct />
        currentSubPage == 'eachsection' ? (
          <DetailedSectionsPage setCurrentSubPage={setCurrentSubPage} />
        ) : (
          ''
        )}
      </div>
    </Layout>
  );
};

export default AcademyPage;
