import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import '../AffiliatePage/affiliate.scss';
import {
  affiliateBalances,
  affiliateId,
  affiliateStats,
  allUsers,
  levelBasedData,
  uplineData,
} from '../AffiliatePage/api';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { useHistory } from 'react-router-dom';
import AffiliateBank from '../AffiliatePage/AffiliateBank';
import Withdraw from '../AffiliatePage/Withdraw';

import search from '../../static/images/malls/search.svg';
import fulllogo from '../../static/images/affiliateBank/fullLogo.svg';
import guest from '../../static/images/affiliate/userlogo.svg';
import ablogo from '../../static/images/affiliate/ablogo.svg';
import axios from 'axios';
import SidebarComponent from '../../Layout/SidebarComponent';

const AffiliateBankPage = () => {
  const {
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    allaffiliatedata,
    setAllaffiliatedata,
    chainuserData,
    setchainUserData,
    appColorCode,
    affStats,
    setAffStats,
    appName,
    upline,
    setUpline,
    invite,
    setInvite,
    setuserfilteredAff,
    iswithdraw,
    setIswithdraw,
    ddselected,
    setDdSelected,
    exchangeData,
    setExchangeData,
    MMData,
    setMMData,
    stakingData,
    setStakingData,
    filteredExchangedata,
    setFilteredExchangeData,
    filteredMMData,
    setFilteredMMData,
    filteredProductsABData,
    setFilteredProductsABData,
    productsABData,
    filteredStakingData,
    setFilteredStakingdata,

    //update user name
    newUserNameSidebar,
    setnewUserNameSidebar,
    newUserName,
    setnewUserName,
    usernameExist,
    setusernameExist,  popular,
    setPopular,
  } = useAppContextDetails();

  const {
    email,
    token,
    username,
    name,
    profileImg,
    copyToClipboard,
    linkname,
    setLinkname,
  } = useContext(BankContext);

  if (!email) {
    history.push('/');
  }

  const history = useHistory();
  const [abTab, setAbTab] = useState('Ledger');

  useEffect(() => {
    let mailId = email;

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      //   console.log(result, 'balance');
      setBalances(result);
    });

    uplineData(mailId).then((response) => {
      let result = response?.data?.uplines?.[0];
      setUpline(result);
      let username = response?.data?.user?.username;
      setLinkname(username);
    });
  }, [email]);

  function filterItem(text) {
    if (ddselected === 'Exchange Fees') {
      let filterItem = exchangeData?.filter((eachitem) => {
        return eachitem?.txn?.email
          ?.toLowerCase()
          ?.includes(text?.toLowerCase());
      });
      setFilteredExchangeData(filterItem);
    } else if (ddselected === 'Staking') {
      let filterItem = stakingData?.filter((eachitem) => {
        return eachitem?.txn?.email
          ?.toLowerCase()
          ?.includes(text?.toLowerCase());
      });
      setFilteredStakingdata(filterItem);
    } else if (ddselected === 'Money Markets') {
      let filterItem = MMData?.filter((eachitem) => {
        return eachitem?.txn?.email
          ?.toLowerCase()
          ?.includes(text?.toLowerCase());
      });
      setFilteredMMData(filterItem);
    } else if (ddselected === 'Products') {
      let filterItem = productsABData?.filter((eachitem) => {
        return eachitem?.txn?.email
          ?.toLowerCase()
          ?.includes(text?.toLowerCase());
      });
      setFilteredProductsABData(filterItem);
    }
  }

  const [loggedInUserBalance, setLoggedInUserBalance] = useState(null)

  useEffect(() => {
    axios.get(`https://comms.globalxchange.io/client/tree/get/vault/balance/${email}`).then(({data}) => {
      if(data.status){
        setLoggedInUserBalance(data.data[0].amount)
      }
    })
  }, [])

  return (
    <Layout active="AffiliateBank" className="affiliate" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="affiliate-navbar">
          <div
            style={{
              height: '100%',
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            <div className="affiliate-icon" style={{ width: '25%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-affiliate" style={{ width: '70%' }}>
              <div className="im">
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  onChange={(event) => filterItem(event.target.value)}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {loggedInUserBalance
                  ? loggedInUserBalance?.toFixed(2)
                  : '0.00'}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-btn"
            onClick={() => {
              setPopular(true);
            }}
            style={{ background: '#7899CF' }}
          >
            Actions
          </div>
        </div>
        <div className="w-space">
          <div className="optionsss" style={{ width: '10%' }}>
            <div
              className={`each-option ${
                abTab === 'Ledger' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: abTab === 'Ledger' ? '600' : '',
              }}
              onClick={() => setAbTab('Ledger')}
            >
              <div>
                <p>Ledger</p>
              </div>
            </div>
          </div>
        </div>
        <AffiliateBank loggedInUserBalance={loggedInUserBalance}/>
        {/* <div
          className="adduserSidebar"
          style={{ right: iswithdraw ? '0' : '-100%' }}
        >
          <Withdraw />
        </div> */}
        {popular && (
          <div className="popular">
            <SidebarComponent/>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AffiliateBankPage;
