import React, { useContext, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import CountUp from 'react-countup';

import defiLogo from '../../static/images/logoIcons/defi.svg';
import calculator from '../../static/images/clipIcons/calculator.svg';
import bank from '../../static/images/clipIcons/bank.svg';
import allApp from '../../static/images/appIcons/allApp.svg';
import searchIcon from '../../static/images/search.svg';
import { NetWorthContext } from '../../context/NetWorthContext';
import { BankContext } from '../../context/Context';
import EarningsController from './EarningsController';
import TierComparison from './TierComparison';
import Axios from 'axios';
import { useEffect } from 'react';
import { FormatCurrency, FormatNumber } from '../../utils/FunctionTools';
import { ChatContext } from '../../context/ChatContext';
import { DefiContext } from '../../context/DefiContext';
import SmartProspectus from './SmartProspectus';
import GenarateQuote from './GenarateQuote';
import HexaNumber from '../HexaNumber/HexaNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

function TrustComponent({ setShowTrust, openReqModal, basicData }) {
  const [searchEnable, setSearchEnable] = useState(false);
  const { userApps } = useContext(NetWorthContext);
  const { email, setChatOn, defaultPrecission } = useContext(BankContext);
  const { setTrustDepositOpen } = useContext(ChatContext);
  const { trustCoinListObject } = useContext(DefiContext);
  const [searchStr, setSearchStr] = useState('');
  const [appSelected, setAppSelected] = useState({
    app_code: 'all',
    app_name: 'All Apps',
    app_icon: allApp,
  });
  const [earningsControllerOpen, setEarningsControllerOpen] = useState(false);
  const [tierComparisonOpen, setTierComparisonOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState('slot0');
  const [currentSlot, setCurrentSlot] = useState('slot1');
  const [tierSlot, setTierSlot] = useState('slot0');
  const [slotIndex, setSlotIndex] = useState(0);
  const [slots, setSlots] = useState([]);
  const [tiObject, setTiObject] = useState(false);
  const [genarateOpen, setGenarateOpen] = useState(false);
  const [coinSearch, setCoinSearch] = useState(false);
  const [coinSearchStr, setCoinSearchStr] = useState('');

  useEffect(() => {
    Axios.get(
      'https://comms.globalxchange.io/coin/iced/get/liquid/interest?coin=BTC'
    ).then(({ data }) => {
      if (data.status) {
        setSlots([
          {
            name: 'slot0',
            amount: 0.0,
          },
          ...data?.interest_rates[0]?.slots,
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (trustCoinListObject && slots) {
      let btcBalance = trustCoinListObject?.BTC?.coinValue;
      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        if (
          i === slots.length - 1 ||
          (btcBalance >= slot.amount && btcBalance < slots[i + 1]?.amount)
        ) {
          // setSelectedSlot(slot.name);
          setCurrentSlot(slot.name);
          // setSlotIndex(i);
          break;
        }
      }
    }
  }, [trustCoinListObject, slots]);
  const [appData, setAppData] = useState({});
  const [simulteRes, setSimulteRes] = useState({});
  useEffect(() => {
    if (selectedSlot && email) {
      Axios.post(
        'https://comms.globalxchange.io/coin/iced/liquid/icetray/interest/simulator',
        {
          coin: 'BTC',
          slotName: selectedSlot,
          email: email,
        }
      ).then(({ data }) => {
        if (data.status) {
          setSimulteRes(data);
          let appsData = {};
          appsData.all = {
            app_code: 'all',
            apptotalUSD: data.totalUSDValue,
            interestValues: data.allAppsInterestValues,
            apptotalInterest: data.totalUSDInterest,
            apptotalInterest_percentage: data.totalUSDInterest_percentage,
            base_apptotalInterest_percentage:
              data.base_totalUSDInterest_percentage,
            base_apptotalInterest: data.base_totalUSDInterest,
          };
          data.appInterestValues.forEach((app) => {
            appsData[app.app_code] = app;
          });
          setAppData(appsData);
        }
      });
    }
  }, [selectedSlot, email]);
  const [tierData, setTierData] = useState({});
  useEffect(() => {
    if (tierSlot && email) {
      Axios.post(
        'https://comms.globalxchange.io/coin/iced/liquid/icetray/interest/simulator',
        {
          coin: 'BTC',
          slotName: tierSlot,
          email: email,
        }
      ).then(({ data }) => {
        if (data.status) {
          let appsData = {};
          appsData.all = {
            app_code: 'all',
            apptotalUSD: data.totalUSDValue,
            interestValues: data.allAppsInterestValues,
            apptotalInterest: data.totalUSDInterest,
            apptotalInterest_percentage: data.totalUSDInterest_percentage,
            base_apptotalInterest_percentage:
              data.base_totalUSDInterest_percentage,
            base_apptotalInterest: data.base_totalUSDInterest,
          };
          data.appInterestValues.forEach((app) => {
            appsData[app.app_code] = app;
          });
          setTierData(appsData);
        }
      });
    }
  }, [tierSlot, email]);

  const vsaApp = userApps?.filter((app) => app.app_code === 'VSA')[0];
  const brokerApp = userApps?.filter((app) => app.app_code === 'manifest')[0];
  const gxliveApp = userApps?.filter((app) => app.app_code === 'gxlive')[0];

  const [nextTier, setNextTier] = useState(0);
  useEffect(() => {
    basicData?.nextSlot &&
      Axios.post(
        'https://comms.globalxchange.io/coin/iced/liquid/icetray/interest/simulator',
        {
          coin: 'BTC',
          slotName: basicData?.nextSlot,
          email: email,
        }
      ).then(({ data }) => {
        setNextTier(data?.totalUSDInterest || 0);
      });
  }, [basicData?.nextSlot]);

  const { updateInterval } = useContext(BankContext);
  const [duration, setDuration] = useState(2);
  const togleDuration = (duration) => {
    setDuration(duration === 2 ? 2.1 : 2);
  };

  return (
    <>
      <div className="trustDetailWrapper">
        <div className="trustDetail">
          <div className="headProfile">
            <img
              src={defiLogo}
              alt=""
              className="dp"
              onClick={() => setShowTrust(false)}
            />
            <div className="texts">
              <div className="headMain">
                <span>EFI TRUST</span>
                <div className="calculator">
                  <img
                    onClick={() => setGenarateOpen(!genarateOpen)}
                    src={calculator}
                    alt=""
                  />
                  <div className="toolTipCalculator">
                    Simulate Custom Balance
                  </div>
                </div>
                <div className="calculator ml-4">
                  <img onClick={() => openReqModal()} src={bank} alt="" />
                  <div className="toolTipCalculator">
                    Trust Reserve Requirements
                  </div>
                </div>
              </div>
              <div className="headSub">
                <span>Viewing: {selectedSlot?.replace('slot', 'Tier ')}</span>
              </div>
            </div>
            <div className="buttonsGroup">
              <div className="btcValue">
                <span className="head">Trust Reserve</span>
                <span className="val">
                  <span className="native">
                    {FormatCurrency(
                      trustCoinListObject?.BTC?.coinValue,
                      'BTC',
                      defaultPrecission
                    )}{' '}
                    BTC
                  </span>
                  <span className="usd">
                    $
                    {FormatCurrency(
                      basicData?.usd_value,
                      'USD',
                      defaultPrecission
                    )}{' '}
                    USD
                  </span>
                </span>
              </div>
              <div
                className="btnAction"
                onClick={() => {
                  setTrustDepositOpen(true);
                  setChatOn(true);
                }}
              >
                <span className="head">Capitalize</span>
                <span className="val">
                  {basicData?.slot?.replace('slot', 'Tier ')}
                </span>
              </div>
              {/* <div className="btnAction">Withdraw</div> */}
            </div>
          </div>
          <div className="blanceNappSwitcher">
            <div className="allAppBalance">
              <div className="allAppText">
                {appSelected?.app_code === 'all'
                  ? 'Total'
                  : appSelected.app_name}{' '}
                Liquid Assets
              </div>
              <div className="balanceAmt">
                $
                <CountUp
                  onEnd={() => {
                    if (updateInterval)
                      setTimeout(() => {
                        togleDuration(duration);
                      }, updateInterval * 1000);
                  }}
                  duration={duration}
                  start={0}
                  end={appData?.[appSelected.app_code]?.apptotalUSD || 0}
                  decimals={2}
                />{' '}
                USD
              </div>
              <div className="toolTipBox">
                <div className="title">App Level Net-Worth</div>
                <p className="desc">
                  The Sum Of All The Balances In BrokerApp Denominated In USD
                </p>
              </div>
            </div>
            <div className="appsGroup">
              <div className="appItm">
                <img src={appSelected.app_icon} alt="" />
              </div>
              {appSelected.app_code !== 'all' && (
                <div
                  className="appItm"
                  onClick={() => {
                    setAppSelected({
                      app_code: 'all',
                      app_name: 'All Apps',
                      app_icon: allApp,
                    });
                  }}
                >
                  <img src={allApp} alt="" />
                </div>
              )}
              {vsaApp && appSelected !== vsaApp && (
                <div className="appItm" onClick={() => setAppSelected(vsaApp)}>
                  <img src={vsaApp?.app_icon} alt="" />
                </div>
              )}
              {gxliveApp && appSelected !== gxliveApp && (
                <div
                  className="appItm"
                  onClick={() => setAppSelected(gxliveApp)}
                >
                  <img src={gxliveApp.app_icon} alt="" />
                </div>
              )}
              {brokerApp && appSelected !== brokerApp && (
                <div
                  className="appItm"
                  onClick={() => setAppSelected(brokerApp)}
                >
                  <img src={brokerApp.app_icon} alt="" />
                </div>
              )}
              <div className={`search-wrapper order-0  ${searchEnable}`}>
                <div className="serch-n-result">
                  {searchEnable ? (
                    <>
                      <div className="search">
                        <input
                          type="text"
                          value={searchStr}
                          onChange={(e) => setSearchStr(e.target.value)}
                          placeholder="Search Apps"
                        />
                        <span
                          className="serch-close"
                          onClick={() => {
                            setSearchEnable(!searchEnable);
                            setSearchStr('');
                          }}
                        >
                          ×
                        </span>
                      </div>
                      <div
                        className="search-res"
                        key={'0'}
                        onClick={() => {
                          setSearchEnable(!searchEnable);
                          setAppSelected({
                            app_code: 'all',
                            app_name: 'All Apps',
                            app_icon: allApp,
                          });
                        }}
                      >
                        <img src={allApp} alt="" className="search-coin" />
                        <div className="coin">All Apps</div>
                      </div>
                      {userApps
                        .filter((app) =>
                          app.app_name
                            .toLowerCase()
                            .includes(searchStr.toLowerCase())
                        )
                        .map((app) => (
                          <div
                            className="search-res"
                            key={app.app_code}
                            onClick={() => {
                              setSearchEnable(!searchEnable);
                              setAppSelected(app);
                            }}
                          >
                            <img
                              src={app.app_icon}
                              alt=""
                              className="search-coin"
                            />
                            <div className="coin">{app.app_name}</div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <img
                      className="search-icon"
                      src={searchIcon}
                      alt=""
                      onClick={() => {
                        setSearchEnable(!searchEnable);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {earningsControllerOpen ? (
            <EarningsController
              onSave={() => {
                setEarningsControllerOpen(false);
              }}
            />
          ) : tierComparisonOpen ? (
            <TierComparison
              selectedSlot={tierSlot}
              currentSlot={currentSlot}
              slots={slots}
              setSelectedSlot={setTierSlot}
              onSave={() => {
                setTierComparisonOpen(false);
              }}
            />
          ) : (
            <>
              <div className="earningTierBox">
                <div
                  className="label earnings"
                  onClick={() => {
                    // setEarningsControllerOpen(!earningsControllerOpen)
                  }}
                >
                  <span>Total Daily Earnings</span>
                </div>
                <div className="valuesPercent">
                  <span className="percent">
                    <CountUp
                      duration={duration}
                      start={0}
                      end={
                        appData?.[appSelected.app_code]
                          ?.apptotalInterest_percentage || 0
                      }
                      decimals={3}
                    />
                    %
                    <div className="toolTipBox">
                      <div className="title">Daily Trust Rate (AVG)</div>
                      <p className="desc">
                        Daily Interest Rate Averaged Over All The Assets In
                        BrokerApp If Your Trust Is In Tier 1
                      </p>
                    </div>
                  </span>
                  <span className="amount">
                    $
                    <CountUp
                      duration={duration}
                      start={0}
                      end={
                        appData?.[appSelected.app_code]?.apptotalInterest || 0
                      }
                      decimals={2}
                    />
                    <div className="toolTipBox">
                      <div className="title">Gross Daily Earnings (In USD)</div>
                      <p className="desc">
                        Total Daily Earnings Over All The Assets In BrokerApp
                        Denominated In USD If Your Trust Is In Tier 1
                      </p>
                    </div>
                  </span>
                </div>
                <div
                  className="label tier"
                  onClick={() => setTierComparisonOpen(!tierComparisonOpen)}
                >
                  <span>{tierSlot.replace('slot', 'Tier ')}</span>
                </div>
                <div className="valuesPercent">
                  <span className="percent">
                    <CountUp
                      duration={duration}
                      start={0}
                      end={
                        tierData?.[appSelected.app_code]
                          ?.apptotalInterest_percentage || 0
                      }
                      decimals={3}
                    />
                    %
                  </span>
                  <span className="amount">
                    $
                    <CountUp
                      duration={duration}
                      start={0}
                      end={
                        tierData?.[appSelected.app_code]?.apptotalInterest || 0
                      }
                      decimals={2}
                    />
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="tableHead">
            <div className="asset">Asset</div>
            <div className="holding">
              Holdings
              <div className="toolTipCalculator">Respective Vault Balance</div>
            </div>
            <div className="rate">
              DBS<div className="toolTipCalculator">Daily Base Rate</div>
            </div>
            <div className="rate">
              DTR<div className="toolTipCalculator">Daily Trust Rate</div>
            </div>
            <div className="payout">
              Payout
              <div className="toolTipCalculator">Gross Daily Earnings</div>
            </div>
            <FontAwesomeIcon
              icon={coinSearch ? faTimes : faSearch}
              className="searchIcon"
              onClick={() => {
                setCoinSearchStr('');
                setCoinSearch(!coinSearch);
              }}
            />
            {coinSearch && (
              <input
                type="text"
                className="searchBar"
                value={coinSearchStr}
                onChange={(e) => setCoinSearchStr(e.target.value)}
              />
            )}
          </div>
          <Scrollbars
            className="coinListWrapper"
            renderView={(props) => <div {...props} className="coinList" />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            {appData[appSelected.app_code]?.interestValues
              .filter(
                (intrestValue) =>
                  intrestValue?.coin
                    .toLowerCase()
                    .includes(coinSearchStr.toLocaleLowerCase()) ||
                  trustCoinListObject[intrestValue?.coin]?.coinName
                    .toLowerCase()
                    .includes(coinSearchStr.toLocaleLowerCase())
              )
              .map((intrestValue) => (
                <div
                  className="coinItem"
                  onClick={() => setTiObject(intrestValue)}
                >
                  <div className="coin">
                    <img
                      src={trustCoinListObject[intrestValue?.coin]?.coinImage}
                      alt=""
                      className="logo"
                    />
                    {trustCoinListObject[intrestValue?.coin]?.coinName}
                  </div>
                  <div className="balance">
                    <CountUp
                      duration={duration}
                      start={0}
                      end={intrestValue.amount || 0}
                      decimals={
                        intrestValue.coin === 'BTC' ||
                        intrestValue.coin === 'ETH'
                          ? 4
                          : 2
                      }
                    />{' '}
                    {intrestValue.coin}
                  </div>
                  <div className="percent">
                    <CountUp
                      duration={duration}
                      start={0}
                      end={intrestValue.base_interest || 0}
                      decimals={4}
                    />
                    %
                  </div>
                  <div className="percent">
                    <CountUp
                      duration={duration}
                      start={0}
                      end={intrestValue.final_interest || 0}
                      decimals={4}
                    />
                    %
                  </div>
                  <div className="total">
                    <CountUp
                      duration={duration}
                      start={0}
                      end={intrestValue.interest_value || 0}
                      decimals={
                        intrestValue.coin === 'BTC' ||
                        intrestValue.coin === 'ETH'
                          ? 4
                          : 2
                      }
                    />{' '}
                    {intrestValue.coin}
                  </div>
                </div>
              ))}
          </Scrollbars>
        </div>
        <div className="sideLevels">
          <HexaNumber
            className="hexa"
            onClick={() => {
              setSlotIndex(
                slotIndex - 3 < 0 ? slotIndex - 3 + slots.length : slotIndex - 3
              );
              setSelectedSlot(
                slots[
                  slotIndex - 3 < 0
                    ? slotIndex - 3 + slots.length
                    : slotIndex - 3
                ].name
              );
            }}
            num={
              slotIndex - 3 < 0
                ? slots[slotIndex - 3 + slots.length]?.name?.replace('slot', '')
                : slots[slotIndex - 3]?.name?.replace('slot', '')
            }
          />{' '}
          <HexaNumber
            className="hexa "
            onClick={() => {
              setSlotIndex(
                slotIndex - 2 < 0 ? slotIndex - 2 + slots.length : slotIndex - 2
              );
              setSelectedSlot(
                slots[
                  slotIndex - 2 < 0
                    ? slotIndex - 2 + slots.length
                    : slotIndex - 2
                ].name
              );
            }}
            num={
              slotIndex - 2 < 0
                ? slots[slotIndex - 2 + slots.length]?.name?.replace('slot', '')
                : slots[slotIndex - 2]?.name?.replace('slot', '')
            }
          />
          <HexaNumber
            className="hexa "
            onClick={() => {
              setSlotIndex(
                slotIndex - 1 < 0 ? slotIndex - 1 + slots.length : slotIndex - 1
              );
              setSelectedSlot(
                slots[
                  slotIndex - 1 < 0
                    ? slotIndex - 1 + slots.length
                    : slotIndex - 1
                ].name
              );
            }}
            num={
              slotIndex - 1 < 0
                ? slots[slotIndex - 1 + slots.length]?.name?.replace('slot', '')
                : slots[slotIndex - 1]?.name?.replace('slot', '')
            }
          />
          <HexaNumber
            className="hexa true"
            onClick={() => {}}
            num={slots[slotIndex]?.name?.replace('slot', '')}
          />
          <HexaNumber
            className="hexa "
            onClick={() => {
              setSlotIndex(
                slotIndex + 1 > slots.length - 1
                  ? slotIndex + 1 - slots.length
                  : slotIndex + 1
              );
              setSelectedSlot(
                slots[
                  slotIndex + 1 > slots.length - 1
                    ? slotIndex + 1 - slots.length
                    : slotIndex + 1
                ].name
              );
            }}
            num={
              slotIndex + 1 > slots.length - 1
                ? slots[slotIndex + 1 - slots.length]?.name?.replace('slot', '')
                : slots[slotIndex + 1]?.name?.replace('slot', '')
            }
          />
          <HexaNumber
            className="hexa "
            onClick={() => {
              setSlotIndex(
                slotIndex + 2 > slots.length - 1
                  ? slotIndex + 2 - slots.length
                  : slotIndex + 2
              );
              setSelectedSlot(
                slots[
                  slotIndex + 2 > slots.length - 1
                    ? slotIndex + 2 - slots.length
                    : slotIndex + 2
                ].name
              );
            }}
            num={
              slotIndex + 2 > slots.length - 1
                ? slots[slotIndex + 2 - slots.length]?.name?.replace('slot', '')
                : slots[slotIndex + 2]?.name?.replace('slot', '')
            }
          />
          <HexaNumber
            className="hexa "
            onClick={() => {
              setSlotIndex(
                slotIndex + 3 > slots.length - 1
                  ? slotIndex + 3 - slots.length
                  : slotIndex + 3
              );
              setSelectedSlot(
                slots[
                  slotIndex + 3 > slots.length - 1
                    ? slotIndex + 3 - slots.length
                    : slotIndex + 3
                ].name
              );
            }}
            num={
              slotIndex + 3 > slots.length - 1
                ? slots[slotIndex + 3 - slots.length]?.name?.replace('slot', '')
                : slots[slotIndex + 3]?.name?.replace('slot', '')
            }
          />
        </div>
        {tiObject && (
          <SmartProspectus
            setOpenModal={setTiObject}
            tiObject={tiObject}
            trustBalance={trustCoinListObject?.BTC?.coinValue}
            simulteRes={simulteRes}
            appSelected={appSelected}
          />
        )}
        {genarateOpen && (
          <GenarateQuote
            selectedSlot={selectedSlot}
            setOpenModal={setGenarateOpen}
            trustBalance={trustCoinListObject?.BTC?.coinValue}
            simulteRes={simulteRes}
            slots={slots}
          />
        )}
      </div>
      <div className="footer-main trust-footer">
        Add The{' '}
        {FormatCurrency(
          basicData?.nextSlotAmount - basicData?.balance,
          basicData?.coin,
          defaultPrecission
        )}{' '}
        {basicData?.coin} To Get To{' '}
        {basicData?.nextSlot?.replace('slot', 'Tier ')}. You Would Have Earned{' '}
        {FormatCurrency(nextTier)} Today
      </div>
    </>
  );
}

export default TrustComponent;
