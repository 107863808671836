import React, { useState, useEffect, useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import styles from './sidebar.module.scss';
import './affiliate.scss';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useUserDetails } from '../../queryHooks';
import AddUserToTank from './AddUserToTank';
import AddUser from './AddUser';
import UpdateUsername from './UpdateUsername';
import WithdrawCommision from './WithdrawCommision';
// import UpdateUsername from './UpdateUsername';
import close from './close.svg';
import Invite from '../../pages/AffiliatePage/Invite';
import PreRegister from './PreRegister';

const SidebarComponent = () => {
  const {
    email,
    token,
    username,
    name,
    profileImg,
    copyToClipboard,
    linkname,
    setLinkname,
  } = useContext(BankContext);
  const { popular, setPopular } = useAppContextDetails();
  const { data: userData } = useUserDetails(email);

  const [selectedStep, setSelectedStep] = useState(null);
  const [addUserEmail, setAddUserEmail] = useState('');
  const [holdingTank, setHoldingTank] = useState([]);
  const [holdingTankLoading, setHoldingTankLoading] = useState(false);

  const [addUserName, setAddUserName] = useState('');
  const [addPhoneNo, setAddPhoneNo] = useState('');
  const [addTempUName, setAddTempUName] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [affId, setAffId] = useState();

  const [updatingUsername, setupdatingUsername] = useState(false);

  const [allSubs, setAllSubs] = useState([]);

  const getAllSubsForOneUser = () => {
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/manifest/order?email=${email}`
      )
      .then(({ data }) => {
        if (data.status) {
          setAllSubs(data?.data);
        }
      });
  };

  useEffect(() => {
    if (email) {
      getAllSubsForOneUser();
    }
  }, [email]);

  const handleCancelSub = (id) => {
    axios
      .post(`https://comms.globalxchange.io/client/stripe/deleteSubcription`, {
        email: email,
        client_app: 'manifest',
        subscriptionId: id,
      })
      .then((res) => {
        console.log(res, 'kjwefkjwbfkwjbf');
        getAllSubsForOneUser();
      });
  };

  const conditionalData = () => {
    if (selectedStep === 'Add user to holding tank') {
      return (
        <AddUserToTank
          setPopular={setPopular}
          setSelectedStep={setSelectedStep}
        />
      );
    } else if (selectedStep === 'Add new user') {
      return (
        <AddUser setPopular={setPopular} setSelectedStep={setSelectedStep} />
      );
    } else if (selectedStep === 'Update Username') {
      return (
        <UpdateUsername
          setPopular={setPopular}
          setSelectedStep={setSelectedStep}
        />
      );
    } else if (selectedStep === 'Withdraw Commissions') {
      return (
        <WithdrawCommision
          setPopular={setPopular}
          setSelectedStep={setSelectedStep}
        />
      );
    } else if (selectedStep === 'Pre Register With A Gift') {
      return (
        <>
          <PreRegister
            setPopular={setPopular}
            setSelectedStep={setSelectedStep}
          />
          {/* <Invite/> */}
        </>
        // <WithdrawCommision setPopular={setPopular} setSelectedStep={setSelectedStep}/>
      );
    } else if (selectedStep === 'Add user Success') {
      return (
        <div>
          <div className={styles.successMsg}>
            You have successfully added a new user to your holding tank
          </div>
          <div className={styles.footer}>
            <div
              className={styles.submitBtn}
              style={{ width: '100%' }}
              onClick={(e) => setPopular(!popular)}
            >
              See Updated Tree
            </div>
          </div>
        </div>
      );
    } else if (selectedStep === 'Add user Success1') {
      return (
        <div>
          <div className={styles.successMsg}>
            You have successfully added a new user
          </div>
          <div className={styles.footer}>
            <div
              className={styles.submitBtn}
              style={{ width: '100%' }}
              onClick={(e) => setPopular(!popular)}
            >
              See Updated Tree
            </div>
          </div>
        </div>
      );
    } else if (selectedStep === 'Cancel Subscription') {
      return (
        <div className="popular">
          <div className="head-txt">
            <div>Actions</div>
            <div
              onClick={() => {
                setSelectedStep(null);
              }}
              className="close-div"
            >
              <img src={close} alt="" />
            </div>
          </div>
          <div>All subscriptions</div>
          {allSubs?.map((item) => (
            <div
              className="each-action-user"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <div style={{ fontWeight: 700, paddingBottom: '7px' }}>
                  {item?.items?.data[0]?.plan?.amount}{' '}
                  {item?.items?.data[0]?.plan?.currency?.toUpperCase()} Per{' '}
                  {item?.items?.data[0]?.plan?.interval}
                </div>
                <div>
                  Created at{' '}
                  {new Date(
                    item?.items?.data[0]?.plan?.created
                  )?.toLocaleDateString()}
                </div>
              </div>
              <div
                className="cardBtn"
                onClick={(e) => handleCancelSub(item?.id)}
              >
                Cancel
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      {!selectedStep ? (
        <div>
          <div className={styles.headTxt}>
            <div>Popular Actions</div>
            <div
              onClick={() => {
                setPopular(false);
                setSelectedStep(null);
              }}
              className="close-div"
            >
              <img src={close} alt="" />
            </div>
          </div>
          <div
            className="each-action"
            style={{ marginTop: '2.75rem' }}
            onClick={() => {
              setSelectedStep('Add user to holding tank');
            }}
          >
            <div>Add user to holding tank</div>
          </div>
          <div
            className="each-action"
            style={{ marginBottom: '1rem' }}
            onClick={() => {
              setSelectedStep('Add new user');
            }}
          >
            <div>Add new user</div>
          </div>
          <div
            className="each-action"
            style={{ marginBottom: '1rem' }}
            onClick={() => {
              setSelectedStep('Withdraw Commissions');
            }}
          >
            <div>Withdraw Commissions</div>
          </div>
          <div
            className="each-action"
            style={{ marginBottom: '1rem' }}
            onClick={() => {
              setSelectedStep('Update Username');
            }}
          >
            <div>Update Username</div>
          </div>
          <div
            className="each-action"
            style={{ marginBottom: '1rem' }}
            onClick={() => {
              setSelectedStep('Cancel Subscription');
            }}
          >
            <div>Cancel Subscription</div>
          </div>
        </div>
      ) : (
        conditionalData()
      )}
    </>
  );
};

export default SidebarComponent;
