import React, { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import { useCoinContextData } from '../../context/CoinContext';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import { SelectFooters } from './SelectFooter';
import { SideBarMenu } from './SideBarMenu';
import aiprowalletAppLogo from '../../static/images/aiprowalletapplogo.svg';
import aiprowalletAppFullLogo from '../../static/images/aiprowalletappfulllogo.svg';
import viralVerseFullLogo from '../../static/images/logos/viralVerse.svg';
import viralVerse from '../../static/images/logos/vv.svg';
import settings from '../../static/images/icons/settings.svg';
import close from '../../static/images/icons/close.svg';
import TrackerSidebarMenu from '../../components/VaultsPage/VaultComponent/TrackerSidebarMenu';

function LayoutSidebar({
  active,
  chatOn,
  setChatOn,
  adminSidebar,
  setAdminSidebar,
}) {
  const history = useHistory();
  const {
    email,
    sidebarCollapse,
    setSidebarCollapse,
    closeSidebars,
    admin,
    trackerSidebarMenu,
  } = useContext(BankContext);
  const {
    coinActionEnabled,
    setCoinActionEnabled,
    coinAction,
    addBankingEnabled,
    setCoinAction,
    coinType,
    isCoinLoadingInAddAction,
    setAddBankingEnabled,
    isClose,
    setIsClose,
  } = useCoinContextData();

  const [openSettings, toggleSettings] = useState(false);
  const [toast, setToast] = useState(false);

  const { appName, appCode, appLogo, appFullLogo, appColorCode } =
    useAppContextDetails();

  const menuEndRef = useRef(null);

  const scrollToBottom = () => {
    if (menuEndRef && menuEndRef.current)
      menuEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (active.includes('transactions')) {
      scrollToBottom();
    }
  }, [active]);

  const togglingIcon = sidebarCollapse ? faAngleRight : faAngleLeft;

  useEffect(() => {
    if (toast) setTimeout(() => setToast(false), 2000);
  }, [toast]);

  const isSecondaryMenu =
    openSettings || coinActionEnabled || addBankingEnabled;

  return (
    <div
      className={`side-bar d-flex flex-column ${
        sidebarCollapse ? 'collapse' : 'expand'
      }`}
    >
      {email ? (
        <div
          className={`profile d-flex ${!adminSidebar && 'clickable'}`}
          id="correctid4"
          style={{
            visibility:
              coinAction[0] === 'Add' && coinType === 'fiat' ? 'hidden' : '',
          }}
        >
          <div
            style={{
              width: '100%',
              // padding: sidebarCollapse ? '' : '10px 10px 10px 1.85rem',
              display: 'flex',
              justifyContent: sidebarCollapse ? '' : 'space-between',
              alignItems: sidebarCollapse ? '' : 'center',
            }}
          >
            <img
              className="clickable"
              src={
                sidebarCollapse
                  ? 'https://publications.apimachine.com/images/d65be59a-6a7e-46c3-9421-a623ee8be513.png'
                  : 'https://publications.apimachine.com/images/874a0bb4-d349-4a63-b173-74cee33f5cb9.png'
              }
              onClick={() => {
                if (admin) {
                  setAdminSidebar(true);
                  setChatOn(false);
                } else {
                  history.push('/malls');
                  setCoinAction(['Menu']);
                  setCoinActionEnabled(false);
                }
              }}
              style={{ maxHeight: '100%' }}
            />
            <img
              src={settings}
              alt="settings"
              style={{
                display: sidebarCollapse ? 'none' : isClose ? 'none' : 'flex',
                width: '17px',
                height: '17px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!isCoinLoadingInAddAction) {
                  if (coinActionEnabled) {
                    setCoinActionEnabled(false);
                    setCoinAction(['Menu']);
                  } else if (addBankingEnabled) {
                    setAddBankingEnabled(false);
                  } else {
                    toggleSettings(!openSettings);
                  }
                }
                if (!isClose) {
                  setIsClose(true);
                }
              }}
            />
          </div>
          {coinAction[0] === 'Menu' && !addBankingEnabled && (
            <div
              className="toggle"
              style={{
                color: '#18191D',
                background: 'white',
              }}
              onClick={() => {
                if (!coinActionEnabled) setSidebarCollapse(!sidebarCollapse);
              }}
            >
              <FontAwesomeIcon icon={togglingIcon} />
            </div>
          )}
        </div>
      ) : (
        <div className="profile d-flex">
          <img
            className="clickable"
            src={viralVerse}
            alt="Icon Logo"
            onClick={() => {
              closeSidebars();
              history.push(`/${appCode}`);
            }}
            style={{ maxHeight: '100%' }}
          />
          {/* <div className="col my-auto">
            <h5>{appName}&nbsp;</h5>
            <div
              className="getStartedBtn"
              onClick={() => {
                history.push('/signup');
              }}
            >
              Get Started
            </div>
          </div> */}
          <div
            className="toggle"
            style={{
              color: '#18191D',
              background: 'white',
            }}
            onClick={() => {
              setSidebarCollapse(!sidebarCollapse);
            }}
          >
            <FontAwesomeIcon icon={togglingIcon} />
          </div>
        </div>
      )}
      <Scrollbars
        className="menu-scrl"
        autoHide
        renderView={(props) => (
          <div
            {...props}
            className="menu-side"
            id="preventScroll"
            style={{ overflow: sidebarCollapse ? 'visible' : '' }}
          />
        )}
        style={{ overflow: sidebarCollapse ? 'visible' : '' }}
      >
        {/* <div className="spacer" /> */}
        {trackerSidebarMenu ? (
          <TrackerSidebarMenu />
        ) : (
          <SideBarMenu
            openSettings={openSettings}
            active={active}
            chatOn={chatOn}
            setChatOn={setChatOn}
            toggleSettings={toggleSettings}
            isSecondaryMenu={isSecondaryMenu}
          />
        )}
        {/* <div ref={menuEndRef} className="spacer" /> */}
      </Scrollbars>
      {toast && (
        <ToastMessage
          textOne="Copied the Coin Address"
          onClose={() => setToast(false)}
        />
      )}
      <SelectFooters
        toggleSettings={toggleSettings}
        setToast={setToast}
        isSecondaryMenu={isSecondaryMenu}
        openSettings={openSettings}
        setAdminSidebar={setAdminSidebar}
      />
    </div>
  );
}

export default LayoutSidebar;
