import React, { useContext, useEffect, useState } from 'react';
import classNames from './treeView.scss';
import Confetti from 'react-confetti';
import shareIcon from './assets/share.svg';
import cloudBg from './assets/Cloud1.webp';
import axios from 'axios';
import { BankContext } from '../../../context/Context';
import Layout from '../../../Layout/Layout';
import arrowUp from './assets/arrowUp.svg';
import userIcon from './assets/User.png';
import Skeleton from 'react-loading-skeleton';

const TreeView = ({ holdingTank }) => {
  const { email } = useContext(BankContext);
  const loggedInEmail = localStorage.getItem('tempAdmin')
    ? localStorage.getItem('tempAdmin')
    : email;
  const [expandedCard, setExpandedCard] = useState(null);
  const [expandedRightCard, setExpandedRightCard] = useState(null);
  const [confetti, setConfetti] = useState(false);
  const [dropPosition, setDropPosition] = useState(null);

  const [treeData1, setTreeData1] = useState([
    {
      title: 'John Doe',
      userId: '23445122',
      profilePic: require('./assets/7.svg'),
      left: 35,
      right: 35,
      total: 351,
      phone: '+44 43151 3134 3122',
      email: 'shorupan@gmail.com',
    },
    {
      title: 'Jany Doe',
      userId: '12345678',
      profilePic: require('./assets/8.svg'),
      left: 35,
      right: 35,
      total: 351,
      phone: '+44 43151 3134 3122',
      email: 'shorupan@gmail.com',
    },
    {
      title: 'Ken Hahn',
      userId: '12345890',
      profilePic: require('./assets/9.svg'),
      left: 35,
      right: 35,
      total: 351,
      phone: '+44 43151 3134 3122',
      email: 'shorupan@gmail.com',
    },
    {
      title: 'John Wick',
      userId: '12345901',
      profilePic: require('./assets/4.svg'),
      left: 35,
      right: 35,
      total: 351,
      phone: '+44 43151 3134 3122',
      email: 'shorupan@gmail.com',
    },
    {
      title: 'John Cina',
      userId: '12345113',
      profilePic: require('./assets/5.svg'),
      left: 35,
      right: 35,
      total: 351,
      phone: '+44 43151 3134 3122',
      email: 'shorupan@gmail.com',
    },
  ]);

  const [droppedItem, setDroppedItem] = useState(null);

  const [treeNodes, setTreeNodes] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [totalNodes, setTotalNodes] = useState(0);
  const [leftNodes, setLeftNodes] = useState(0);
  const [rightNodes, setRightNodes] = useState(0);

  const [foundUser, setFoundUser] = useState(null);

  useEffect(() => {
    if (selectedUser !== null) {
      const tempSelected = treeNodes.tree.find(
        (o) => o?.email === selectedUser?.email,
      );
      console.log(tempSelected, 'kjwgefkhwekjfhwkefh');
      setFoundUser(tempSelected);
    }
  }, [selectedUser]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/user/tree?email=${treeNodes?.user?.email}`,
      )
      .then(({ data }) => {
        if (data.status) {
          setTotalNodes(
            data?.user?.direct_leg_count + data?.user?.people_leg_count,
          );
          setLeftNodes(
            data?.user?.direct_left_leg + data?.user?.people_left_leg,
          );
          setRightNodes(
            data?.user?.direct_right_leg + data?.user?.people_right_leg,
          );
        }
      });
  }, [treeNodes?.user?.email]);

  const stepUp = (email) => {
    getTreeData(email);
  };

  const moveToEmptyLeft = (email) => {
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/user/emptyleg?email=${email}&client_app=manifest&leg=left`,
      )
      .then(({ data }) => {
        if (data.status) {
          getTreeData(data?.emptyUser?.email);
        }
      });
  };
  const moveToEmptyRight = (email) => {
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/user/emptyleg?email=${email}&client_app=manifest&leg=right`,
      )
      .then(({ data }) => {
        if (data.status) {
          getTreeData(data?.emptyUser?.email);
        }
      });
  };
  const [rankData, setRankData] = useState('');
  const getRank = (email) => {
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/user/rank?email=${email}&client_app=manifest`,
      )
      .then(({ data }) => {
        if (data.status) {
          console.log(data?.message, 'lkdqlkeflwkenf');
          if (data?.message?.direct_leg_count === 0) {
            setRankData({
              percentage: 0,
              name: data?.message?.user_count_rank,
            });
          } else if (data?.message?.direct_leg_count > 30) {
            setRankData({
              percentage: 100,
              name: data?.message?.user_count_rank,
            });
          } else {
            setRankData({
              percentage:
                (data?.message?.direct_leg_count /
                  data?.message?.next_rank_required) *
                100,
              name: data?.message?.user_count_rank,
            });
          }
        }
      });
  };

  const [loadingTreeData, setLoadingTreeData] = useState(false);
  const getTreeData = (email) => {
    setLoadingTreeData(true);
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/get/user/tree?email=${email}`,
      )
      .then(({ data }) => {
        if (data.status) {
          setTreeNodes(data);
          setLoadingTreeData(false);
        }
      });
  };

  useEffect(() => {
    getTreeData(loggedInEmail);
    getRank(loggedInEmail);
  }, []);

  const counters = [
    { label: 'TOTAL LEFT', count: leftNodes },
    { label: 'TOTAL', count: totalNodes },
    { label: 'TOTAL RIGHT', count: rightNodes },
  ];

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drag = (ev, userId) => {
    ev.dataTransfer.setData('userId', userId);
  };

  const drop = (ev, dropZoneId) => {
    ev.preventDefault();
    const userId = ev.dataTransfer.getData('userId');
    const droppedUser = holdingTank.find((user) => user._id === userId);

    if (droppedUser) {
      setDroppedItem(droppedUser);
      if (dropZoneId === 'dropZone1') {
        setDropPosition('middle-left');
      } else if (dropZoneId === 'dropZone2') {
        setDropPosition('middle-right');
      } else if (dropZoneId === 'dropZone3') {
        setDropPosition('bottom-left1');
      } else if (dropZoneId === 'dropZone4') {
        setDropPosition('bottom-right1');
      } else if (dropZoneId === 'dropZone5') {
        setDropPosition('bottom-left2');
      } else if (dropZoneId === 'dropZone6') {
        setDropPosition('bottom-right2');
      }
      setConfetti(true); // Trigger confetti
      setTimeout(() => setConfetti(false), 4500);
      // TODO : Update it with API
      setTreeData1((prevTreeData) =>
        prevTreeData.filter((user) => user.userId !== userId),
      );
      console.log(`User dropped into ${dropZoneId}`);
    }
    pushDropItem(dropZoneId, droppedUser);
  };

  const pushDropItem = (dropZoneId, droppedUser) => {
    let immidiateNode;
    if (dropZoneId === 'dropZone1' || dropZoneId === 'dropZone2') {
      immidiateNode = treeNodes?.user;
    } else if (dropZoneId === 'dropZone3' || dropZoneId === 'dropZone4') {
      immidiateNode = treeNodes?.user?.left;
    } else {
      immidiateNode = treeNodes?.user?.right;
    }
    // console.log(
    //   {
    //     direct_upline: treeNodes?.user?.affiliate_id,
    //     immediate_email: immidiateNode?.email,
    //     immediate_upline: immidiateNode?.affiliate_id,
    //     user_email: droppedUser?.email,
    //     leg:
    //       dropZoneId === 'dropZone1' ||
    //       dropZoneId === 'dropZone3' ||
    //       dropZoneId === 'dropZone5'
    //         ? 'left'
    //         : 'right',
    //     client_app: 'manifest',
    //   },
    //   'kjgfkwgefkwjfgkwef'
    // );
    axios
      .post(`https://comms.globalxchange.io/client/tree/add/user/tree`, {
        direct_upline: treeNodes?.user?.affiliate_id,
        immediate_email: immidiateNode?.email,
        immediate_upline: immidiateNode?.affiliate_id,
        user_email: droppedUser?.email,
        leg:
          dropZoneId === 'dropZone1' ||
          dropZoneId === 'dropZone3' ||
          dropZoneId === 'dropZone5'
            ? 'left'
            : 'right',
        client_app: 'manifest',
      })
      .then(({ data }) => {
        if (data.status) {
          getTreeData(treeNodes?.user?.email);
        }
      });
  };

  const getMiddleNodes = (email, side) => {
    const found = treeNodes?.tree?.find((o) => o.email === email);
    if (side === 'Left') {
      if (treeNodes?.user?.left?.email) {
        return (
          <>
            <div className="branchLeft">
              {treeNodes?.user?.upline?.leg !== ''
                ? found?.upline?.leg === treeNodes?.user?.upline?.leg
                  ? 'Outside'
                  : 'Inside'
                : 'Outside'}
            </div>
            <div
              className={
                selectedUser?.email === treeNodes?.user?.left?.email
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard1'
              }
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  onClick={(e) => setSelectedUser(treeNodes?.user?.left)}
                  src={
                    found?.profile_img
                      ? found?.profile_img.indexOf('http://') !== -1 ||
                        found?.profile_img.indexOf('https://') !== -1
                        ? found?.profile_img
                        : userIcon
                      : userIcon
                  }
                  className="profileIcon"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '12px',
                }}
              >
                <div>
                  <div className="cardTitle" style={{ fontSize: '15px' }}>
                    {selectedUser?.email === found?.email
                      ? found?.name
                      : found?.name?.length > 12
                        ? found?.name?.substring(0, 12) + '...'
                        : found?.name}
                  </div>
                  {selectedUser?.email === found?.email &&
                    selectedUser !== null && (
                      <div className="cardSubTitle">{found?.rank}</div>
                    )}
                  <div className="cardSubTitle">{found?.status}</div>
                </div>
                <img
                  src={shareIcon}
                  alt=""
                  style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                  onClick={(e) => getTreeData(found?.email)}
                />
              </div>
              {selectedUser?.email === found?.email &&
                selectedUser !== null && (
                  <div>
                    <div className="leftRightRow">
                      <div>
                        <div className="lable">Left</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_left_leg +
                            foundUser?.people_left_leg}
                        </div>
                      </div>
                      <div>
                        <div className="lable">Right</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_right_leg +
                            foundUser?.people_right_leg}
                        </div>
                      </div>
                    </div>
                    <div className="totalRow">
                      Total:{' '}
                      <span className="totalText">
                        {foundUser?.direct_leg_count +
                          foundUser?.people_leg_count}
                      </span>
                    </div>
                    <div className="phoneBtn">+91-1234567890</div>
                    <div className="phoneBtn">{found?.email}</div>
                  </div>
                )}
            </div>
          </>
        );
      } else {
        return (
          <div
            className="itemWrapper"
            style={{
              marginTop:
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? '-20vh'
                  : '',
            }}
          >
            <div
              className={
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard1'
              }
              id="dropZone1"
              onDrop={(ev) => drop(ev, 'dropZone1')}
              onDragOver={allowDrop}
            >
              {confetti && <Confetti />}
              {droppedItem && dropPosition === 'middle-left' && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      onClick={(e) => {
                        setExpandedCard(true);
                        setSelectedUser(droppedItem);
                      }}
                      src={
                        droppedItem?.profilePic.indexOf('http://') !== -1 ||
                        droppedItem?.profilePic.indexOf('https://') !== -1
                          ? droppedItem?.profilePic
                          : userIcon
                      }
                      className="profileIcon"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '12px',
                    }}
                  >
                    <div>
                      <div className="cardTitle" style={{ fontSize: '14px' }}>
                        {droppedItem?.title}
                      </div>
                    </div>
                    <img
                      src={shareIcon}
                      alt=""
                      style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => getTreeData(droppedItem?.email)}
                    />
                  </div>
                  {expandedCard &&
                    expandedCard.userId === droppedItem?.userId && (
                      <div>
                        <div className="leftRightRow">
                          <div>
                            <div className="lable">Left</div>
                            <div className="countTextExpanded">
                              {expandedCard?.left}
                            </div>
                          </div>
                          <div>
                            <div className="lable">Right</div>
                            <div className="countTextExpanded">
                              {expandedCard?.right}
                            </div>
                          </div>
                        </div>
                        <div className="totalRow">
                          Total:{' '}
                          <span className="totalText">
                            {expandedCard?.total}
                          </span>
                        </div>
                        <div className="phoneBtn">{expandedCard?.phone}</div>
                        <div className="phoneBtn">{expandedCard?.email}</div>
                      </div>
                    )}
                </div>
              )}
              {!droppedItem && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={require('./assets/plusIcon.svg')}
                    style={{
                      width: '40px',
                      height: '40px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
    } else if (side === 'Right') {
      if (treeNodes?.user?.right?.email) {
        return (
          <>
            <div className="branchRight">
              {treeNodes?.user?.upline?.leg !== ''
                ? found?.upline?.leg === treeNodes?.user?.upline?.leg
                  ? 'Outside'
                  : 'Inside'
                : 'Inside'}
            </div>
            <div
              className={
                selectedUser?.email === treeNodes?.user?.right?.email
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard1'
              }
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  onClick={(e) => setSelectedUser(treeNodes?.user?.right)}
                  src={
                    (found?.profile_img &&
                      found?.profile_img.indexOf('http://') !== -1) ||
                    (found?.profile_img &&
                      found?.profile_img.indexOf('https://') !== -1)
                      ? found?.profile_img
                      : userIcon
                  }
                  className="profileIcon"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '12px',
                }}
              >
                <div>
                  <div className="cardTitle" style={{ fontSize: '15px' }}>
                    {selectedUser?.email === found?.email
                      ? found?.name
                      : found?.name?.length > 12
                        ? found?.name?.substring(0, 12) + '...'
                        : found?.name}
                  </div>
                  {selectedUser?.email === treeNodes?.user?.right?.email &&
                    selectedUser !== null && (
                      <div className="cardSubTitle">{found?.rank}</div>
                    )}
                  <div className="cardSubTitle">{found?.status}</div>
                </div>
                <img
                  src={shareIcon}
                  alt=""
                  style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                  onClick={(e) => getTreeData(treeNodes?.user?.right?.email)}
                />
              </div>
              {selectedUser?.email === treeNodes?.user?.right?.email &&
                selectedUser !== null && (
                  <div>
                    <div className="leftRightRow">
                      <div>
                        <div className="lable">Left</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_left_leg +
                            foundUser?.people_left_leg}
                        </div>
                      </div>
                      <div>
                        <div className="lable">Right</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_right_leg +
                            foundUser?.people_right_leg}
                        </div>
                      </div>
                    </div>
                    <div className="totalRow">
                      Total:{' '}
                      <span className="totalText">
                        {foundUser?.direct_leg_count +
                          foundUser?.people_leg_count}
                      </span>
                    </div>
                    <div className="phoneBtn">+91-1234567890</div>
                    <div className="phoneBtn">
                      {treeNodes?.user?.left?.email}
                    </div>
                  </div>
                )}
            </div>
          </>
        );
      } else {
        return (
          <div
            className="itemWrapper"
            style={{
              marginTop:
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? '-20vh'
                  : '',
            }}
          >
            <div
              className={
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard1'
              }
              id="dropZone2"
              onDrop={(ev) => drop(ev, 'dropZone2')}
              onDragOver={allowDrop}
            >
              {confetti && <Confetti />}
              {droppedItem && dropPosition === 'middle-right' && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      onClick={(e) => {
                        setExpandedCard(true);
                        setSelectedUser(droppedItem);
                      }}
                      src={
                        droppedItem?.profilePic.indexOf('http://') !== -1 ||
                        droppedItem?.profilePic.indexOf('https://') !== -1
                          ? droppedItem?.profilePic
                          : userIcon
                      }
                      className="profileIcon"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '12px',
                    }}
                  >
                    <div>
                      <div className="cardTitle" style={{ fontSize: '14px' }}>
                        {droppedItem?.title}
                      </div>
                    </div>
                    <img
                      src={shareIcon}
                      alt=""
                      style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => getTreeData(droppedItem?.email)}
                    />
                  </div>
                  {expandedCard &&
                    expandedCard.userId === droppedItem?.userId && (
                      <div>
                        <div className="leftRightRow">
                          <div>
                            <div className="lable">Left</div>
                            <div className="countTextExpanded">
                              {expandedCard?.left}
                            </div>
                          </div>
                          <div>
                            <div className="lable">Right</div>
                            <div className="countTextExpanded">
                              {expandedCard?.right}
                            </div>
                          </div>
                        </div>
                        <div className="totalRow">
                          Total:{' '}
                          <span className="totalText">
                            {expandedCard?.total}
                          </span>
                        </div>
                        <div className="phoneBtn">{expandedCard?.phone}</div>
                        <div className="phoneBtn">{expandedCard?.email}</div>
                      </div>
                    )}
                </div>
              )}
              {!droppedItem && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={require('./assets/plusIcon.svg')}
                    style={{
                      width: '40px',
                      height: '40px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
    }
  };

  const getBottomtNodes1 = (email, side) => {
    const found = treeNodes?.tree?.find((o) => o.email === email);
    if (side === 'Left') {
      if (found?.left) {
        return (
          <>
            <div
              className="itemWrapper"
              style={{
                marginTop:
                  selectedUser?.email === found?.left?.email ? '-20vh' : '',
              }}
            >
              <div className="branchLeft1">
                {treeNodes?.tree?.find((o) => o.email === found?.left?.email)
                  ?.upline?.leg === found?.upline?.leg
                  ? 'Outside'
                  : 'Inside'}
              </div>
              <div
                className={
                  selectedUser?.email === found?.left?.email
                    ? 'treeUserCardExpanded'
                    : 'treeUserCard2'
                }
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      found?.left?.profile_img
                        ? found?.left?.profile_img.indexOf('http://') !== -1 ||
                          found?.left?.profile_img.indexOf('https://') !== -1
                          ? found?.left?.profile_img
                          : userIcon
                        : userIcon
                    }
                    className="profileIcon"
                    onClick={(e) => setSelectedUser(found?.left)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '12px',
                  }}
                >
                  <div>
                    <div className="cardTitle" style={{ fontSize: '13px' }}>
                      {selectedUser?.email === found?.left?.email
                        ? found?.left?.name
                        : found?.left?.name?.length > 12
                          ? found?.left?.name?.substring(0, 12) + '...'
                          : found?.left?.name}
                      {/* {selectedUser === null ? found?.left?.name.length>12 ? found?.left?.name.substring(0, 12) + "..." : found?.left?.name: found?.left?.name} */}
                      {selectedUser?.email === found?.left?.email &&
                        selectedUser !== null && (
                          <div className="cardSubTitle">{found?.rank}</div>
                        )}
                      <div className="cardSubTitle">{found?.status}</div>
                    </div>
                  </div>
                  <img
                    src={shareIcon}
                    alt=""
                    style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                    onClick={(e) => getTreeData(found.left?.email)}
                  />
                </div>
                {selectedUser?.email === found?.left?.email && (
                  <div>
                    <div className="leftRightRow">
                      <div>
                        <div className="lable">Left</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_left_leg +
                            foundUser?.people_left_leg}
                        </div>
                      </div>
                      <div>
                        <div className="lable">Right</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_right_leg +
                            foundUser?.people_right_leg}
                        </div>
                      </div>
                    </div>
                    <div className="totalRow">
                      Total:{' '}
                      <span className="totalText">
                        {foundUser?.direct_leg_count +
                          foundUser?.people_leg_count}
                      </span>
                    </div>
                    <div className="phoneBtn">+91-1234567890</div>
                    <div className="phoneBtn">
                      {treeNodes?.user?.left?.email}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div
            className="itemWrapper"
            style={{
              marginTop:
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? '-20vh'
                  : '',
            }}
          >
            <div
              className={
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard2'
              }
              id="dropZone3"
              onDrop={(ev) => drop(ev, 'dropZone3')}
              onDragOver={allowDrop}
            >
              {confetti && <Confetti />}
              {droppedItem && dropPosition === 'bottom-left1' && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      onClick={(e) => {
                        setExpandedCard(true);
                        setSelectedUser(droppedItem);
                      }}
                      src={
                        droppedItem?.profilePic.indexOf('http://') !== -1 ||
                        droppedItem?.profilePic.indexOf('https://') !== -1
                          ? droppedItem?.profilePic
                          : userIcon
                      }
                      className="profileIcon"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '12px',
                      paddingLeft: '5px',
                    }}
                  >
                    <div>
                      <div className="cardTitle" style={{ fontSize: '12px' }}>
                        {selectedUser === null
                          ? droppedItem?.name?.length > 12
                            ? droppedItem?.name?.substring(0, 12) + '...'
                            : droppedItem?.name
                          : droppedItem?.name}
                      </div>
                    </div>
                    <img
                      src={shareIcon}
                      alt=""
                      style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => getTreeData(droppedItem?.email)}
                    />
                  </div>
                  {expandedCard &&
                    expandedCard.userId === droppedItem?.userId && (
                      <div>
                        <div className="leftRightRow">
                          <div>
                            <div className="lable">Left</div>
                            <div className="countTextExpanded">
                              {expandedCard?.left}
                            </div>
                          </div>
                          <div>
                            <div className="lable">Right</div>
                            <div className="countTextExpanded">
                              {expandedCard?.right}
                            </div>
                          </div>
                        </div>
                        <div className="totalRow">
                          Total:{' '}
                          <span className="totalText">
                            {expandedCard?.total}
                          </span>
                        </div>
                        <div className="phoneBtn">{expandedCard?.phone}</div>
                        <div className="phoneBtn">{expandedCard?.email}</div>
                      </div>
                    )}
                </div>
              )}
              {!droppedItem && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={require('./assets/plusIcon.svg')}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
    } else if (side === 'Right') {
      if (found?.right) {
        return (
          <>
            <div
              className="itemWrapper"
              style={{
                marginTop:
                  selectedUser?.email === found?.right?.email ? '-20vh' : '',
              }}
            >
              <div className="branchRight1">
                {treeNodes?.tree?.find((o) => o.email === found?.right?.email)
                  ?.upline?.leg === found?.upline?.leg
                  ? 'Outside'
                  : 'Inside'}
              </div>
              {/* <div className='branchRight1'>{foundRight?.upline?.leg === "left" ? "Outside": "Inside"}</div> */}
              <div
                className={
                  selectedUser?.email === found?.right?.email
                    ? 'treeUserCardExpanded'
                    : 'treeUserCard2'
                }
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      (found?.right?.profile_img &&
                        found?.right?.profile_img.indexOf('http://') !== -1) ||
                      (found?.right?.profile_img &&
                        found?.right?.profile_img.indexOf('https://') !== -1)
                        ? found?.right?.profile_img
                        : userIcon
                    }
                    className="profileIcon"
                    onClick={(e) => setSelectedUser(found?.right)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '12px',
                    paddingLeft: '5px',
                  }}
                >
                  <div>
                    <div className="cardTitle" style={{ fontSize: '14px' }}>
                      {selectedUser?.email === found?.right?.email
                        ? found?.right?.name
                        : found?.right?.name?.length > 12
                          ? found?.right?.name?.substring(0, 12) + '...'
                          : found?.right?.name}
                    </div>
                    {selectedUser?.email === found?.right?.email &&
                      selectedUser !== null && (
                        <div className="cardSubTitle">{found?.rank}</div>
                      )}
                    <div className="cardSubTitle">{found?.status}</div>
                  </div>
                  <img
                    src={shareIcon}
                    alt=""
                    style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                    onClick={(e) => getTreeData(found.right?.email)}
                  />
                </div>
                {selectedUser?.email === found?.right?.email && (
                  <div>
                    <div className="leftRightRow">
                      <div>
                        <div className="lable">Left</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_left_leg +
                            foundUser?.people_left_leg}
                        </div>
                      </div>
                      <div>
                        <div className="lable">Right</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_right_leg +
                            foundUser?.people_right_leg}
                        </div>
                      </div>
                    </div>
                    <div className="totalRow">
                      Total:{' '}
                      <span className="totalText">
                        {foundUser?.direct_leg_count +
                          foundUser?.people_leg_count}
                      </span>
                    </div>
                    <div className="phoneBtn">+91-1234567890</div>
                    <div className="phoneBtn">
                      {treeNodes?.user?.left?.email}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div
            className="itemWrapper"
            style={{
              marginTop:
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? '-20vh'
                  : '',
            }}
          >
            <div
              className={
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard2'
              }
              id="dropZone4"
              onDrop={(ev) => drop(ev, 'dropZone4')}
              onDragOver={allowDrop}
            >
              {confetti && <Confetti />}
              {droppedItem && dropPosition === 'bottom-right1' && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      onClick={(e) => {
                        setExpandedCard(true);
                        setSelectedUser(droppedItem);
                      }}
                      src={
                        droppedItem?.profilePic.indexOf('http://') !== -1 ||
                        droppedItem?.profilePic.indexOf('https://') !== -1
                          ? droppedItem?.profilePic
                          : userIcon
                      }
                      className="profileIcon"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '15px',
                    }}
                  >
                    <div>
                      <div className="cardTitle" style={{ fontSize: '12px' }}>
                        {droppedItem?.name}
                      </div>
                    </div>
                    <img
                      src={shareIcon}
                      alt=""
                      style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => getTreeData(droppedItem?.email)}
                    />
                  </div>
                  {expandedCard &&
                    expandedCard.userId === droppedItem?.userId && (
                      <div>
                        <div className="leftRightRow">
                          <div>
                            <div className="lable">Left</div>
                            <div className="countTextExpanded">
                              {expandedCard?.left}
                            </div>
                          </div>
                          <div>
                            <div className="lable">Right</div>
                            <div className="countTextExpanded">
                              {expandedCard?.right}
                            </div>
                          </div>
                        </div>
                        <div className="totalRow">
                          Total:{' '}
                          <span className="totalText">
                            {expandedCard?.total}
                          </span>
                        </div>
                        <div className="phoneBtn">{expandedCard?.phone}</div>
                        <div className="phoneBtn">{expandedCard?.email}</div>
                      </div>
                    )}
                </div>
              )}
              {!droppedItem && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={require('./assets/plusIcon.svg')}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
    }
  };
  const getBottomtNodes2 = (email, side) => {
    const found = treeNodes?.tree?.find((o) => o.email === email);
    if (side === 'Left') {
      if (found?.left) {
        return (
          <>
            <div
              className="itemWrapper"
              style={{
                marginTop:
                  selectedUser?.email === found?.left?.email ? '-20vh' : '',
              }}
            >
              <div className="branchLeft1">
                {treeNodes?.tree?.find((o) => o.email === found?.left?.email)
                  ?.upline?.leg === found?.upline?.leg
                  ? 'Outside'
                  : 'Inside'}
              </div>
              <div
                className={
                  selectedUser?.email === found?.left?.email
                    ? 'treeUserCardExpanded'
                    : 'treeUserCard2'
                }
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      (found?.left?.profile_img &&
                        found?.left?.profile_img.indexOf('http://') !== -1) ||
                      (found?.left?.profile_img &&
                        found?.left?.profile_img.indexOf('https://') !== -1)
                        ? found?.left?.profile_img
                        : userIcon
                    }
                    className="profileIcon"
                    onClick={(e) => setSelectedUser(found?.left)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '12px',
                    paddingLeft: '5px',
                  }}
                >
                  <div>
                    <div className="cardTitle" style={{ fontSize: '14px' }}>
                      {selectedUser?.email === found?.left?.email
                        ? found?.left?.name
                        : found?.left?.name?.length > 12
                          ? found?.left?.name?.substring(0, 12) + '...'
                          : found?.left?.name}
                    </div>
                    {selectedUser?.email === found?.left?.email &&
                      selectedUser !== null && (
                        <div className="cardSubTitle">{found?.rank}</div>
                      )}
                    <div className="cardSubTitle">{found?.status}</div>
                  </div>
                  <img
                    src={shareIcon}
                    alt=""
                    style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                    onClick={(e) => getTreeData(found.left?.email)}
                  />
                </div>
                {selectedUser?.email === found?.left?.email && (
                  <div>
                    <div className="leftRightRow">
                      <div>
                        <div className="lable">Left</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_left_leg +
                            foundUser?.people_left_leg}
                        </div>
                      </div>
                      <div>
                        <div className="lable">Right</div>
                        <div className="countTextExpanded">
                          {foundUser?.direct_right_leg +
                            foundUser?.people_right_leg}
                        </div>
                      </div>
                    </div>
                    <div className="totalRow">
                      Total:{' '}
                      <span className="totalText">
                        {foundUser?.direct_leg_count +
                          foundUser?.people_leg_count}
                      </span>
                    </div>
                    <div className="phoneBtn">+91-1234567890</div>
                    <div className="phoneBtn">
                      {treeNodes?.user?.left?.email}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div
            className="itemWrapper"
            style={{
              marginTop:
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? '-20vh'
                  : '',
            }}
          >
            <div
              className={
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard2'
              }
              id="dropZone5"
              onDrop={(ev) => drop(ev, 'dropZone5')}
              onDragOver={allowDrop}
            >
              {confetti && <Confetti />}
              {droppedItem && dropPosition === 'bottom-left2' && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      onClick={(e) => {
                        setExpandedCard(true);
                        setSelectedUser(droppedItem);
                      }}
                      src={
                        droppedItem?.profilePic.indexOf('http://') !== -1 ||
                        droppedItem?.profilePic.indexOf('https://') !== -1
                          ? droppedItem?.profilePic
                          : userIcon
                      }
                      className="profileIcon"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '15px',
                    }}
                  >
                    <div>
                      <div className="cardTitle" style={{ fontSize: '12px' }}>
                        {selectedUser?.email === found?.right?.email
                          ? found?.right?.name
                          : found?.right?.name?.length > 12
                            ? found?.right?.name?.substring(0, 12) + '...'
                            : found?.right?.name}
                      </div>
                    </div>
                    <img
                      src={shareIcon}
                      alt=""
                      style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => getTreeData(droppedItem?.email)}
                    />
                  </div>
                  {expandedCard &&
                    expandedCard.userId === droppedItem?.userId && (
                      <div>
                        <div className="leftRightRow">
                          <div>
                            <div className="lable">Left</div>
                            <div className="countTextExpanded">
                              {expandedCard?.left}
                            </div>
                          </div>
                          <div>
                            <div className="lable">Right</div>
                            <div className="countTextExpanded">
                              {expandedCard?.right}
                            </div>
                          </div>
                        </div>
                        <div className="totalRow">
                          Total:{' '}
                          <span className="totalText">
                            {expandedCard?.total}
                          </span>
                        </div>
                        <div className="phoneBtn">{expandedCard?.phone}</div>
                        <div className="phoneBtn">{expandedCard?.email}</div>
                      </div>
                    )}
                </div>
              )}
              {!droppedItem && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={require('./assets/plusIcon.svg')}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
    } else if (side === 'Right') {
      if (found?.right) {
        return (
          <>
            <div
              className="itemWrapper"
              style={{
                marginTop:
                  selectedUser?.email === found?.right?.email ? '-20vh' : '',
              }}
            >
              <div className="branchRight1">
                {treeNodes?.tree?.find((o) => o.email === found?.right?.email)
                  ?.upline?.leg === found?.upline?.leg
                  ? 'Outside'
                  : 'Inside'}
              </div>
              {/* <div className='branchRight1'>{found?.upline?.leg === "left" ? "Outside": "Inside"}</div> */}
              <div
                className={
                  selectedUser?.email === found?.right?.email
                    ? 'treeUserCardExpanded'
                    : 'treeUserCard2'
                }
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={
                      (found?.right?.profile_img &&
                        found?.right?.profile_img.indexOf('http://') !== -1) ||
                      (found?.right?.profile_img &&
                        found?.right?.profile_img.indexOf('https://') !== -1)
                        ? found?.right?.profile_img
                        : userIcon
                    }
                    className="profileIcon"
                    onClick={(e) => setSelectedUser(found?.right)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '12px',
                    paddingLeft: '5px',
                  }}
                >
                  <div>
                    <div className="cardTitle" style={{ fontSize: '14px' }}>
                      {selectedUser === null
                        ? found?.right?.name?.length > 12
                          ? found?.right?.name?.substring(0, 12) + '...'
                          : found?.right?.name
                        : found?.right?.name}
                    </div>
                    {selectedUser?.email === found?.right?.email &&
                      selectedUser !== null && (
                        <div className="cardSubTitle">{found?.rank}</div>
                      )}
                    <div className="cardSubTitle">{found?.status}</div>
                  </div>
                  <img
                    src={shareIcon}
                    alt=""
                    style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                    onClick={(e) => getTreeData(found.right?.email)}
                  />
                </div>
                {selectedUser?.email === found?.right?.email && (
                  <div>
                    <div className="leftRightRow">
                      <div>
                        <div className="lable">Left</div>
                        <div className="countTextExpanded">
                          {foundUser?.people_left_leg}
                        </div>
                      </div>
                      <div>
                        <div className="lable">Right</div>
                        <div className="countTextExpanded">
                          {foundUser?.people_right_leg}
                        </div>
                      </div>
                    </div>
                    <div className="totalRow">
                      Total:{' '}
                      <span className="totalText">
                        {foundUser?.people_left_leg +
                          foundUser?.people_right_leg}
                      </span>
                    </div>
                    <div className="phoneBtn">+91-1234567890</div>
                    <div className="phoneBtn">{found?.right?.email}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div
            className="itemWrapper"
            style={{
              marginTop:
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? '-20vh'
                  : '',
            }}
          >
            <div
              className={
                expandedCard && expandedCard.userId === droppedItem?.userId
                  ? 'treeUserCardExpanded'
                  : 'treeUserCard2'
              }
              id="dropZone6"
              onDrop={(ev) => drop(ev, 'dropZone6')}
              onDragOver={allowDrop}
            >
              {confetti && <Confetti />}
              {droppedItem && dropPosition === 'bottom-right2' && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      onClick={(e) => {
                        setExpandedCard(true);
                        setSelectedUser(droppedItem);
                      }}
                      src={
                        droppedItem?.profilePic.indexOf('http://') !== -1 ||
                        droppedItem?.profilePic.indexOf('https://') !== -1
                          ? droppedItem?.profilePic
                          : userIcon
                      }
                      className="profileIcon"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '15px',
                    }}
                  >
                    <div>
                      <div className="cardTitle" style={{ fontSize: '12px' }}>
                        {selectedUser === null
                          ? droppedItem?.name?.length > 12
                            ? droppedItem?.name?.substring(0, 12) + '...'
                            : droppedItem?.name
                          : droppedItem?.name}
                      </div>
                    </div>
                    <img
                      src={shareIcon}
                      alt=""
                      style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => getTreeData(droppedItem?.email)}
                    />
                  </div>
                  {expandedCard &&
                    expandedCard.userId === droppedItem?.userId && (
                      <div>
                        <div className="leftRightRow">
                          <div>
                            <div className="lable">Left</div>
                            <div className="countTextExpanded">
                              {expandedCard?.left}
                            </div>
                          </div>
                          <div>
                            <div className="lable">Right</div>
                            <div className="countTextExpanded">
                              {expandedCard?.right}
                            </div>
                          </div>
                        </div>
                        <div className="totalRow">
                          Total:{' '}
                          <span className="totalText">
                            {expandedCard?.total}
                          </span>
                        </div>
                        <div className="phoneBtn">{expandedCard?.phone}</div>
                        <div className="phoneBtn">{expandedCard?.email}</div>
                      </div>
                    )}
                </div>
              )}
              {!droppedItem && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={require('./assets/plusIcon.svg')}
                    style={{
                      width: '30px',
                      height: '30px',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className="container2">
      {selectedUser !== null || expandedRightCard !== null ? (
        <div
          className="container2Expanded"
          onClick={(e) => {
            setSelectedUser(null);
            setExpandedRightCard(null);
          }}
        ></div>
      ) : null}
      <div className="treerightSide" style={{ padding: '20px' }}>
        {/* <div className="progressBarWrapper"> */}
        <div
          className="bottomHalf"
          style={{
            height: '100%',
            borderRadius: '15px 15px 15px 15px',
            position: 'relative',
            alignSelf: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              // position: 'absolute',
              // top: 20,
              textAlign: 'center',
              color: 'white',
              fontSize: '18px',
              fontWeight: 700,
              paddingBottom: '20px',
            }}
          >
            {treeNodes?.user?.temp_rank
              ? treeNodes?.user?.temp_rank?.charAt(0).toUpperCase() +
                treeNodes?.user?.temp_rank?.slice(1)
              : ''}
          </div>
          <div
            className="progressBar"
            style={{
              height: `${rankData?.percentage}%`,
              // height: '10%',
              transition: 'height 2.5s ease-in-out',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '27px',
              fontWeight: 700,
              paddingBottom: '10px',
              position: 'relative',
            }}
          >
            {/* <div style={{ fontSize: '12px' }}>{rankData?.name}</div> */}
            {typeof rankData?.percentage === 'number'
              ? rankData?.percentage.toFixed(0) + '%'
              : '100%'}
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="treeCenter">
        <div className="row0">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: '0px 40px',
            }}
          >
            {treeNodes?.user?.email !== loggedInEmail && (
              <div
                style={{
                  position: 'absolute',
                  left: '17%',
                  marginTop: '15px',
                  cursor: 'pointer',
                }}
                onClick={(e) => getTreeData(email)}
              >
                <img src={arrowUp} alt="" />
              </div>
            )}
            {counters.map((counter, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    background: 'rgba(255, 255, 255, 0.5)',
                    // index === 0
                    //   ? '#8EA5D5'
                    //   : index === 1
                    //   ? '#d3a6bf'
                    //   : '#ECB5CC',
                    borderRadius: '10px',
                    padding: '10px 0px',
                    paddingTop: '20px',
                    fontWeight: 700,
                    width: '200px',
                    textAlign: 'center',
                    // marginBottom: '-20px',
                  }}
                >
                  <p style={{ color: '#8EA5D5' }}>{counter.label}</p>
                </div>
                <div
                  style={{
                    background: 'rgba(255, 255, 255, 1)',
                    // index === 0
                    //   ? '#8EA5D5'
                    //   : index === 1
                    //   ? '#d3a6bf'
                    //   : '#ECB5CC',
                    borderRadius: '10px',
                    marginTop: '-20px',
                    padding: '0px 20px',
                  }}
                >
                  <div
                    style={{
                      maxWidth: '100%',
                      margin: 'auto',
                    }}
                  >
                    <h1
                      style={{
                        color: '#8EA5D5',
                        fontWeight: 'bold',
                        paddingTop: '5px',
                      }}
                    >
                      {counter.count}
                    </h1>
                  </div>
                </div>
              </div>
            ))}
            {treeNodes?.user?.email !== loggedInEmail && (
              <div
                style={{
                  position: 'absolute',
                  right: '17%',
                  marginTop: '15px',
                  cursor: 'pointer',
                }}
                onClick={(e) =>
                  email !== treeNodes?.user?.upline?.email &&
                  stepUp(treeNodes?.user?.upline?.email)
                }
              >
                <img src={arrowUp} alt="" />
              </div>
            )}
          </div>
        </div>
        <div className="row1">
          <div
            style={{
              border: '2px solid white',
              width: '42%',
              position: 'absolute',
              marginTop: '16rem',
            }}
          ></div>
          <div
            style={{
              border: '2px solid white',
              width: '2px',

              height: '8rem',
              marginTop: '8rem',
              left: '50%',
              position: 'absolute',
            }}
          ></div>
          <div
            style={{
              border: '2px solid white',
              width: '2px',
              height: '7rem',
              marginTop: '23rem',
              left: '29%',
              position: 'absolute',
            }}
          ></div>
          <div
            style={{
              border: '2px solid white',
              width: '2px',
              height: '7rem',
              marginTop: '23rem',
              right: '29%',
              position: 'absolute',
            }}
          ></div>
          <div
            className={
              selectedUser?._id === treeNodes?.user?._id && selectedUser
                ? 'treeUserCardExpanded'
                : 'treeUserCard'
            }
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {!loadingTreeData ? (
                <img
                  onClick={(e) => {
                    setSelectedUser(treeNodes?.user);
                  }}
                  src={
                    (treeNodes?.user?.profile_img &&
                      treeNodes?.user?.profile_img.indexOf('http://') !== -1) ||
                    (treeNodes?.user?.profile_img &&
                      treeNodes?.user?.profile_img.indexOf('https://') !== -1)
                      ? treeNodes?.user?.profile_img
                      : userIcon
                  }
                  style={{
                    width: '80px',
                    height: '80px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    marginTop: '-10px',
                  }}
                />
              ) : (
                <Skeleton height={80} width={80} borderRadius={50} />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '12px',
                // zIndex: 2,
              }}
            >
              <div>
                <div className="cardTitle">
                  {!loadingTreeData ? (
                    selectedUser === null ? (
                      treeNodes?.user?.name?.length > 18 ? (
                        treeNodes?.user?.name?.substring(0, 18) + '...'
                      ) : (
                        treeNodes?.user?.name
                      )
                    ) : (
                      treeNodes?.user?.name
                    )
                  ) : (
                    <Skeleton height={10} width={150} />
                  )}
                </div>
                {selectedUser?._id === treeNodes?.user?._id &&
                  selectedUser !== null && (
                    <div className="cardSubTitle">{treeNodes?.user?.rank}</div>
                  )}
                <div className="cardSubTitle">{treeNodes?.user?.status}</div>
              </div>
              <img
                src={shareIcon}
                alt=""
                // onClick={(e) => getTreeData(treeNodes?.user?.email)}
              />
            </div>

            {selectedUser?._id === treeNodes?.user?._id && selectedUser && (
              <div>
                <div className="leftRightRow">
                  <div>
                    <div className="lable">Left</div>
                    <div className="countTextExpanded">
                      {treeNodes?.user?.direct_left_leg +
                        treeNodes?.user?.people_left_leg}
                    </div>
                  </div>
                  <div>
                    <div className="lable">Right</div>
                    <div className="countTextExpanded">
                      {treeNodes?.user?.direct_right_leg +
                        treeNodes?.user?.people_right_leg}
                    </div>
                  </div>
                </div>
                <div className="totalRow">
                  Total:{' '}
                  <span className="totalText">
                    {treeNodes?.user?.direct_leg_count +
                      treeNodes?.user?.people_leg_count}
                  </span>
                </div>
                <div className="phoneBtn">+91-1234567890</div>
                <div className="phoneBtn">{selectedUser?.email}</div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="rowDivider">
          <div className="countText">3 Left</div>
          <div className="lText1">L1</div>
          <div className="countText">4 Right</div>
        </div> */}
        <div
          className="row2"
          style={{
            position: 'relative',
            // display: 'flex',
            // justifyContent: 'center',
            padding: '0px 7.5rem',
          }}
        >
          <div
            style={{
              border: '2px solid white',
              width: '2px',
              height: '6rem',
              marginTop: '10rem',
              left: '14rem',
              position: 'absolute',
            }}
          ></div>
          <div
            style={{
              border: '2px solid white',
              width: '2px',
              height: '6rem',
              marginTop: '10rem',
              right: '14rem',
              position: 'absolute',
            }}
          ></div>
          <div
            style={{
              border: '2px solid white',
              // width: '11.4rem',
              marginTop: '16rem',
              // marginLeft: '7.6rem',
              position: 'absolute',
              width: '18.9%',
              marginLeft: '12.5%',
              left: 0,
            }}
          ></div>
          <div
            style={{
              border: '2px solid white',
              // width: '11.4rem',
              marginTop: '16rem',
              // marginLeft: '7.6rem',
              position: 'absolute',
              width: '18.9%',
              marginRight: '12.5%',
              right: 0,
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              border: '2px solid white',
              width: '2px',
              height: '9rem',
              marginTop: '25rem',
              left: 0,
              marginLeft: '12.5%',
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              border: '2px solid white',
              height: '9rem',
              marginTop: '25rem',
              left: 0,
              marginLeft: '31.2%',
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              border: '2px solid white',
              width: '2px',
              height: '9rem',
              marginTop: '25rem',
              right: 0,
              marginRight: '12.5%',
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              border: '2px solid white',
              // height: '40%',
              // marginTop: '38%',
              height: '9rem',
              marginTop: '25rem',
              right: 0,
              marginRight: '31.2%',
            }}
          ></div>
          <div
            className="itemWrapper"
            style={{ left: '12%', position: 'absolute' }}
          >
            {getMiddleNodes(treeNodes?.user?.left?.email, 'Left')}
          </div>
          {/* <div className="itemWrapper" style={{ opacity: 0 }}>
            <div className="treeUserCard1"></div>
          </div> */}

          <div
            className="itemWrapper"
            style={{ right: '12%', position: 'absolute' }}
          >
            {getMiddleNodes(treeNodes?.user?.right?.email, 'Right')}
          </div>
        </div>
        {/* <div className="rowDivider1">
          <div className="countText">3 Left</div>
          <div className="countText">4 Right</div>
          <div className="lText2">L2</div>
          <div className="countText">3 Left</div>
          <div className="countText">4 Right</div>
        </div> */}
        <div className="row3">
          {getBottomtNodes1(treeNodes?.user?.left?.email, 'Left')}
          {getBottomtNodes1(treeNodes?.user?.left?.email, 'Right')}

          <div className="itemWrapper" style={{ opacity: 0 }}>
            <div className="treeUserCard2"></div>
          </div>
          {getBottomtNodes2(treeNodes?.user?.right?.email, 'Left')}
          {getBottomtNodes2(treeNodes?.user?.right?.email, 'Right')}
        </div>
      </div>
      <div className="treerightSide">
        <div className="innerRight" style={{ position: 'relative' }}>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img src={require('./assets/leftArrow.svg')} />
            <img src={require('./assets/rightArrow.svg')} />
          </div> */}
          <div
            style={{
              fontSize: '16px',
              fontWeight: 700,
              textAlign: 'center',
              color: 'white',
              padding: '20px 0px',
            }}
          >
            Holding Tank
          </div>

          <div className="rightcardWrapper">
            {holdingTank?.map((item) => (
              <div
                className={
                  expandedRightCard?._id === item?._id
                    ? 'rightCardExpanded'
                    : 'rightCard'
                }
                draggable
                onDragStart={(e) => drag(e, item._id)}
              >
                {expandedRightCard?._id === item?._id && (
                  <div className="expandedRightWrapper">
                    <div className="expandedRightTitle">
                      {expandedRightCard?.name}
                    </div>
                    <div className="expandedRightPhone">
                      {expandedRightCard?.rank}
                    </div>
                    <div
                      className="expandedRightPhone"
                      style={{ fontSize: '12px' }}
                    >
                      {expandedRightCard?.email}
                    </div>
                  </div>
                )}
                <div>
                  <img
                    onClick={(e) => setExpandedRightCard(item)}
                    src={
                      item?.profile_img.indexOf('http://') !== -1 ||
                      item?.profile_img.indexOf('https://') !== -1
                        ? item?.profile_img
                        : userIcon
                    }
                    style={{
                      width: '100px',
                      height: '100px',
                      cursor: 'pointer',
                    }}
                  />
                  <div className="rightTitle">{item?.name}</div>
                </div>
              </div>
            ))}
            {/* {Array(10)
              .fill("")
              .map((item) => (
                <div
                  className={"rightCard"}
                  draggable
                  // onDragStart={(e) => drag(e, item._id)}
                >
                  <div>
                    <img
                      // onClick={(e) => setExpandedRightCard(item)}
                      src={userIcon}
                      style={{
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                    />
                    <div className="rightTitle">Hello</div>
                  </div>
                </div>
              ))} */}
          </div>
          <div className="buttonGroup">
            <button onClick={(e) => moveToEmptyLeft(treeNodes?.user?.email)}>
              Find Empty Left
            </button>
            <button onClick={(e) => moveToEmptyRight(treeNodes?.user?.email)}>
              Find Empty Right
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreeView;
