import React, {useState, useContext } from 'react';
import styles from "./sidebar.module.scss"
import close from './close.svg';
import Withdraw from '../../pages/AffiliatePage/Withdraw';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import { placeWithdrawal } from './api';
import userArr from '../../static/images/affiliate/userArr.svg';
import userClose from '../../static/images/affiliate/userClose.svg';
import { LoadingAnimation } from '../../components/LoadingAnimation';

const WithdrawCommision = ({setPopular, setSelectedStep}) => {
    

    const {
        withdrawStep,
        setWithdrawStep,
        setIswithdraw,
        withdrawVal,
        setWithdrawVal,
        balances,
        appLogo
      } = useAppContextDetails();

    const {
        email,
        token,profileId,
        username,
        name,
        profileImg,
        copyToClipboard,
        linkname,
        setLinkname,
      } = useContext(BankContext);
      const [isloading, setIsloading] = useState(false);
      const [errmessage, setErrMessage] = useState('');

    const withdrawApiCall = () => {
        setIsloading(true);
        let obj = {
          email,
          token,
          app_code: 'manifest',
          profile_id: profileId,
          amount: withdrawVal,
        };
        placeWithdrawal(obj).then((response) => {
          let result = response?.data;
          // console.log(result, 'withdraw result');
          if (result?.message === 'Withdraw Successfull') {
            setIsloading(false);
            setWithdrawStep('step2');
          } else {
            setIsloading(false);
            setErrMessage(result);
          }
        });
      };


  const onBlur = (e) => {
    const float = parseFloat(e.target.value);
    setWithdrawVal(float.toFixed(2));
  };

    return ( 
        <>
       <div className={styles.headTxt}>
            <div>Withdraw Commision</div>
            <div
            onClick={() => {
                setPopular(false);
                setSelectedStep(null);
            }}
            className="close-div"
            >
            <img src={close} alt="" />
            </div>
        </div>
      
        <div style={{ width: '100%', height: '100%' }}>
        <div className="headerr">
          {/* <div className="topp">
            <div style={{ width: '80%' }}>
              <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>
                {withdrawStep === 'step1' ? 'Withdraw Earnings' : 'Success'}
              </p>
            </div>
            <div className="closebttn">
              <img
                src={userArr}
                alt=""
                onClick={() => {
                  setWithdrawStep('step1');
                  setWithdrawVal('');
                }}
              />
              <img
                src={userClose}
                alt=""
                onClick={() => {
                  setWithdrawStep('step1');
                  setIswithdraw(false);
                }}
              />
            </div>
          </div> */}
          <div className={styles.otherFields} >
            <div  style={{
                fontSize: '14px',
                fontWeight: '400',
                paddingBottom: '17px',
              }}>
              {withdrawStep === 'step1'
                ? 'Current Withdrawable Balance'
                : 'New Withdrawable Balance'}
            </div>
            <div className={styles.inputWrapper}>
              {withdrawStep === 'step1' ? (
                <input
                  type="text"
                  style={{
                    pointerEvents: 'none',
                    fontSize: '1.25rem',
                    fontWeight: '650',
                    color: '#050505',
                    border:"none"
                  }}
                  value={`$${
                    balances?._2weekDelay_balance
                      ? balances?._2weekDelay_balance?.toFixed(2)
                      : '0.00'
                  }`}
                />
              ) : (
                <input
                  type="text"
                  style={{
                    pointerEvents: 'none',
                    fontSize: '1.25rem',
                    fontWeight: '650',
                    color: '#050505',
                    border:"none"
                  }}
                  value="$233.31"
                />
              )}
            </div>
          </div>
          <div className={styles.otherFields} style={{ paddingTop: '30px' }}>
            <div style={{
                fontSize: '14px',
                fontWeight: '400',
                paddingBottom: '17px',
              }}>
              {withdrawStep === 'step1'
                ? 'How Much Do You Want To Withdraw?'
                : 'DGP USD Vault Balance'}
            </div>
            <div className={styles.inputWrapper}>
              {withdrawStep === 'step1' ? (
                <input
                  type="number"
                  placeholder="$0.00"
                  style={{
                    cursor: 'text',
                    fontSize: '1.25rem',
                    fontWeight: '650',
                    color: '#050505',
                    border:"none"
                  }}
                  className="placee"
                  value={withdrawVal}
                  onChange={(e) => setWithdrawVal(e.target.value)}
                  onBlur={onBlur}
                />
              ) : (
                <input
                  type="text"
                  style={{
                    pointerEvents: 'none',
                    fontSize: '1.25rem',
                    fontWeight: '650',
                    color: '#050505',
                    border:"none"
                  }}
                  value="$233.31"
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          {withdrawStep === 'step1' && (
            <div
              className={withdrawVal.length > 0 ? styles.submitBtn : styles.addd}
              style={{
                width: '100%',
                pointerEvents: withdrawVal.length > 0 ? '' : 'none',
              }}
              onClick={() => {
                withdrawApiCall();
              }}
            >
              Confirm Withdrawal
            </div>
          )}
          {withdrawStep === 'step2' && (
            <div className={styles.submitBtn} style={{ width: '100%' }}>
              Go To USD Vault
            </div>
          )}
        </div>
      </div>

      {isloading && (
        <div className="loadingIcon" style={{ height: '100%', opacity: '1' }}>
          <LoadingAnimation icon={appLogo} width={250} />
          <div
            style={{
              position: 'absolute',
              bottom: '19%',
              color: '#18191D',
              fontSize: '1.3rem',
              textAlign: 'center',
              width: '80%',
              lineHeight: '1.75',
            }}
          >
            {`Withdrawing $${withdrawVal} From Your AffiliateBank Balance To Your DGPWallet
            USD Vault`}
          </div>
        </div>
      )}
        </>
     );
}
 
export default WithdrawCommision;