import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useCoinContextData } from '../../../context/CoinContext';
import './addAccountStyles.scss';
import { getAppByCode } from '../../../queryHooks';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useLoadBankingData } from '../../../pages/Banking/api';

const AccountDetails = () => {
  const { appCode } = useAppContextDetails();
  const {
    setAddAccountStepName,
    selectedPaymentType,
    nameOnBankAccount,
    setNameOnBankAccount,
    bankingPhoneNumber,
    setBankingPhoneNumber,
    beneficiaryAddress,
    setBeneficiaryAddress,
    upiId,
    setUpiId,
    upiPhoneNumber,
    setUpiPhoneNumber,

    selectedBankType,
    updateBankAccounts,
    setUpdateBankAccounts,
    setBackToMenu,
    setAddBankingEnabled,
  } = useCoinContextData();
  const { email, token, setSidebarCollapse } = useContext(BankContext);

  const { data: bankingDataResponse, isLoading } = useLoadBankingData(
    email,
    updateBankAccounts,
    setUpdateBankAccounts
  );

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [appColor, setAppColor] = useState('');
  const [appIcon, setAppIcon] = useState('');

  const [accountType, setAccountType] = useState('');

  const [accNo, setAccNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');

  useEffect(() => {
    getAppByCode(appCode).then((response) => {
      const color = response?.color_codes[0];
      const logo = response?.app_icon;
      // console.log(color, logo, 'ljqwehflk3wbefljk3bwef');
      setAppColor(color);
      setAppIcon(logo);
    });
  }, [appCode]);

  const AddAccountIndiaUPI = () => {
    setLoading(true);

    axios
      .post(`https://comms.globalxchange.io/coin/user/bank/account/add`, {
        email: email,
        token: token,
        paymentMethod: 'GPAY',
        institute_id: selectedBankType?._id,
        bank_name: selectedBankType?.institute_name,
        country: 'India',
        account_name: nameOnBankAccount,
        phone_number: bankingPhoneNumber,
        beneficiary_address: beneficiaryAddress,
        currency: 'INR',
        bank_information: {
          value1: {
            name: 'UPI ID',
            valuetype: 'text',
            value: upiId,
          },
          value2: {
            name: 'Phone Number',
            valuetype: 'text',
            value: upiPhoneNumber,
          },
        },
      })
      .then(({ data }) => {
        console.log(data, 'kjwhkjfwekfjew');
        if (data.status) {
          setLoading(false);
          setUpdateBankAccounts(true);
          setAddBankingEnabled(false);
          //   setSidebarCollapse(true);
          // Close Sidebar Draw
          // Refresh All Accounts
        }
      });
  };

  const AddAccountIndiaBT = () => {
    setLoading(true);
    axios
      .post(`https://comms.globalxchange.io/coin/user/bank/account/add`, {
        email: email,
        token: token,
        paymentMethod: 'BT',

        institute_id: selectedBankType?._id,
        bank_name: selectedBankType?.institute_name,
        country: 'India',
        account_name: nameOnBankAccount,
        phone_number: bankingPhoneNumber,
        beneficiary_address: beneficiaryAddress,
        currency: 'INR',

        bank_information: {
          value1: {
            name: 'Account Number',
            valuetype: 'text',
            value: accNo,
          },
          value2: {
            name: 'Account Type',
            valuetype: 'text',
            value: accountType,
          },
          value3: {
            name: 'IFSC Code',
            valuetype: 'text',
            value: ifscCode,
          },
        },
      })
      .then(({ data }) => {
        if (data.status) {
          setLoading(false);
          setUpdateBankAccounts(true);
          setAddBankingEnabled(false);
          //   setSidebarCollapse(true);
        }
      });
  };

  //   const refetchAccounts = () => {
  //     axios
  //       .get(
  //         `https://comms.globalxchange.io/coin/user/bank/account/get?email=${email}`
  //       )
  //       .then((res) => {});
  //   };

  const conditionalFields = () => {
    if (selectedPaymentType.code === 'GPAY') {
      return (
        <>
          <div className="inputWrapper">
            <div className="inputSection">
              <div className="inputHeader">What Is Your UPI ID?</div>
              <input
                placeholder="Enter UPI ID.."
                className="inputStyle"
                value={upiId}
                onChange={(e) => setUpiId(e.target.value)}
              />
            </div>
            <div className="inputSection">
              <div className="inputHeader">What Is The UPI Phone Number?</div>
              <input
                placeholder="Enter Number.."
                className="inputStyle"
                value={upiPhoneNumber}
                onChange={(e) => setUpiPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="buttonWrapper">
            <div onClick={(e) => setAddAccountStepName('selectBank')}>Back</div>
            <div
              onClick={(e) =>
                upiId && upiPhoneNumber ? AddAccountIndiaUPI() : null
              }
              style={{
                background: `#${appColor?.primarycolourcode}`,
                color: 'white',
                opacity: upiId && upiPhoneNumber ? 1 : 0.4,
              }}
            >
              Next Step
            </div>
          </div>
        </>
      );
    } else if (selectedPaymentType.code === 'BT') {
      return (
        <>
          <div className="inputWrapper">
            <div className="inputSection">
              <div className="inputHeader">Account Number</div>
              <input
                placeholder="Enter Account Number.."
                className="inputStyle"
                value={accNo}
                onChange={(e) => setAccNo(e.target.value)}
              />
            </div>
            <div className="inputSection">
              <div className="inputHeader">IFSC Code</div>
              <input
                placeholder="Enter Number.."
                className="inputStyle"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              />
            </div>
            <div className="inputSection">
              <div className="inputHeader">Account Type</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  className={
                    accountType === 'Current'
                      ? 'roundButtonSelected'
                      : 'roundButton'
                  }
                  onClick={(e) => setAccountType('Current')}
                >
                  <div>Current</div>
                </div>
                <div
                  className={
                    accountType === 'Savings'
                      ? 'roundButtonSelected'
                      : 'roundButton'
                  }
                  onClick={(e) => setAccountType('Savings')}
                >
                  <div>Savings</div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttonWrapper">
            <div onClick={(e) => setAddAccountStepName('selectBank')}>Back</div>
            <div
              onClick={(e) =>
                accNo && ifscCode && accountType ? AddAccountIndiaBT() : null
              }
              style={{
                background: `#${appColor?.primarycolourcode}`,
                color: 'white',
                opacity: accNo && ifscCode && accountType ? 1 : 0.4,
              }}
            >
              Add Account
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="addAccountContainer">
        <div>
          <span
            className="prevStep"
            onClick={(e) => setAddAccountStepName('accountHolder')}
          >
            Account Holder
          </span>{' '}
          {`>`}{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            Account Details
          </span>
        </div>

        {conditionalFields()}
        {loading ? (
          <div className="loadingIcon">
            <LoadingAnimation icon={appIcon} width={200} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AccountDetails;
