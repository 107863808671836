import React, {useState, useEffect, useContext } from 'react';
import styles from "./sidebar.module.scss"
import close from './close.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import userArr from '../../static/images/affiliate/userArr.svg';
import userClose from '../../static/images/affiliate/userClose.svg';
import dropdown from '../../static/images/affiliate/dropdown.svg';
import loader from '../../static/images/affiliate/loader1.svg';
import { balForSelectedApp, getAllApps, inviteWithGift } from './api';
import { LoadingAnimation } from '../../components/LoadingAnimation';

const PreRegister = ({setPopular, setSelectedStep}) => {

    const {
        inviteStep,
        setInviteStep,
        invite,
        setInvite,
        selectedInviteApp,
        setSelectedInviteApp,
        isInviteappselected,
        setIsInviteappselected,
        selectedInviteAsset,
        setSelectedInviteAsset,
        isInviteAssetSelected,
        setIsInviteAssetSelected,
        invitefilteredApps,
        setinvitefilteredApps,
        invitefilteredAsset,
        setinvitefilteredAsset,
        appLogo,
      } = useAppContextDetails();

    const { email, token } = useContext(BankContext);
    const [allapps, setAllapps] = useState([]);
    const [coinData, setCoinData] = useState([]);
    const [friendname, setFriendName] = useState('');
    const [friendemail, setFriendEmail] = useState('');
    const [phoneno, setPhoneno] = useState('');
    const [message, setMessage] = useState('');
    const [amount, setAmount] = useState('');
    const [expiry, setExpiry] = useState('');
    const [isloading, setIsloading] = useState(false);


    useEffect(() => {
        getAllApps(email).then((response) => {
          let result = response?.data?.userApps;
          setAllapps(result);
          setinvitefilteredApps(result);
        });
      }, []);
    
      const assetSelection = () => {
        let obj = {
          app_code: selectedInviteApp?.app_code,
          profile_id: selectedInviteApp?.profile_id,
        };
        balForSelectedApp(obj).then((response) => {
          let result = response?.data?.coins_data;
          setCoinData(result);
          setinvitefilteredAsset(result);
        });
      };
    
      const initiateInvite = () => {
        setIsloading(true);
        let obj = {
          email,
          token,
          app_code: selectedInviteApp?.app_code,
          profile_id: selectedInviteApp?.profile_id,
          sending_currency: selectedInviteAsset?.coinSymbol,
          receiving_currency: selectedInviteAsset?.coinSymbol,
          sending_amount: amount,
          user_email: friendemail,
          user_name: friendname,
          user_phone: phoneno,
          custom_message: message,
          expire_in_days: expiry,
          stats: false,
        };


        inviteWithGift(obj).then((response) => {
          let result = response?.data;
          //   console.log(result, 'result');
          setIsloading(false);
          setInvite(false);
          setInviteStep('step1');
          setAllapps([]);
          setCoinData([]);
          setFriendName('');
          setFriendEmail('');
          setPhoneno('');
          setMessage('');
          setAmount('');
          setExpiry('');
          setSelectedInviteApp([]);
          setIsInviteappselected(false);
          setSelectedInviteAsset([]);
          setIsInviteAssetSelected(false);
        });
      };
    
      function filterItem(text) {
        let filterItem = allapps?.filter((eachitem) => {
          return eachitem?.app_name?.toLowerCase()?.includes(text?.toLowerCase());
        });
        setinvitefilteredApps(filterItem);
      }

      function filterItemAsset(text) {
        let filterItem = coinData?.filter((eachitem) => {
          return eachitem?.coinSymbol?.toLowerCase()?.includes(text?.toLowerCase());
        });
        setinvitefilteredAsset(filterItem);
      }

    return ( 
        <>
        <div className={styles.headTxt}>
            <div>Pre Register With A Gift</div>
            <div
            onClick={() => {
                setPopular(false);
                setSelectedStep(null);
            }}
            className="close-div"
            >
            <img src={close} alt="" />
            </div>
        </div>
        <div>
        {inviteStep === 'step1' ? (
            <div
              style={{
                height: '70vh',
                overflowY: 'scroll',
                marginTop: '1.5rem',
              }}
            >
              <div>
              <div className={styles.inputTitle}>Friends Name</div>
              <div className={styles.inputWrapper}>
                  <input
                    type="text"
                    placeholder="Name..."
                    value={friendname}
                    onChange={(e) => setFriendName(e.target.value)}
                    style={{ cursor: 'text', border:"none", width:'100%' }}
                  />
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Friends Email</div>
                 <div className={styles.inputWrapper}>
                  <input
                    type="email"
                    placeholder="Email..."
                    value={friendemail}
                    onChange={(e) => setFriendEmail(e.target.value)}
                    style={{ cursor: 'text', border:"none",width:'100%' }}
                  />
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Friends Phone Number</div>
                 <div className={styles.inputWrapper}>
                  <input
                    type="number"
                    placeholder="Phone Number..."
                    value={phoneno}
                    onChange={(e) => setPhoneno(e.target.value)}
                    style={{ cursor: 'text', border:"none",width:'100%' }}
                  />
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Message For Your Friend</div>
                 <div className={styles.inputWrapper}>
                  <input
                    type="text"
                    placeholder="Description...."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ cursor: 'text', border:"none",width:'100%' }}
                  />
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Select Debiting App</div>
              <div className={styles.inputWrapper}
                  onClick={() => setInviteStep('step2')}
                  style={{display:'flex', justifyContent:'space-between'}}
                >
                  {isInviteappselected ? (
                    <div style={{display:'flex', alignItems:'center'}}>
                      <img
                        src={selectedInviteApp?.app_icon}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <div style={{ fontSize: '1.15rem', fontWeight: '650', paddingLeft:"10px" }}>
                          {selectedInviteApp?.app_name}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Click Here To Select An App"
                      style={{ cursor: 'pointer', border:"none" }}
                    />
                  )}
                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Select Gift Asset</div>
              <div className={styles.inputWrapper}
                  onClick={() => {
                    assetSelection();
                    setInviteStep('step3');
                  }}
                  style={{display:'flex', justifyContent:'space-between'}}
                >
                  {isInviteAssetSelected ? (
                    <div className="each-appp2" style={{display:'flex', alignItems:'center'}}>
                      <img
                        src={selectedInviteAsset?.coinImage}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <div style={{ fontSize: '1.15rem', fontWeight: '650', paddingLeft:'10px' }}>
                          {selectedInviteAsset?.coinSymbol}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Click Here To Select The Asset"
                      style={{ cursor: 'pointer', border:'none' }}
                    />
                  )}

                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Enter Amount</div>
              <div className={styles.inputWrapper} style={{ paddingRight: '0' }}>
                  <input
                    type="number"
                    placeholder="0.000"
                    style={{ cursor: 'text', width: '80%', border:"none" }}
                    className="placeee"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="tbddiv">TBD</div>
                </div>
              </div>
              <div style={{ paddingTop: '30px' }}>
              <div className={styles.inputTitle}>Days Until Expiration</div>
              <div className={styles.inputWrapper} style={{ paddingRight: '0' }}>
                  <input
                    type="number"
                    placeholder="0"
                    style={{ cursor: 'text', width: '80%', border:'none' }}
                    className="placeee"
                    value={expiry}
                    onChange={(e) => setExpiry(e.target.value)}
                  />
                  <div className="tbddiv">Days</div>
                </div>
              </div>
            </div>
          ) : inviteStep === 'step2' ? (
            <div
              style={{
                height: '80vh',
                marginTop: '1.5rem',
              }}
            >
              <div className="each-field" style={{ paddingBottom: '0' }}>
              {/* <div className={styles.inputTitle}>Select App</div> */}
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  className="inputt-field"
                  placeholder="Search Apps..."
                  onChange={(event) => filterItem(event.target.value)}
                  style={{border:"none"}}
                />
                </div>
              </div>
              <div className="appslist" style={{ height: '80%',  overflowY: 'scroll', }}>
                {invitefilteredApps?.map((e, i) => {
                  return (
                    <div
                      className={styles.eachApp}
                      key={i}
                      onClick={() => {
                        setSelectedInviteApp(e);
                        setIsInviteappselected(true);
                        setInviteStep('step1');
                      }}
                    >
                      <img
                        src={e?.app_icon}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.app_name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
                <div className={styles.footerr} onClick={() => setInviteStep('step1')}>
                <div className={styles.submitBtn} style={{ width: '100%', marginTop:'20px' }}>
                    Cancel Selection
                </div>
                </div>
            </div>
          ) : (
            <div
              style={{
                height: '80vh',
                marginTop: '1.5rem',
              }}
            >
              <div className="each-field" style={{ paddingBottom: '0' }}>
                {/* <p>Select Asset</p> */}
                <div className={styles.inputWrapper}>
                <input
                  type="text"
                  className="inputt-field"
                  placeholder="Search Asset..."
                  onChange={(event) => filterItemAsset(event.target.value)}
                  style={{border:"none"}}
                />
                </div>
              </div>
              <div className="appslist" style={{ height: '80%', overflowY: 'scroll', }}>
                {invitefilteredAsset?.map((e, i) => {
                  return (
                    <div
                      className={styles.eachApp1}
                      key={i}
                      onClick={() => {
                        setSelectedInviteAsset(e);
                        setIsInviteAssetSelected(true);
                        setInviteStep('step1');
                      }}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <img
                        src={e?.coinImage}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.coinSymbol}
                        </p>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.coinValue?.toFixed(4)}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.footerr} onClick={() => setInviteStep('step1')}>
                <div className={styles.submitBtn} style={{ width: '100%', marginTop:'20px' }}>
                    Cancel Selection
                </div>
                </div>
            </div>
          )}
        </div>
        <div className="footerr" onClick={() => initiateInvite()}>
            <div className={styles.submitBtn} style={{ width: '100%', marginTop:"20px" }}>
                 Send Invitation
            </div>
          
        </div>
        </>
     );
}
 
export default PreRegister;