import React from 'react';
import { useEffect, useContext } from 'react';
import { useState } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { addActionForex } from './api';
import './addForex.scss';
import { ForexAddSkeletons } from './ForexAddSkeletons';
import { EachCardForList } from '../../EachCardForList';
import { ForexBreadCrumbs } from './ConditionalForexComponents';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../../context/AppContext';
import viralVerseFullLogo from '../../../static/images/logos/viralVerse.svg';

const AddForexAction = ({ ques, previousPage }) => {
  const {
    selectedCoin,
    setSelectedCoin,
    addApiValue,
    setAddApiValue,
    countryApiValue,
    setCountryApiValue,
    eachCardShowValue,
    setEachCardShowValue,
    toCurrencyApiValue,
    setToCurrencyApiValue,
    payMethodApiValue,
    setPayMethodApiValue,
    fundingCurrency,
    payMethod,
    otcDesk,
    currentStep,
    newWholeValue,
    setNewWholeValue,
    fromCurrencyData,
    setFromCurrencyData,
    setFundingCurrency,
    setCurrentStep,
    setPayMethod,
    setOtcDesk,
    setAddApiValueCoin,
    setBankerId,
    setSelectedTab,
    setOtcApiValue,
    setEnterAmount,
    setCoinAction,
    setCoinActionEnabled,
    senbtnClicked,
    setSendBtnClicked,
    enterAmount,
    coinAction,
    selectedTab,
  } = useCoinContextData();
  const { sidebarCollapse, admin } = useContext(BankContext);
  const { appLogo, appFullLogo } = useAppContextDetails();
  const history = useHistory();

  const [isLoading, setisLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debounceFunc = (value) => {
    setSearchValue(value);
    if (eachCardShowValue == 'step1' && fromCurrencyData.step1) {
      let searchValues = fromCurrencyData.step1.filter((data) => {
        if (data.coin_metadata) {
          let searchdata = data.coin_metadata.coinName.toLowerCase();
          let searchSymbol = data.coin_metadata.coinSymbol.toLowerCase();
          return (
            searchdata.includes(value.toLowerCase()) ||
            searchSymbol.includes(value.toLowerCase())
          );
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step1: searchValues,
      });
    } else if (eachCardShowValue == 'step2' && fromCurrencyData.step2) {
      let searchValues = fromCurrencyData.step2.filter((data) => {
        if (data.metadata) {
          let searchdata = data.metadata.name.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step2: searchValues,
      });
    } else if (eachCardShowValue == 'step3' && fromCurrencyData.step3) {
      let searchValues = fromCurrencyData.step3.filter((data) => {
        if (data.metadata) {
          let searchdata = data.metadata.name.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step3: searchValues,
      });
    } else if (eachCardShowValue == 'step4' && fromCurrencyData.step4) {
      let searchValues = fromCurrencyData.step4.filter((data) => {
        if (data) {
          let searchdata = data.displayName.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step4: searchValues,
      });
    }
  };

  useEffect(() => {
    setisLoading(true);
    let obj = {
      select_type: 'fund',
      to_currency: selectedCoin?.coinSymbol,
    };

    addActionForex(obj).then((response) => {
      let result = response.data.pathData.from_currency;
      setNewWholeValue({ ...newWholeValue, step1: result });
      setFromCurrencyData({ ...newWholeValue, step1: result });
      setisLoading(false);
    });
  }, []);

  useEffect(() => {
    setisLoading(true);

    if (payMethodApiValue) {
      obj = {
        select_type: 'fund',
        to_currency: selectedCoin?.coinSymbol,
        from_currency: addApiValue.value,
        country: countryApiValue.value,
        paymentMethod: payMethodApiValue.value,
      };
      getResponseFunc(obj);
    } else if (countryApiValue) {
      obj = {
        select_type: 'fund',
        to_currency: selectedCoin?.coinSymbol,
        from_currency: addApiValue.value,
        country: countryApiValue.value,
      };
      getResponseFunc(obj);
    } else if (addApiValue) {
      obj = {
        select_type: 'fund',
        to_currency: selectedCoin?.coinSymbol,
        from_currency: addApiValue.value,
      };
      getResponseFunc(obj);
    } else if (selectedCoin) {
      var obj = {
        select_type: 'fund',
        to_currency: selectedCoin?.coinSymbol,
      };
      getResponseFunc(obj);
    }

    function getResponseFunc() {
      addActionForex(obj).then((response) => {
        if (!fundingCurrency) {
          var result = response.data.pathData.from_currency;
          setEachCardShowValue('step1');
        } else if (!payMethod) {
          var result = response.data.pathData.country;
          setEachCardShowValue('step2');
        } else if (!otcDesk) {
          var result = response.data.pathData.paymentMethod;
          setEachCardShowValue('step3');
        } else {
          var result = response.data.pathData.banker;
          setEachCardShowValue('step4');
        }
        if (currentStep == 'step1') {
          setNewWholeValue({ ...newWholeValue, step1: result });
          setFromCurrencyData({ ...newWholeValue, step1: result });
        } else if (currentStep == 'step2') {
          setNewWholeValue({ ...newWholeValue, step2: result });
          setFromCurrencyData({ ...newWholeValue, step2: result });
        } else if (currentStep == 'step3') {
          setNewWholeValue({ ...newWholeValue, step3: result });
          setFromCurrencyData({ ...newWholeValue, step3: result });
        } else {
          setNewWholeValue({ ...newWholeValue, step4: result });
          setFromCurrencyData({ ...newWholeValue, step4: result });
        }
        setisLoading(false);
      });
    }
  }, [
    addApiValue,
    setAddApiValue,
    toCurrencyApiValue,
    setToCurrencyApiValue,
    countryApiValue,
    setCountryApiValue,
    payMethodApiValue,
    setPayMethodApiValue,
    selectedCoin,
    setSelectedCoin,
  ]);

  const nextPage = (value) => {
    if (!fundingCurrency) {
      setAddApiValue(value);
      setFundingCurrency(true);
      setCurrentStep('step2');
      setSelectedTab('fundingCurrency');
    } else if (!payMethod) {
      setCountryApiValue(value);
      setCurrentStep('step3');
      setPayMethod(true);
      setSelectedTab('payMethod');
    } else if (!otcDesk) {
      setPayMethodApiValue(value);
      setCurrentStep('step4');
      setOtcDesk(true);
      setSelectedTab('otcDesk');
    } else {
      setOtcApiValue(value);
      setCurrentStep('step5');
      setEnterAmount(true);
      setSelectedTab('enterAmount');
    }
  };

  const nextPageFunc = (value) => {
    nextPage(value);
  };

  return (
    <div className="newSidebar">
      <div className="top-text">
        <img
          className="clickable"
          src='https://publications.apimachine.com/images/874a0bb4-d349-4a63-b173-74cee33f5cb9.png'
          style={{ width: '60%' }}
          onClick={() => {
            if (admin) {
              // setAdminSidebar(true);
              // setChatOn(false);
              console.log('admin');
            } else {
              history.push('/malls');
              setCoinAction(['Menu']);
              setCoinActionEnabled(false);
            }
          }}
        />
      </div>
      <div className="new-breadcrumb">
        <ForexBreadCrumbs />
      </div>
      <div className="sending-text">
        {ques ? ques : 'Which Currency Are You Sending?'}
      </div>
      <div className="userAppscard" onMouseDown={(e) => e.stopPropagation()}>
        <>
          <div className="apps-card">
            {!isLoading ? (
              currentStep == 'step1' && fromCurrencyData.step1.length > 0 ? (
                newWholeValue.step1.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        setAddApiValueCoin(
                          singledata?.coin_metadata?.coinImage
                        );
                        nextPageFunc({
                          value: singledata.coin_metadata.coinSymbol,
                        });
                        setSearchValue('');
                      }}
                      icon={singledata.coin_metadata.coinImage}
                      label={singledata?.coin_metadata?.coinName}
                    />
                  );
                })
              ) : currentStep == 'step2' && newWholeValue.step2.length > 0 ? (
                newWholeValue.step2.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        nextPageFunc({
                          value: singledata?.metadata?.name,
                        });
                        setSearchValue('');
                      }}
                      icon={singledata?.metadata?.image}
                      label={singledata?.metadata?.name}
                    />
                  );
                })
              ) : currentStep == 'step3' && newWholeValue.step3.length > 0 ? (
                newWholeValue.step3.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        nextPageFunc({
                          value: singledata?.metadata?.code,
                        });
                        setSearchValue('');
                      }}
                      icon={singledata?.metadata?.icon}
                      label={singledata?.metadata?.name}
                    />
                  );
                })
              ) : newWholeValue.step4.length > 0 ? (
                newWholeValue.step4.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        setBankerId(singledata?._id);
                        nextPageFunc({
                          value: singledata?.displayName,
                        });
                        setSearchValue('');
                      }}
                      icon={singledata?.icons?.image1}
                      label={singledata?.displayName}
                    />
                  );
                })
              ) : (
                <div></div>
              )
            ) : (
              <ForexAddSkeletons />
            )}
          </div>
        </>
        <div className="newbtns-div">
          <div className="top-btns">
            <div
              style={{ background: '#9FD6DF' }}
              onClick={() => {
                if (selectedTab === 'sendingCurrency') {
                  setCoinAction(['Menu']);
                } else {
                  previousPage();
                }
              }}
            >
              Back
            </div>
            <div
              style={{ background: '#FF5E5E' }}
              onClick={() => {
                setCoinActionEnabled(false);
                setCoinAction(['Menu']);
                setFundingCurrency(false);
                setPayMethod(false);
                setOtcDesk(false);
                setEnterAmount(false);
                setCurrentStep('step1');
                setSelectedTab('sendingCurrency');
                setAddApiValue('');
                setToCurrencyApiValue('');
                setCountryApiValue('');
                setPayMethodApiValue('');
                setOtcApiValue('');
                setEachCardShowValue('step1');
                setNewWholeValue({
                  ...newWholeValue,
                  step2: '',
                  step3: '',
                  step4: '',
                });
                setSendBtnClicked('');
              }}
            >
              Close
            </div>
          </div>
          <div
            className="bottom-btn"
            onClick={() => {
              setCoinAction(['Menu']);
              setFundingCurrency(false);
              setPayMethod(false);
              setOtcDesk(false);
              setEnterAmount(false);
              setCurrentStep('step1');
              setSelectedTab('sendingCurrency');
              setAddApiValue('');
              setToCurrencyApiValue('');
              setCountryApiValue('');
              setPayMethodApiValue('');
              setOtcApiValue('');
              setEachCardShowValue('step1');
              setNewWholeValue({
                ...newWholeValue,
                step2: '',
                step3: '',
                step4: '',
              });
            }}
          >
            {selectedCoin?.coinSymbol ? selectedCoin?.coinSymbol : 'CAD'}{' '}
            Actions
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForexAction;
