import React from 'react';
import { useEffect, useContext } from 'react';
import Layout from '../../Layout/Layout';
import '../../pages/Malls/malls.scss';
import ddown from '../../static/images/malls/ddown.svg';
import mall from '../../static/images/malls/mall-icon.svg';
import search from '../../static/images/malls/search.svg';
import img1 from '../../static/images/malls/ham.svg';
import support from '../../static/images/malls/support.svg';
import cart from '../../static/images/malls/cart.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import CoinComponent from './CoinComponent';
import Step4 from './Step4';
import { planBContext } from '../../context/PlanBContext';

const MallProduct = () => {
  const history = useHistory();
  const { param } = useParams();
  const { index, setIndex, buy, setBuy, mallCoindata, setfilteredcoins } =
    useAppContextDetails();
  const { email } = useContext(BankContext);

  const {
    aiIndexOfferings,
    filteredApiData,
    setFilteredApiData,
    apiData,
    setApiData,
    isloading,
    setisloading,
  } = useContext(planBContext);


  useEffect(() => {
    if(filteredApiData){
      const url = history.location.pathname;
      const lastPart = url.substring(url.lastIndexOf('/') + 1);
      const found = filteredApiData.find(o => o.product_code === lastPart)
      if(found){
        setIndex(found)
      }

    }
  }, [filteredApiData])



  // useEffect(() => {
  //   let product = localStorage.getItem('product');
  //   setIndex(JSON.parse(product));
  //   console.log(JSON.parse(product), 'productsfswfwefw');
  //   // console.log(
  //   //   JSON.parse(product)?.pricesWithAppFees?.[1]?.coin,
  //   //   'product coin'
  //   // );
  // }, []);

  function filterItem(text) {
    let filterItem = mallCoindata?.filter((eachitem) => {
      return eachitem?.coinSymbol?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setfilteredcoins(filterItem);
  }

  return (
    <div className="malls">
      <div className="malls-products">
        <div className="w-space">
          <div className="optionss">
            <div className="hamburger">
              <p
                onClick={() => {
                  if(email){
                    history.push('/malls');
                  }else{
                    history.push('/shop');
                  }
                  setBuy('step1');
                }}
                className="hov-animate"
              >
                Home{' '}
              </p>{' '}
              &nbsp; &gt;
              <p> &nbsp; {index?.product_name}</p>
            </div>
          </div>
        </div>
        <div className="content-area">
          <div className="left-divv">
            <div className="productt-det">
              <img
                src={index?.product_icon}
                alt=""
                style={{ width: '20%', height: '100%' }}
              />
              <div className="pro-name">
                <p
                  style={{
                    fontSize: '2rem',
                    fontWeight: '700',
                    color: '#18191d',
                  }}
                >
                  {index?.product_name}
                </p>
                <p style={{ color: '#757883' }}>{index?.sub_text}</p>
              </div>
            </div>

            <div className="price-section">
              <div className="about-section">
                <p
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '700',
                    color: '#18191d',
                  }}
                >
                  About {index?.product_name}
                </p>
                <div>{index?.full_description}</div>
              </div>
              <div className="price-divv">
                <p
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '700',
                    color: '#18191d',
                  }}
                >
                  Pricing Details
                </p>
                <div className="pricing">
                  <div className="billing-txt">Billing Type:</div>
                  <div className="pricing-btns">
                    <button
                      style={{
                        background: index?.lifetime
                          ? 'rgba(229, 229, 229, 0.37)'
                          : '#FFFFFF',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      One Time
                    </button>
                    <button
                      style={{
                        background: index?.lifetime
                          ? '#FFFFFF'
                          : 'rgba(229, 229, 229, 0.37)',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      Recurring
                    </button>
                  </div>
                </div>
                <div className="pricing">
                  <div className="billing-txt">Frequency:</div>
                  <div className="pricing-btns">
                    <button
                      style={{
                        background: index?.monthly
                          ? 'rgba(229, 229, 229, 0.37)'
                          : '#FFFFFF',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      Monthly
                    </button>
                    <button
                      style={{
                        background: index?.monthly
                          ? '#FFFFFF'
                          : 'rgba(229, 229, 229, 0.37)',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      Anually
                    </button>
                  </div>
                </div>
                <div className="pricing">
                  <div className="billing-txt">Staking:</div>
                  <div className="pricing-btns">
                    <button
                      style={{
                        background: index?.staking_allowed
                          ? 'rgba(229, 229, 229, 0.37)'
                          : '#FFFFFF',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      Allowed
                    </button>
                    <button
                      style={{
                        background: index?.staking_allowed
                          ? '#FFFFFF'
                          : 'rgba(229, 229, 229, 0.37)',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      Not Allowed
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="price-divv"
                style={{
                  paddingTop: '2rem',
                  height: '23rem',
                  borderBottom: 'none',
                }}
              >
                <p
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '700',
                    color: '#18191d',
                  }}
                >
                  Other Data
                </p>
                <div className="pricing">
                  <div className="billing-txt">Affiliate Points:</div>
                  <div className="pricing-btns">
                    <button
                      style={{
                        background: index?.points_creation
                          ? 'rgba(229, 229, 229, 0.37)'
                          : '#FFFFFF',
                      }}
                      className="onetym-btn"
                    >
                      Yes
                    </button>
                    <button
                      style={{
                        background: index?.points_creation
                          ? '#FFFFFF'
                          : 'rgba(229, 229, 229, 0.37)',
                        border: '0.5px solid #e5e5e5',
                      }}
                      className="onetym-btn"
                    >
                      No
                    </button>
                  </div>
                </div>
                <div className="pricing">
                  <div className="billing-txt">Grace Period:</div>
                  <div
                    className="pricing-btns"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#18191d',
                      fontWeight: '700',
                    }}
                  >
                    {index?.grace_period} Days
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-divv">
            {buy === 'step1' ? (
              <>
                <div className="amount-details">
                  <div
                    className="left-amnt"
                    style={{ borderRight: '1px solid #E7E7E7' }}
                  >
                    <p className="amnt-font">
                      {/* {index?.pricesWithAppFees?.length > 0 &&
                      index?.pricesWithAppFees[0]?.price
                        ? index?.pricesWithAppFees[0]?.price?.toFixed(2)
                        : '0.00'}
                      &nbsp;
                      {index?.pricesWithAppFees?.length > 0 &&
                      index?.pricesWithAppFees?.[0]?.coin
                        ? index?.pricesWithAppFees?.[0]?.coin
                        : ''} */}
                          {index?.first_purchase?.price|| '0.00'}
                    </p>
                    <p className="text-font">
                      {/* {index?.pricesWithAppFees?.length > 0 &&
                      index?.pricesWithAppFees[0]?.billing_method ===
                        'first_purchase'
                        ? 'First Purchase'
                        : ''} */}
                        First Purchase
                    </p>
                  </div>
                  <div className="left-amnt1">
                    <p className="amnt-font">
                      {/* {index?.pricesWithAppFees?.length > 0 &&
                      index?.pricesWithAppFees[1]?.price
                        ? index?.pricesWithAppFees[1]?.price?.toFixed(2)
                        : '0.00'}
                      &nbsp;
                      {index?.pricesWithAppFees?.length > 0 &&
                      index?.pricesWithAppFees[1]
                        ? index?.pricesWithAppFees?.[1]?.coin
                        : ''} */}
                        {index?.billing_cycle?.monthly 
                          ? index?.billing_cycle?.monthly?.price ? index?.billing_cycle?.monthly?.price : '0.00'
                          : index?.billing_cycle?.annual?.price ? index?.billing_cycle?.annual?.price : '0.00'}
                    </p>
                    <p className="text-font">
                      {/* {index?.pricesWithAppFees?.length > 0 &&
                      index?.pricesWithAppFees?.[1]?.billing_method ===
                        'monthly'
                        ? 'Monthly'
                        : index?.pricesWithAppFees?.[1]?.billing_method ===
                          'lifetime'
                        ? 'Lifetime'
                        : ''} */}
                          {index?.annual? 'Annual': 'Monthly'}
                    </p>
                  </div>
                </div>
                <div className="billing">
                  <div>Billing Currency:</div>
                  <div>
                    {index?.billing_cycle?.monthly?.coin
                      ? index?.billing_cycle?.monthly?.coin
                      : index?.billing_cycle?.annual?.coin}
                  </div>
                </div>
                {/* <div className="buttonss">
                  <button
                    className="buy-btn"
                    onClick={() => {
                      // setBuy('step2');
                      openStripeLink(index?.product_id)
                    }}
                  >
                    Buy Now
                  </button>
                  <button className="share-btn">Share</button>
                </div> */}
                <div className='buttonWrapper1'>
                  <div className='buyBtn' onClick={e => window.open(`${index?.other_data?.IBOproductlink}?client_reference_id=${index?.other_data?.IBOproductid}`)}>Buy With $20/Month IBO Add On</div>
                  <div className='buyBtn1' onClick={e => window.open(`${index?.other_data?.link}?client_reference_id=${index?.product_id}`)}>Buy Without IBO Add On</div>
                </div>
              </>
            ) : buy === 'step2' ? (
              <div className="buy-step1">
                <div
                  style={{
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    color: '#18191d',
                  }}
                >
                  Select Currency To Pay With?
                </div>
                <div className="searchh">
                  <input
                    type="text"
                    placeholder="Search DGP Vaults.."
                    onChange={(event) => filterItem(event.target.value)}
                  />
                </div>
                <div className="coin-options">
                  <CoinComponent />
                </div>
              </div>
            ) : buy === 'step3' ? (
              <div className="buy-step1">
                <div
                  style={{
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    color: '#18191d',
                  }}
                >
                  Are You Sure You Want To Subscribe To {index?.product_name}?
                </div>
                <div className="boxx" onClick={() => setBuy('step4')}>
                  Confirm Subscription
                </div>
                <div
                  className="boxx"
                  style={{
                    marginTop: '1.5rem',
                  }}
                  onClick={() => setBuy('step2')}
                >
                  Go Back
                </div>
                <div
                  className="boxx"
                  style={{
                    marginTop: '1.5rem',
                  }}
                >
                  Cancel Order
                </div>
              </div>
            ) : (
              <div className="buy-step1">
                <Step4 />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MallProduct;
