import React from 'react';
import classNames from './learn.module.scss';
import './learn.scss';
import { useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { useState } from 'react';

import playBtn from '../../static/images/academy/playlogo.svg';
import Skeleton from 'react-loading-skeleton';

import camIcon from '../../assets/images/icons/cam.svg';
import playIcon from '../../assets/images/icons/play.svg';
import { ReactComponent as PlayFill } from '../../assets/images/icons/playfill.svg';
import { ReactComponent as RightArrow } from '../../assets/images/icons/rightarrow.svg';

import coursesIcon from '../../assets/images/icons/curriculum/courses.svg';
import webinarsIcon from '../../assets/images/icons/curriculum/webinars.svg';
import testsIcon from '../../assets/images/icons/curriculum/tests.svg';
import certificatesIcon from '../../assets/images/icons/curriculum/certificates.svg';

import profilePictureTemp from '../../assets/images/temp/profile.svg';
import { ReactComponent as CompletedTickIcon } from '../../assets/images/icons/completedtick.svg';
import { ReactComponent as PlayIconComponent } from '../../assets/images/icons/playicon.svg';
import { ReactComponent as AccessIcon } from '../../assets/images/icons/access.svg';
import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder.svg';
import { ReactComponent as CertificateIcon } from '../../assets/images/icons/certificate.svg';
import { ReactComponent as AiIcon } from '../../assets/images/icons/ai.svg';
import { ReactComponent as LiveIcon } from '../../assets/images/icons/live.svg';
import { ReactComponent as ChatIcon } from '../../assets/images/icons/chat.svg';
import { ReactComponent as MentorIcon } from '../../assets/images/icons/mentor.svg';
import { ReactComponent as PeersIcon } from '../../assets/images/icons/peers.svg';
import ratingIcon from '../../assets/images/icons/rating.svg';

import calendarIcon from '../../assets/images/icons/calendar.svg';
import clockIcon from '../../assets/images/icons/clock.svg';
import moment from 'moment';
import { useRef } from 'react';

const Learn = ({ setCurrentSubPage }) => {
  const { publicationIDGlobal } = useContext(GlobalContext);
  const [learnCourses, setLearnCourses] = useState('');
  const [learnCoursesLoading, setLearnCoursesLoading] = useState('');

  const [allWebinars, setAllWebinars] = useState('');
  const [allWebinarsLoading, setAllWebinarsLoading] = useState('');

  useEffect(() => {
    setLearnCoursesLoading(true);
    axios
      .get(
        `https://publications.apimachine.com/courses/list?publication_id=64ca3664faa4a7072d3cd751`
      )
      .then((response) => {
        let res = response?.data?.data;
        if (response?.data?.status && res?.length > 0) {
          setLearnCourses(res);
        }
        setLearnCoursesLoading(false);
        // console.log(response?.data, 'learn response');
      })
      .catch((error) => {
        console.log(error?.message);
        setLearnCoursesLoading(false);
      });
  }, []);

  useEffect(() => {
    setAllWebinarsLoading(true);
    axios
      .get(
        `https://publications.apimachine.com/webinar/list?publication_id=64ca3664faa4a7072d3cd751`
      )
      .then((response) => {
        let res = response?.data?.data;
        if (response?.data?.status && res?.length > 0) {
          setAllWebinars(res);
        }
        setAllWebinarsLoading(false);
        // console.log(response?.data, 'all webinars response');
      })
      .catch((error) => {
        console.log(error?.message);
        setAllWebinarsLoading(false);
      });
  }, []);

  const allCurriculam = [
    {
      icon: coursesIcon,
      count: '10',
      title: 'Total Courses',
      mainColor: 'rgba(23, 197, 205, 0.1)',
      borderColor: 'rgba(23, 197, 205, 1)',
    },
    {
      icon: webinarsIcon,
      count: '10',
      title: 'Total Webinars',
      mainColor: 'rgba(75, 42, 145, 0.1)',
      borderColor: 'rgba(75, 42, 145, 1)',
    },
    {
      icon: testsIcon,
      count: '8/32',
      title: 'Completed Tests',
      mainColor: 'rgba(255, 186, 50, 0.1)',
      borderColor: 'rgba(255, 186, 50, 1)',
    },
    {
      icon: certificatesIcon,
      count: '3/13',
      title: 'Certificates',
      mainColor: 'rgba(255, 109, 58, 0.1)',
      borderColor: 'rgba(255, 109, 58, 1)',
    },
  ];

  return (
    <div
      className={classNames.learnComponent}
      // style={{ background: '#f5f5f5' }}
    >
      <div className={classNames.allCurriculum}>
        <div className={classNames.heading}>Curriculum Overview</div>
        <div className={classNames.curriculum}>
          {allCurriculam?.length > 0 &&
            allCurriculam?.map(
              ({ icon, count, title, mainColor, borderColor }, index) => {
                return (
                  <div
                    key={title + index}
                    style={{
                      border: `1px solid ${borderColor}`,
                      background: mainColor,
                    }}
                  >
                    <div className={classNames.topDiv}>
                      <div className={classNames.imageDiv}>
                        <img src={icon} alt={title} />
                      </div>
                      <div className={classNames.contentDiv}>
                        <div className={classNames.count}>{count}</div>
                        <div className={classNames.title}>{title}</div>
                      </div>
                    </div>
                    <div
                      className={classNames.bottomDiv}
                      style={{ borderTop: `1px solid ${borderColor}` }}
                    >
                      <span style={{ color: borderColor }}>See Details</span>
                      <RightArrow fill={borderColor} />
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </div>

      <div className={classNames.allCourses}>
        <div className={classNames.heading}>All Courses</div>
        <div className={classNames.courses}>
          {learnCoursesLoading
            ? Array(3)
                .fill(' ')
                .map((item, index) => <EachLearnCardLoading />)
            : learnCourses?.length > 0
            ? learnCourses?.map((eachcourse, index) => {
                return (
                  <EachLearnCard
                    {...eachcourse}
                    key={'learncourse' + index}
                    setCurrentSubPage={setCurrentSubPage}
                  />
                );
              })
            : ''}
        </div>
      </div>

      <div className={classNames.allStreams}>
        <div className={classNames.heading}>Upcoming Webinars</div>
        <div className={classNames.streams}>
          {allWebinarsLoading
            ? Array(3)
                .fill(' ')
                .map((item, index) => <EachLearnCardLoading />)
            : allWebinars?.length > 0
            ? allWebinars?.map((eachcourse, index) => {
                return (
                  <EachStreamCard
                    {...eachcourse}
                    key={'learnstream' + index}
                    setCurrentSubPage={setCurrentSubPage}
                  />
                );
              })
            : ''}
        </div>
      </div>
    </div>
  );
};

export default Learn;

const EachLearnCard = ({
  name,
  profile_pic,
  tagline,
  cost,
  currency,
  preview_video,
  _id,
  setCurrentSubPage,
}) => {
  const { courseDetailsAPI } = useContext(GlobalContext);
  return (
    <div
      className={classNames.learnCard}
      onClick={() => {
        setCurrentSubPage('singlecoursepage');
        courseDetailsAPI(_id);
      }}
    >
      <div className={classNames.imageContainer}>
        <img
          src={profile_pic ? profile_pic : ''}
          alt=""
          className={classNames.learnImage}
        />
        <img
          src={playBtn ? playBtn : ''}
          alt=""
          className={classNames.playImage}
          onClick={() => {
            if (preview_video?.includes('http')) {
              window.open(preview_video, '_blank');
            }
          }}
        />
      </div>
      <div className={classNames.contentContainer}>
        <div className={classNames.title}>{name ? name : ''}</div>
        <div className={classNames.description}>{tagline ? tagline : ''}</div>
        {/* <div className={classNames.amount}>
          <div>
            Only &nbsp;{cost ? cost?.toFixed(2) : ''}&nbsp;
            {currency ? currency : ''}
          </div>
          <div>4.5</div>
        </div> */}
        <div className={classNames.btns}>
          <div>
            Only {cost ? cost?.toFixed(2) : ''} {currency ? currency : ''}
          </div>
          {/* <div>Learn More</div> */}
        </div>
      </div>
    </div>
  );
};
const EachStreamCard = ({
  category,
  image,
  email,
  title,
  date,
  startTime,
  endTime,
  registrationLink,
}) => {
  const { courseDetailsAPI } = useContext(GlobalContext);
  return (
    <div className={classNames.streamCard}>
      <div className={classNames.imageContainer}>
        <img
          src={image ? image : ''}
          alt=""
          className={classNames.learnImage}
        />
      </div>
      <div
        className={classNames.contentContainer}
        // onClick={() => {
        //   setCurrentSubPage('singlecoursepage');
        //   courseDetailsAPI(_id);
        // }}
      >
        <div className={classNames.author}>
          <span>{category?.length > 0 ? category[0] : ''}</span>
          <span> {email ? email : ''}</span>
        </div>
        <div className={classNames.title}>{title ? title : ''}</div>
        <div className={classNames.date}>
          <span>
            <img src={calendarIcon} alt="calendarIcon" />{' '}
            <span>{date ? moment(date).format('MMM Do YYYY') : ''}</span>
          </span>
          <span>
            <img src={clockIcon} alt="clockIcon" />
            <span>
              {startTime ? startTime : ''} - {endTime ? endTime : ''}
            </span>
          </span>
        </div>
        {/* <div className={classNames.amount}>
          <div>
            Only &nbsp;{cost ? cost?.toFixed(2) : ''}&nbsp;
            {currency ? currency : ''}
          </div>
          <div>4.5</div>
        </div> */}
      </div>
      <div className={classNames.btns}>
        <div
          onClick={() => {
            window.open(registrationLink, '_blank');
          }}
        >
          Join Now
        </div>
        {/* <div>Learn More</div> */}
      </div>
    </div>
  );
};

const EachLearnCardLoading = ({
  name,
  profile_pic,
  tagline,
  cost,
  currency,
  preview_video,
}) => {
  return (
    <div className={classNames.learnCard}>
      <div className={classNames.imageContainer}>
        <Skeleton
          className={classNames.learnImage}
          width={'100%'}
          height={80}
          // style={{ height: '120%', width: '40%' }}
        />
        <img
          src={playBtn ? playBtn : ''}
          alt=""
          className={classNames.playImage}
          onClick={() => {
            if (preview_video?.includes('http')) {
              window.open(preview_video, '_blank');
            }
          }}
        />
      </div>
      <div className={classNames.contentContainer}>
        <Skeleton width={180} height={25} />
        <Skeleton width={160} height={25} />
        <div className={classNames.amount}>
          <div>
            <Skeleton width={130} height={25} />
          </div>
          <Skeleton width={120} height={25} />
        </div>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <Skeleton width={130} height={25} />
          <Skeleton width={130} height={25} />
        </div>
      </div>
    </div>
  );
};
// camIcon;
export const DetailedCoursePage = ({ setCurrentSubPage }) => {
  const { selectedCourseDetails, setSelectedSectionDetails } =
    useContext(GlobalContext);
  const [selectedToggle, setSelectedToggle] = useState('Personal');
  const targetDivRef = useRef(null);

  useEffect(() => {
    let firstsection = document.querySelector('#breadcrumb-text');
    let hiddendiv = document.querySelector('#hiding-div');
    // let buynow = document.querySelector('.hide-container');
    let videoSection = document.querySelector('#video-section');
    let sidebarcontainer = document.querySelector('#malls-sidebar-container');
    let sidebarcontainerLeft = document.querySelector(
      '#malls-sidebar-container-left'
    );

    let observer = new IntersectionObserver(function (entries) {
      entries.forEach((eachitem) => {
        if (
          eachitem.target.className == 'breadcrumb-text' &&
          eachitem.isIntersecting
        ) {
          hiddendiv.style.display = 'none';
        } else if (
          eachitem.target.className == 'breadcrumb-text' &&
          eachitem.isIntersecting == false
        ) {
          hiddendiv.style.display = 'block';
        }

        if (
          // eachitem.target.className == 'hide-container' &&
          eachitem.isIntersecting == false
        ) {
          videoSection.style.display = 'none';
          sidebarcontainer.style.position = 'absolute';
          sidebarcontainer.style.zIndex = '3';
          sidebarcontainer.style.top = 'calc(12% + 1rem)';
          sidebarcontainer.style.right = '3rem';
          sidebarcontainer.style.width = '30.5%';
          sidebarcontainerLeft.style.width = 'calc(67.5% - 3rem)';
        } else {
          sidebarcontainer.style.position = 'unset';
          sidebarcontainer.style.width = '35%';
          videoSection.style.display = 'flex';
          sidebarcontainerLeft.style.width = '70%';
        }
        // else if (
        //   eachitem.target.className == 'hide-container' &&
        //   eachitem.isIntersecting == true
        // ) {
        //   videoSection.style.display = 'flex';
        //   sidebarcontainer.style.position = 'unset';
        //   sidebarcontainer.style.width = '35%';
        // }
      });
    });

    let observerArray = [firstsection];
    observerArray.forEach((eachitem) => {
      observer.observe(eachitem);
    });
  }, []);

  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let hiddendiv = document.querySelector('#hiding-div');
    const targetDiv = targetDivRef?.current;

    // console.log(targetDiv, 'targetDiv');
    const handleScroll = () => {
      const scrollY = targetDiv.scrollTop;
      // console.log('working scrollY', scrollY);
      // setIsVisible(scrollY >= 50);
      if (scrollY >= 80) {
        hiddendiv.style.display = 'block';
      } else {
        hiddendiv.style.display = 'none';
      }
    };

    if (targetDiv) {
      targetDiv.addEventListener('scroll', handleScroll);
    }

    // Cleanup the event listener when the component is unmounted
    return () => {
      if (targetDiv) {
        targetDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const learnCourse = {
    _id: '64ca3b8ffaa4a7072d3cd755',
    category: ['64ca39f8faa4a7072d3cd753'],
    navbar: ['64ca3a45faa4a7072d3cd754'],
    subscription: 'paid',
    status: 'active',
    name: 'Sales Training Masterclass',
    publication_id: '64ca3664faa4a7072d3cd751',
    profile_pic:
      'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/8bb02746db9ceedf94b62cf8822c79a3',
    tagline: 'The first step in transforming your sales career',
    language: 'English',
    what_you_will_learn: 'The first step in transforming your sales career',
    requirements: 'The first step in transforming your sales career',
    description: 'The first step in transforming your sales career',
    audience: 'Everyone',
    preview_video: 'https://arminshafee.wistia.com/medias/pzlbsq6qgy',
    cost: 994,
    currency: 'USD',
    createdAt: '2023-08-02T11:18:39.448Z',
    updatedAt: '2023-08-02T11:18:39.448Z',
    __v: 0,
    email: 'shorupan@gmail.com',
    user_details: [
      {
        _id: '6446c1eb82327c997cd909d6',
        first_name: 'shorupan',
        last_name: 'pirakaspathy',
        email: 'shorupan@gmail.com',
        profile_pic: '',
      },
    ],
    pub_sections: [
      {
        _id: '64cbcdede1838110c582b327',
        status: 'active',
        course_id: '64ca3b8ffaa4a7072d3cd755',
        section_name: 'Master Sales',
        section_description:
          '\nThe sale master is the source of new ideas to a customer. They understand the paradox that we get what we want by helping others get what they want. The master sales person can measure the results of their sales activities and has prioritized their time around the most important tasks.',
        section_order: 1,
        createdAt: '2023-08-03T15:55:25.193Z',
        updatedAt: '2023-08-03T15:55:25.193Z',
        __v: 0,
        pub_video_sections: [
          {
            _id: '64cbceaee1838110c582b328',
            status: 'active',
            section_id: '64cbcdede1838110c582b327',
            name: 'Lesson 1 - Pitfalls To Avoid',
            description:
              "There's a number of common missteps that almost everyone takes during their sales career. Trying to win a sale, salespeople tend to exaggerate, overestimate, misinterpret, and otherwise miss the mark.",
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/95594f7b39f1fc557f9b024a43e07398',
            video_link: 'https://arminshafee.wistia.com/medias/pzlbsq6qgy',
            video_length: '160.00',
            video_order: 1,
            createdAt: '2023-08-03T15:58:38.575Z',
            updatedAt: '2023-08-03T15:58:38.575Z',
            __v: 0,
          },
          {
            _id: '64cbcf38e1838110c582b329',
            status: 'active',
            section_id: '64cbcdede1838110c582b327',
            name: 'Lesson 2 - The Goal Of Every Sales Call',
            description:
              "All initial sales calls have one prime objective – build brand trust in the prospect's mind! However, it can't be the same for every type of sales call during the sales process. Usually, sales call objective depends on the type of calls you are conducting. Be clear about your objectives to keep the ball in your court.\n",
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/15e88fcda8559ca13f46c1d773bc3d05',
            video_link: 'https://arminshafee.wistia.com/medias/yok8eepeui',
            video_length: '18',
            video_order: 2,
            createdAt: '2023-08-03T16:00:56.632Z',
            updatedAt: '2023-08-03T16:00:56.632Z',
            __v: 0,
          },
          {
            _id: '64cbdd39e1838110c582b32b',
            status: 'active',
            section_id: '64cbcdede1838110c582b327',
            name: 'Lesson 3 -The Anatomy Of A High-Ticket Sales Call',
            description:
              "Questions are fundamental to the selling process. Where many salespeople fail is listening to the response of their question. If you're too focused on what you're going to ask next, you aren't having a conversation, you're running through a checklist.\n",
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/26dcf8606c5aa5ad724a9fb56e602ec3',
            video_link: 'https://arminshafee.wistia.com/medias/oeba94p2il',
            video_length: '11',
            video_order: 3,
            createdAt: '2023-08-03T17:00:41.176Z',
            updatedAt: '2023-08-03T17:00:41.176Z',
            __v: 0,
          },
          {
            _id: '64cbdd82e1838110c582b32c',
            status: 'active',
            section_id: '64cbcdede1838110c582b327',
            name: 'Lesson 4 - The Push Away Strategy',
            description:
              'In summary, push strategies focus on the distribution channel and on encouraging intermediaries to stock and sell the product. In contrast, pull strategies focus on creating consumer demand and encouraging them to request the product from intermediaries.\n',
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/9c4e5c10fd645698a6a74c5453b2840d',
            video_link: 'https://arminshafee.wistia.com/medias/6k3ggkcqdn',
            video_length: '40',
            video_order: 4,
            createdAt: '2023-08-03T17:01:54.722Z',
            updatedAt: '2023-08-03T17:01:54.722Z',
            __v: 0,
          },
        ],
        video_count: 4,
      },
      {
        _id: '64cbddbee1838110c582b32d',
        status: 'active',
        course_id: '64ca3b8ffaa4a7072d3cd755',
        section_name: 'Messenger Sales (Booking Calls)',
        section_description:
          "Messenger calls let you use link sharing to connect to more people. Depending on your call settings, participants won't need to download, log in to or connect on Messenger to join your call. When you use Messenger calls, these privacy settings apply: Any participant can leave the call if they feel uncomfortable.\n",
        section_order: 2,
        createdAt: '2023-08-03T17:02:54.515Z',
        updatedAt: '2023-08-03T17:02:54.515Z',
        __v: 0,
        pub_video_sections: [
          {
            _id: '64cbde5fe1838110c582b32f',
            status: 'active',
            section_id: '64cbddbee1838110c582b32d',
            name: 'Lesson 11 - The Conversation Loop Strategy',
            description:
              "Conversation flow happens when conversation is comfortable, effortless and smooth. It's the way conversations are supposed to work. Sometimes, conversation flow seems to happen automatically.\n",
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/95febb06d6b933984bf1021e3038600c',
            video_link: 'https://arminshafee.wistia.com/medias/a8sezdnh8p',
            video_length: '6',
            video_order: 1,
            createdAt: '2023-08-03T17:05:35.112Z',
            updatedAt: '2023-08-03T17:17:18.125Z',
            __v: 0,
          },
          {
            _id: '64cbdec3e1838110c582b330',
            status: 'active',
            section_id: '64cbddbee1838110c582b32d',
            name: 'Lesson 12 - Messenger Script Mastery',
            description:
              '\nSome chatbots earn money through commission-based models. For example, a chatbot may recommend products or services to customers and receive a commission on any sales that result from the recommendation. This can incentivize the chatbot to provide high-quality recommendations that result in sales.',
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/6ad1ed12d13773c255c7693b0dd01040',
            video_link: 'https://arminshafee.wistia.com/medias/9h1yltmff5',
            video_length: '90',
            video_order: 2,
            createdAt: '2023-08-03T17:07:15.270Z',
            updatedAt: '2023-08-03T17:07:15.270Z',
            __v: 0,
          },
          {
            _id: '64cbdf3ae1838110c582b331',
            status: 'active',
            section_id: '64cbddbee1838110c582b32d',
            name: 'Lesson 13 - The Testimonial Setting Method',
            description:
              'A testimonial is a statement from a customer about how a product or service has benefitted them and often includes them recommending it. Testimonial advertising uses these authentic customer reviews in ad copy and creative to build trust in potential customers—making it one of the best ways to market your business.',
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/ffac5e18910a446508ec007337574bf7',
            video_link: 'https://arminshafee.wistia.com/medias/8psz6du5pr',
            video_length: '12',
            video_order: 3,
            createdAt: '2023-08-03T17:09:14.892Z',
            updatedAt: '2023-08-03T17:09:14.892Z',
            __v: 0,
          },
        ],
        video_count: 3,
      },
      {
        _id: '64cbddf3e1838110c582b32e',
        status: 'active',
        course_id: '64ca3b8ffaa4a7072d3cd755',
        section_name: 'Sales Vault',
        section_description:
          'Sales training is the process of educating salespeople and equipping them with the skills they need to succeed in their role. Formal sales training can cover topics ranging from how to overcome buyer objections to learning how to use a new CRM software.25-Jul-2022\n',
        section_order: 3,
        createdAt: '2023-08-03T17:03:47.043Z',
        updatedAt: '2023-08-03T17:03:47.043Z',
        __v: 0,
        pub_video_sections: [
          {
            _id: '64cbdf98e1838110c582b332',
            status: 'active',
            section_id: '64cbddf3e1838110c582b32e',
            name: 'Lesson 14 - Armin Closing A Sale In Real-Time',
            description:
              '\nThe Closing Process is a step in the accounting cycle that occurs at the end of the accounting period, after the financial statements are completed. This serves to get everything ready for the next year.',
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/6963788e42f178d3d796469559464cf8',
            video_link: 'https://arminshafee.wistia.com/medias/6s4w8zhwbe',
            video_length: '72',
            video_order: 1,
            createdAt: '2023-08-03T17:10:48.641Z',
            updatedAt: '2023-08-03T17:10:48.641Z',
            __v: 0,
          },
          {
            _id: '64cbdfdce1838110c582b333',
            status: 'active',
            section_id: '64cbddf3e1838110c582b32e',
            name: 'Lesson 15 - Leveraging Objections To Close The Sale',
            description:
              "After you're confident you've uncovered all objections, address the most important objection first. Once you work through the greatest barrier to moving forward, other concerns may no longer matter as much to the buyer. You should do your best to resolve their issue right away if possible.",
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/f33f1ece212b54452e6a867dcd2d0ef7',
            video_link: 'https://arminshafee.wistia.com/medias/o8wantulh0',
            video_length: '28',
            video_order: 2,
            createdAt: '2023-08-03T17:11:56.339Z',
            updatedAt: '2023-08-03T17:11:56.339Z',
            __v: 0,
          },
          {
            _id: '64cbe025e1838110c582b334',
            status: 'active',
            section_id: '64cbddf3e1838110c582b32e',
            name: 'Lesson 16 - How to close Couples on a Sales call',
            description:
              "Highlight your value proposition. One of the perfect ways of closing a sales call is by highlighting the key points of your offering. However, you must remember that you won't close the deal on every sales call, but you can say something that lets your prospect rethink you",
            image:
              'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/229faa2fbbc20f69243892f249c5b473',
            video_link: 'https://arminshafee.wistia.com/medias/0upwbgmg7u',
            video_length: '16',
            video_order: 3,
            createdAt: '2023-08-03T17:13:09.749Z',
            updatedAt: '2023-08-03T17:13:09.749Z',
            __v: 0,
          },
        ],
        video_count: 3,
      },
    ],
    section_count: 3,
    section_video_count: 10,
  };
  return (
    <div className={classNames.detailedCourse} ref={targetDivRef}>
      {/* imported */}
      <div id="hiding-div" style={{ background: '#7899CF' }}>
        <p
          style={{
            fontWeight: '700',
            fontSize: '1.1rem',
            marginBottom: '0.4rem',
            color: 'white',
          }}
        >
          Master Sales Today: From Beginner Sales Person To Expert Closer
        </p>
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: 'white' }}>
            4.0 <span style={{ paddingLeft: '0.5rem' }}>|</span>
          </span>
          <span style={{ color: 'white' }}> 335 Ratings </span>
          <span>|</span>
          <span style={{ color: 'white' }}>
            <span style={{ paddingRight: '0.5rem' }}>|</span> 502 Students
          </span>
        </p>
      </div>
      {/* imported end */}

      <div
        className={`${classNames.breadCrumb}`}
        id="breadcrumb-text"
        style={{ background: '#7899CF' }}
      >
        <span
          onClick={() => {
            setCurrentSubPage('main');
          }}
        >
          All Courses{' '}
        </span>
        <span>&gt;</span>
        <span
        // style={{ color: '#7899CF' }}
        >
          {selectedCourseDetails?.name ? selectedCourseDetails?.name : ''}
        </span>
      </div>
      <div className={classNames.contentContainer}>
        <div
          className={classNames.leftContainer}
          id="malls-sidebar-container-left"
        >
          <div className={classNames.title}>
            {selectedCourseDetails?.name ? selectedCourseDetails?.name : ''}
          </div>
          <div className={classNames.courseDesc}>
            {selectedCourseDetails?.description
              ? selectedCourseDetails?.description
              : ''}
          </div>
          <div className={classNames.courseRatingDate}>
            <div className={classNames.rating}>
              <span className={classNames.points}>
                4.0
                <span>
                  <img src={ratingIcon} alt="ratingIcon" />
                  <img src={ratingIcon} alt="ratingIcon" />
                  <img src={ratingIcon} alt="ratingIcon" />
                  <img src={ratingIcon} alt="ratingIcon" />
                  <img
                    src={ratingIcon}
                    alt="ratingIcon"
                    style={{ opacity: '0.5' }}
                  />
                </span>
              </span>
              <span>(335 Ratings)</span>
            </div>
            <div className={classNames.studentCount}>502 Students</div>
          </div>
          <div
            className={`${classNames.courseRatingDate} ${classNames.courseRatingDateOnly}`}
          >
            <div className={classNames.datetime}>
              Last Updated On Jan 7th 2023
            </div>
            <div className={classNames.datetime}>English</div>
          </div>
          <div className={classNames.instructor}>
            <div className={classNames.heading}>The Instructor</div>
            <div className={classNames.content}>
              <img src={profilePictureTemp} alt="profilePictureTemp" />
              <div className={classNames.authorDetails}>
                <div className={classNames.name}>Shorupan Pirakaspathy</div>
                <div className={classNames.desc}>
                  In higher education a course is a unit of teaching that
                  typically lasts one academic term, is led by one or more
                  instructors, and has a fixed roster of students. A course
                  usually covers an ind...
                  <span className={classNames.readMoreBtn}>Read More</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames.context}>
            <div className={classNames.heading}>What You Will Learn</div>
            <div className={classNames.allContext}>
              {Array.from({ length: 8 }).map((_, index) => {
                return (
                  <div className={classNames.eachContext}>
                    <CompletedTickIcon />
                    <span>
                      You will learn how to leverage the power of Python to
                      solve tasks.
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classNames.context}>
            <div className={classNames.heading}>This Course Includes</div>
            <div className={classNames.allContext}>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <PlayIconComponent />
                <span>22 Hours On Demand Video</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <AccessIcon />
                <span>Access of Mobile and TV</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <FolderIcon />
                <span>11 Downloadable Resources </span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <CertificateIcon />
                <span>Certificate On Completion</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <AiIcon />
                <span>AI Learning Assistant</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <LiveIcon />
                <span>Live Group Lessons</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <ChatIcon />
                <span>Classroom Chat</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <MentorIcon />
                <span>1 On 1 Lessons</span>
              </div>
              <div
                className={`${classNames.eachContext} ${classNames.eachContextIncludes}`}
              >
                <PeersIcon />
                <span>6 Peer Generated Tests</span>
              </div>
            </div>
          </div>
          {/* <div className={classNames.buttonDiv}>
            <div>
              Buy Now For{' '}
              {selectedCourseDetails?.cost
                ? selectedCourseDetails?.cost?.toFixed(2)
                : ''}{' '}
              {selectedCourseDetails?.currency
                ? selectedCourseDetails?.currency
                : ''}
            </div>
          </div> */}
          <div className={classNames.allSections}>
            <div className={classNames.heading}>Sections</div>
            <div className={classNames.sectionContainer}>
              {selectedCourseDetails?.pub_sections?.length > 0 &&
              Array?.isArray(selectedCourseDetails?.pub_sections)
                ? selectedCourseDetails?.pub_sections?.map((eachItem) => {
                    return (
                      <div
                        className={classNames.eachSection}
                        onClick={() => {
                          setSelectedSectionDetails(eachItem);
                          setCurrentSubPage('eachsection');
                        }}
                      >
                        <div className={classNames.title}>
                          <img src={camIcon} alt="camIcon" />
                          <span>
                            {eachItem?.section_name
                              ? eachItem?.section_name
                              : ''}
                          </span>
                        </div>
                        <div className={classNames.videoCount}>
                          {eachItem?.video_count ? eachItem?.video_count : ''}
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
          <div className={classNames.aboutCourse}>
            <div className={classNames.heading}>Requirements</div>
            <ul className={classNames.dotList}>
              <li>No physical equipment is needed</li>
              <li>
                A willingness to learn, and situations to practise are advised
              </li>
            </ul>
          </div>
          <div className={classNames.aboutCourse}>
            <div className={classNames.heading}>Description</div>
            <ul className={classNames.para}>
              In higher education a course is a unit of teaching that typically
              lasts one academic term, is led by one or more instructors, and
              has a fixed roster of students. In higher education a course is a
              unit of teaching that typically lasts one academic term, is led by
              one or more instructors, and has a fixed roster of students.
            </ul>
          </div>
          <div className={classNames.aboutCourse}>
            <div className={classNames.heading}>Who Is This Course For?</div>
            <ol className={classNames.orderList}>
              <li>
                A willingness to learn, and situations to practise are advised
              </li>
              <li>
                Those considering sales as a career in the future Those
                currently working in sales looking to increase their figures
              </li>
            </ol>
          </div>
          <div className={classNames.aboutCourse}>
            <div className={classNames.heading}>Students Also Bought</div>
            <div className={classNames.courses}>
              {Array.from({ length: 2 }).map((_, index) => {
                return (
                  <EachLearnCard
                    {...learnCourse}
                    key={'learncourse' + index}
                    setCurrentSubPage={setCurrentSubPage}
                  />
                );
              })}
            </div>
          </div>
          <div className={classNames.aboutCourse}>
            <div className={classNames.heading}>Reviews</div>
            <div className={classNames.allReviews}>
              {Array.from({ length: 2 }).map((_, index) => {
                return (
                  <div className={classNames.eachReview}>
                    <div className={classNames.profile}>
                      <img src={profilePictureTemp} alt="profilePictureTemp" />
                      <div className={classNames.details}>
                        <div className={classNames.name}>Philip Thomas</div>
                        <div className={classNames.rating}>
                          4.0
                          <span>
                            <img src={ratingIcon} alt="ratingIcon" />
                            <img src={ratingIcon} alt="ratingIcon" />
                            <img src={ratingIcon} alt="ratingIcon" />
                            <img src={ratingIcon} alt="ratingIcon" />
                            <img
                              src={ratingIcon}
                              alt="ratingIcon"
                              style={{ opacity: '0.5' }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={classNames.para}>
                      Really Great course about sales closure, got upgraded my
                      skills here. Thank you Mr Chris Croft for preparing this
                      course work. Appreciated ! Just need to add some more
                      topic on dealing with competitors. Hope it get upgraded in
                      next time...{' '}
                      <span className={classNames.btn}>Show More</span>
                    </div>
                    <div className={classNames.date}>A Week Ago</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={`${classNames.rightContainer}`}
          id="malls-sidebar-container"
        >
          <img
            id="video-section"
            src={
              selectedCourseDetails?.profile_pic
                ? selectedCourseDetails?.profile_pic
                : ''
            }
            alt={selectedCourseDetails?.name ? selectedCourseDetails?.name : ''}
          />
          <div className={classNames.toggle}>
            <div
              className={
                selectedToggle == 'Personal' ? classNames.selectedToggle : ''
              }
              onClick={() => {
                setSelectedToggle('Personal');
              }}
            >
              Personal
            </div>
            <div
              className={
                selectedToggle == 'Teams' ? classNames.selectedToggle : ''
              }
              onClick={() => {
                setSelectedToggle('Teams');
              }}
            >
              Teams
            </div>
          </div>
          <div className={classNames.priceDiv}>
            <span>
              {selectedCourseDetails?.currency
                ? selectedCourseDetails?.currency
                : ''}{' '}
              {selectedCourseDetails?.cost
                ? selectedCourseDetails?.cost?.toFixed(2)
                : ''}
            </span>
            <span>
              {selectedCourseDetails?.currency
                ? selectedCourseDetails?.currency
                : ''}{' '}
              {selectedCourseDetails?.cost
                ? selectedCourseDetails?.cost?.toFixed(2)
                : ''}
            </span>
            <span>(50% Off)</span>
          </div>
          <div className={classNames.buyBtn}>Buy This Course</div>
          <div className={classNames.otherDetails}>
            <div className={classNames.guarentee}>
              7 Day Money Back Guarantee
            </div>
            <div className={classNames.access}>Full Lifetime Access</div>
            <div className={classNames.offer}>
              <div>Share</div>
              <div>Gift This Course</div>
              <div>Apply Coupon</div>
            </div>
          </div>
          <div className={classNames.seperator}>
            <span>OR</span>
          </div>
          <div className={classNames.subscribe}>
            <div className={classNames.title}>Subscribe To Nirvana Plus</div>
            <div className={classNames.para}>
              Get this course, plus 8,000+ of our top-rated courses, with
              Personal Plan
            </div>
            <div className={classNames.btn}>Start Subscription</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DetailedSectionsPage = ({ setCurrentSubPage }) => {
  const {
    selectedCourseDetails,
    selectedSectionDetails,
    setSelectedSectionDetails,
  } = useContext(GlobalContext);
  const [selectedPubVideo, setSelectedPubVideo] = useState(0);
  return (
    <div className={classNames.detailedCourse}>
      <div className={classNames.breadCrumb} style={{ background: '#7899CF' }}>
        <span
          onClick={() => {
            setCurrentSubPage('main');
          }}
        >
          All Courses{' '}
        </span>
        <span>&gt;</span>
        <span
          // style={{ color: '#7899CF' }}
          onClick={() => {
            setCurrentSubPage('singlecoursepage');
          }}
        >
          {selectedCourseDetails?.name ? selectedCourseDetails?.name : ''}
        </span>
        <span>&gt;</span>
        <span
        // style={{ color: '#7899CF' }}
        // onClick={() => {
        //   setCurrentSubPage('eachsection');
        // }}
        >
          {selectedSectionDetails?.section_name
            ? selectedSectionDetails?.section_name
            : ''}
        </span>
      </div>
      <div className={classNames.contentContainer}>
        <div
          className={classNames.leftContainer}
          style={{ paddingBottom: '2rem' }}
        >
          <div
            className={classNames.fullVideo}
            onClick={() => {
              let preview_video =
                selectedSectionDetails?.pub_video_sections?.length >
                  selectedPubVideo &&
                Array.isArray(selectedSectionDetails?.pub_video_sections)
                  ? selectedSectionDetails?.pub_video_sections[selectedPubVideo]
                      ?.video_link
                  : '';

              if (preview_video?.includes('http')) {
                window.open(preview_video, '_blank');
              }
            }}
          >
            <img
              src={
                selectedSectionDetails?.pub_video_sections?.length >
                  selectedPubVideo &&
                Array.isArray(selectedSectionDetails?.pub_video_sections)
                  ? selectedSectionDetails?.pub_video_sections[selectedPubVideo]
                      ?.image
                  : ''
              }
              alt={
                selectedSectionDetails?.pub_video_sections?.length >
                  selectedPubVideo &&
                Array.isArray(selectedSectionDetails?.pub_video_sections)
                  ? selectedSectionDetails?.pub_video_sections[selectedPubVideo]
                      ?.name
                  : ''
              }
              className={classNames.thumbnail}
            />
            <img src={playIcon} alt="playIcon" className={classNames.playBtn} />
          </div>
          <div className={classNames.title}>
            {selectedSectionDetails?.pub_video_sections?.length >
              selectedPubVideo &&
            Array.isArray(selectedSectionDetails?.pub_video_sections)
              ? selectedSectionDetails?.pub_video_sections[selectedPubVideo]
                  ?.name
              : ''}
          </div>
          <div className={classNames.author}>
            A Course By &nbsp;
            <span>
              {selectedCourseDetails?.email ? selectedCourseDetails?.email : ''}
            </span>
          </div>
          <div className={classNames.aboutCourse}>
            <div className={classNames.heading}>About This Lesson</div>
            <div className={classNames.para}>
              {selectedSectionDetails?.pub_video_sections?.length >
                selectedPubVideo &&
              Array.isArray(selectedSectionDetails?.pub_video_sections)
                ? selectedSectionDetails?.pub_video_sections[selectedPubVideo]
                    ?.description
                : ''}
            </div>
          </div>
        </div>
        <div className={classNames.rightContainer}>
          {selectedSectionDetails?.pub_video_sections?.length > 0 &&
          Array.isArray(selectedSectionDetails?.pub_video_sections)
            ? selectedSectionDetails?.pub_video_sections?.map(
                (eachItem, index) => {
                  return (
                    <div
                      className={classNames.eachSection}
                      onClick={() => {
                        setSelectedPubVideo(index);
                      }}
                      key={eachItem?.name + index}
                    >
                      <div className={classNames.title}>
                        <PlayFill />
                        <span>{eachItem?.name ? eachItem?.name : ''}</span>
                      </div>
                      <div className={classNames.videoCount}>
                        {eachItem?.video_length ? eachItem?.video_length : ''}
                      </div>
                    </div>
                  );
                }
              )
            : ''}
        </div>
      </div>
    </div>
  );
};
