import React, { useState, useEffect, useContext } from 'react';
import { commissionForStaking } from './api';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import moment from 'moment';
import uparrow from '../../static/images/affiliate/uparrow.svg';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';

const StakingCommission = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [minDate, setMinDate] = useState(getDefaultMinDate());
  const [maxDate, setMaxDate] = useState(getDefaultMaxDate());
  const { email } = useContext(BankContext);
  const {
    stakingData,
    setStakingData,
    filteredStakingData,
    setFilteredStakingdata,
  } = useAppContextDetails();

  function formatDateCustom(inputDate) {
    // Parse input date string using Moment.js
    const dateObj = moment(inputDate);
  
    // Format the date
    const formattedDate = dateObj.format('MMM Do YYYY @ h:mm A z');
  
    return formattedDate;
  }
  
  function getTextAfterLastIs(inputString) {
    const lastIndex = inputString.lastIndexOf("is");
  
    if (lastIndex !== -1) {
      const textAfterIs = inputString.slice(lastIndex + 3).trim();
      if (textAfterIs === "notApplicable") {
        return "No Rank";
      } else {
        return textAfterIs;
      }
    } else {
      return "--";
    }
  }

  function fetchData() {
    setIsLoading(true);
    commissionForStaking(email)
      .then((response) => {
        let result = response?.data?.logsData?.logs;
        // console.log(result, 'result');
        if (result?.length > 0) {
          setStakingData(result);
          setFilteredStakingdata(result);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error, 'error in fetchData for Staking Commission');
        setIsLoading(false);
      });
  }

  function handleScroll(event) {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && !isLoading) {
      setMinDate(getPreviousMonth(minDate));
    }
  }

  function getPreviousMonth(date) {
    const d = new Date(date);
    d.setMonth(d.getMonth() - 1);
    return formatDate(d);
  }

  function getDefaultMinDate() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return formatDate(d);
  }

  function getDefaultMaxDate() {
    return formatDate(new Date());
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  // useEffect(() => {
  //   fetchData();
  // }, [minDate, maxDate]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const [affArr, setAffArr] = useState([]);

  useEffect(() => {
    axios.get(`https://comms.globalxchange.io/client/tree/get/vault/logs/adamdonovan1988@gmail.com`).then(({data}) => {
      if(data.status){
        setAffArr(data.data)
      }
    })
  }, [])

  return (
    <div
      onScroll={handleScroll}
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      {affArr &&
        affArr?.map((e) => (
          <div className="headd-section ind-detailss" style={{fontSize:'14px', fontWeight:400, background:'white'}}>
            {/* <div className="type1" style={{ width: '25%' }}>
              <div className="detailss">
                <p style={{ fontSize: '0.9rem', fontWeight: '600' }}>
                  {formatDateCustom(e.date)}
                </p>
              </div>
            </div>
            <div className="type1" style={{ width: '17%' }}>
              <div className="detailss">
                <p style={{ fontSize: '0.9rem', fontWeight: '600' }}>
                 --
                </p>
              </div>
            </div>
            <div className="dd" style={{ width: '17%', color:"#4CBD5E" }}>
              <div style={{ fontSize: '0.9rem', fontWeight: '600' }}>
              ${e['credit/debit'] === "credit" ? e.amount :"--"}
              </div>
            </div>
            <div
              className="direct"
              style={{width: '17%',padding:'0px', fontSize: '0.9rem', fontWeight: '600', color:"#EA4335" }}
            >
              ${e['credit/debit'] === "debit" ? e.amount :"--"}
            </div>
            <div
              className="direct"
              style={{width: '17%', padding:'0px', fontSize: '0.9rem', fontWeight: '600' }}
            >
              --
            </div> */}
             <div className="type" style={{ width: '25%' }}>
             {formatDateCustom(e.date)}
              </div>
              <div className="user" style={{ width: '17%' }}>
              {getTextAfterLastIs(e.description)}
              </div>
              <div className="dd" style={{ width: '17%', color:"#4CBD5E" }}>
              ${e['credit/debit'] === "credit" ? e.amount?.toFixed(2) :"--"}
              </div>
              <div className="direct" style={{ width: '17%', padding:"0px", color:"#EA4335" }}>
              ${e['credit/debit'] === "debit" ? e.amount?.toFixed(2) :"--"}
              </div>
              <div className="direct" style={{ width: '17%', padding:"0px" }}>--</div>
          </div>
        ))}
      {isLoading &&
        Array(10)
          .fill(' ')
          .map((item, index) => {
            return (
              <div className="ind-detailss" key={index}>
                <div className="type1" style={{ width: '20%' }}>
                  <div className="detailss">
                    <Skeleton width={75} height={15} />
                    <p>
                      <Skeleton width={100} height={15} />
                    </p>
                  </div>
                </div>
                <div className="type1" style={{ width: '20%' }}>
                  <div className="detailss">
                    <Skeleton width={100} height={15} />
                    <p>
                      <Skeleton width={100} height={15} />
                    </p>
                  </div>
                </div>
                <div className="dd1" style={{ width: '15%' }}>
                  <Skeleton width={60} height={25} />
                </div>
                <div className="direct1">
                  <Skeleton width={60} height={25} />
                </div>
                <div className="direct1">
                  <Skeleton width={60} height={25} />
                </div>
                <div className="baln1" style={{ width: '15%' }}>
                  <Skeleton width={60} height={25} />
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default StakingCommission;
