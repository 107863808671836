import React, {useContext, useState} from 'react';
import styles from "./sidebar.module.scss"
import axios from 'axios';
import { useUserDetails } from '../../queryHooks';
import { BankContext } from '../../context/Context';
import close from './close.svg';

const AddUserToTank = ({setSelectedStep, setPopular}) => {
    const {
        email,
        token,
        username,
        name,
        profileImg,
        copyToClipboard,
        linkname,
        setLinkname,
      } = useContext(BankContext);
    const { data: userData } = useUserDetails(email);
    const [addUserEmail, setAddUserEmail] = useState('');
    const [holdingTank, setHoldingTank] = useState([]);
    const [holdingTankLoading, setHoldingTankLoading] = useState(false);

    const addUserToTank = () => {
        axios
          .post(`https://comms.globalxchange.io/client/tree/add/user`, {
            email: addUserEmail,
            client_app: 'manifest',
            upline_id: userData?.affiliate_id,
          })
          .then(({ data }) => {
            if (data.status) {
              setSelectedStep('Add user Success');
            }
          });
      };

    return ( 
        <>
        <div className={styles.headTxt}>
                <div>Add User To Tank</div>
                <div
                onClick={() => {
                    setPopular(false);
                    setSelectedStep(null);
                }}
                className="close-div"
                >
                <img src={close} alt="" />
                </div>
        </div>
        <div>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '400',
                paddingBottom: '17px',
              }}
            >
              Enter the email of the user
            </div>
            <div className={styles.inputWrapper}>
              <input
                value={addUserEmail}
                onChange={(e) => setAddUserEmail(e.target.value)}
                type="text"
                placeholder="Email"
                style={{ outline: 'none', width: '100%', border: 'none' }}
              />
            </div>
            <div className={styles.footer}>
              <div className={styles.backBtn} onClick={e => {
                 setPopular(false);
                 setSelectedStep(null);
              }}>Go Back</div>
              <div className={styles.submitBtn} onClick={(e) => addUserToTank()}>
                Submit
              </div>
            </div>
        </div>
        </>
     );
}
 
export default AddUserToTank;