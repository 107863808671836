import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import Layout from '../../Layout/Layout';
import fulllogo from '../../static/images/gameImage.svg';
import search from '../../static/images/malls/search.svg';
import Car from './Car';
import './game.scss';
import { useAppContextDetails } from '../../context/AppContext';
import toRight from './asset/right.svg';
import toDown from './asset/down.svg';
import toLeft from './asset/left.svg';
import profilePic from './asset/profilePic.svg';
import playIcon from './asset/playIcon.svg';
import close from './asset/close.svg';
import axios from 'axios';
import { BankContext } from '../../context/Context';

const GamePage = () => {
  const { journeyTab, setJourneyTab, newProfilePic, popular, setPopular } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const svgContainerRef = useRef(null);

  const [carPosition, setCarPosition] = useState({ x: '10%', y: '2%' });
  const [targetDestination, setTargetDestination] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [currentDest, setCurrentDest] = useState(0);
  const [step, setStep] = useState(0);
  const [profileData, setProfileData] = useState(null);

  const getBrandProfileData = useCallback(() => {
    axios
      .get(`https://comms.globalxchange.io/client/tree/get/user/tree?email=${email}`)
      .then(({ data }) => {
        if (data.status) {
          setProfileData(data?.user);
        }
      })
      .catch((error) => {
        console.log(error, 'brand status check error');
      });
  }, [email]);

  useEffect(() => {
    getBrandProfileData();
  }, [getBrandProfileData]);

  const destinations = useMemo(() => [
    { x: '10%', y: '10%', image: toRight, title: 'What Is Manifest' },
    { x: '80%', y: '10%', image: toDown, title: 'Travel' },
    { x: '80%', y: '25%', image: toLeft, title: 'Softwares' },
    { x: '10%', y: '25%', image: toDown, title: 'Onboarding via Website' },
    { x: '10%', y: '40%', image: toDown, title: 'Onboarding via Broker' },
    { x: '80%', y: '40%', image: toDown, title: '3 FOR 3' },
    { x: '80%', y: '55%', image: toLeft, title: 'Comp Plan' },
    { x: '10%', y: '55%', image: toDown, title: 'Trade Signals?' },
    { x: '10%', y: '70%', image: toDown, title: 'Choose a Broker' },
    { x: '80%', y: '70%', image: toDown, title: 'Linking Broker' },
    { x: '80%', y: '85%', image: toDown, title: 'Place a Trade via Telegram' },
  ], []);

  const getCurrentDestinationIndex = useCallback(() => {
    for (let index = 0; index < destinations.length; index++) {
      const destination = destinations[index];
      if (
        Math.abs(parseFloat(carPosition.x) - parseFloat(destination.x)) < 0.01 &&
        Math.abs(parseFloat(carPosition.y) - parseFloat(destination.y)) < 0.01
      ) {
        return index;
      }
    }
    return -1;
  }, [carPosition, destinations]);

  const saveDestination = useCallback((step) => {
    axios.post(`https://comms.globalxchange.io/client/tree/user/step/set`, {
      email: email,
      currentStep: step + 1,
    });
  }, [email]);

  const getDestination = useCallback(() => {
    axios
      .get(`https://comms.globalxchange.io/client/tree/user/step/get?email=${email}`)
      .then(({ data }) => {
        if (data.status) {
          const index = data.data.currentStep - 1;
          setCarPosition({
            x: destinations[index].x,
            y: destinations[index].y,
          });
          setProgressPercentage(((index + 1) / 11) * 100);
        }
      });
  }, [email, destinations]);

  useEffect(() => {
    getDestination();
  }, [getDestination]);

  const SvgContainer = useCallback(({ children }) => {
    return (
      <div 
        style={{
          width: '100%',
          height: '90vh',
          overflowY: 'scroll',
          position: 'absolute',         
        }}
      >
        {children}
      </div>
    );
  }, []);

  useEffect(() => {
    const currentDestinationIndex = getCurrentDestinationIndex();
    setCurrentDest(currentDestinationIndex);
    setProgressPercentage(((currentDestinationIndex + 1) / 11) * 100);
  }, [targetDestination]);

  const handleDestinationClick = useCallback((destination, destIndex) => {
    const currentDestinationIndex = getCurrentDestinationIndex();

    if (
      destIndex === currentDestinationIndex + 1 ||
      destIndex === currentDestinationIndex - 1
    ) {
      setTargetDestination(destination);
      saveDestination(destIndex);
    }
  }, [getCurrentDestinationIndex, saveDestination]);

  useEffect(() => {
    let animationFrameId;

    const moveCar = () => {
      if (targetDestination) {
        const dx = parseFloat(targetDestination.x) - parseFloat(carPosition.x);
        const dy = parseFloat(targetDestination.y) - parseFloat(carPosition.y);
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance > 1) {
          const speed = 0.5;
          const newX = parseFloat(carPosition.x) + (dx / distance) * speed;
          const newY = parseFloat(carPosition.y) + (dy / distance) * speed;
          setCarPosition({ x: `${newX}%`, y: `${newY}%` });
          animationFrameId = requestAnimationFrame(moveCar);
        } else {
          setCarPosition(targetDestination);
          setTargetDestination(null);
          setShowPopup(true);
        }
      }
    };

    if (targetDestination) {
      animationFrameId = requestAnimationFrame(moveCar);
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [carPosition, targetDestination]);

  const closePopup = useCallback(() => {
    setShowPopup(false);
    setStep(0);
  }, []);

  const questions = useMemo(() => [
    {
      title:"What Is Manifest",
      question: "Which of the following are you familiar with?",
      answer:[
        "Crypto", "Stocks", "CFD’s", "All of the above", "None of the above"
      ]
    },
    {
      title:"Travel",
      question: "were you aware that Manifest covers travel, personal development, health and well being, and nancial education?",
      answer:[
        "Yes", "No"
      ]
    }, 
    {
      title:"Softwares",
      question: "Are there any guarantees made in regard to earnings or prots?",
      answer:[
        "Yes", "No"
      ]
    },
    {
      title:"Onboarding via Website",
      question: "Can I aord to potentially lose any or all money I invest?",
      answer:[
        "Yes", "No"
      ]
    },
    {
      title:"Onboarding via Broker",
      question: "What is the prot share for Platinum members across the softwares?",
      answer:[
        "10%", "25%", "50%"
      ]
    },
    {
      title:"3 FOR 3",
      question: "What is the prot share for CEO members across the softwares?",
      answer:[
        "10%", "25%", "50%"
      ]
    },
    {
      title:"Comp Plan",
      question: "How will company announcements be sent out to Manifest members?",
      answer:[
        "Email newsletter / Telegram gorup", "Fax", "letter", "Social Media"
      ]
    },
    {
      title:"Trade Signals?",
      question: "Before deciding to trade with real funds we strongly recommend that you obtain independant nancial advice. Have you done so?",
      answer:[
        "Yes", "No"
      ]
    },
  ], []);


  const getTitleText = useCallback(() => {
    const currentDestinationIndex = getCurrentDestinationIndex();
    console.log(destinations[currentDestinationIndex].title, 'kjkfjekrj');
  }, [getCurrentDestinationIndex, destinations]);

  const conditionalQuestions = useCallback((title) => {
    return questions.map(item => {
      if(title === item.title){
        return (
          <div key={item.title}>
            <div className="popup-title">{item.question}</div>
            <div>
              {item.answer.map((item1, index1) => (
                <div key={index1}>
                  <input type="radio" id={index1} name="fav_language" value={item1}/>
                  <label htmlFor={index1}>{item1}</label>
                </div>
              ))}
            </div>
          </div>
        );
      }
    });
  }, [questions]);

  const conditionalContent = useCallback((title) => {
    if(step === 0){

      if (title === 'What Is Manifest') {
        return (
          <div>
            <li>Passive income opportunity</li>
            <li>Providing automated EA systems</li>
            <li>
              Offers Travel, Crypto & NFT’s, Real Estate, E-Commerce, investments,
              Stocks & shares, Forex, Financial Education, Wealth management,
              Personal Development, Mindfulness & Health & Wellbeing.
            </li>
            <li>
              Monthly / Annual subscriptions available to have access to all
              benets
            </li>
          </div>
        );
      } else if (title === 'Travel') {
        return (
          <div>
            <li>
              Discounted booking platform for over 1.5 million hotels worldwide
            </li>
            <li>Flights & Car hire</li>
            <li>Ocean and river cruises</li>
            <li>Villas and cottages</li>
            <li>Travel services (parking, transfers, attractions)</li>
            <li>Save on days out in the UK</li>
          </div>
        );
      } else if (title === 'Softwares') {
        return (
          <div>
            <ul>
              <li>
                Goose - one of our most liquid investments allowing monthly
                withdrawals
              </li>
              <li>Crypto - 12 month fixed term as soon as you have connected</li>
              <li>
                Stock bot - Requires a separate membership, allows daily
                withdrawals
              </li>
              <li>
                Supercharged - Newest software also allows monthly withdrawals
              </li>
            </ul>
          </div>
        );
      } else if (title === 'Onboarding via Website') {
        return (
          <div>
            <ul>
              <li>
                Go to our website{' '}
                <a href="https://manifest.family/">https://manifest.family/</a>{' '}
                and pick your preferred package
              </li>
              <li>
                Enter your personal details and submit for access to all the
                benefits included in your membership
              </li>
            </ul>
          </div>
        );
      } else if (title === 'Onboarding via Broker') {
        return (
          <div>
            <ul>
              <li>
                Once signed up to Manifest, you will need to register your details
                with our broker
              </li>
              <li>
                Create a live account, verify details, fund your wallet account &
                connect to your chosen software
              </li>
            </ul>
          </div>
        );
      } else if (title === '3 FOR 3') {
        return (
          <div>
            <ul>
              <li>
                Bring on 3 people on the same package as you or above to qualify
                for a free membership
              </li>
              <li>
                All 3 members must stay active, if one cancels, you will need to
                find a replacement
              </li>
            </ul>
          </div>
        );
      } else if (title === 'Comp Plan') {
        return (
          <div>
            <ul>
              <li>2 legs</li>
              <li>You gain CV points for each referral</li>
              <li>
                Hit the total CV points for each level to unlock the next rank
              </li>
              <li>The higher the ranks, the more commissions you earn</li>
            </ul>
          </div>
        );
      } else if (title === 'Trade Signals?') {
        return (
          <div>
            <ul>
              <li>Private group via Telegram</li>
              <li>
                Educators post trades for you to copy onto your own trading
                platform
              </li>
              <li>Can use Metatrader 4 or 5</li>
              <li>Minimum deposit $300</li>
            </ul>
          </div>
        );
      } else if (title === 'Choose a Broker') {
        return (
          <div>
            <ul>
              <li>If you are based in the UK, you will use Vantage UK</li>
              <li>
                If you are based in the US and elsewhere, you will use Ox
                Securities
              </li>
            </ul>
          </div>
        );
      } else if (title === 'Linking Broker') {
        return (
          <div>
            <ul>
              <li>
                Once you have created an account with the broker, you will need to
                download the MT4 or MT5 app
              </li>
              <li>The broker will then send your login details via email</li>
              <li>
                Once you have received your login details, open the Meta app and
                select ‘New account’ and input your account number and password
              </li>
            </ul>
          </div>
        );
      } else if (title === 'Place a Trade via Telegram') {
        return (
          <div>
            <ul>
              <li>You will receive a trading signal in the Telegram group</li>
              <li>
                Open Metatrader, select the currency that is being traded and
                select trade
              </li>
              <li>
                If the trade is not on your ‘quotes’ page, then you will need to
                select the ‘+’ and search for the currency
              </li>
              <li>
                Once you have selected the currency, you will need to follow the
                signal by inputting the stop loss (SL) and take profit (TP)
                exactly as shown in the group
              </li>
              <li>
                If the trade is a buy trade, select ‘buy’, if it is a sell trade
                select ‘sell’
              </li>
              <li>
                The trade you have placed, will then show live on your trades page
              </li>
              <li>
                If the live trade hits either your take profit or stop loss, this
                will automatically close however, if the educator advises
                otherwise, you will be able to manually close the trade
              </li>
            </ul>
          </div>
        );
      }
    }else{
      return conditionalQuestions(title)
    }
  }, [conditionalQuestions, step]);





  return (
    <Layout active="game" className="academy" hideFooter>
    <div style={{ width: '100%', height: '100%' }}>
        <div className="academy-navbar" style={{zIndex:1, background:"white"}}>
          <div className="academy-icon">
            <img src={fulllogo} alt="" />
            Journey
          </div>
          <div className="search-academy">
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div
            className="academy-btn"
            onClick={() => {
              setPopular(!popular);
            }}
          >
            Actions
          </div>
        </div>
        <div className="w-space" style={{zIndex:1, background:'white'}}>
          <div className="optionss" style={{ width: '100%' }}>
            <div
              className={`each-option ${
                journeyTab === 'My Journey' ? 'each-option-selected' : ''
              }`}
              onClick={() => setJourneyTab('My Journey')}
            >
              <div>
                <p>My Journey</p>
              </div>
            </div>
            {/* <div
              className={`each-option ${
                journeyTab === 'My Downline’s Jorney'
                  ? 'each-option-selected'
                  : ''
              }`}
              onClick={() => setJourneyTab('My Downline’s Jorney')}
              style={{ pointerEvents: 'none' }}
            >
              <div>
                <p>My Downline’s Jorney</p>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container1">
          <div className="leftSide">
            <div className="pageTitle" style={{zIndex:1}}>COMPLETE OUR TUTORIAL VIDEOS</div>
            <div className="pageSubTitle" style={{zIndex:1}}>TO MOVE ONTO THE NEXT STAGE</div>
            <SvgContainer>
              <svg width="70vw" height="2000px">
                {/* Draw roads */}
                {destinations.map(
                  (destination, index) =>
                    index < destinations.length - 1 && (
                      // <line
                      //   key={`road-${index}`}
                      //   x1={destination.x}
                      //   y1={destination.y}
                      //   x2={destinations[index + 1].x}
                      //   y2={destinations[index + 1].y}
                      //   stroke="black"
                      //   strokeWidth="50"
                      // />
                      <g key={`road-${index}`}>
                        <line
                          x1={destination.x}
                          y1={destination.y}
                          x2={destinations[index + 1].x}
                          y2={destinations[index + 1].y}
                          stroke="black"
                          strokeWidth="50"
                        />
                        <line
                          x1={destination.x}
                          y1={destination.y}
                          x2={destinations[index + 1].x}
                          y2={destinations[index + 1].y}
                          stroke="white"
                          strokeWidth="4"
                          strokeDasharray="20,20"
                        />
                      </g>
                    )
                )}

                {destinations.map((destination, index) => {
                  const width = 120; // Set the width of the image
                  const height = 120; // Set the height of the image

                  // Adjust the x and y coordinates to center the image
                  const x = `calc(${destination.x} - ${width / 2}px)`;
                  // const y = `calc(${destination.y} - ${height / 2}px)`;
                  const yImage = `calc(${destination.y} - ${height / 2}px)`;
                  const yText = `calc(${destination.y} + ${height / 2 + 20}px`; // Adjust 15 for spacing

                  return (
                    <g id={destination.title} key={`destination-${index}`}>
                      <image
                        href={destination.image}
                        x={x}
                        y={yImage}
                        width={width}
                        height={height}
                        // width={
                        //   carPosition.x === destination.x &&
                        //   carPosition.y === destination.y
                        //     ? width * 1.5
                        //     : width
                        // }
                        // height={
                        //   carPosition.x === destination.x &&
                        //   carPosition.y === destination.y
                        //     ? height * 1.5
                        //     : height
                        // }
                        onClick={() =>
                          handleDestinationClick(destination, index)
                        }
                        style={{ cursor: 'pointer' }}
                      />

                      <foreignObject x={x} y={yText} width={width} height="70">
                        <div
                          xmlns="http://www.w3.org/1999/xhtml"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            padding: '10px',
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: '20px',
                            fontSize: '15px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          {destination.title}
                        </div>
                      </foreignObject>
                    </g>
                  );
                })}
                
                <image
                    href={profileData?.profile_img ? profileData?.profile_img : profilePic}
                    x={`calc(${carPosition.x} - ${52 / 2 + 3}px)`}
                    y={`calc(${carPosition.y} - ${52 / 2 - 26}px)`}
                    // width="52px"
                    // height="52px"
                    style={{
                        width:"52px",
                        height:"52px",
                        cursor: 'pointer',
                        clipPath: 'circle(50%)'
                    }}
                    onClick={() => handleDestinationClick(destinations[0])}
                />
              </svg>
            </SvgContainer>
          </div>
          <div className="progressBarWrapper">
            <div className="topHalf">
              <div>Current Step</div>
              <img
                style={{ cursor: 'pointer' }}
                src={playIcon}
                alt=""
                onClick={(e) => setShowPopup(destinations[currentDest])}
              />
              <div>{destinations[currentDest]?.title}</div>
            </div>
            <div className="bottomHalf">
              <div
                className="progressBar"
                style={{
                  height: `${progressPercentage}%`,
                  transition: 'height 2.5s ease-in-out',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '30px',
                  fontWeight: 700,
                }}
              >
                {Math.floor(progressPercentage)}%
              </div>
            </div>
          </div>
        </div>
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
            {step === 0 &&
              <div className="popup-title">
                {destinations[currentDest]?.title}
              </div>}
              <div className="popup-list">
                {conditionalContent(destinations[currentDest]?.title)}
              </div>
              {step === 0 ? <button onClick={e => setStep(1)}>Next</button>:<button onClick={closePopup}>Close</button>}
              
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default GamePage;
