import React, { useEffect } from 'react';
import './influencemarketmalls.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/malls/fulllogo.svg';
// import search from '../../static/images/malls/search.svg';
import searchIcon from '../../static/images/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import Skeleton from 'react-loading-skeleton';
import { RxHamburgerMenu } from 'react-icons/rx';

import successIcon from "./success.svg"

const LoggedOutThankyou = () => {
  const {
    IMtab,
    setIMtab,
    setfiltersearch,
    shopCompanies,
    setShopCompanies,
    shopBilling,
    setShopBilling,
    shopMarket,
    setShopMarket,appFullLogo
  } = useAppContextDetails();
  const {
    aiIndexOfferings,
    setAiIndexOfferings,
    shopCompaniesFilter,
    shopBillingFilter,
    shopMarketFilter,
    setShopMarketFilter,
  } = useContext(planBContext);
const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState('default');
  const [btnOptionCompany, setBtnOptionCompany] = useState('All');
  const [btnOptionBilling, setBtnOptionBilling] = useState('All');
  const [btnOptionMarket, setBtnOptionMarket] = useState('All');

  return (
    <Layout active="InfluenceMarket" className="influencemarket" hideFooter sidebarCustom>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="influencemarket-navbar">
          {/* <div
            className="influencemarket-hamburger"
            onClick={() => setAiIndexOfferings(!aiIndexOfferings)}
          >
            {aiIndexOfferings ? <div>x</div> : <RxHamburgerMenu />}
          </div> */}
          <div className="influencemarket-icon">
            <img src={appFullLogo} alt="" />
          </div>
          <div className="search-influencemarket">
            <div className="im">
              <input
                type="text"
                placeholder="Ex. Product Name..."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-space"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="optionss" style={{ width: '15%', borderRadius:'20px' }}>
            <div
              className={`each-option ${
                IMtab === 'shop' ? 'each-option-selected' : ''
              }`}
              onClick={() => setIMtab('shop')}
            >
              <div>
                <p>Receipts</p>
              </div>
            </div>
          
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width:'100%', height:'80%'}}>
          
          <img src={successIcon} alt="" />
          <div style={{fontSize:'30px', fontWeight:600, paddingTop:'50px', paddingBottom:'20px'}}>Order Success</div>
          <div style={{fontSize:'22px', fontWeight:400, paddingBottom: "70px", textAlign:'center'}}>
            Order Reference Number:
            <div style={{fontSize:"14px", paddingTop:'10px'}}>{location.search.substring(1, location.search.length)}</div>
          </div>
          <div>
            <div className='affBtn' onClick={e => {
                window.open('https://app.manifest.family/', '_blank');
            }}>Affiliate Portal</div>
            <div className='eduBtn' onClick={e => {
                window.open(' https://manifest.family/login/?redirect_to=https%3A%2F%2Fmanifest.family%2Fmanifest-portal', '_blank');
            }}>Education Portal</div>
          </div>
          
          </div>
      </div>
    </Layout>
  );
};

export default LoggedOutThankyou;

const AIOpportunityCard = ({
  item_name,
  company_name,
  product_icon,
  product_description,
  cost_per_month,
  link,
  last_month_ROI,
  last_12_months_ROI,
  months_running,
  avg_drawdown,
  subscribers,
  company_website,
  _id,
}) => {
  const { IMtab, aiIndexOfferingSelected, setAiIndexOfferingSelected } =
    useAppContextDetails();
  return (
    <div
      className="ai-card"
      onClick={() => {
        setAiIndexOfferingSelected(_id);
      }}
      style={{
        boxShadow:
          aiIndexOfferingSelected == _id
            ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
            : '',
      }}
    >
      <div className="top-data">
        <div className="imgg-div">
          <img
            src={product_icon?.includes('http') ? product_icon : sample}
            alt=""
          />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">{item_name}</div>
              <div>
                By{' '}
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!company_website?.includes('https://' || 'www.')) {
                      window.open('https://' + company_website, '_blank');
                    } else {
                      window.open(company_website, '_blank');
                    }
                  }}
                >
                  {company_name}
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  {cost_per_month
                    ? '$' + Number(cost_per_month).toFixed(2)
                    : '$0'}
                </div>
                <div style={{ fontSize: '0.8rem' }}>USD Per Month</div>
              </div>
              <div
                className="gs-btn"
                onClick={() => {
                  if (IMtab !== 'businesses') {
                    if (!link?.includes('https://' || 'www.')) {
                      window.open('https://' + link, '_blank');
                    } else {
                      window.open(link, '_blank');
                    }
                  }
                }}
              >
                {IMtab == 'businesses' ? 'Edit' : 'Get Started'}
              </div>
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">{product_description}</p>
        </div>
      </div>
      <div className="bottom-data">
        <div>
          <div>Last Month</div>
          <div className="txtt2">
            {last_month_ROI ? Number(last_month_ROI).toFixed(2) + '%' : '0%'}
          </div>
        </div>
        <div>
          <div>Last 12 Months</div>
          <div className="txtt2">
            {last_12_months_ROI
              ? Number(last_12_months_ROI).toFixed(2) + '%'
              : '0%'}
          </div>
        </div>
        <div>
          <div>Months Running</div>
          <div className="txtt3">{months_running} Months</div>
        </div>
        <div>
          <div>Avg Drawdown</div>
          <div className="txtt3">
            {avg_drawdown ? Number(avg_drawdown).toFixed(2) + '%' : ''}
          </div>
        </div>
        <div>
          <div>Subscribers</div>
          <div className="txtt3">{subscribers}</div>
        </div>
      </div>
    </div>
  );
};

const AIOpportunityCardLoading = () => {
  return (
    <div className="ai-card">
      <div className="top-data">
        <div className="imgg-div">
          <Skeleton circle />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">
                <Skeleton width={120} />
              </div>
            </div>
            <div>
              <div className="txtt1">
                <Skeleton />
              </div>
              <div>
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  <Skeleton />
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  <Skeleton />
                </div>
                <div style={{ fontSize: '0.8rem' }}>
                  <Skeleton />
                </div>
              </div>
              {/* <div className="gs-btn">
                <Skeleton />
              </div> */}
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
        </div>
      </div>
      <div className="bottom-data">
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterItem = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);
  return (
    <div className="filterItem">
      <div
        onClick={() => {
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#18191D ',
          }}
        >
          {funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return <div onClick={() => func(eachOption)}>{eachOption}</div>;
            })
          : ''}
        {funcValue && <div onClick={() => func('')}>All</div>}
      </div>
    </div>
  );
};

const FilterItem1 = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [btnOption, setBtnOption] = useState('');

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);

  return (
    <div className="filterItem">
      <div
        onClick={() => {
          // console.log(typeFilter, 'typeFilter');
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#18191D ',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {btnOption ? btnOption : funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return title === 'Brands' ? (
                <div
                  onClick={() => {
                    setBtnOption(eachOption?.name);
                    func(eachOption?.email);
                  }}
                >
                  {eachOption?.profile_img && (
                    <img
                      src={eachOption?.profile_img}
                      alt=""
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <span>{eachOption?.name}</span>
                </div>
              ) : title === 'Billing Type' ? (
                <div
                  onClick={() => {
                    func(eachOption?.billingMethod);
                  }}
                >
                  {eachOption?.billingMethod ? eachOption?.billingMethod : ''}
                </div>
              ) : (
                <div
                  onClick={() => {
                    setBtnOption(eachOption?.app_name);
                    func(eachOption?.app_code);
                  }}
                >
                  {eachOption?.app_icon && (
                    <img
                      src={eachOption?.app_icon}
                      alt=""
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <span>{eachOption?.app_name}</span>
                </div>
              );
            })
          : ''}
        {funcValue && (
          <div
            onClick={() => {
              setBtnOption('');
              func('');
            }}
          >
            All
          </div>
        )}
      </div>
    </div>
  );
};
