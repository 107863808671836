import axios from 'axios';
import { useQuery } from 'react-query';

export const getAffiliateAppData = async () => {
  try {
    const data = await axios.get(
      'https://comms.globalxchange.io/gxb/apps/mobile/app/links/logs/get',
      {
        params: {
          app_code: 'manifest',
        },
      }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const useLoadAffiliateAppData = () => {
  const query = useQuery('loadAffiliateAppData', getAffiliateAppData);
  return query;
};

export const levelBasedData = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/com/earnings/get?email=${email}&orderByLevel=true` //url changed
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const affiliateBalances = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/vault/gxb/balance?email=${email}` //url changed
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getApps = async () => {
  try {
    const data = await axios.get(
      'https://comms.globalxchange.io/gxb/apps/get' //url changed
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const affiliateId = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?email=${email}` //url changed
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const allUsers = async (object) => {
  try {
    const data = await axios.post(
      `https://comms.globalxchange.io/get_broker_names`, //url changed
      object
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const preRegistration = async (body) => {
  try {
    const data = await axios.post(
      'https://gxauth.apimachine.com/gx/user/admin/signup',
      body
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const placeWithdrawal = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/gxb/balance/withdraw',
      body
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const placeUnderMe = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/user/profile/data/get?email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const affiliateStats = async (id) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/stats/getUsers?affiliate_id=${id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const uplineData = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/stats/get/uplines?email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllApps = async (email) => {
  if (email) {
    try {
      const data = await axios.get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
};

export const balForSelectedApp = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/coins/get',
      body
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const inviteWithGift = async (body) => {
  try {
    const data = await axios.post(
      'https://comms.globalxchange.io/coin/vault/service/invite/user/by/transfer',
      body
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const commissionForExchangeFees = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/otc/txn/stats/get?email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const commissionForMM = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/interest/comm/txns/get?email=${email}&liquid=true`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const commissionForStaking = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/interest/comm/txns/get?email=${email}&iced=true`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const stakingContractHolders = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/coin/iced/bond/users/get?affiliate_email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const brandsUserFollows = async (email) => {
  try {
    const data = await axios.get(
      `https://teller2.apimachine.com/banker/followingList?email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCommission = async (email, creator) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/comp4/txn/stats/get?email=${email}&product_created_by=${creator}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const usersWithTrade = async (email) => {
  try {
    const data = await axios.get(
      `https://comms.globalxchange.io/brokerage/otc/user/txn/stats/get?email=${email}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
