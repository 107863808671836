import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useCoinContextData } from '../../context/CoinContext';
import { SIDEBAR_CONTENTS } from './constants';
import { SIDEBAR_MENU } from './constants';
import { useLocation } from 'react-router-dom';

//images
import settings from '../../static/images/icons/settings.svg';
import close from '../../static/images/icons/close.svg';
import plus from '../../static/images/clipIcons/plusBlack.svg';
import ddown from '../../static/images/clipIcons/ddown.svg';
import ddown1 from '../../static/images/clipIcons/dup.svg';
import guest from '../../static/images/guest.jpg';

import digifinex from '../../static/images/icons/digifinex.svg';
import coinMarketCap from '../../static/images/icons/coinMarketCap.svg';
import etherScan from '../../static/images/icons/etherScan.svg';
import dgp from '../../static/images/icons/dgp.svg';
import axios from 'axios';
import { useAppContextDetails } from '../../context/AppContext';
import { openAppRetiredApp } from '../../assets/Functions/miniFunctions';

export const DefaultSideBarContent = ({
  active,
  sidebarCollapse,
  chatOn,
  setChatOn,
  appColorCode,
  openSettings,
  isSecondaryMenu,
  toggleSettings,
}) => {
  const {
    email,
    setSidebarCollapse,
    setSelectedTopItemReact,
    username,
    name,
    profileImg,
  } = useContext(BankContext);

  const {
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    isCoinLoadingInAddAction,
    addBankingEnabled,
    setAddBankingEnabled,
    isClose,
    setIsClose,
    setSelectedCoin,
    setCoinType,
    senbtnClicked,
    setSendBtnClicked,
  } = useCoinContextData();
  const {
    hideEveryone,
    setHideEveryone,
    hideAffiliate,
    setHideAffiliate,
    hideBrands,
    setHideBrands,
    hideInfluencers,
    setHideInfluencers,
    hideContentCreators,
    setHideContentCreators,
  } = useAppContextDetails();

  const { pathname } = useLocation();
  const history = useHistory();
  // const sideBarBorder = { borderLeft: '7px solid #7899CF' };
  const sideBarBorder = {
    background: '#FFFFFF',
    border: ' 0.5px solid #E5E5E5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    height: 'max-content',
    aspectRatio: '1/1',
    margin: '2rem 1rem',
  };
  const applyStyle = (condition) => (condition ? sideBarBorder : {});
  const closeChatScreen = () => (chatOn ? setChatOn(false) : null);
  const [hoverItem, setHoverItem] = useState('');

  function hoverFunctionOpportunities() {
    setHoverItem('Opportunities');
  }

  function hoverFunctionConnection1() {
    setHoverItem('Connection1');
  }

  function hoverFunctionPublishing() {
    setHoverItem('Publishing');
  }

  function hoverFunctionProduction() {
    setHoverItem('Production');
  }

  function hoverFunctionInfluenceMarket() {
    setHoverItem('InfluenceMarket');
  }

  function hoverFunctionVaults() {
    setHoverItem('vaults');
  }

  function hoverFunctionPlay() {
    setHoverItem('Ranks');
  }

  function hoverFunctionAffiliate() {
    setHoverItem('Affiliate');
  }

  function hoverFunctionCRM() {
    setHoverItem('CRM');
  }

  function hoverFunctionBrands() {
    setHoverItem('Brands');
  }

  function hoverFunctionAffiliateBank() {
    setHoverItem('AffiliateBank');
  }

  function hoverFunctionCRM1() {
    setHoverItem('Network Chains');
  }

  function hoverFunctionBrands1() {
    setHoverItem('Deals');
  }

  function hoverFunctionEngagement() {
    setHoverItem('Engagement');
  }

  function hoverFunctionSubscriptions() {
    setHoverItem('Subscriptions');
  }

  function hoverFunctionCommercial() {
    setHoverItem('Commercials');
  }

  function hoverFunctionMalls() {
    setHoverItem('Retailer');
  }

  function hoverFunctionAdvertisers() {
    setHoverItem('Advertisers');
  }

  function hoverFunctionEndorsement() {
    setHoverItem('Endorsement');
  }

  function hoverFunctionLearn() {
    setHoverItem('Learn');
  }
  function hoverFunctionGame() {
    setHoverItem('Game');
  }

  function hoverLeaveFunc() {
    setHoverItem('');
  }

  // useEffect(() => {
  //   if (
  //     pathname.includes('malls') ||
  //     pathname.includes('opportunities') ||
  //     pathname.includes('subscriptions') ||
  //     pathname.includes('learn')
  //   ) {
  //     setHideEveryone(false);
  //     setHideAffiliate(true);
  //     setHideBrands(true);
  //     setHideContentCreators(true);
  //     setHideInfluencers(true);
  //   }
  //   if (
  //     pathname.includes('crm') ||
  //     pathname.includes('affiliatebank') ||
  //     pathname.includes('networkChain') ||
  //     pathname.includes('vault') ||
  //     pathname.includes('levels') ||
  //     pathname.includes('affiliate')
  //   ) {
  //     setHideEveryone(true);
  //     setHideAffiliate(false);
  //     setHideBrands(true);
  //     setHideContentCreators(true);
  //     setHideInfluencers(true);
  //   }
  //   if (
  //     // pathname.includes('vault') ||
  //     pathname.includes('advertise') ||
  //     pathname.includes('retailer') ||
  //     pathname.includes('engagement') ||
  //     pathname.includes('commercial')
  //   ) {
  //     setHideEveryone(true);
  //     setHideAffiliate(true);
  //     setHideBrands(false);
  //     setHideContentCreators(true);
  //     setHideInfluencers(true);
  //   }
  // }, []);

  useEffect(() => {
    setHideAffiliate(false)
  }, [])

  return (
    <div
      style={{
        width: '100%',
        height: sidebarCollapse ? '92%' : '100%',
        display: isClose ? 'none' : 'flex',
        flexWrap: sidebarCollapse ? 'nowrap' : 'wrap',
        border: sidebarCollapse ? 'none' : '0.5px solid #EBEBEB',
        borderRight: 'none',
        flexFlow: sidebarCollapse ? 'column' : 'wrap',
        alignContent: sidebarCollapse ? '' : 'flex-start',
        borderLeft: 'none',
        borderBottom: 'none',
        justifyContent: sidebarCollapse ? 'center' : '',
        marginTop: sidebarCollapse ? '-32px' : '0',
      }}
    >
      {/* <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
        }}
        onClick={() => {
          setHideEveryone(!hideEveryone);
          if (!hideAffiliate) {
            setHideAffiliate(true);
          }
          if (!hideBrands) {
            setHideBrands(true);
          }
          if (!hideContentCreators) {
            setHideContentCreators(true);
          }
          if (!hideInfluencers) {
            setHideInfluencers(true);
          }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideEveryone ? ddown : ddown1} alt="" />
          </div>
          <div>For Everyone</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div> */}
        <div
        className="header-boxx"
        style={{
          display: sidebarCollapse ? 'none' : 'flex',
          borderTop: !hideEveryone ? '0.5px solid #e7e7e7' : '',
        }}
        onClick={() => {
          setHideAffiliate(!hideAffiliate);
          // setHideBrands(!hideBrands);

          // if (!hideEveryone) {
          //   setHideEveryone(true);
          // }
          // if (!hideBrands) {
          //   setHideBrands(true);
          // }
          // if (!hideContentCreators) {
          //   setHideContentCreators(true);
          // }
          // if (!hideInfluencers) {
          //   setHideInfluencers(true);
          // }
        }}
      >
        <div className="text-container">
          <div>
            <img src={hideAffiliate ? ddown : ddown1} alt="" />
          </div>
          <div>For Affiliates</div>
        </div>
        <div>
          <img src={plus} alt="plus" />
        </div>
      </div>

      {/* <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'game' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
          borderBottom: 'none',
        }}
        onClick={() => {
          if (email) {
            history.push('/game');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionGame}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[22].icon}
          alt="LearnIcon"
          style={{
            height: hoverItem === 'Game' || active === 'game' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'game' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[22].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display: hoverItem === 'Game' && active != 'game' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[22].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'InfluenceMarket' && !chatOn && sidebarCollapse
          ),
          display: hideAffiliate ? 'none' : 'flex',
          // borderBottom: 'none',
        }}
        onClick={() => {
          if (email) {
            setSelectedTopItemReact('InfluenceMarket');
            history.push('/malls');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionInfluenceMarket}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[1].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'InfluenceMarket' || active === 'InfluenceMarket'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'InfluenceMarket' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[1].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'InfluenceMarket' && active != 'InfluenceMarket'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[1].name}
        </div>
      </div> */}

<div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'InfluenceMarket' && !chatOn && sidebarCollapse
          ),
          display: hideAffiliate ? 'none' : 'flex',
          // borderBottom: 'none',
        }}
        onClick={() => {
          if (email) {
            setSelectedTopItemReact('InfluenceMarket');
            history.push('/malls');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionInfluenceMarket}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[1].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'InfluenceMarket' || active === 'InfluenceMarket'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'InfluenceMarket' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[1].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'InfluenceMarket' && active != 'InfluenceMarket'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[1].name}
        </div>
      </div>
   
   
      {/* <div
        className="menu-itm active clickable"
        style={{
          borderBottom: 'none',
          ...applyStyle(active === 'game' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
          borderBottom: '0.5px solid #ebebeb',
        }}
        onClick={() => {
          if (email) {
            history.push('/game');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionGame}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[22].icon}
          alt="LearnIcon"
          style={{
            height: hoverItem === 'Game' || active === 'game' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'game' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[22].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display: hoverItem === 'Game' && active != 'game' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[22].name}
        </div>
      </div> */}

      


      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(active === 'CRM' && !chatOn && sidebarCollapse),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/crm');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionCRM}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[4].icon}
          alt="VaultIcon"
          style={{
            height: hoverItem === 'CRM' || active === 'CRM' ? '50px' : '40px',
          }}
        />
        {!sidebarCollapse && active === 'CRM' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[4].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display: hoverItem === 'CRM' && active != 'CRM' ? 'flex' : 'none',
          }}
        >
          {SIDEBAR_MENU[4].name}
        </div>
      </div>

      
      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'AffiliateBank' && !chatOn && sidebarCollapse
          ),
          display: hideAffiliate ? 'none' : 'flex',
        }}
        onClick={() => {
          if (email) {
            history.push('/affiliatebank');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionAffiliateBank}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[6].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'AffiliateBank' || active === 'AffiliateBank'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'AffiliateBank' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[6].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'AffiliateBank' && active != 'AffiliateBank'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[6].name}
        </div>
      </div>

      <div
        className="menu-itm active clickable"
        style={{
          ...applyStyle(
            active === 'Network Chains' && !chatOn && sidebarCollapse
          ),
          display: hideAffiliate ? 'none' : 'flex',
          borderRight: '0.5px solid #ebebeb',
        }}
        onClick={() => {
          if (email) {
            history.push('/networkChain');
          } else {
            history.push('/');
          }
          closeChatScreen();
        }}
        onMouseEnter={hoverFunctionCRM1}
        onMouseLeave={hoverLeaveFunc}
      >
        <img
          src={SIDEBAR_MENU[7].icon}
          alt="VaultIcon"
          style={{
            height:
              hoverItem === 'Network Chains' || active === 'Network Chains'
                ? '50px'
                : '40px',
          }}
        />
        {!sidebarCollapse && active === 'Network Chains' && (
          <div className="sidebar-icon-label">{SIDEBAR_MENU[7].name}</div>
        )}
        <div
          className={
            sidebarCollapse
              ? 'sidebar-icon-label1 sidebar-icon-label1-collapsed'
              : 'sidebar-icon-label1'
          }
          style={{
            display:
              hoverItem === 'Network Chains' && active != 'Network Chains'
                ? 'flex'
                : 'none',
          }}
        >
          {SIDEBAR_MENU[7].name}
        </div>
      </div>

    </div>
  );
};
