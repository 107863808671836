import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useCoinContextData } from '../../context/CoinContext';
import { SETTINGS_MENU } from './constants';
import OnholdComponent from '../../components/Onhold';

export const SettingsMenu = ({
  sidebarCollapse,
  toggleSettings,
  openSettings,
}) => {
  const { userLoginHandler, setChatOn } = useContext(BankContext);
  const {
    isClose,
    setIsClose,
    addBankingEnabled,
    setAddBankingEnabled,
    isCoinLoadingInAddAction,
    coinActionEnabled,
    setCoinActionEnabled,
    setCoinAction,
    onhold,
    setOnHold,
  } = useCoinContextData();

  const history = useHistory();
  const iconSize = 60;
  const iconSize1 = 50;
  const marginLeftSize = {
    marginLeft: sidebarCollapse ? '15px' : '0',
    padding: sidebarCollapse ? '1rem' : '1rem 1rem 1rem 0',
  };

  const onLogoutClick = () => {
    setIsClose(!isClose);
    toggleSettings(!openSettings);
    userLoginHandler();
    localStorage.clear();
    history.push(`/`);
  };

  // const onTaxesClick = () => {
  //   setIsClose(false);
  //   history.push('/taxes');
  // };

  const onSupportClick = () => setChatOn((prev) => !prev);

  // const onShareTokenClick = () => {
  //   setIsClose(false);
  //   history.push('/sharetoken');
  // };

  const onSettingsClick = () => {
    setIsClose(false);
    history.push('/settings');
  };

  const onHoldClick = () => {
    setOnHold('step1');
  };

  const onRankClick = () => {
    setIsClose(false);
    history.push('/ranks/ads');
    // history.push('/settings');
    console.log('onRankClick');
  };

  const onVaultClick = () => {
    setIsClose(false);
    history.push('/vault');
    // history.push('/settings');
    console.log('onVaultClick');
  } 
  const onJourneyClick = () => {
    setIsClose(false);
    history.push('/game');
    // history.push('/settings');
    console.log('onVaultClick');
  } 
  
  const onSubscriptionClick = () => {
    setIsClose(false);
    history.push('/subscriptions');
    // history.push('/settings');
    console.log('onSubscriptionClick');
  }


  const settingsMenu = SETTINGS_MENU(
    onLogoutClick,
    onSupportClick,
    onSettingsClick,
    onHoldClick,
    onRankClick,
    onVaultClick,onJourneyClick,
    onSubscriptionClick,
    // onTaxesClick,
    // onShareTokenClick,
  );

  const handleSettingsClick = (onClick, text) => () => {
    console.log(text, 'oufgokwhefhwef');
    if (text === 'Ranks') {
      onRankClick();
      return;
    } else if(text === 'Vaults') {
      onVaultClick();
      return;
    }else if(text === 'Journey') {
      onJourneyClick();
      return;
    } else if(text === 'Subscriptions') {
      onSubscriptionClick();
      return;
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div
        className="sidebar-menu"
        style={{
          height:
            onhold || sidebarCollapse
              ? 'calc(100% - 2px)'
              : 'calc(100% - 2px - 7rem)',
        }}
      >
        {!sidebarCollapse && (
          <div
            className="breadCrumbs"
            style={{
              marginTop: '0',
              marginLeft: '0',
              padding: '0 1.85rem',
              borderTop: '0.5px solid #e5e5e5',
              borderBottom: '0.5px solid #e5e5e5',
            }}
          >
            <div style={{ fontWeight: '550' }}>
              {onhold ? 'OnHold' : 'Advanced Menu'}
            </div>
          </div>
        )}
        {onhold ? (
          <OnholdComponent onhold={onhold} setOnHold={setOnHold} />
        ) : (
          settingsMenu.map((option) => (
            <Fragment key={option.id}>
              <div
                className="d-flex sidebar-container clickable-div"
                onClick={
                  handleSettingsClick(option.onClick, option.text)
                  // option.onClick
                }
                style={{
                  opacity: option.onClick ? 1 : 1,
                  cursor: option.onClick ? 'pointer' : 'pointer',
                  // pointerEvents: !option.onClick && 'none',
                  paddingLeft: sidebarCollapse ? '0' : '1.85rem',
                }}
              >
                <img
                  className="sidebar-content-icon"
                  src={option.icon}
                  alt={option.text}
                  width={sidebarCollapse ? iconSize : iconSize1}
                  style={marginLeftSize}
                />
                {!sidebarCollapse && (
                  <div className="col my-auto p-0">
                    <h5 className="action-label" style={{ fontWeight: '600' }}>
                      {option.text}
                    </h5>
                    <div className="getStartedBtn action-sublabel">
                      {option.subText}
                    </div>
                  </div>
                )}
              </div>
              <div className="sidebar-spacer" />
            </Fragment>
          ))
        )}
      </div>
      {!sidebarCollapse && !onhold && (
        <div className="tabsss">
          <div
            className="close-button"
            onClick={(e) => {
              e.stopPropagation();
              if (!isCoinLoadingInAddAction) {
                if (coinActionEnabled) {
                  setCoinActionEnabled(false);
                  setCoinAction(['Menu']);
                } else if (addBankingEnabled) {
                  setAddBankingEnabled(false);
                } else {
                  toggleSettings(!openSettings);
                }
              }
              if (isClose) {
                setIsClose(false);
              }
            }}
          >
            Close
          </div>
        </div>
      )}
    </>
  );
};
