import React, { useEffect, useState, useContext } from 'react';
import userArr from '../../static/images/affiliate/userArr.svg';
import userClose from '../../static/images/affiliate/userClose.svg';
import dropdown from '../../static/images/affiliate/dropdown.svg';
import loader from '../../static/images/affiliate/loader1.svg';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import {
  affiliateId,
  emailCheck,
  getApps,
  placeUnderMe,
  preRegistration,
} from './api';

const AddUser = () => {
  const {
    appLogo,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    setAllaffiliatedata,
    allaffiliatedata,
    selectedApp,
    setSelectedApp,
    selectedAffiliate,
    setSelectedAffiliate,
    isaffiliateselected,
    setIsaffiliateselected,
    isappselected,
    setIsappselected,
    mail,
    setMail,
    userName,
    setUsername,
    userfilteredApps,
    setuserfilteredApps,
    userfilteredAff,
    setuserfilteredAff,
  } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const [allapps, setAllapps] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [emailResult, setEmailResult] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [finalresult, setFinalresult] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [selectedNetworkChain, setSelectedNetworkChain] = useState('');

  useEffect(() => {
    getApps().then((response) => {
      let result = response?.data?.apps;
      setAllapps(result);
      setuserfilteredApps(result);
    });
  }, []);

  const validateEmail = (email) => {
    const regex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(email);
  };

  const handleChange = (event) => {
    setMail(event.target.value);
    setIsValid(validateEmail(event.target.value));
  };

  const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const fetchData = debounce(async () => {
    const response = await fetch(
      `https://comms.globalxchange.io/user/profile/data/get?email=${mail}`
    );
    const data = await response.json();
    setEmailResult(data?.count);
  }, 200);

  useEffect(() => {
    fetchData();
  }, [mail]);

  const userfetchData = debounce(async () => {
    const response = await fetch(
      `https://comms.globalxchange.io/user/profile/data/get?username=${userName}` //url changed
    );
    const data = await response.json();
    setUserResult(data?.count);
  }, 200);

  useEffect(() => {
    userfetchData();
  }, [userName]);

  const registration = () => {
    setIsloading(true);
    let obj = {
      username: userName,
      email: mail,
      ref_affiliate: affiliateid,
      app_code: selectedApp?.app_code,
    };

    preRegistration(obj).then((response) => {
      let result = response?.data?.email;
      setFinalresult(result);
      setIsloading(false);
      setAdduserStep('step4');
    });
  };

  const placementunderme = () => {
    placeUnderMe(email).then((response) => {
      let result = response?.data?.usersData?.[0]?.hardCoded?.[0]?.data;
      // console.log(result, 'result');
      setSelectedAffiliate(result);
      setIsaffiliateselected(true);
      setAdduserStep('step1');
    });
  };

  function filterItem(text) {
    let filterItem = allapps?.filter((eachitem) => {
      return eachitem?.app_name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setuserfilteredApps(filterItem);
  }

  function filterItemplacement(text) {
    let filterItem = allaffiliatedata?.filter((eachitem) => {
      return eachitem?.name?.toLowerCase()?.includes(text?.toLowerCase());
    });
    setuserfilteredAff(filterItem);
  }

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="headerr">
          <div className="topp">
            <div style={{ width: '80%' }}>
              <p style={{ fontSize: '1.75rem', fontWeight: '650' }}>New User</p>
              <p style={{ fontSize: '0.85rem' }}>
                Add New &rarr;{' '}
                <span
                  style={{
                    textDecorationLine:
                      adduserStep === 'step1' ? 'underline' : '',
                    fontWeight: adduserStep === 'step1' ? '650' : '',
                  }}
                >
                  User
                </span>
                {adduserStep === 'step2' && (
                  <>
                    &nbsp; &rarr;{' '}
                    <span
                      style={{
                        textDecorationLine: 'underline',
                        fontWeight: '650',
                      }}
                    >
                      Select App
                    </span>
                  </>
                )}
                {adduserStep === 'step3' && (
                  <>
                    &nbsp; &rarr;{' '}
                    <span
                      style={{
                        textDecorationLine: 'underline',
                        fontWeight: '650',
                      }}
                    >
                      Placement
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className="closebttn">
              <img
                src={userArr}
                alt=""
                onClick={() => {
                  setAdduserStep('step1');
                }}
              />
              <img
                src={userClose}
                alt=""
                onClick={() => {
                  setAdduser(false);
                  setAdduserStep('step1');
                  setSelectedAffiliate([]);
                  setSelectedApp([]);
                  setIsappselected(false);
                  setIsaffiliateselected(false);
                  setMail('');
                  setUsername('');
                  setAllapps([]);
                  setuserfilteredApps([]);
                  setAllaffiliatedata([]);
                  setuserfilteredAff([]);
                }}
              />
            </div>
          </div>
          {adduserStep === 'step1' ? (
            <div style={{ overflowY: 'scroll', height: '85vh' }}>
              <div className="each-field" style={{ marginTop: '2rem' }}>
                <p>Email</p>
                <div className="inputt-field">
                  <input
                    type="email"
                    placeholder="Enter The Users Email"
                    value={mail}
                    onChange={handleChange}
                    style={{ cursor: 'text' }}
                  />
                  <div
                    className="dotdiv"
                    style={{
                      background:
                        mail.length < 5 || emailResult || !isValid
                          ? '#d80027'
                          : '#57D6A1',
                    }}
                  ></div>
                </div>
              </div>
              <div className="each-field">
                <p>Username</p>
                <div className="inputt-field">
                  <input
                    type="text"
                    placeholder="Create A Username"
                    value={userName}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{
                      cursor: isValid && !emailResult ? 'text' : 'not-allowed',
                      pointerEvents: isValid && !emailResult ? '' : 'none',
                    }}
                  />
                  <div
                    className="dotdiv"
                    style={{
                      background:
                        userName.length < 1 || userResult
                          ? '#d80027'
                          : '#57D6A1',
                    }}
                  ></div>
                </div>
              </div>
              <div className="each-field">
                <p>Select App</p>
                <div
                  className="inputt-field"
                  onClick={() => setAdduserStep('step2')}
                  //  style={{pointerEvents: userResult ? 'none' : ''}}
                >
                  {isappselected ? (
                    <div className="each-appp2">
                      <img
                        src={selectedApp?.app_icon}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {selectedApp?.app_name}
                        </p>
                        <p
                          style={{ marginTop: '-0.2rem', fontSize: '0.95rem' }}
                        >
                          {selectedApp?.app_code}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="email"
                      placeholder="Click Here To Select An App"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
              <div className="each-field">
                <p>Placement</p>
                <div
                  className="inputt-field"
                  onClick={() => setAdduserStep('step3')}
                  // style={{ pointerEvents: isappselected ? '' : 'none' }}
                >
                  {isaffiliateselected ? (
                    <div className="each-appp1">
                      <p style={{ fontSize: '1.1rem', fontWeight: '650' }}>
                        {selectedAffiliate?.name || selectedAffiliate?.username}
                      </p>
                      <p>{selectedAffiliate?.email}</p>
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Click Here To Place The User"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
              <div className="each-field">
                <p>Select Network</p>
                <div
                  className="inputt-field"
                  onClick={() => setAdduserStep('step6')}
                  // style={{ pointerEvents: isappselected ? '' : 'none' }}
                >
                  {selectedNetworkChain ? (
                    <div className="each-appp1">
                      <p style={{ fontSize: '1.1rem', fontWeight: '650' }}>
                        {selectedNetworkChain}
                      </p>
                      {/* <p>{selectedAffiliate?.email}</p> */}
                    </div>
                  ) : (
                    <input
                      type="text"
                      placeholder="Click Here To Select Network Chain"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <div className="dddiv">
                    <img src={dropdown} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ) : adduserStep === 'step2' ? (
            <>
              <div
                className="each-field"
                style={{ marginTop: '2rem', paddingBottom: '0' }}
              >
                <p>Select App</p>
                <input
                  type="text"
                  className="inputt-field"
                  placeholder="Search Apps..."
                  onChange={(event) => filterItem(event.target.value)}
                />
              </div>
              <div className="appslist">
                {userfilteredApps?.map((e, i) => {
                  return (
                    <div
                      className="each-appp"
                      key={i}
                      onClick={() => {
                        setSelectedApp(e);
                        setIsappselected(true);
                        setAdduserStep('step1');
                      }}
                    >
                      <img
                        src={e?.app_icon}
                        alt=""
                        style={{ width: '40px', height: '40px' }}
                      />
                      <div>
                        <p style={{ fontSize: '1.15rem', fontWeight: '650' }}>
                          {e?.app_name}
                        </p>
                        <p>{e?.app_code}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : adduserStep === 'step6' ? (
            <div style={{ paddingTop: '30px' }}>
              <div
                className="networkLeg"
                onClick={(e) => {
                  setSelectedNetworkChain('Left Leg');
                  setAdduserStep('step1');
                }}
              >
                Left Leg
              </div>
              <div
                className="networkLeg"
                onClick={(e) => {
                  setSelectedNetworkChain('Right Leg');
                  setAdduserStep('step1');
                }}
              >
                Right Leg
              </div>
            </div>
          ) : (
            <>
              <div
                className="each-field"
                style={{ marginTop: '2rem', paddingBottom: '0' }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Select Placement</p>
                  <p
                    style={{
                      textDecorationLine: 'underline',
                      fontWeight: '650',
                      fontSize: '0.85rem',
                      marginLeft: '4rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      placementunderme();
                    }}
                  >
                    Place Directly Under Me
                  </p>
                  <p></p>
                </div>
                <input
                  type="text"
                  className="inputt-field"
                  placeholder="Search Network..."
                  onChange={(event) => filterItemplacement(event.target.value)}
                />
              </div>
              <div className="appslist">
                {userfilteredAff?.map((e, i) => {
                  return (
                    <div
                      className="each-appp1"
                      key={i}
                      onClick={() => {
                        setSelectedAffiliate(e);
                        setIsaffiliateselected(true);
                        setAdduserStep('step1');
                      }}
                    >
                      <p style={{ fontSize: '1.1rem', fontWeight: '650' }}>
                        {e?.name}
                      </p>
                      <p>{e?.email}</p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div className="footerr">
          <div
            className="goback"
            onClick={() => {
              setAdduserStep('step1');
            }}
          >
            Go Back
          </div>
          <div
            className={
              mail &&
              userName &&
              affiliateid &&
              selectedApp?.app_code &&
              (selectedAffiliate?.name || selectedAffiliate?.username)
                ? 'addd1'
                : 'addd'
            }
            onClick={() => {
              if (
                mail &&
                userName &&
                affiliateid &&
                selectedApp?.app_code &&
                (selectedAffiliate?.name || selectedAffiliate?.username)
              ) {
                registration();
              }
            }}
          >
            Add User
          </div>
        </div>
      </div>

      {adduserStep === 'step4' && (
        <div
          className="loadingIcon"
          style={{ height: '100%', opacity: '1', padding: '0 2rem' }}
        >
          <div
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              color: '#18191D',
            }}
          >
            {`You Have Successfully Added ${finalresult} To Your Network. You
            Will Automatically Be Redirected To Your NetworkChain Page`}
          </div>
        </div>
      )}

      {isloading && (
        <div className="loadingIcon" style={{ height: '100%', opacity: '1' }}>
          <LoadingAnimation icon={appLogo} width={250} />
          <div
            style={{
              position: 'absolute',
              bottom: '5%',
              color: '#18191D',
              fontSize: '1.3rem',
            }}
          >
            Adding New User To Your Network
          </div>
        </div>
      )}
    </>
  );
};

export default AddUser;
