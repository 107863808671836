import React from 'react';
import './Car.scss';

const Car = ({ destination, onClick }) => {
  return (
    <div
      className={`car car-${destination}`}
      onClick={() => onClick(destination)}
    >
      🚗
    </div>
  );
};

export default Car;
