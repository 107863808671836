import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import Layout from '../../Layout/Layout';
import '../AffiliatePage/affiliate.scss';
import ChainView from '../AffiliatePage/ChainView';
import ChainView1 from '../AffiliatePage/ChainView1';
import { affiliateBalances, uplineData } from '../AffiliatePage/api';
import styles from './drawer.module.scss';

import search from '../../static/images/malls/search.svg';
import fulllogo from '../../static/images/new-menu/networkchain.svg';
import fulllogo1 from '../../static/images/networkChain/fullLogo.svg';
import guest from '../../static/images/affiliate/userlogo.svg';
import ablogo from '../../static/images/affiliate/ablogo.svg';
import TreeView from '../AffiliatePage/TreeView/index';

import axios from 'axios';
import { useUserApps, useUserDetails, useUserVaults } from '../../queryHooks';
import { planBContext } from '../../context/PlanBContext';


import SidebarComponent from '../../Layout/SidebarComponent';

const NetworkChain = () => {
  const {
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    allaffiliatedata,
    setAllaffiliatedata,
    chainuserData,
    setchainUserData,
    appColorCode,
    affStats,
    setAffStats,
    appName,
    upline,
    setUpline,
    invite,
    setInvite,
    setuserfilteredAff,
    iswithdraw,
    setIswithdraw,
    //update user name
    newUserNameSidebar,
    setnewUserNameSidebar,
    newUserName,
    setnewUserName,
    usernameExist,
    setusernameExist,
    popular,
    setPopular,
  } = useAppContextDetails();
  // const email = localStorage.getItem("tempAdmin") ? localStorage.getItem("tempAdmin") : 'adamdonovan1988@gmail.com';
  const {
    email,
    token,
    username,
    name,
    profileImg,
    copyToClipboard,
    linkname,
    setLinkname,
  } = useContext(BankContext);
  const loggedInEmail = localStorage.getItem("tempAdmin") ? localStorage.getItem("tempAdmin") : email;
  if (!loggedInEmail) {
    history.push('/');
  }
  const { data: userData } = useUserDetails(loggedInEmail);


  const history = useHistory();
  const [ncTab, setNcTab] = useState('Left Leg');
  const [viewingUser, setViewingUser] = useState('');

  const [selectedStep, setSelectedStep] = useState(null);
  const [addUserEmail, setAddUserEmail] = useState('');
  const [holdingTank, setHoldingTank] = useState([]);
  const [holdingTankLoading, setHoldingTankLoading] = useState(false);

  const [addUserName, setAddUserName] = useState('');
  const [addPhoneNo, setAddPhoneNo] = useState('');
  const [addTempUName, setAddTempUName] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [affId, setAffId] = useState();

  const [updatingUsername, setupdatingUsername] = useState(false)
  const [tempAdmin, setTempAdmin] = useState(null)

  
  const getHoldingTankData = () => {
    setHoldingTankLoading(true);
    axios
      .get(
        ` https://comms.globalxchange.io/client/tree/get/downline/user?loggedInEmail=${loggedInEmail}&client_app=manifest&legAssigned=false`
      )
      .then(({ data }) => {
        if (data.status) {
          setHoldingTank(data.data);
          setHoldingTankLoading(false);
        }
      });
  };


  useEffect(() => {
    if(!popular){
      getHoldingTankData();
    }
  }, [popular]);

  useEffect(() => {
    let mailId = loggedInEmail;

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      //   console.log(result, 'balance');
      setBalances(result);
    });

    uplineData(mailId).then((response) => {
      let result = response?.data?.uplines?.[0];
      setUpline(result);
      let username = response?.data?.user?.username;
      setLinkname(username);
    });
  }, [loggedInEmail]);

  // useEffect(() => {
  //   if(localStorage.getItem("tempAdmin")){
  //     setTempAdmin(localStorage.getItem("tempAdmin"));
  //   }
  // }, [localStorage.getItem("tempAdmin")])


  return (
    <Layout active="Network Chains" className="affiliate" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="affiliate-navbar" style={{ position: 'relative' }}>
          <div
            style={{
              height: '100%',
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            {/* <div className="affiliate-icon" style={{ width: '32%' }}>
              <img src={fulllogo} alt="" />
              Tree
            </div> */}
            <div
              className="academy-icon"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img src={fulllogo} alt="" />
              <span
                style={{
                  fontSize: '1.35rem',
                  fontWeight: 600,
                  paddingLeft: '10px',
                }}
              >
                Tree
              </span>
            </div>
          </div>
          <div
            className="search-affiliate"
            style={{
              width: '43%',
              height: 'calc(100% - 2rem)',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '50%',
              left: '42%',
            }}
          >
            <div className="im">
              <input type="text" placeholder="Im Looking For..." />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          {/* <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '50px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '45px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {balances?.gxbroker_balance
                    ? balances?.gxbroker_balance?.toFixed(2)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div> */}
          <div
            className="academy-btn"
            onClick={() => {
              setPopular(!popular);
            }}
          >
            Actions
          </div>
        </div>
        <div className="w-space" style={{ borderBottom: '1px solid #e7e7e7' }}>
          <div className="optionsss" style={{ width: '18%' }}>
            <div
              className={`each-option ${
                ncTab === 'Left Leg' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: ncTab === 'Left Leg' ? '600' : '',
              }}
              onClick={() => setNcTab('Left Leg')}
            >
              <div>
                <p>Tree View</p>
              </div>
              
            </div>
           
            {/* <div
              className={`each-option ${
                ncTab === 'Right Leg' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: ncTab === 'Right Leg' ? '600' : '',
              }}
              onClick={() => setNcTab('Right Leg')}
            >
              <div>
                <p>Legacy View</p>
              </div>
            </div> */}
            <div style={{ display:'flex',
                justifyContent:'flex-end',
                alignItems:'center', position:'absolute', right:40}}>
               {localStorage.getItem("tempAdmin") && <span className={styles.selectedAdmin}>{localStorage.getItem("tempAdmin")}</span> }
                <input className={styles.adminInput} type="text" placeholder='Enter Email' value={tempAdmin} onChange={e => setTempAdmin(e.target.value)}/>
                <button className={styles.adminSubmit} onClick={e => {localStorage.setItem("tempAdmin", tempAdmin)
                  setTempAdmin("");
                  window.location.reload();
              }}>Submit</button>
                {localStorage.getItem("tempAdmin") && <button className={styles.adminClear} onClick={e => {localStorage.removeItem("tempAdmin")
                setTempAdmin("");
                window.location.reload();
              }}>Clear</button>}
              </div>
          </div>
          
        </div>
        
        {/* {ncTab === 'Left Leg' ? ( */}
         
          <TreeView holdingTank={holdingTank} setHoldingTank={setHoldingTank} />
        {/* ) : (
          <ChainView1
            viewingUser={viewingUser}
            setViewingUser={setViewingUser}
          />
        )} */}
        {/* <ChainView
            viewingUser={viewingUser}
            setViewingUser={setViewingUser}
          />
        </div> */}
        {popular && (
          <div className="popular">
            <SidebarComponent/>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NetworkChain;


