import React, {useContext, useState, useEffect} from 'react';
import styles from "./sidebar.module.scss"
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { planBContext } from '../../context/PlanBContext';
import dummyProfile from '../../static/images/dummyProfile.svg';
import uploading from '../../static/images/uploading.svg';
import { uploadImageFunc } from '../../utils/imageUpload';
import close from './close.svg';

const AddUser = ({setSelectedStep, setPopular}) => {

    const {
        email,
        token,
        username,
        name,
        profileImg,
        copyToClipboard,
        linkname,
        setLinkname,
      } = useContext(BankContext);

    const [addUserEmail, setAddUserEmail] = useState('');


    const [addUserName, setAddUserName] = useState('');
    const [addPhoneNo, setAddPhoneNo] = useState('');
    const [addTempUName, setAddTempUName] = useState('');
    const [userProfile, setUserProfile] = useState('');
    const [affId, setAffId] = useState();

    const [updatingUsername, setupdatingUsername] = useState(false)

    useEffect(() => {
      axios
        .get(
          `https://comms.globalxchange.io/client/tree/get/user/profile?email=${email}`
        )
        .then(({ data }) => {
          if (data.status) {
            setAffId(data.data.affiliate_id);
          }
        });
    }, []);
  

    const addUser = () => {

        axios
          .post(`https://gxauth.apimachine.com/gx/user/admin/signup`, {
            username: addTempUName,
            user_full_name: addUserName,
            profile_img: userProfile,
            mobile: addPhoneNo,
            email: addUserEmail,
            ref_affiliate: affId,
            app_code: 'manifest',
          })
          .then(({ data }) => {
            if (data.status) {
              setSelectedStep('Add user Success1');
            }
          });
      };

    return ( 
        <>
            <div className={styles.headTxt}>
                <div>Add new user</div>
                <div
                onClick={() => {
                    setPopular(false);
                    setSelectedStep(null);
                }}
                className="close-div"
                >
                <img src={close} alt="" />
                </div>
            </div>
            <div>
                {/* User Email */}
                <div>
                <div className={styles.inputTitle}>Enter the email of the user</div>
                <div className={styles.inputWrapper}>
                    <input
                    value={addUserEmail}
                    onChange={(e) => setAddUserEmail(e.target.value)}
                    type="text"
                    placeholder="Email"
                    style={{ outline: 'none', width: '100%', border: 'none' }}
                    />
                </div>
                </div>
                {/* User Name */}
                <div style={{ paddingTop: '30px' }}>
                <div className={styles.inputTitle}>Enter their full name</div>
                <div className={styles.inputWrapper}>
                    <input
                    value={addUserName}
                    onChange={(e) => setAddUserName(e.target.value)}
                    type="text"
                    placeholder="Name..."
                    style={{ outline: 'none', width: '100%', border: 'none' }}
                    />
                </div>
                </div>
                {/* Phone No */}
                <div style={{ paddingTop: '30px' }}>
                <div className={styles.inputTitle}>Enter their phone number</div>
                <div className={styles.inputWrapper}>
                    <input
                    value={addPhoneNo}
                    onChange={(e) => setAddPhoneNo(e.target.value)}
                    type="number"
                    placeholder="Number..."
                    style={{ outline: 'none', width: '100%', border: 'none' }}
                    />
                </div>
                </div>
                {/* Temp Username */}
                <div style={{ paddingTop: '30px' }}>
                <div className={styles.inputTitle}>Create a temporary username</div>
                <div className={styles.inputWrapper}>
                    <input
                    value={addTempUName}
                    onChange={(e) => setAddTempUName(e.target.value)}
                    type="text"
                    placeholder="Username..."
                    style={{ outline: 'none', width: '100%', border: 'none' }}
                    />
                </div>
                </div>
                <div style={{ paddingTop: '30px' }}>
                <ImageUploadDiv setFunc={setUserProfile} funcValue={userProfile} />
                </div>

                <div className={styles.footer}>
                <div className={styles.backBtn}>Go Back</div>
                <div className={styles.submitBtn} onClick={(e) => addUser()}>
                    Submit
                </div>
                </div>
            </div>
        </>
     );
}
 
export default AddUser;

export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
    const {
      planBAccountPicUploading,
      setplanBAccountPicUploading,
      setSelectedDropDown,
    } = useContext(planBContext);
    return (
      <div className="imageUploadDiv" onClick={() => setSelectedDropDown('')}>
        <div className="heading">{heading}</div>
        <div className="imageDiv">
          <img
            src={funcValue ? funcValue : dummyProfile}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
          />
          {planBAccountPicUploading ? (
            <div className="overlayDiv">
              <img src={uploading} alt="uploading" className="uploadingimg" />
            </div>
          ) : (
            ''
          )}
        </div>
        <label htmlFor="profileUpdateImgPlanB" className="uploadFileDiv">
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div>
            {planBAccountPicUploading ? 'Uploading...' : 'Upload Profile Photo'}
          </div>
        </label>
      </div>
    );
  };