import React, { useEffect, useState, useContext, useRef } from 'react';
import Layout from '../../Layout/Layout';
import fulllogo from '../../static/images/Funds/funds.svg';

import ts from '../../static/images/Funds/ts.svg';
import usdt from '../../static/images/Funds/usdt.svg';
import search from '../../static/images/malls/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import Skeleton from 'react-loading-skeleton';
import Holdings from './Holdings';
import { useAppContextDetails } from '../../context/AppContext';
import { useHistory } from 'react-router-dom';
import Eapage from './EAPage';
import {
  allFunds,
  SeletedFunds,
  buyProductPrice,
  CovertPrice,
  vaultBalance,
  AssetsBalance,
} from './apidata';
import { BankContext } from '../../context/Context';
import './Funds.scss';
import AddnewFund from './AddnewFund';
import Table from './Table';
export default function Index() {
  const [toggle, settoggle] = useState(false);
  const [drawerToggle, setdrawerToggle] = useState(false);
  const {
    setFundsTab,
    FundsTab,
    setStoreFundsDetail,
    StoreFundsDetail,
    setfundsDetailTable,
    setcardNameFunds,
    setfinaldata,
    finaldata,
    setCoinStore,
    CoinStore,
    setbuyItemDetail,
    buyItemDetail,
    setamountFunds,
    amountFunds,
    setUpdateVaultFunds,
    setAssetsFunds,
    setToggleAddFunds,
    ToggleAddFunds,
    AssetsFunds,
    UpdateVaultFunds,
  } = useAppContextDetails();
  const { email, profileId, token } = useContext(BankContext);
  const [list, setlist] = useState('sucess');
  const history = useHistory();
  useEffect(() => {
    allFunds().then((response) => {
      let result = response?.data?.investmentSubTypes;
      setStoreFundsDetail(result);
    });
  }, []);

  if (!email) {
    history.push('/');
  }
  useEffect(() => {}, [FundsTab]);

  const fundssSeleted = (e) => {
    SeletedFunds(e).then((response) => {
      let result = response?.data?.paths;
      setfundsDetailTable(result);
    });
  };
  const nFormatter = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
  };

  const callhander = (e) => {
    setcardNameFunds(e.name);
    fundssSeleted(e.code);
    settoggle(true);
  };
  console.log('StoreFundsDetail', finaldata);

  const assetBalanceFunction = () => {
    let obj = {
      app_code: 'manifest',
      email: email,
      include_coins: [buyItemDetail.name],
    };

    AssetsBalance(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        // setCoinStore(result.coins_data);
        // console.log('result', result);

        setAssetsFunds(result?.coins_data);
        valuebalanceFunction();
      }
    });
  };

  const handleBuy = async (e) => {
    let obj = {
      app_code: 'manifest',
      email: email,
      post_app_prices: true,
      with_balances: true,
      orderby_dsc: true,
    };
    await setlist('list');
    await setdrawerToggle(true);
    await setfinaldata(e);
    buyProductPrice(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        setCoinStore(result.coins_data);
        setamountFunds({});
        console.log('result', result.coins_data);
      }
    });
  };

  const valuebalanceFunction = () => {
    let obj = {
      email: email,
      app_code: 'manifest',
      token: finaldata?.token_profile_data?.coinSymbol,
    };
    console.log('tokezxczxczxn', obj);
    vaultBalance(obj).then((response) => {
      let result = response?.data;
      if (result.status) {
        setUpdateVaultFunds(result?.vaultsData?.[0]?.tokens);
        setapiloading(false);
        setlist('sucess');
        // setCoinStore(result.coins_data);
      }
    });
  };
  console.log('123', UpdateVaultFunds);

  const buylisttoke = (e) => {
    setbuyItemDetail({
      name: e.coinSymbol,
      img: e.coinImage,
      coin: e.coinValue,
    });
    setlist('buy');
    setdrawerToggle(true);
  };
  const [apiloading, setapiloading] = useState(false);
  const [coinValue, setcoinValue] = useState(false);
  const ref = useRef(null);

  const GenerateCallTrade = () => {
    let obj = {
      email: email,
      token: token,
      app_code: 'manifest',
      profile_id: profileId,
      path_id: finaldata?.path_id,
      user_pay_coin: buyItemDetail?.name,
      pay_amount: coinValue,
      stats: false,
    };
    setapiloading(true);
    console.log('hey', obj);
    if (coinValue.length > 0) {
      CovertPrice(obj).then((response) => {
        let result = response?.data;
        console.log('resultzzzzz', result);
        if (result.status) {
          //   setamountFunds(result);
          assetBalanceFunction();
        }
      });
    }
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setdrawerToggle(false);
    }
  };
  //

  const GenerateCall = () => {
    let obj = {
      email: email,
      token: token,
      app_code: 'manifest',
      profile_id: profileId,
      path_id: finaldata?.path_id,
      user_pay_coin: buyItemDetail?.name,
      pay_amount: coinValue,
      stats: true,
    };
    console.log('hey', obj);
    setapiloading(true);
    if (coinValue.length > 0) {
      CovertPrice(obj).then((response) => {
        let result = response?.data;
        console.log('resultzzzzz', result);
        if (result.status) {
          setamountFunds(result);
          setapiloading(false);
          setlist('trade');
        }
      });
    }
  };

  //   useEffect(() => {
  //     document.addEventListener('click', handleClickOutside, false);
  //     return () => {
  //       document.removeEventListener('click', handleClickOutside, false);
  //     };
  //   }, []);

  //   return { , isComponentVisible, setdrawerToggle };

  //
  //   const history = useHistory();

  //   useEffect(() => {
  //     let product = localStorage.getItem('product');
  //     setIndex(JSON.parse(product));

  //     let obj = {
  //       email,
  //       token,
  //       product_id: index?.product_id,
  //       billing_method: 'monthly',
  //       pay_with_coin: mallselectedCoin?.symbol,
  //       app_code: 'manifest',
  //       profile_id: profileId,
  //     };
  //     buyProduct(obj).then((response) => {
  //       let result = response?.data;
  //       if(result.status) {
  //         setIsfinalstep(true);
  //         setisloading(false);
  //       }

  //     });
  //   }, []);
  const fundslayout = () => {
    switch (FundsTab) {
      case 'EA’s':
        return <Eapage />;
      case 'Holdings':
        return <Holdings />;
      case "IFO's":
        return (
          <div className="left-fund-container">
            <div className="cards-funds">
              {StoreFundsDetail?.length > 0 ? (
                <>
                  {StoreFundsDetail?.map((item) => {
                    return (
                      <div
                        className="ItemCards"
                        onClick={() => callhander(item)}
                      >
                        <div
                          className="topCard"
                          style={{
                            backgroundColor: `#${item?.color_codes[0]}`,
                          }}
                        ></div>
                        <div className="bottomcard">
                          <div className="ImageSection">
                            <div className="leftbox">
                              <img src={item?.icon} />
                            </div>
                            <div className="Rightbox">Invest</div>
                          </div>
                          <div className="SectionBottomFunds">
                            <div className="contentSection">
                              <div className="fundsName">{item?.name}</div>
                              <div className="fundsvalue">
                                {item?.pathsCount} Funds
                              </div>
                            </div>
                            <div className="fundDes">{item.description}</div>
                            <div className="lowerSection">
                              <div className="subItemFunds">
                                <div className="BottomDetail">
                                  All Time Returns
                                </div>
                                <div className="retunsValue">--</div>
                              </div>
                              <div className="subItemFunds">
                                <div className="BottomDetail">Investors</div>
                                <div className="BottomValue">
                                  {item?.totalTokenHolders}
                                </div>
                              </div>
                              <div className="subItemFunds">
                                <div className="BottomDetail">Market Cap</div>
                                <div className="BottomValue">
                                  {nFormatter(item.total_market_cap_in_dc)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {' '}
                  <div className="ItemCards">
                    <div
                      className="topCard"
                      style={{
                        backgroundColor: `#ddd`,
                      }}
                    ></div>
                    <div className="bottomcard">
                      <div className="ImageSection">
                        <div className="leftbox">
                          <Skeleton className="sectionskeletoncard" />
                        </div>
                        <div className="Rightbox">Invest</div>
                      </div>

                      <div className="SectionBottomFunds">
                        <div className="contentSection"></div>
                        <Skeleton className="sectionskeletoncard1" />
                        <div className="lowerSection">
                          <div className="subItemFunds">
                            <div className="BottomDetail">All Time Returns</div>
                            <div className="retunsValue">--</div>
                          </div>
                          <div className="subItemFunds">
                            <div className="BottomDetail">Investors</div>
                            <div className="BottomValue">
                              <Skeleton className="sectionskeletoncard" />
                            </div>
                          </div>
                          <div className="subItemFunds">
                            <div className="BottomDetail">Market Cap</div>
                            <div className="BottomValue">
                              <Skeleton className="sectionskeletoncard" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ItemCards">
                    <div
                      className="topCard"
                      style={{
                        backgroundColor: `#ddd`,
                      }}
                    ></div>
                    <div className="bottomcard">
                      <div className="ImageSection">
                        <div className="leftbox">
                          <Skeleton className="sectionskeletoncard" />
                        </div>
                        <div className="Rightbox">Invest</div>
                      </div>

                      <div className="SectionBottomFunds">
                        <div className="contentSection"></div>
                        <Skeleton className="sectionskeletoncard1" />
                        <div className="lowerSection">
                          <div className="subItemFunds">
                            <div className="BottomDetail">All Time Returns</div>
                            <div className="retunsValue">--</div>
                          </div>
                          <div className="subItemFunds">
                            <div className="BottomDetail">Investors</div>
                            <div className="BottomValue">
                              <Skeleton className="sectionskeletoncard" />
                            </div>
                          </div>
                          <div className="subItemFunds">
                            <div className="BottomDetail">Market Cap</div>
                            <div className="BottomValue">
                              <Skeleton className="sectionskeletoncard" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
    }
  };

  const drawerFunction = () => {
    switch (list) {
      case 'sucess':
        return (
          <>
            <div
              className={
                drawerToggle
                  ? 'rightfundContiner slowfunddrawer showDrawer '
                  : 'rightfundContiner slowfunddrawer  notshowDrawer '
              }
              ref={ref}
            >
              <h1>Purchase Successful</h1>
              <p>
                You Have Purchased{' '}
                {amountFunds?.investmentAsset_stats?.tokens_amount?.toFixed(2)}{' '}
                {UpdateVaultFunds?.[0]?.token_profile_data?.coinName}s
              </p>
              <div
                className="priceCoinFunds"
                style={{ fontWeight: 600, height: 'calc(100% - 31.1rem)' }}
              >
                <div className="textcoin">
                  Updated {buyItemDetail.name} Vault Balance
                </div>
                <div className="listofCoinPrice" style={{ marginTop: '1rem' }}>
                  <div className="imgpricelist">
                    <img src={AssetsFunds?.[0]?.coinImage} />
                    <h2> {AssetsFunds?.[0]?.coinSymbol}</h2>
                  </div>
                  <h5
                    style={{
                      opacity: 0.25,
                    }}
                  >
                    {AssetsFunds?.[0]?.coinValue?.toFixed(2)}
                  </h5>
                </div>
                <div className="textcoin" style={{ fontWeight: 600 }}>
                  Updated {finaldata?.token_profile_data?.coinSymbol} Vault
                  Balance
                </div>
                <div className="listofCoinPrice" style={{ marginTop: '1rem' }}>
                  <div className="imgpricelist">
                    <img
                      src={UpdateVaultFunds?.[0]?.token_profile_data?.coinImage}
                    />
                    <h2>
                      {UpdateVaultFunds?.[0]?.token_profile_data?.coinSymbol}
                    </h2>
                  </div>
                  <h5
                    style={{
                      opacity: 0.25,
                    }}
                  >
                    {UpdateVaultFunds?.[0]?.value?.toFixed(2)}
                  </h5>
                </div>
              </div>
              <div
                className="labelfundscoin"
                style={{
                  marginTop: '1rem',
                  background: 'white',
                  color: '#18191d',
                  border: '1px solid #E7E7E7',
                }}
                // onClick={assetBalanceFunction}
              >
                See {buyItemDetail.name} Vault
              </div>{' '}
              <div
                className="labelfundscoin"
                style={{ marginTop: '1rem', background: '#9A4C97' }}
              >
                See {finaldata?.token_profile_data?.coinSymbol} Vault
              </div>{' '}
              <div
                className="labelfundscoin"
                style={{ marginTop: '1rem', background: '#9FD6DF' }}
                onClick={() => setdrawerToggle(false)}
              >
                Close
              </div>{' '}
            </div>
          </>
        );
      case 'list':
        return (
          <>
            {' '}
            <div
              className={
                drawerToggle
                  ? 'rightfundContiner slowfunddrawer showDrawer '
                  : 'rightfundContiner slowfunddrawer  notshowDrawer '
              }
              ref={ref}
            >
              <div
                className="ajustheightfund"
                style={{
                  opacity: apiloading ? 0.2 : 1,
                }}
              >
                <h1>Buy {finaldata?.token_profile_data?.coinSymbol}</h1>
                <p>Please Select One Your Vaults To Pay With</p>
                <div className="priceCoinFunds">
                  {CoinStore?.length > 0 ? (
                    <>
                      {CoinStore?.map((item) => {
                        return (
                          <div
                            className="listofCoinPrice"
                            onClick={() => buylisttoke(item)}
                          >
                            <div className="imgpricelist">
                              <img src={item?.coinImage} />
                              <h2>{item?.coinSymbol}</h2>
                            </div>
                            <h5>{item?.coinValue}</h5>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {' '}
                      <div className="listofCoinPrice">
                        <div className="imgpricelist">
                          <Skeleton className="sectionskeletonloger" />
                        </div>
                        <Skeleton className="sectionskeletonloger" />
                      </div>
                      <div className="listofCoinPrice">
                        <div className="imgpricelist">
                          <Skeleton className="sectionskeletonloger" />
                        </div>
                        <Skeleton className="sectionskeletonloger" />
                      </div>
                      <div className="listofCoinPrice">
                        <div className="imgpricelist">
                          <Skeleton className="sectionskeletonloger" />
                        </div>
                        <Skeleton className="sectionskeletonloger" />
                      </div>
                      <div className="listofCoinPrice">
                        <div className="imgpricelist">
                          <Skeleton className="sectionskeletonloger" />
                        </div>
                        <Skeleton className="sectionskeletonloger" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="labelfundscoin">Next Step</div>{' '} */}
          </>
        );
      case 'buy':
        return (
          <>
            <div
              className={
                drawerToggle
                  ? 'rightfundContiner slowfunddrawer showDrawer '
                  : 'rightfundContiner slowfunddrawer  notshowDrawer '
              }
              ref={ref}
            >
              <div
                className="ajustheightfund"
                style={{
                  opacity: apiloading ? 0.2 : 1,
                }}
              >
                <div className="Quote">Generate Quote</div>
                <div className="texthandler">
                  <div className="textcoin">You Are Investing</div>

                  <div className="textcoin">
                    Balance: {buyItemDetail?.coin?.toFixed(4)}
                  </div>
                </div>
                <div className="priceCoinFundsList">
                  <div className="boxInputFunds">
                    <input
                      placeholder="0.0000"
                      onChange={(event) => setcoinValue(event.target.value)}
                    />
                    <div className="leftsideCoing">
                      <img src={buyItemDetail?.img} />
                      <div className="seletedCoinname">
                        {buyItemDetail?.name}
                      </div>
                    </div>
                  </div>
                  <div className="textcoin">Converting To</div>
                  <div className="boxInputFunds">
                    <input
                      style={{
                        pointerEvents: ' none',
                      }}
                      placeholder="0.0000"
                      //   value={amountFunds?.finalToAmount}
                      readonly
                    />
                    <div className="leftsideCoing">
                      <img src={finaldata?.asset_metaData?.coinImage} />
                      <div className="seletedCoinname">{finaldata?.asset}</div>
                    </div>
                  </div>
                  <div className="textcoin">You Will Receive</div>
                  <div className="boxInputFunds">
                    <input
                      placeholder="0.0000"
                      readonly
                      style={{
                        pointerEvents: ' none',
                      }}
                      //   value={amountFunds?.investmentAsset_stats?.tokens_amount?.toFixed(
                      //     2
                      //   )}
                    />
                    <div className="leftsideCoing">
                      <img src={finaldata?.token_profile_data?.coinImage} />
                      <div className="seletedCoinname">
                        {finaldata?.token_profile_data?.coinSymbol}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="labelfundscoin" onClick={GenerateCall}>
                  Buy {buyItemDetail?.name}
                </div>
              </div>
            </div>
          </>
        );

      case 'trade':
        return (
          <>
            <div
              className={
                drawerToggle
                  ? 'rightfundContiner slowfunddrawer showDrawer '
                  : 'rightfundContiner slowfunddrawer  notshowDrawer '
              }
              ref={ref}
            >
              <div
                className="ajustheightfund"
                style={{
                  opacity: apiloading ? 0.2 : 1,
                }}
              >
                <div className="Quote">Place Trade</div>
                <div className="texthandler">
                  <div className="textcoin">You Are Investing</div>

                  <div className="textcoin">
                    {' '}
                    Balance: {buyItemDetail?.coin?.toFixed(4)}
                  </div>
                </div>
                <div className="priceCoinFundsList">
                  <div className="boxInputFunds">
                    <input
                      placeholder="0.0000"
                      value={amountFunds?.finalFromAmount}
                      readonly
                      style={{
                        pointerEvents: ' none',
                      }}
                    />
                    <div className="leftsideCoing">
                      <img src={buyItemDetail?.img} />
                      <div className="seletedCoinname">
                        {buyItemDetail?.name}
                      </div>
                    </div>
                  </div>
                  <div className="textcoin">Converting To</div>
                  <div className="boxInputFunds">
                    <input
                      placeholder="0.0000"
                      value={amountFunds?.finalToAmount}
                      readonly
                      style={{
                        pointerEvents: ' none',
                      }}
                    />
                    <div className="leftsideCoing">
                      <img src={finaldata?.asset_metaData?.coinImage} />
                      <div className="seletedCoinname">{finaldata?.asset}</div>
                    </div>
                  </div>
                  <div className="textcoin">You Will Receive</div>
                  <div className="boxInputFunds">
                    <input
                      placeholder="0.0000"
                      readonly
                      value={amountFunds?.investmentAsset_stats?.tokens_amount?.toFixed(
                        2
                      )}
                      style={{
                        pointerEvents: ' none',
                      }}
                    />
                    <div className="leftsideCoing">
                      <img src={finaldata?.token_profile_data?.coinImage} />
                      <div className="seletedCoinname">
                        {finaldata?.token_profile_data?.coinSymbol}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="labelfundscoin" onClick={GenerateCallTrade}>
                  Buy {buyItemDetail?.name}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  //   console.log("IFO's", FundsTab);
  return (
    <Layout active="funds" className="funds" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="aiindex-navbar">
          <div className="navhandling">
            <div className="aiindex-icon">
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-aiindex">
              <div className="im">
                <input
                  type="text"
                  placeholder="Ex. Bitcoin..."
                  // onChange={(event) => setfiltersearch(event?.target?.value)}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
          <label onClick={() => setToggleAddFunds(true)}>Launch Fund</label>
        </div>
        <div className="w-space">
          <div className="optionss" style={{ width: '45%' }}>
            <div
              className="each-option"
              style={{
                borderBottom: FundsTab === "IFO's" ? '2px solid #18191d' : '',
                fontWeight: FundsTab === "IFO's" ? '600' : '',
              }}
              onClick={() => {
                setFundsTab("IFO's");
                settoggle(false);
              }}
            >
              <div>
                <p>IFO's</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom:
                  FundsTab === 'Holdings' ? '2px solid #18191d' : '',
                fontWeight: FundsTab === 'Holdings' ? '600' : '',
              }}
              onClick={async () => {
                setFundsTab('Holdings');
                settoggle(false);
              }}
            >
              <div>
                <p>Holdings</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom: FundsTab === 'EA’s' ? '2px solid #18191d' : '',
                fontWeight: FundsTab === 'EA’s' ? '600' : '',
              }}
              onClick={() => setFundsTab('EA’s')}
            >
              <div>
                <p>EA’s</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                borderBottom:
                  FundsTab === 'Terminals' ? '2px solid #18191d' : '',
                fontWeight: FundsTab === 'Terminals' ? '600' : '',
              }}
              onClick={() => setFundsTab('Terminals')}
            >
              <div>
                <p>Terminals</p>
              </div>
            </div>
          </div>
        </div>
        <div className="funds-content">
          {toggle ? (
            <Table handleBuy={handleBuy} setdrawerToggle={setdrawerToggle} />
          ) : (
            <>{fundslayout()}</>
          )}
          {ToggleAddFunds && <AddnewFund />}

          <div className="handingdrawer">{drawerFunction()}</div>
        </div>
      </div>
      {/*  */}
    </Layout>
  );
}
