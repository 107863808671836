import React, { createContext, useState, useEffect, useContext } from 'react';
import vaultIcon from '../assets/imported/images/logos/tokenHashIcon.svg';
import axios from 'axios';

export const GlobalContext = createContext();

function GlobalContextProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  // todo sidebar
  const [opencard, setopencard] = useState('translateX(-140%)');
  const [therightcard, settherightcard] = useState('translateX(-140%)');
  const [closecard, setclosecard] = useState('');
  const [pagemask, setpagemask] = useState('');
  const [closedisp, setclosedisp] = useState('none');
  const [closerightdisp, setcloserightdisp] = useState('none');
  const [icondisp, seticondisp] = useState('none');

  // ? circle data
  const [selectedbondCurrencies, setSelectedBondCurrencies] =
    useState('index0');
  const [cryptoCircleLoading, setCryptoCircleLoading] = useState(true);
  const [cryptoCircle, setCryptoCircle] = useState();
  const [forexCircle, setForexCircle] = useState();
  const [moneyMarkets4Containers, setmoneyMarkets4Containers] = useState();
  const [bondCurrencies, setbondCurrencies] = useState([]);
  const [bondQueries, setbondQueries] = useState();
  const [bondQueriesLoading, setbondQueriesLoading] = useState();
  const [shareTokensSide, setShareTokensSide] = useState();
  const [fundSide, setFundSide] = useState();

  const [productDropdown, setProductDropdown] = useState(false);

  const [currentPath, setCurrentPath] = useState('/');
  const [selectedBondsNav, setSelectedBondsNav] = useState('Stats');

  //imported things for bond
  const [userType, setUserType] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [email, setEmail] = useState(
    localStorage.getItem('nvestBankLoginAccount') || ''
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('nvestBankAccessToken') || ''
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem('nvestBankIdToken') || ''
  );
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');

  //imported
  const login = (paramEmail, paramAccessToken, paramIdToken) => {
    setEmail(paramEmail);
    setAdminEmail(paramEmail);
    setAccessToken(paramAccessToken);
    setIdToken(paramIdToken);
  };

  //imported toast context
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');
  const [icon, setIcon] = useState(vaultIcon);
  function toastShowOn(message, submessage, icon) {
    setShow(true);
    setMessage(message);
    setSubMessage(submessage);
    setIcon(icon || vaultIcon);
    setTimeout(() => {
      setShow(false);
      setMessage('');
      setSubMessage('');
      setIcon('');
    }, 2000);
  }

  // global publication id
  const [allStoryTemplate, setAllStoryTemplate] = useState([]);

  const [publicationIDGlobal, setPublicationIDGlobal] = useState(
    // publicationIDParams
    //   ? publicationIDParams
    // :
    localStorage.getItem('publicationIDGlobal')
      ? localStorage.getItem('publicationIDGlobal')
      : ''
  );
  const [publicationObjectGlobal, setPublicationObjectGlobal] = useState(() => {
    // Check if the object exists in localStorage
    const storedObject = localStorage.getItem('publicationObject');
    // If it exists, parse and return the object
    if (storedObject) {
      return JSON.parse(storedObject);
    }
    // If it doesn't exist, return the initial value
    return '';
  });

  //publication data
  const [allPublications, setAllPublications] = useState('');
  const [allPublicationsLoading, setAllPublicationsLoading] = useState(false);
  const [globalNavImg, setGlobalNavImg] = useState('');
  const [globalMiniNavImg, setGlobalMiniNavImg] = useState('');

  //don't touch
  useEffect(() => {
    setAllPublicationsLoading(true);
    axios
      .get('https://publications.apimachine.com/publication')
      .then((response) => {
        if (response?.data?.status && response?.data?.data?.length > 0) {
          let filteredParamsID = window?.location?.pathname
            ?.split('/')
            ?.filter((str) => str !== '');
          if (filteredParamsID?.length > 0) {
            filteredParamsID = filteredParamsID[filteredParamsID?.length - 1];
          }
          let filterData = response?.data?.data?.filter((eachpublication) => {
            return eachpublication?._id == filteredParamsID;
          });
          // let filteredParamsIDres = filteredParamsID;
          if (filterData) {
            // let filterData = response?.data?.data?.filter((eachpublication) => {
            //   return eachpublication?._id == filteredParamsID;
            // });
            if (filterData?.length > 0) {
              filterData = filterData[0];
              const objectString = JSON.stringify(filterData);
              localStorage.setItem('publicationObject', objectString);
              setPublicationObjectGlobal(filterData);
              setPublicationIDGlobal(filterData?._id);
              setAllStoryTemplate([]);
              localStorage.setItem('publicationIDGlobal', filterData?._id);
              setGlobalColors(
                filterData?.textColor,
                filterData?.primaryColor,
                filterData?.font,
                filterData?.fullColoredLogo,
                filterData?.profile_pic
              );
            }
            // console.log('publicationIDParams publications', filterData);
          }
          // console.log(response?.data?.data, "publication data");
          setAllPublications(response?.data?.data);
        }
        setAllPublicationsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'all publications error');
        setAllPublicationsLoading(false);
      });
  }, []);

  function setGlobalColors(
    textColor,
    primaryColor,
    font,
    profile_pic,
    miniLogo
  ) {
    textColor = textColor?.replace('#', '');
    primaryColor = primaryColor?.replace('#', '');

    // console.log("global color set", textColor, primaryColor, font, profile_pic);
    if (profile_pic) {
      setGlobalNavImg(profile_pic);
    }
    if (miniLogo) {
      setGlobalMiniNavImg(miniLogo);
    }
    document.documentElement.style.setProperty('--font-color', '#' + textColor);
    document.documentElement.style.setProperty(
      '--background-color',
      '#' + primaryColor
    );
    document.documentElement.style.setProperty(
      '--font-color-selected',
      convertHexToRGBA(primaryColor, 0.8)
    );
    document.documentElement.style.setProperty(
      '--font-color-opacity',
      convertHexToRGBA(primaryColor, 0.1)
    );
    document.documentElement.style.setProperty('--font-fam', '#' + font);

    localStorage.setItem('textColorPublication', textColor);
    localStorage.setItem('primaryColorPublication', primaryColor);
    localStorage.setItem('fontPublication', font);
    localStorage.setItem('globalNavImgPublication', profile_pic);
    localStorage.setItem('globalMiniNavImgPublication', miniLogo);
  }

  const convertHexToRGBA = (hex, opacity) => {
    // Remove the "#" symbol from the hex color
    const hexValue = hex.replace('#', '');

    // Convert the hex value to RGB values
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);

    // Construct the RGBA value with the desired opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  // learn
  const [selectedCourseDetails, setSelectedCourseDetails] = useState('');
  const [selectedSectionDetails, setSelectedSectionDetails] = useState('');

  function courseDetailsAPI(id) {
    axios
      .get(`https://publications.apimachine.com/courses/list?course_id=${id}`)
      .then((response) => {
        // console.log(response?.data, 'learn API deatailed course response');
        if (response?.data?.status && response?.data?.data?.length > 0) {
          setSelectedCourseDetails(response?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error?.message);
      });
  }

  return (
    <GlobalContext.Provider
      value={{
        navOpen,
        setNavOpen,

        isVisible,
        setIsVisible,
        // indexfundsref,

        opencard,
        closecard,
        pagemask,
        therightcard,
        closedisp,
        closerightdisp,
        icondisp,
        setopencard,
        setclosecard,
        setpagemask,
        settherightcard,
        setclosedisp,
        setcloserightdisp,
        seticondisp,
        cryptoCircle,
        setCryptoCircle,
        forexCircle,
        setForexCircle,
        moneyMarkets4Containers,
        setmoneyMarkets4Containers,
        bondCurrencies,
        setbondCurrencies,
        cryptoCircleLoading,
        setCryptoCircleLoading,
        bondQueries,
        setbondQueries,
        selectedbondCurrencies,
        setSelectedBondCurrencies,
        bondQueriesLoading,
        setbondQueriesLoading,
        shareTokensSide,
        setShareTokensSide,
        fundSide,
        setFundSide,
        productDropdown,
        setProductDropdown,
        currentPath,
        setCurrentPath,
        selectedBondsNav,
        setSelectedBondsNav,

        //imported for bonds
        userType,
        setUserType,
        adminEmail,
        setAdminEmail,
        email,
        setEmail,
        accessToken,
        setAccessToken,
        idToken,
        setIdToken,
        login,
        username,
        name: name || username,
        toastShowOn,

        //globalPublication
        allPublications,
        setAllPublications,
        allPublicationsLoading,
        setAllPublicationsLoading,
        publicationIDGlobal,
        setPublicationIDGlobal,
        globalNavImg,
        setGlobalNavImg,
        publicationObjectGlobal,
        setPublicationObjectGlobal,
        setGlobalColors,
        allStoryTemplate,
        setAllStoryTemplate,
        globalMiniNavImg,
        setGlobalMiniNavImg,

        //learn
        selectedCourseDetails,
        setSelectedCourseDetails,
        courseDetailsAPI,
        selectedSectionDetails,
        setSelectedSectionDetails,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
