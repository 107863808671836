import React, { useEffect } from 'react';
import './influencemarketmalls.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';

import fulllogo from '../../static/images/malls/fulllogo.svg';
// import search from '../../static/images/malls/search.svg';
import searchIcon from '../../static/images/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import Skeleton from 'react-loading-skeleton';
import { RxHamburgerMenu } from 'react-icons/rx';

const InfluenceMarket = () => {
  const {
    IMtab,
    setIMtab,
    setfiltersearch,
    shopCompanies,
    setShopCompanies,
    shopBilling,
    setShopBilling,
    shopMarket,
    setShopMarket,
  } = useAppContextDetails();
  const {
    aiIndexOfferings,
    setAiIndexOfferings,
    shopCompaniesFilter,
    shopBillingFilter,
    shopMarketFilter,
    setShopMarketFilter,
  } = useContext(planBContext);

  const [selectedFilter, setSelectedFilter] = useState('default');
  const [btnOptionCompany, setBtnOptionCompany] = useState('All');
  const [btnOptionBilling, setBtnOptionBilling] = useState('All');
  const [btnOptionMarket, setBtnOptionMarket] = useState('All');

  return (
    <Layout active="InfluenceMarket" className="influencemarket" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="influencemarket-navbar">
          <div
            className="influencemarket-hamburger"
            onClick={() => setAiIndexOfferings(!aiIndexOfferings)}
          >
            {aiIndexOfferings ? <div>x</div> : <RxHamburgerMenu />}
          </div>
          <div className="influencemarket-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-influencemarket">
            <div className="im">
              <input
                type="text"
                placeholder="Ex. Product Name..."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-space"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="optionss" style={{ width: '15%' }}>
            <div
              className={`each-option ${
                IMtab === 'shop' ? 'each-option-selected' : ''
              }`}
              onClick={() => setIMtab('shop')}
            >
              <div>
                <p>Shop</p>
              </div>
            </div>
            {/* <div
              className={`each-option ${
                IMtab === 'Receipts' ? 'each-option-selected' : ''
              }`}
              onClick={() => setIMtab('Receipts')}
            >
              <div>
                <p>Receipts</p>
              </div>
            </div> */}
          </div>
        </div>
        {IMtab == 'shop' ? (
          <div
            style={{
              flexDirection: 'row-reverse',
            }}
            className="influencemarket-content"
          >
            <div
              className="left-ai-container"
              style={{
                position: 'relative',
                width: '100%',
              }}
            >
              <ShopComponent />
            </div>
            <div
              className="right-ai-container"
              style={{
                display: aiIndexOfferings ? '' : 'none',
              }}
            >
              {selectedFilter === 'default' && (
                <div className="right-ai-items">
                  <div className="filterItemR">
                    <div>
                      <div>Marketplace</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Marketplace');
                        }}
                      >
                        {btnOptionMarket}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Brands</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Brands');
                        }}
                      >
                        {btnOptionCompany}
                      </div>
                    </div>
                  </div>

                  <div className="filterItemR">
                    <div>
                      <div>Billing Type</div>
                      <div
                        className="btn"
                        onClick={() => {
                          setSelectedFilter('Billing Type');
                        }}
                      >
                        {btnOptionBilling}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedFilter === 'Brands' && (
                <div className="right-ai-items" style={{ padding: '0' }}>
                  <div className="filterOption">
                    <div className="headerTxt">Select Brand</div>
                    <div className="scrollableDiv">
                      {shopCompaniesFilter?.length > 0
                        ? shopCompaniesFilter?.map((e, i) => {
                            return (
                              <div
                                className="eachDiv"
                                key={i}
                                onClick={() => {
                                  setShopCompanies(e?.email);
                                  setBtnOptionCompany(e?.name);
                                }}
                              >
                                {e?.profile_img ? (
                                  <div>
                                    <img
                                      src={e?.profile_img}
                                      alt=""
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '8px',
                                        borderRadius: '50%',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}

                                <div>{e?.name}</div>
                              </div>
                            );
                          })
                        : ''}
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        onClick={() => {
                          setShopCompanies('');
                          setBtnOptionCompany('All');
                        }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#7899CF' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedFilter === 'Billing Type' && (
                <div className="right-ai-items" style={{ padding: '0' }}>
                  <div className="filterOption">
                    <div className="headerTxt">Select Billing Type</div>
                    <div className="scrollableDiv">
                      {shopBillingFilter?.length > 0
                        ? shopBillingFilter?.map((e, i) => {
                            return (
                              <div
                                className="eachDiv"
                                key={i}
                                onClick={() => {
                                  setShopBilling(e?.billingMethod);
                                  setBtnOptionBilling(e?.billingMethod);
                                }}
                              >
                                <div>
                                  {e?.billingMethod === 'monthly'
                                    ? 'Monthly'
                                    : e?.billingMethod === 'lifetime'
                                    ? 'One Time'
                                    : e?.billingMethod}
                                </div>
                              </div>
                            );
                          })
                        : ''}
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        onClick={() => {
                          setShopBilling('');
                          setBtnOptionBilling('All');
                        }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#7899CF' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedFilter === 'Marketplace' && (
                <div className="right-ai-items" style={{ padding: '0' }}>
                  <div className="filterOption">
                    <div className="headerTxt">Select Marketplace</div>
                    <div className="scrollableDiv">
                      {shopMarketFilter?.length > 0
                        ? shopMarketFilter?.map((e, i) => {
                            return (
                              <div
                                className="eachDiv"
                                key={i}
                                onClick={() => {
                                  setShopMarket(e?.app_code);
                                  setBtnOptionMarket(e?.app_name);
                                }}
                              >
                                {e?.app_icon ? (
                                  <div>
                                    <img
                                      src={e?.app_icon}
                                      alt=""
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '8px',
                                        borderRadius: '50%',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div>{e?.app_name}</div>
                              </div>
                            );
                          })
                        : ''}
                    </div>
                    <div className="BtnsDiv">
                      <div
                        style={{ background: '#18191d' }}
                        onClick={() => {
                          setShopMarket('');
                          setBtnOptionMarket('All');
                        }}
                      >
                        Clear All Filters
                      </div>
                      <div
                        style={{ background: '#7899CF' }}
                        onClick={() => {
                          setSelectedFilter('default');
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div style={{ position: 'relative', width: '100%', height: '88%' }}>
            <div className="malls">
              <div className="malls-content">
                <div
                  style={{ width: '100%', height: '100%', overflowY: 'scroll' }}
                >
                  <Subscription />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default InfluenceMarket;

const AIOpportunityCard = ({
  item_name,
  company_name,
  product_icon,
  product_description,
  cost_per_month,
  link,
  last_month_ROI,
  last_12_months_ROI,
  months_running,
  avg_drawdown,
  subscribers,
  company_website,
  _id,
}) => {
  const { IMtab, aiIndexOfferingSelected, setAiIndexOfferingSelected } =
    useAppContextDetails();
  return (
    <div
      className="ai-card"
      onClick={() => {
        setAiIndexOfferingSelected(_id);
      }}
      style={{
        boxShadow:
          aiIndexOfferingSelected == _id
            ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
            : '',
      }}
    >
      <div className="top-data">
        <div className="imgg-div">
          <img
            src={product_icon?.includes('http') ? product_icon : sample}
            alt=""
          />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">{item_name}</div>
              <div>
                By{' '}
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (!company_website?.includes('https://' || 'www.')) {
                      window.open('https://' + company_website, '_blank');
                    } else {
                      window.open(company_website, '_blank');
                    }
                  }}
                >
                  {company_name}
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  {cost_per_month
                    ? '$' + Number(cost_per_month).toFixed(2)
                    : '$0'}
                </div>
                <div style={{ fontSize: '0.8rem' }}>USD Per Month</div>
              </div>
              <div
                className="gs-btn"
                onClick={() => {
                  if (IMtab !== 'businesses') {
                    if (!link?.includes('https://' || 'www.')) {
                      window.open('https://' + link, '_blank');
                    } else {
                      window.open(link, '_blank');
                    }
                  }
                }}
              >
                {IMtab == 'businesses' ? 'Edit' : 'Get Started'}
              </div>
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">{product_description}</p>
        </div>
      </div>
      <div className="bottom-data">
        <div>
          <div>Last Month</div>
          <div className="txtt2">
            {last_month_ROI ? Number(last_month_ROI).toFixed(2) + '%' : '0%'}
          </div>
        </div>
        <div>
          <div>Last 12 Months</div>
          <div className="txtt2">
            {last_12_months_ROI
              ? Number(last_12_months_ROI).toFixed(2) + '%'
              : '0%'}
          </div>
        </div>
        <div>
          <div>Months Running</div>
          <div className="txtt3">{months_running} Months</div>
        </div>
        <div>
          <div>Avg Drawdown</div>
          <div className="txtt3">
            {avg_drawdown ? Number(avg_drawdown).toFixed(2) + '%' : ''}
          </div>
        </div>
        <div>
          <div>Subscribers</div>
          <div className="txtt3">{subscribers}</div>
        </div>
      </div>
    </div>
  );
};

const AIOpportunityCardLoading = () => {
  return (
    <div className="ai-card">
      <div className="top-data">
        <div className="imgg-div">
          <Skeleton circle />
        </div>
        <div className="top-rightt-divv">
          <div className="top-txt">
            <div>
              <div className="txtt1">
                <Skeleton width={120} />
              </div>
            </div>
            <div>
              <div className="txtt1">
                <Skeleton />
              </div>
              <div>
                <span
                  style={{
                    fontWeight: '600',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  <Skeleton />
                </span>
              </div>
            </div>
            <div className="top-right-txt">
              <div>
                <div className="txtt1">
                  <Skeleton />
                </div>
                <div style={{ fontSize: '0.8rem' }}>
                  <Skeleton />
                </div>
              </div>
              {/* <div className="gs-btn">
                <Skeleton />
              </div> */}
              <div className="center-line"></div>
            </div>
          </div>

          <p className="bottom-txt">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </p>
        </div>
      </div>
      <div className="bottom-data">
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt2">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
        <div>
          <div>
            <Skeleton width={80} />
          </div>
          <div className="txtt3">
            <Skeleton width={80} />
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterItem = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);
  return (
    <div className="filterItem">
      <div
        onClick={() => {
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#18191D ',
          }}
        >
          {funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return <div onClick={() => func(eachOption)}>{eachOption}</div>;
            })
          : ''}
        {funcValue && <div onClick={() => func('')}>All</div>}
      </div>
    </div>
  );
};

const FilterItem1 = ({
  title,
  value,
  selected,
  typeFilter,
  funcValue,
  func,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [btnOption, setBtnOption] = useState('');

  useEffect(() => {
    setOpenOptions(false);
  }, [funcValue]);

  return (
    <div className="filterItem">
      <div
        onClick={() => {
          // console.log(typeFilter, 'typeFilter');
          if (typeFilter) {
            setOpenOptions(!openOptions);
          }
        }}
      >
        <div>{title}</div>
        <div
          className="btn"
          style={{
            background: selected ? '' : 'white',
            fontWeight: selected ? '' : '500',
            color: '#18191D ',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {btnOption ? btnOption : funcValue ? funcValue : value}
        </div>
      </div>
      <div
        className="filterItemOptions"
        style={{ display: typeFilter && openOptions ? '' : 'none' }}
      >
        {typeFilter?.length > 0
          ? typeFilter?.map((eachOption) => {
              return title === 'Brands' ? (
                <div
                  onClick={() => {
                    setBtnOption(eachOption?.name);
                    func(eachOption?.email);
                  }}
                >
                  {eachOption?.profile_img && (
                    <img
                      src={eachOption?.profile_img}
                      alt=""
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <span>{eachOption?.name}</span>
                </div>
              ) : title === 'Billing Type' ? (
                <div
                  onClick={() => {
                    func(eachOption?.billingMethod);
                  }}
                >
                  {eachOption?.billingMethod ? eachOption?.billingMethod : ''}
                </div>
              ) : (
                <div
                  onClick={() => {
                    setBtnOption(eachOption?.app_name);
                    func(eachOption?.app_code);
                  }}
                >
                  {eachOption?.app_icon && (
                    <img
                      src={eachOption?.app_icon}
                      alt=""
                      style={{
                        width: '10px',
                        height: '10px',
                        marginRight: '8px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  <span>{eachOption?.app_name}</span>
                </div>
              );
            })
          : ''}
        {funcValue && (
          <div
            onClick={() => {
              setBtnOption('');
              func('');
            }}
          >
            All
          </div>
        )}
      </div>
    </div>
  );
};
