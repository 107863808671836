import React, { useEffect } from 'react';
import './settings.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import edit from '../../components/BuyBlocks/components/edit.svg';

import fulllogo from '../../static/images/settingsFullLogo.svg';
import search from '../../static/images/malls/search.svg';
import ddown from '../../static/images/malls/ddown.svg';
import sample from '../../static/images/aiindex/sample.svg';
import CreatePlanBContainer from '../../components/BuyBlocks';
import { planBContext } from '../../context/PlanBContext';
import Subscription from '../Shop/Subscription';
import ShopComponent from '../Shop';
import {
  DateSelectionDiv,
  InputDivs,
  InputDivs1,
  InputDivs2,
  InputDivsCheck,
  InputDivsMap,
  InputDivsTextArea,
  InputDivsTextArea1,
  InputDivsWithMT,
  InputDivsWithColorCode,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { uploadImageFunc } from '../../utils/imageUpload';
import uploading from '../../static/images/uploading.svg';
import dummyProfile from '../../static/images/dummyProfile.svg';
import Skeleton from 'react-loading-skeleton';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import QRCode from 'qrcode.react';
import { InputDropdown } from '../../components/BuyBlocks/components/CreatePlanB';
import close from '../CRMPage/close.svg';
import loadingLogo from '../../static/images/logos/vv.svg';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import upload from './upload.svg';
import uploadGrey from './uploadGrey.svg';
import close1 from './close.svg';
import cover from './cover.svg';

const SettingsPage = () => {
  const {
    appLogo,
    settingsTab,
    setSettingsTab,
    allOpportunities,
    filteredallOpportunities,
    settFilteredallOpportunities,
    setfiltersearch,
    settingsProfile,
    setSettingsProfile,
    authenticated2FA,
    setAuthenticated2FA,
    changePassword,
    setChangePassword,
    settingsProfileData,
    //settings
    settingsEmail,
    setSettingsEmail,
    settingsUsername,
    setSettingsUsername,
    allCountries,
    setAllCountries,
    //settings profile data
    settingsCountry,
    setSettingsCountry,
    settingsCountryDropdown,
    setSettingsCountryDropdown,

    //edit brand profile
    editAddress,
    setEditAddress,
    editDisplayName,
    setEditDisplayName,
    editPhoneNo,
    setEditPhoneNo,
    editDescription,
    setEditDescription,
    editColorCode,
    setEditColorCode,
    editCountry,
    setEditCountry,
    editPartneringInstitutions,
    setEditPartneringInstitutions,
    editAutoDeposit,
    setEditAutoDeposit,
    editCashBack,
    setEditCashBack,
    editCategory,
    setEditCategory,
    editSubCategory,
    setEditSubCategory,
    editSpecialities,
    setEditSpecialities,
    editCoverPic,
    setEditCoverPic,
    editProfilePic,
    setEditProfilePic,

    //brand profile new values
    newAddress,
    setNewAddress,
    newDisplayName,
    setNewDisplayName,
    newPhoneNo,
    setNewPhoneNo,
    newDescription,
    setNewDescription,
    newColorCode,
    setNewColorCode,
    newCountry,
    setNewCountry,
    newPartneringInstitutions,
    setNewPartneringInstitutions,
    newAutoDeposit,
    setNewAutoDeposit,
    newCashBack,
    setNewCashBack,
    newCategory,
    setNewCategory,
    newSubCategory,
    setNewSubCategory,
    newSpecialities,
    setNewSpecialities,
    newCoverPic,
    setNewCoverPic,
    newProfilePic,
    setNewProfilePic,
  } = useAppContextDetails();

  const {
    insideplanBCreate,
    setinsideplanBCreate,
    aiIndexDefaultSelected,
    setaiIndexDefaultSelected,
  } = useContext(planBContext);

  const { email, accessToken, token } = useContext(BankContext);

  const [createBrandProfile, setCreateBrandProfile] = useState(false);
  const [createBrandProfileStep, setCreateBrandProfileStep] = useState(1);
  const [brandProfileStatus, setBrandProfileStatus] = useState(false);
  const [brandProfileData, setBrandProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userName, setUserName] = useState();
  const [coverPhoto1, setCoverPhoto1] = useState();
  const [brandDisplayName, setBrandDisplayName] = useState();
  const [brandUserName, setBrandUserName] = useState();
  const [brandDescription, setBrandDescription] = useState();
  const [brandColorCode, setBrandColorCode] = useState();
  const [headquarter, setHeadquarter] = useState();
  const [brandAddress, setBrandAddress] = useState();
  const [whiteProPic, setWhiteProPic] = useState();
  const [isloading, setIsloading] = useState(false);

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    getBrandProfileData();
    if (editAddress) {
      setEditAddress(false);
      setNewAddress('');
    } else if (editDisplayName) {
      setEditDisplayName(false);
      setNewDisplayName('');
    } else if (editDescription) {
      setEditDescription(false);
      setNewDescription('');
    } else if (editPhoneNo) {
      setEditPhoneNo(false);
      setNewPhoneNo('');
    } else if (editColorCode) {
      setEditColorCode(false);
      setNewColorCode('');
    } else if (editCountry) {
      setEditCountry(false);
      setNewCountry('');
    } else if (editPartneringInstitutions) {
      setEditPartneringInstitutions(false);
      setNewPartneringInstitutions('');
    } else if (editCategory) {
      setEditCategory(false);
      setNewCategory('');
    } else if (editSubCategory) {
      setEditSubCategory(false);
      setNewSubCategory('');
    } else if (editSpecialities) {
      setEditSpecialities(false);
      setNewSpecialities('');
    } else if (editAutoDeposit) {
      setEditAutoDeposit(false);
      setNewAutoDeposit('');
    } else if (editCashBack) {
      setEditCashBack(false);
      setNewCashBack('');
    } else if (editCoverPic) {
      setEditCoverPic(false);
      setNewCoverPic('');
    } else if (editProfilePic) {
      setEditProfilePic(false);
      setNewProfilePic('');
    }
  }

  const myTimeout1 = () => {
    setTimeout(reload1, 3000);
  };

  function reload1() {
    setCreateBrandProfile(false);
    setCreateBrandProfileStep(1);
    setProfilePicture('');
    setFirstName('');
    setLastName('');
    setUserName('');
    setCoverPhoto1('');
    setBrandDisplayName('');
    setBrandUserName('');
    setBrandDescription('');
    setBrandColorCode('');
    setHeadquarter('');
    setBrandAddress('');
    setWhiteProPic('');
    getBrandProfileData();
  }
  const [profileData, setProfileData] = useState(null);
  const getBrandProfileData = () => {
    axios
      .get(
        `https://comms.globalxchange.io/client/tree/user/get/all?email=${email}`
        // `https://comms.globalxchange.io/client/tree/get/user/tree?email=${email}`
      )
      .then(({ data }) => {
        if (data.status) {
          setProfileData(data?.data?.native_manifest_users[0]);
        }
      })
      .catch((error) => {
        console.log(error, 'brand status check error');
      });
  };

  useEffect(() => {
    getBrandProfileData();
  }, []);

  const updateProfilePic = (imgUrl) => {
    axios
      .put(`https://comms.globalxchange.io/client/tree/update/user/profile`, {
        email: email,
        profile_img: imgUrl,
      })
      .then(({ data }) => {
        if (data.status) {
          getBrandProfileData();
        }
      });
  };

  useEffect(() => {
    if (settingsProfile) {
      // console.log(settingsProfile, 'settingsProfile');
      updateProfilePic(settingsProfile);
    }
  }, [settingsProfile]);

  const editData = async (field, value) => {
    setLoading(true);

    let body = {
      [field]: value,
    };

    try {
      let result = await axios.put(
        'https://teller2.apimachine.com/lxUser/update/banker',
        body,
        {
          headers: { token, email },
        }
      );
      // console.log(result, 'editData result');
      if (result?.data?.status) {
        myTimeout();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error, 'error in editData');
    }
  };

  const createLXProfile = () => {
    axios
      .post(
        'https://teller2.apimachine.com/lxUser/register',
        {
          profilePicURL: profilePicture,
          firstName: firstName,
          lastName: lastName,
          lxTag: userName,
        },
        { headers: { email, token } }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'createLXProfile result');
        if (result?.message === 'Email is Already Registered as LX User') {
          setCreateBrandProfileStep(2);
        }
      })
      .catch((error) => {
        console.log(error, 'error in createLXProfile');
      });
  };

  const createBankerProfile = () => {
    setIsloading(true);
    axios
      .post(
        'https://teller2.apimachine.com/lxUser/register/banker',
        {
          bankerTag: brandUserName,
          colorCode: brandColorCode,
          address: brandAddress,
          coverPicURL: coverPhoto1,
          displayName: brandDisplayName,
          description: brandDescription,
          partneringInstitutions: [],
          country: headquarter,
          profilePicURL: profilePicture,
          profilePicPNG: profilePicture,
          profilePicWhite: whiteProPic,
          profilePicWhitePNG: whiteProPic,
          autoDeposit: false,
          sefcoin_cashback: false,
          other_data: {},
        },
        { headers: { email, token } }
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, 'createBankerProfile result');
        setIsloading(false);
        setCreateBrandProfileStep(3);
        myTimeout1();
      })
      .catch((error) => {
        console.log(error, 'error in createBankerProfile');
      });
  };

  return (
    <Layout active="settings" className="settings" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="settings-navbar">
          <div className="settings-icon">
            <img src={fulllogo} alt="" />
          </div>
          <div className="search-settings">
            <div className="im">
              <input
                type="text"
                placeholder="Search.."
                onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          {/* <div
            className="settings-listBtn"
            onClick={() => {
              setinsideplanBCreate(!insideplanBCreate);
              // setSelectedDropDown(!selectedDropDown);
            }}
          >
            List Your Product
          </div> */}
        </div>

        <div className="w-space">
          <div className="optionss" style={{ width: '40%' }}>
            <div
              className={`each-option ${
                settingsTab === 'Affiliate Profile'
                  ? 'each-option-selected'
                  : ''
              }`}
              style={{
                fontWeight: settingsTab === 'Affiliate Profile' ? '600' : '',
              }}
              onClick={() => setSettingsTab('Affiliate Profile')}
            >
              <div>
                <p>Affiliate Profile</p>
              </div>
            </div>

            {/* <div
              className={`each-option ${
                settingsTab === 'Brand Profile' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: settingsTab === 'Brand Profile' ? '600' : '',
              }}
              onClick={() => setSettingsTab('Brand Profile')}
            >
              <div>
                <p>Brand Profile</p>
              </div>
            </div>

            <div
              className={`each-option ${
                settingsTab === 'Identification' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: settingsTab === 'Identification' ? '600' : '',
              }}
              onClick={() => setSettingsTab('Identification')}
            >
              <div>
                <p>Identification</p>
              </div>
            </div> */}
          </div>
        </div>

        {changePassword ? <SettingsSidebar /> : ''}

        {settingsTab == 'Affiliate Profile' ? (
          <div className="settings-content">
            <div className="left-ai-container">
              <div className="left-ai-div">
                <div className="heading">Basic Details</div>
                <ImageUploadDiv
                  setFunc={setSettingsProfile}
                  funcValue={profileData?.profile_img}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="Email"
                    placeholderText="Email"
                    // setFunc={setSettingsEmail}
                    funcValue={profileData?.email}
                  />
                  <InputDivs
                    heading="Username"
                    placeholderText="Username"
                    // setFunc={setSettingsUsername}
                    funcValue={profileData?.username}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="First Name"
                    placeholderText="Name"
                    // setFunc={setaidropdownRegLink}
                    funcValue={profileData?.name}
                  />
                  <InputDivs
                    heading="Phone number"
                    placeholderText="Phone"
                    // setFunc={setaidropdownRegLink}
                    funcValue={profileData?.mobile}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Member since"
                    placeholderText="Date"
                    // setFunc={setaidropdownRegLink}
                    funcValue={profileData?.date}
                  />
                  <InputDivs
                    heading="Status"
                    placeholderText="Status"
                    // setFunc={setaidropdownRegLink}
                    funcValue={profileData?.legAssigned}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Affiliate id"
                    placeholderText="id"
                    // setFunc={setaidropdownRegLink}
                    funcValue={profileData?.affiliate_id}
                  />
                  {/* <InputDivs
                    heading="Status"
                    placeholderText="Status"
                    // setFunc={setaidropdownRegLink}
                    funcValue={profileData?.legAssigned}
                  /> */}
                </div>

                {/* <div className="dateDiv">
                  <DateSelectionDiv heading="Birthday" />
                </div> */}

                {/* <div className="heading">Address Details</div>
                <div className="split-divs">
                  <InputDivs
                    heading="Country"
                    placeholderText="Country"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="State"
                    placeholderText="State"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <InputDivs
                  heading="Address "
                  placeholderText="Address "
                  // setFunc={setaidropdownRegLink}
                  // funcValue={aidropdownRegLink}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="City"
                    placeholderText="City"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Postal code"
                    placeholderText="Postal code"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div> */}
              </div>
            </div>
            <div className="right-ai-container">
              {/* <CreatePlanBContainer /> */}
              <div className="change-pass">
                <div className="title">Change Password</div>
                <div className="para">
                  Click here to change your password. You will need to verify
                  your email again to reset your password.
                </div>
                <div
                  className="btn"
                  onClick={() => setChangePassword('changepass1')}
                >
                  Change Password
                </div>
              </div>
              {/* <div className="change-pass">
                <div className="title">
                  {authenticated2FA?.mfa_enabled ? 'Disable 2FA' : 'Enable 2FA'}
                </div>
                <div className="para">
                  {authenticated2FA?.mfa_enabled
                    ? 'You have already secured your account. Click here if you want to disable the 2FA from Google Authenticator.'
                    : 'For an additional layer of security you can enable 2 factor authentication via Google Authenticator.'}
                </div>
                <div
                  className="btn"
                  onClick={() => {
                    if (authenticated2FA?.mfa_enabled) {
                      setChangePassword('twofactorDisable1');
                    } else if (authenticated2FA?.mfa_enabled == false) {
                      setChangePassword('twofactorEnable1');
                    } else {
                    }
                  }}
                  style={{
                    display:
                      authenticated2FA?.mfa_enabled == true ||
                      authenticated2FA?.mfa_enabled == false
                        ? ''
                        : 'none',
                  }}
                >
                  {authenticated2FA?.mfa_enabled == true
                    ? 'Disable'
                    : authenticated2FA?.mfa_enabled == false
                    ? 'Enable'
                    : ''}
                </div>
              </div> */}
            </div>
          </div>
        ) : settingsTab == 'Identification' ? (
          <div className="settings-content">
            <div className="left-ai-container">
              <div className="left-ai-div">
                {/* <div className="heading">Basic Details</div>
                <ImageUploadDiv
                  setFunc={setSettingsProfile}
                  funcValue={settingsProfile}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="Email"
                    placeholderText="Email"
                    setFunc={setSettingsEmail}
                    funcValue={settingsEmail}
                  />
                  <InputDivs
                    heading="Username"
                    placeholderText="Username"
                    setFunc={setSettingsUsername}
                    funcValue={settingsUsername}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="First Name"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="First Name"
                    placeholderText="Username"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="split-divs">
                  <InputDivs
                    heading="Upline"
                    placeholderText="Email"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Phone Number"
                    placeholderText="Phone Number"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <div className="dateDiv">
                  <DateSelectionDiv heading="Birthday" />
                </div> */}
                <div className="heading">Address Details</div>
                <div className="split-divs">
                  {/* <InputDivs
                    heading="Country"
                    placeholderText="Country"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  /> */}

                  <InputDropdown
                    heading="Country"
                    mapValue={allCountries}
                    setFunc={setSettingsCountry}
                    funcValue={settingsCountry}
                    dropdown={settingsCountryDropdown}
                    dropdownFunc={setSettingsCountryDropdown}
                  />
                  <InputDivs
                    heading="State"
                    placeholderText="State"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
                <InputDivs
                  heading="Address "
                  placeholderText="Address "
                  // setFunc={setaidropdownRegLink}
                  // funcValue={aidropdownRegLink}
                />
                <div className="split-divs">
                  <InputDivs
                    heading="City"
                    placeholderText="City"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                  <InputDivs
                    heading="Postal code"
                    placeholderText="Postal code"
                    // setFunc={setaidropdownRegLink}
                    // funcValue={aidropdownRegLink}
                  />
                </div>
              </div>
            </div>
            <div className="right-ai-container">
              {/* <CreatePlanBContainer /> */}
              <div className="change-pass">
                <div className="title">Perform KYC</div>
                <div className="para">
                  If you plan on funding your forex Vaults in AiProWallet you
                  have to complete the KYC for our exchange partners.
                </div>
                <div
                  className="btn"
                  onClick={() =>
                    window.open(
                      'https://in.sumsub.com/idensic/l/#/sbx_uni_2hSenyE0Msz9cyjI',
                      '_blank'
                    )
                  }
                >
                  Sending CAD
                </div>
                <div
                  className="btn"
                  style={{ pointerEvents: 'none', opacity: '0.5' }}
                >
                  Sending EUR, GBP & USD
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="settings-content"
            style={{
              padding: '1.5rem 3rem',
              background: 'rgba(237, 237, 237, 0.2)',
            }}
          >
            {!brandProfileStatus ? (
              <div
                className="createBtnn"
                onClick={() => {
                  setCreateBrandProfile(true);
                }}
              >
                Create A Brand Profile
              </div>
            ) : (
              <div
                className="left-ai-container"
                style={{
                  border: '0.5px solid #ebebeb',
                  padding: '1rem',
                  borderRadius: '15px',
                  background: '#ffffff',
                }}
              >
                <div className="left-ai-div" style={{ border: 'none' }}>
                  <div className="heading">Brand Details</div>
                  <div
                    style={{
                      position: 'relative',
                      width: '100px',
                      height: '100px',
                    }}
                  >
                    <div
                      className="editIconn"
                      onClick={() => {
                        setEditProfilePic(true);
                      }}
                    >
                      <img
                        src={edit}
                        alt=""
                        style={{ width: '12px', height: '12px' }}
                      />
                    </div>
                    <img
                      src={brandProfileData?.profilePicURL}
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        border: '0.5px solid #e5e5e5',
                      }}
                    />
                  </div>
                  <div className="split-divs">
                    <InputDivs1
                      heading="Email"
                      placeholderText="Email"
                      funcValue={brandProfileData?.email}
                    />
                    <InputDivs1
                      heading="Brand Tag"
                      placeholderText="Brand Tag"
                      funcValue={brandProfileData?.bankerTag}
                    />
                  </div>
                  <div className="split-divs">
                    <InputDivs1
                      heading="Country"
                      placeholderText="Country"
                      funcValue={brandProfileData?.country}
                    />
                    <InputDivs1
                      heading="Address"
                      placeholderText="Address"
                      funcValue={brandProfileData?.address}
                    />
                  </div>
                  <div className="split-divs">
                    <InputDivs1
                      heading="Display Name"
                      placeholderText="Display Name"
                      funcValue={brandProfileData?.displayName}
                    />
                    <InputDivs1
                      heading="Phone Number"
                      placeholderText="Phone Number"
                      funcValue={brandProfileData?.phone}
                    />
                  </div>
                  <InputDivsTextArea
                    heading="Description"
                    placeholderText="Text"
                    funcValue={brandProfileData?.description}
                  />

                  <InputDivs2
                    heading="Cover Photo"
                    funcValue={brandProfileData?.coverPicURL}
                  />
                  <div className="split-divs">
                    <InputDivs1
                      heading="Colour Code"
                      placeholderText="Colour Code"
                      funcValue={brandProfileData?.colorCode}
                      colorCode={brandProfileData?.colorCode}
                    />
                    <InputDivs1
                      heading="Partnering Institutions"
                      placeholderText="Partnering Institutions"
                      funcValue={
                        brandProfileData?.partneringInstitutions?.[0]
                          ?.institute_name
                      }
                    />
                  </div>
                  <div className="split-divs">
                    <InputDivs1
                      heading="Auto Deposit"
                      placeholderText="Auto Deposit"
                      funcValue={brandProfileData?.autoDeposit}
                    />
                    <InputDivs1
                      heading="Cashback"
                      placeholderText="Cashback"
                      funcValue={
                        brandProfileData?.sefcoin_cashback === null
                          ? 'null'
                          : brandProfileData?.sefcoin_cashback
                      }
                    />
                  </div>
                  <div className="split-divs">
                    <InputDivs1
                      heading="Category"
                      placeholderText="Category"
                      funcValue={brandProfileData?.category}
                    />
                    <InputDivs1
                      heading="Sub Category"
                      placeholderText="Sub Category"
                      funcValue={brandProfileData?.subCategory}
                    />
                  </div>
                  <InputDivsMap
                    heading="Specialties"
                    placeholderText="Specialties"
                    funcValue={brandProfileData?.specialities}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        {editAddress && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Address</div>
              <div
                onClick={() => {
                  setEditAddress(false);
                  setNewAddress('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.address}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Address.."
                  onChange={(e) => {
                    setNewAddress(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newAddress ? '1' : '0.25',
                  cursor: newAddress ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newAddress) {
                    editData('address', newAddress);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editDisplayName && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Display Name</div>
              <div
                onClick={() => {
                  setEditDisplayName(false);
                  setNewDisplayName('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.displayName}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Display Name.."
                  onChange={(e) => {
                    setNewDisplayName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newDisplayName ? '1' : '0.25',
                  cursor: newDisplayName ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newDisplayName) {
                    editData('displayName', newDisplayName);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editDescription && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Description</div>
              <div
                onClick={() => {
                  setEditDescription(false);
                  setNewDescription('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.description}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Description.."
                  onChange={(e) => {
                    setNewDescription(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newDescription ? '1' : '0.25',
                  cursor: newDescription ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newDescription) {
                    editData('description', newDescription);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editPhoneNo && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Phone Number</div>
              <div
                onClick={() => {
                  setEditPhoneNo(false);
                  setNewPhoneNo('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.phone}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="number"
                  placeholder="New Phone Number.."
                  onChange={(e) => {
                    setNewPhoneNo(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newPhoneNo ? '1' : '0.25',
                  cursor: newPhoneNo ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newPhoneNo) {
                    editData('phone', newPhoneNo);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editColorCode && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Colour Code</div>
              <div
                onClick={() => {
                  setEditColorCode(false);
                  setNewColorCode('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1" style={{ position: 'relative' }}>
                <div>{brandProfileData?.colorCode}</div>
                <div
                  className="bgColorDiv"
                  style={{
                    background: `#${brandProfileData?.colorCode}`,
                  }}
                ></div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1" style={{ position: 'relative' }}>
                <input
                  type="text"
                  placeholder="New Colour Code.."
                  onChange={(e) => {
                    setNewColorCode(e.target.value);
                  }}
                />
                <div
                  className="bgColorDiv"
                  style={{
                    background: newColorCode
                      ? `#${newColorCode}`
                      : 'transparent',
                  }}
                ></div>
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newColorCode ? '1' : '0.25',
                  cursor: newColorCode ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newColorCode) {
                    editData('colorCode', newColorCode);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editCountry && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Country</div>
              <div
                onClick={() => {
                  setEditCountry(false);
                  setNewCountry('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.country}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Country.."
                  onChange={(e) => {
                    setNewCountry(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newCountry ? '1' : '0.25',
                  cursor: newCountry ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newCountry) {
                    editData('country', newCountry);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editPartneringInstitutions && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Partnering Institutions</div>
              <div
                onClick={() => {
                  setEditPartneringInstitutions(false);
                  setNewPartneringInstitutions('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>
                  {
                    brandProfileData?.partneringInstitutions?.[0]
                      ?.institute_name
                  }
                </div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Partnering Institutions.."
                  onChange={(e) => {
                    setNewPartneringInstitutions(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newPartneringInstitutions ? '1' : '0.25',
                  cursor: newPartneringInstitutions ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newPartneringInstitutions) {
                    editData(
                      'partneringInstitutions',
                      newPartneringInstitutions
                    );
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editCategory && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Category</div>
              <div
                onClick={() => {
                  setEditCategory(false);
                  setNewCategory('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.category}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="Select New Category"
                  onChange={(e) => {
                    setNewCategory(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newCategory ? '1' : '0.25',
                  cursor: newCategory ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newCategory) {
                    editData('category', newCategory);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editSubCategory && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Sub Category</div>
              <div
                onClick={() => {
                  setEditSubCategory(false);
                  setNewSubCategory('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.subCategory}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="Select New Sub Category"
                  onChange={(e) => {
                    setNewSubCategory(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newSubCategory ? '1' : '0.25',
                  cursor: newSubCategory ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newSubCategory) {
                    editData('subCategory', newSubCategory);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editSpecialities && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Specialities</div>
              <div
                onClick={() => {
                  setEditSpecialities(false);
                  setNewSpecialities('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              {brandProfileData?.specialities
                ? brandProfileData?.specialities?.map((e, i) => {
                    return (
                      <div
                        className="each-action1"
                        key={i}
                        style={{ position: 'relative' }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            right: '0',
                            top: '-16%',
                            zIndex: '10',
                          }}
                        >
                          <img src={close1} alt="" />
                        </div>
                        <div>{e}</div>
                      </div>
                    );
                  })
                : ''}

              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="Select New Speciality"
                  onChange={(e) => {
                    setNewSpecialities(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newSpecialities ? '1' : '0.25',
                  cursor: newSpecialities ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newSpecialities) {
                    editData('specialities', newSpecialities);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editAutoDeposit && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Auto Deposit</div>
              <div
                onClick={() => {
                  setEditAutoDeposit(false);
                  setNewAutoDeposit('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>{brandProfileData?.autoDeposit}</div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New Auto Deposit.."
                  onChange={(e) => {
                    setNewAutoDeposit(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newAutoDeposit ? '1' : '0.25',
                  cursor: newAutoDeposit ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newAutoDeposit) {
                    editData('autoDeposit', newAutoDeposit);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editCashBack && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit CashBack</div>
              <div
                onClick={() => {
                  setEditCashBack(false);
                  setNewCashBack('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1">
                <div>
                  {brandProfileData?.sefcoin_cashback === null
                    ? 'null'
                    : brandProfileData?.sefcoin_cashback}
                </div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1">
                <input
                  type="text"
                  placeholder="New CashBack.."
                  onChange={(e) => {
                    setNewCashBack(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newCashBack ? '1' : '0.25',
                  cursor: newCashBack ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newCashBack) {
                    editData('cashback', newCashBack);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editCoverPic && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Cover Photo</div>
              <div
                onClick={() => {
                  setEditCoverPic(false);
                  setNewCoverPic('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div className="each-action1" style={{ height: '12rem' }}>
                <div style={{ height: '100%', width: '100%' }}>
                  <img
                    src={brandProfileData?.coverPicURL}
                    alt=""
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div className="each-action1" style={{ height: '12rem' }}>
                <ImageUploadDivCoverPic
                  setFunc={setNewCoverPic}
                  funcValue={newCoverPic}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newCoverPic ? '1' : '0.25',
                  cursor: newCoverPic ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newCoverPic) {
                    editData('coverPicURL', newCoverPic);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {editProfilePic && (
          <div className="popularS">
            <div className="head-txt">
              <div>Edit Profile Picture</div>
              <div
                onClick={() => {
                  setEditProfilePic(false);
                  setNewProfilePic('');
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overall-div">
              <div
                className="each-action1"
                style={{ border: 'none', justifyContent: 'center' }}
              >
                <div style={{ height: '100px', width: '100px' }}>
                  <img
                    src={brandProfileData?.profilePicURL}
                    alt=""
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              </div>
              <div className="line-container">
                <div className="linee"></div>
                <div className="new-txt">New</div>
                <div className="linee"></div>
              </div>
              <div
                className="each-action1"
                style={{ border: 'none', justifyContent: 'center' }}
              >
                <ImageUploadDivProfilePic
                  setFunc={setNewProfilePic}
                  funcValue={newProfilePic}
                />
              </div>
            </div>

            <div className="stepBtns">
              <div
                style={{
                  opacity: newProfilePic ? '1' : '0.25',
                  cursor: newProfilePic ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (newProfilePic) {
                    editData('profilePicURL', newProfilePic);
                  }
                }}
              >
                Submit Edit
              </div>
            </div>

            {loading && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}
          </div>
        )}

        {createBrandProfile && (
          <div
            className="popularS"
            style={{
              padding:
                createBrandProfileStep === 2
                  ? '1rem 0rem 2rem'
                  : '1rem 3rem 2rem',
            }}
          >
            {createBrandProfileStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Create Brand Profile (Step 1)</div>
                  <div
                    onClick={() => {
                      setCreateBrandProfile(false);
                      setUserName('');
                      setLastName('');
                      setFirstName('');
                      setProfilePicture('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div
                  className="overall-div"
                  style={{ height: 'calc(100% - 6rem)' }}
                >
                  <div style={{ marginBottom: '0.5rem', fontSize: '1.1rem' }}>
                    Upload Profile Picture
                  </div>
                  <ImageUploadDivProfilePic1
                    setFunc={setProfilePicture}
                    funcValue={profilePicture}
                  />
                  <InputDivsWithMT
                    heading="What is your first name?"
                    placeholderText="First Name.."
                    setFunc={setFirstName}
                    funcValue={firstName}
                  />
                  <InputDivsWithMT
                    heading="What is your last name?"
                    placeholderText="Last Name.."
                    setFunc={setLastName}
                    funcValue={lastName}
                  />
                  <InputDivsCheck
                    heading="Create a brand username"
                    placeholderText="Username..."
                    setFunc={setUserName}
                    funcValue={userName}
                  />
                  <div className="stepBtns" style={{ marginTop: '3.5rem' }}>
                    <div
                      style={{
                        opacity: '0.25',
                        cursor: 'not-allowed',
                        background: '#7899CF',
                        width: '48%',
                        minHeight: '4rem',
                        maxHeight: '4rem',
                      }}
                    >
                      Go Back
                    </div>
                    <div
                      style={{
                        minHeight: '4rem',
                        maxHeight: '4rem',
                        opacity:
                          profilePicture && firstName && lastName && userName
                            ? '1'
                            : '0.25',
                        cursor:
                          profilePicture && firstName && lastName && userName
                            ? 'pointer'
                            : 'not-allowed',
                        background: '#18191D',
                        width: '48%',
                      }}
                      onClick={() => {
                        if (
                          profilePicture &&
                          firstName &&
                          lastName &&
                          userName
                        ) {
                          createLXProfile();
                        }
                      }}
                    >
                      Next Step
                    </div>
                  </div>
                </div>
              </>
            )}

            {createBrandProfileStep === 2 && (
              <>
                <div className="head-txt" style={{ padding: '0 3rem' }}>
                  <div>Create Brand Profile (Step 2)</div>
                  <div
                    onClick={() => {
                      setCreateBrandProfile(false);
                      setCreateBrandProfileStep(1);
                      setWhiteProPic('');
                      setBrandAddress('');
                      setHeadquarter('');
                      setBrandColorCode('');
                      setBrandDescription('');
                      setBrandUserName('');
                      setBrandDisplayName('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div
                  className="overall-div"
                  style={{ height: 'calc(100% - 6rem)' }}
                >
                  <div className="coverPic-container">
                    <div className="coverPicDiv">
                      <ImageUploadDivCoverPic1
                        setFunc={setCoverPhoto1}
                        funcValue={coverPhoto1}
                      />
                    </div>
                    <div className="logoDiv">
                      <img
                        src={profilePicture}
                        alt=""
                        style={{
                          width: '75%',
                          height: '75%',
                          borderRadius: '50%',
                          border: 'none',
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputs-container">
                    <InputDivsWithMT
                      heading="Brand Display Name"
                      placeholderText="Display Name.."
                      setFunc={setBrandDisplayName}
                      funcValue={brandDisplayName}
                    />
                    <InputDivsCheck
                      heading="Brand Username"
                      placeholderText="Username.."
                      setFunc={setBrandUserName}
                      funcValue={brandUserName}
                    />
                    <InputDivsTextArea1
                      heading="Describe Your Brand"
                      placeholderText="Description..."
                      setFunc={setBrandDescription}
                      funcValue={brandDescription}
                    />
                    <InputDivsWithColorCode
                      heading="Brand Colour Code"
                      placeholderText="#000000"
                      setFunc={setBrandColorCode}
                      funcValue={brandColorCode}
                      colorCode={brandColorCode}
                    />
                    <InputDivsWithMT
                      heading="What Country Is The Brand Headquartered In?"
                      placeholderText="Click To Select"
                      setFunc={setHeadquarter}
                      funcValue={headquarter}
                    />
                    <InputDivsWithMT
                      heading="What is the brand address?"
                      placeholderText="Enter address..."
                      setFunc={setBrandAddress}
                      funcValue={brandAddress}
                    />
                    <div
                      style={{
                        marginTop: '3rem',
                        marginBottom: '0.5rem',
                        fontSize: '1.1rem',
                      }}
                    >
                      Upload white profile picture
                    </div>
                    <ImageUploadProfilePic2
                      setFunc={setWhiteProPic}
                      funcValue={whiteProPic}
                    />
                    <div className="stepBtns" style={{ marginTop: '3.5rem' }}>
                      <div
                        style={{
                          cursor: 'pointer',
                          background: '#7899CF',
                          width: '48%',
                        }}
                        onClick={() => {
                          setWhiteProPic('');
                          setBrandAddress('');
                          setHeadquarter('');
                          setBrandColorCode('');
                          setBrandDescription('');
                          setBrandUserName('');
                          setBrandDisplayName('');
                          setCoverPhoto1('');
                          setCreateBrandProfileStep(1);
                        }}
                      >
                        Go Back
                      </div>
                      <div
                        style={{
                          opacity:
                            coverPhoto1 &&
                            whiteProPic &&
                            brandAddress &&
                            headquarter &&
                            brandColorCode &&
                            brandDescription &&
                            brandUserName &&
                            brandDisplayName
                              ? '1'
                              : '0.25',
                          cursor:
                            coverPhoto1 &&
                            whiteProPic &&
                            brandAddress &&
                            headquarter &&
                            brandColorCode &&
                            brandDescription &&
                            brandUserName &&
                            brandDisplayName
                              ? 'pointer'
                              : 'not-allowed',
                          background: '#18191D',
                          width: '48%',
                        }}
                        onClick={() => {
                          if (
                            coverPhoto1 &&
                            whiteProPic &&
                            brandAddress &&
                            headquarter &&
                            brandColorCode &&
                            brandDescription &&
                            brandUserName &&
                            brandDisplayName
                          ) {
                            createBankerProfile();
                          }
                        }}
                      >
                        Next Step
                      </div>
                    </div>
                  </div>
                </div>
                {isloading && (
                  <div
                    className="loading-component"
                    style={{ top: '0', left: '0', width: '100%' }}
                  >
                    <LoadingAnimation icon={appLogo} width={200} />
                  </div>
                )}
              </>
            )}

            {createBrandProfileStep === 3 && (
              <div className="successMsg">
                You Have Successfully Created A Banker Profile
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SettingsPage;

export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div className="imageUploadDiv" onClick={() => setSelectedDropDown('')}>
      <div className="heading">{heading}</div>
      <div className="imageDiv">
        <img
          src={funcValue ? funcValue : dummyProfile}
          alt="planBAccountPic"
          className="profileImg"
          htmlFor="profileUpdateImgPlanB"
        />
        {planBAccountPicUploading ? (
          <div className="overlayDiv">
            <img src={uploading} alt="uploading" className="uploadingimg" />
          </div>
        ) : (
          ''
        )}
      </div>
      <label htmlFor="profileUpdateImgPlanB" className="uploadFileDiv">
        <input
          className="uploadNewPicPlanB"
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Profile Photo'}
        </div>
      </label>
    </div>
  );
};

export const ImageUploadDivCoverPic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{ width: '100%', height: '100%' }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={upload}
                  alt=""
                  style={{ width: '50px', height: '50px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivCoverPic1 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%', borderRadius: '0' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading</div>
            ) : (
              <div>
                <img src={cover} alt="" />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

// export const ImageUploadDivLogo = ({ setFunc, funcValue }) => {
//   const {
//     planBAccountPicUploading,
//     setplanBAccountPicUploading,
//     setSelectedDropDown,
//   } = useContext(planBContext);

//   return (
//     <div
//       className="imageUploadDiv"
//       onClick={() => setSelectedDropDown('')}
//       style={{
//         width: '120px',
//         height: '120px',
//         // border: '0.5px solid #e7e7e7',
//         borderRadius: '50%',
//       }}
//     >
//       {funcValue ? (
//         <div
//           className="imageDiv"
//           style={{ height: '100%', width: '100%', marginRight: '0' }}
//         >
//           <img
//             src={funcValue}
//             alt="planBAccountPic"
//             className="profileImg"
//             htmlFor="profileUpdateImgPlanB"
//             style={{ width: '100%', height: '100%' }}
//           />
//         </div>
//       ) : (
//         <label
//           htmlFor="profileUpdateImgPlanB"
//           className="uploadFileDiv"
//           style={{
//             width: '100%',
//             height: '100%',
//             marginBottom: '0',
//           }}
//         >
//           <input
//             className="uploadNewPicPlanB"
//             type="file"
//             onChange={(e) => {
//               uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
//             }}
//             accept="image/*"
//             id="profileUpdateImgPlanB"
//           />
//           <div
//             style={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               border: 'none',
//               borderRadius: '50%',
//             }}
//           >
//             {planBAccountPicUploading ? (
//               <div>Uploading</div>
//             ) : (
//               <div>
//                 <img
//                   src={logo}
//                   alt=""
//                   style={{ width: '50px', height: '50px' }}
//                 />
//               </div>
//             )}
//           </div>
//         </label>
//       )}
//     </div>
//   );
// };

export const ImageUploadDivProfilePic = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        width: '100px',
        height: '100px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={upload}
                  alt=""
                  style={{ width: '30px', height: '30px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadDivProfilePic1 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        minWidth: '140px',
        minHeight: '140px',
        maxWidth: '140px',
        maxHeight: '140px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={uploadGrey}
                  alt=""
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

export const ImageUploadProfilePic2 = ({ setFunc, funcValue }) => {
  const {
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className="imageUploadDiv"
      onClick={() => setSelectedDropDown('')}
      style={{
        minWidth: '140px',
        minHeight: '140px',
        maxWidth: '140px',
        maxHeight: '140px',
        border: '0.5px solid #e7e7e7',
        borderRadius: '50%',
      }}
    >
      {funcValue ? (
        <div
          className="imageDiv"
          style={{ height: '100%', width: '100%', marginRight: '0' }}
        >
          <img
            src={funcValue}
            alt="planBAccountPic"
            className="profileImg"
            htmlFor="profileUpdateImgPlanB"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      ) : (
        <label
          htmlFor="profileUpdateImgPlanB"
          className="uploadFileDiv"
          style={{
            width: '140px',
            height: '140px',
            marginBottom: '0',
          }}
        >
          <input
            className="uploadNewPicPlanB"
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
            }}
            accept="image/*"
            id="profileUpdateImgPlanB"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              borderRadius: '50%',
            }}
          >
            {planBAccountPicUploading ? (
              <div>Uploading...</div>
            ) : (
              <div>
                <img
                  src={uploadGrey}
                  alt=""
                  style={{ width: '40px', height: '40px' }}
                />
              </div>
            )}
          </div>
        </label>
      )}
    </div>
  );
};

const SettingsSidebar = () => {
  const {
    changePassword,
    setChangePassword,
    contentData,
    setContentData,
    changePasswordPin,
    setChangePasswordPin,
    changePasswordPass,
    setChangePasswordPass,
    changePasswordCnfmPass,
    setChangePasswordCnfmPass,
    changePasswordLoading,
    setChangePasswordLoading,
    message,
    setMessage,
    qrData2FA,
    setqrData2FA,
    qr2FAPin,
    setqr2FAPin,
    refreshSettings,
    setrefreshSettings,
  } = useAppContextDetails();

  const { email, accessToken } = useContext(BankContext);

  function nextStepChangePass() {
    setMessage('');
    switch (changePassword) {
      case 'changepass1':
        initiatePassReset();
        break;
      case 'changepass2':
        setChangePassword('changepass3');
        break;
      case 'changepass3':
        setChangePassword('changepass4');
        break;
      case 'changepass4':
        cnfmPassword();
        break;
      case 'changepass5':
        setChangePassword('');
        break;
      case 'changepassfailed':
        setChangePassword('');
        break;
      case 'twofactorEnable1':
        initiateEnable2FA();
        break;
      case 'twofactorEnable2':
        setChangePassword('twofactorEnable3');
        break;
      case 'twofactorEnable3':
        verify2FA();
        break;
      case 'twofactorEnable4':
        setChangePassword('');
        break;
      case 'twofactorDisable1':
        disable2FA();
        break;
      case 'twofactorDisable2':
        setChangePassword('');
        break;
      default:
        setChangePassword('');
        break;
    }
  }

  function initiateEnable2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/set', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken aiprowallet'),
        client_app: 'manifest',
      })
      .then((response) => {
        if (response?.data?.status) {
          setqrData2FA(response?.data);
          setChangePassword('twofactorEnable2');
        } else {
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'initiateEnable2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
      });
  }
  function verify2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/set/verify', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken aiprowallet'),
        code: qr2FAPin ? qr2FAPin : '',
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('twofactorEnable4');
        } else {
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
        setrefreshSettings('2FA enabled');
      })
      .catch((error) => {
        console.log(error?.message, 'verify2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
        setrefreshSettings('2FA enabling failed');
      });
  }
  function disable2FA() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/mfa/disable', {
        email: email ? email : '',
        accessToken: accessToken
          ? accessToken
          : localStorage.getItem('accessToken aiprowallet'),
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('twofactorDisable2');
        } else {
          setChangePassword('twofactorDisableFailed');
          setMessage(response?.data?.message);
        }
        setChangePasswordLoading(false);
        setrefreshSettings('2FA disable');
      })
      .catch((error) => {
        console.log(error?.message, 'verify2FA error');
        setMessage(error?.message);
        setChangePasswordLoading(false);
        setrefreshSettings('2FA disable');
      });
  }

  function cnfmPassword() {
    if (changePasswordPass == changePasswordCnfmPass) {
      changePasswordFunc();
    } else {
      setMessage("Passwords don't match...");
    }
  }

  function changePasswordFunc() {
    setChangePasswordLoading(true);
    axios
      .post('https://gxauth.apimachine.com/gx/user/password/forgot/confirm', {
        email: email,
        code: changePasswordPin,
        newPassword: changePasswordPass,
      })
      .then((response) => {
        setMessage(response?.data?.message);
        if (response?.data?.status) {
          setChangePassword('changepass5');
        } else {
          setChangePassword('changepassfailed');
        }
        setChangePasswordLoading(false);
      })
      .catch((error) => {
        console.log(error?.message, 'password change failed');
        setChangePassword('changepassfailed');
        setChangePasswordLoading(false);
      });
  }

  function initiatePassReset() {
    setChangePasswordLoading(true);

    axios
      .post('https://gxauth.apimachine.com/gx/user/password/forgot/request', {
        email: email,
        app_code: 'manifest',
      })
      .then((response) => {
        if (response?.data?.status) {
          setChangePassword('changepass2');
          setChangePasswordLoading(false);
        } else {
          setMessage(response?.data?.message);
          setChangePasswordLoading(false);
        }
      })
      .catch((error) => {
        console.log(error?.message, 'Initiate forget password failed');
        setChangePasswordLoading(false);
      });
  }

  return (
    <div className="settingsSidebar">
      <div
        onClick={() => {
          // if (changePassword?.includes('changepass')) {
          //   setChangePassword('');
          // }else if (authenticated2FA?.mfa_enabled) {
          // }else{

          // }
          setChangePassword('');
        }}
      ></div>
      <div className="sidebarContainer">
        <div className="contentDiv">
          <div className="title">{contentData ? contentData?.name : ''}</div>
          <div className="content">
            {changePassword == 'changepassfailed' && message
              ? message
              : changePassword == 'twofactorEnable4' && message
              ? message
              : changePassword == 'twofactorDisableFailed' && message
              ? message
              : contentData
              ? contentData?.para
              : ''}
          </div>
          {contentData?.inputDiv == 'pin' ? (
            <OtpInput
              value={
                contentData?.name == 'Enable 2FA' ? qr2FAPin : changePasswordPin
              }
              onChange={
                contentData?.name == 'Enable 2FA'
                  ? setqr2FAPin
                  : setChangePasswordPin
              }
              numInputs={6}
              separator={<span> </span>}
              shouldAutoFocus
              containerStyle="otpInputWrapper"
              inputStyle="otpInput"
            />
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'password' ? (
            <div className="password">
              <input
                placeholder="Enter new password..."
                type="password"
                value={changePasswordPass}
                onChange={(event) =>
                  setChangePasswordPass(event?.target?.value)
                }
              />
            </div>
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'cnfmpassword' ? (
            <div className="password">
              <input
                placeholder="Enter confirm password..."
                type="password"
                value={changePasswordCnfmPass}
                onChange={(event) =>
                  setChangePasswordCnfmPass(event?.target?.value)
                }
              />
            </div>
          ) : (
            ''
          )}
          {contentData?.inputDiv == 'qrdiv' ? (
            <div className="qRContainer">
              {qrData2FA && (
                <div className="qRsingleDiv">
                  <QRCode
                    className="qr-svg"
                    value={qrData2FA ? qrData2FA?.qrcodeValue : ''}
                    renderAs="svg"
                    size={300}
                  />
                </div>
              )}
              <div className="seperateBorder">OR</div>
              <div className="content">
                Enter this secret code into your Google Auth App.
              </div>
              <div className="qrcodes">
                {qrData2FA ? qrData2FA?.SecretCode : ''}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {message &&
          changePassword !== 'changepassfailed' &&
          changePassword !== 'changepass5' &&
          changePassword !== 'twofactorDisableFailed' &&
          changePassword !== 'twofactorEnable4' && (
            <div className="messageDiv">
              {message ? 'Note : ' + message : ''}
            </div>
          )}
        <div className="btn">
          {contentData?.btn1 && (
            <div
              onClick={nextStepChangePass}
              style={{
                pointerEvents: changePasswordLoading
                  ? 'none'
                  : contentData?.inputDiv !== 'pin'
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    changePasswordPin?.toString()?.split('')?.length >= 6
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    qr2FAPin?.toString()?.split('')?.length >= 6
                  ? ''
                  : 'none',
                opacity: changePasswordLoading
                  ? 'none'
                  : contentData?.inputDiv !== 'pin'
                  ? ''
                  : contentData?.inputDiv == 'pin' &&
                    changePasswordPin?.toString()?.split('')?.length >= 6
                  ? '1'
                  : contentData?.inputDiv == 'pin' &&
                    qr2FAPin?.toString()?.split('')?.length >= 6
                  ? '1'
                  : '0.7',
              }}
              className="topBtn"
            >
              <div>{contentData ? contentData?.btn1 : ''}</div>
              {changePasswordLoading && (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          )}
          <div
            onClick={() => (contentData ? setChangePassword('') : '')}
            className="bottomBtn"
          >
            {contentData ? contentData?.btn2 : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
