import React, { useEffect } from 'react';
import './commercial.scss';
import Layout from '../../Layout/Layout';
import { useState, useContext } from 'react';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { RxHamburgerMenu } from 'react-icons/rx';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import {
  productLinesFunction,
  fixedPayoutCampaigns,
  deleteCampaignFunction,
  vaultCurrencies,
  createFPCampaignFunction,
} from '../AdvertisePage/api';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import {
  ImageUploadDiv,
  ImageUploadDiv1,
  InputDivsWithMT,
  ImageUploadDiv2,
  InputDropdown1,
} from '../../components/BuyBlocks/components/CreatePlanB';
import { becomeAdvertiserFunc, createProductLineFunc } from './api';

import fulllogo from '../../static/images/commercial/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import close from '../CRMPage/close.svg';
import loadingLogo from '../../static/images/logos/vv.svg';
import down from '../AdvertisePage/dArrow.svg';
import up from '../AdvertisePage/UArrow.svg';

const CommercialPage = () => {
  const { email, token, commercialTab, setCommercialTab } =
    useContext(BankContext);
  const { appLogo } = useAppContextDetails();

  const [productLineData, setProductLineData] = useState([]);
  const [filteredproductLineData, setFilteredProductLineData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videCardLoading, setvideCardLoading] = useState(true);
  const [payoutCampaignsData, setPayoutCampaignsData] = useState([]);
  const [filteredpayoutCampaignsData, setFilteredPayoutCampaignsData] =
    useState([]);
  const [campaignSelected, setCampaignSelected] = useState(false);
  const [selectedCampaignData, setSelectedCampaignData] = useState([]);
  const [campaignAction, setCampaignAction] = useState(false);
  const [deleteCampaign, setDeleteCampaign] = useState(false);
  const [deletingCampaign, setDeletingCampaign] = useState(false);
  const [deleteCampaignStep, setDeleteCampaignStep] = useState(1);

  const [popularActionEnabled, setPopularActionEnabled] = useState(false);
  const [createFPCampaign, setCreateFPCampaign] = useState(false);
  const [createFPCampaignStep, setCreateFPCampaignStep] = useState('step1');
  const [productLineData1, setProductLineData1] = useState([]);
  const [gettingLineData, setGettingLineData] = useState(false);
  const [selectedLine, setSelectedLine] = useState('');
  const [campaignName, setcampaignName] = useState('');
  const [campaignNickName, setcampaignNickName] = useState('');
  const [campaignDescription, setcampaignDescription] = useState('');
  const [campaignThumbnail, setCampaignThumbnail] = useState('');
  const [campaignVideoLink, setCampaignVideoLink] = useState('');
  const [payoutCurrencydata, setPayoutCurrencyData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [payoutAmount, setPayoutAmount] = useState();
  const [peopleCount, setPeopleCount] = useState();
  const [igClicked, setIgClicked] = useState(false);
  const [utubeClicked, setUtubeClicked] = useState(false);
  const [fbClicked, setFbClicked] = useState(false);
  const [igLink, setIgLink] = useState('');
  const [igAmount, setIgAmount] = useState();
  const [utubeLink, setUtubeLink] = useState('');
  const [utubeAmount, setUtubeAmount] = useState();
  const [fbLink, setFbLink] = useState('');
  const [fbAmount, setFbAmount] = useState();
  const [creating, setCreating] = useState(false);

  const [becomeAdvertiser, setBecomeAdvertiser] = useState(false);
  const [becomeAdvertiserStep, setBecomeAdvertiserStep] = useState(1);
  const [brandName, setBrandName] = useState();
  const [brandAddress, setBrandAddress] = useState();
  const [webLink, setWebLink] = useState();
  const [buttonText, setButtonText] = useState();
  const [buttonLink, setButtonLink] = useState();
  const [profileVideoLink, setProfileVideoLink] = useState();
  const [colorCode, setColorCode] = useState('');
  const [profileImgColor, setProfileImgColor] = useState();
  const [profileImgWhitee, setProfileImgWhitee] = useState();
  const [imgLoading, setImgLoading] = useState(false);

  const [createProductLine, setCreateProductLine] = useState(false);
  const [createProductLineStep, setCreateProductLineStep] = useState(1);
  const [productLineName, setProductLineName] = useState();
  const [brandCode, setBrandCode] = useState();
  const [productLineImg, setProductLineImg] = useState();
  const [imgLoading1, setImgLoading1] = useState(false);
  const [brandCodeData, setBrandCodeData] = useState([]);
  const [brandCodedropDown, setBrandCodedropDown] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');

  function payoutCampaign() {
    setvideCardLoading(true);
    fixedPayoutCampaigns(email).then((response) => {
      let result = response?.data?.videoCampaigns;
      // console.log(result, 'fixedPayoutCampaigns result');
      setPayoutCampaignsData(result);
      setFilteredPayoutCampaignsData(result);
      setvideCardLoading(false);
    });
  }

  function getProductLines() {
    setLoading(true);
    productLinesFunction(email).then((response) => {
      let result = response?.data?.productLines;
      // console.log(result, 'productLinesFunction result');
      setProductLineData(result);
      setFilteredProductLineData(result);
      setLoading(false);
    });
  }

  useEffect(() => {
    payoutCampaign();
  }, []);

  useEffect(() => {
    getProductLines();
  }, []);

  const myTimeout1 = () => {
    setTimeout(reload1, 3000);
  };

  function reload1() {
    setSelectedCampaignData('');
    setDeleteCampaign(false);
    setDeleteCampaignStep(1);
    payoutCampaign();
    setCampaignSelected(false);
  }

  const deleteACampaign = () => {
    setDeletingCampaign(true);

    let obj = {
      token,
      email,
      videoCode: selectedCampaignData?.videoCode,
    };
    // console.log(obj, 'obj');

    deleteCampaignFunction(obj).then((response) => {
      let result = response?.data;
      // console.log(result);
      if (result?.status) {
        setDeletingCampaign(false);
        setDeleteCampaignStep(2);
        myTimeout1();
      } else {
        setDeletingCampaign(false);
      }
    });
  };

  const lineData = () => {
    setGettingLineData(true);
    productLinesFunction(email).then((response) => {
      let result = response?.data?.productLines;
      console.log(result, 'productLinesFunction result');
      setProductLineData1(result);
      setGettingLineData(false);
    });
  };

  const getVaultCurrency = () => {
    setGettingLineData(true);
    let obj = {
      app_code: 'farm',
    };

    vaultCurrencies(obj).then((response) => {
      let result = response?.data?.coins_data;
      setPayoutCurrencyData(result);
      setGettingLineData(false);
    });
  };

  const myTimeout = () => {
    setTimeout(reload, 3000);
  };

  function reload() {
    setCreateFPCampaign(false);
    setCreateFPCampaignStep('step1');
    setSelectedLine('');
    setcampaignName('');
    setcampaignNickName('');
    setcampaignDescription('');
    setCampaignVideoLink('');
    setSelectedCurrency('');
    setPayoutAmount('');
    setPeopleCount('');
    setIgClicked(false);
    setFbClicked(false);
    setUtubeClicked(false);
    setIgAmount('');
    setIgLink('');
    setFbAmount('');
    setFbLink('');
    setUtubeAmount('');
    setUtubeLink('');
    payoutCampaign();
    setCommercialTab('Fixed Payout Campaigns');
  }

  const createCampaign = () => {
    setCreating(true);
    let obj = {
      token,
      email,
      productLine_id: selectedLine,
      video_title: campaignName,
      video_nickname: campaignNickName,
      video_description: campaignDescription,
      video_thumbnail: campaignThumbnail,
      video_link: campaignVideoLink,
      platform_links: [
        {
          name: 'facebook',
          code: 'fb',
          link: fbLink,
          payOut: fbAmount,
          coin: selectedCurrency,
        },
        {
          name: 'Instagram',
          code: 'ig',
          link: igLink,
          payOut: igAmount,
          coin: selectedCurrency,
        },
        {
          name: 'YouTube',
          code: 'yt',
          link: utubeLink,
          payOut: utubeAmount,
          coin: selectedCurrency,
        },
      ],
      payOutCoin: selectedCurrency,
      payout_amount: payoutAmount,
      max_redeem_count: peopleCount,
    };

    createFPCampaignFunction(obj).then((response) => {
      let result = response?.data;
      if (result?.status) {
        setCreating(false);
        setCreateFPCampaignStep('step7');
        myTimeout();
      } else {
        setCreating(false);
      }
    });
  };

  const myTimeout2 = () => {
    setTimeout(reload2, 3000);
  };

  function reload2() {
    setBecomeAdvertiser(false);
    setProfileImgWhitee('');
    setProfileImgColor('');
    setColorCode('');
    setProfileVideoLink('');
    setButtonLink('');
    setButtonText('');
    setWebLink('');
    setBrandAddress('');
    setBrandName('');
    setBecomeAdvertiserStep(1);
  }

  const advertiserApiCall = () => {
    setCreating(true);
    let obj = {
      email,
      token,
      profile_img: profileImgColor,
      name: brandName,
      address: brandAddress,
      profile_video: profileVideoLink,
      website_link: webLink,
      call_action_name: buttonText,
      call_action_link: buttonLink,
      color_code: [colorCode?.toString()],
      whiteinvrersedlogo: profileImgWhitee,
    };

    becomeAdvertiserFunc(obj).then((response) => {
      let result = response?.data;
      if (result?.status) {
        setCreating(false);
        setBecomeAdvertiserStep(2);
        myTimeout2();
      } else {
        setCreating(false);
      }
    });
  };

  const getBrandCodes = () => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/get?email=${email}`
      )
      .then((response) => {
        let result = response?.data?.brands;
        console.log(result, 'getBrandCodes result');
        setBrandCodeData(result);
      })
      .catch((error) => {
        console.log(error, 'error in getBrandCodes');
      });
  };

  useEffect(() => {
    getBrandCodes();
  }, []);

  const myTimeout3 = () => {
    setTimeout(reload3, 3000);
  };

  function reload3() {
    setCreateProductLine(false);
    setCreateProductLineStep(1);
    setProductLineName('');
    setBrandCode('');
    setProductLineImg('');
    setBrandCodeData('');
    setBrandCodedropDown(false);
    setSelectedBrand('');
    getProductLines();
  }

  const createProductLineApiCall = () => {
    setCreating(true);
    let obj = {
      email,
      token,
      name: productLineName,
      brand_code: brandCode,
      icon: productLineImg,
      other_data: {},
    };

    createProductLineFunc(obj).then((response) => {
      let result = response?.data;
      if (result?.status) {
        setCreateProductLineStep(2);
        setCreating(false);
        myTimeout3();
      } else {
        setCreating(false);
      }
    });
  };

  return (
    <Layout active="Commercials" className="commercial" hideFooter>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="commercial-navbar">
          <div
            style={{
              width: '25%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="commercial-hamburger">
              <RxHamburgerMenu />
            </div>
            <div className="commercial-icon">
              <img src={fulllogo} alt="" />
            </div>
          </div>
          <div className="search-commercial">
            <div className="im">
              <input
                type="text"
                placeholder="Search Anything.."
                // onChange={(event) => setfiltersearch(event?.target?.value)}
              />
              <div className="arroww" style={{ cursor: 'pointer' }}>
                <img src={search} alt="" />
              </div>
            </div>
          </div>
          <div
            className="commercial-listBtn"
            onClick={() => {
              setPopularActionEnabled(!popularActionEnabled);
            }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className="optionss" style={{ width: '60%' }}>
            <div
              style={{
                fontWeight: commercialTab === 'Product Lines' ? '600' : '',
              }}
              className={`each-option ${
                commercialTab === 'Product Lines' ? 'each-option-selected' : ''
              }`}
              onClick={() => {
                setCommercialTab('Product Lines');
              }}
            >
              <div>
                <p>Product Lines</p>
              </div>
            </div>
            <div
              style={{
                fontWeight:
                  commercialTab === 'Fixed Payout Campaigns' ? '600' : '',
              }}
              className={`each-option ${
                commercialTab === 'Fixed Payout Campaigns'
                  ? 'each-option-selected'
                  : ''
              }`}
              onClick={() => setCommercialTab('Fixed Payout Campaigns')}
            >
              <div>
                <p>Fixed Payout Campaigns</p>
              </div>
            </div>
            <div
              className="each-option"
              style={{
                fontWeight: commercialTab === 'Dynamic Campaigns' ? '600' : '',
              }}
              //   onClick={() => setCommercialTab('Dynamic Campaigns')}
              // className={`each-option ${
              //     commercialTab === 'Dynamic Campaigns' ? 'each-option-selected' : ''
              //   }`}
            >
              <div>
                <p>Dynamic Campaigns</p>
              </div>
            </div>
          </div>
        </div>

        {commercialTab === 'Product Lines' ? (
          <div className="commercial-content1">
            <div className="header">
              <div className="userDiv">User</div>
              <div className="brandDiv">Brand Code</div>
              <div className="campaignsDiv">Campaigns</div>
            </div>
            <div className="contentData">
              {loading
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="eachData" key={index}>
                          <div className="userDiv1">
                            <div>
                              <Skeleton
                                width={30}
                                height={30}
                                borderRadius={50}
                              />
                            </div>
                            <div>
                              <div>
                                <Skeleton width={100} height={10} />
                              </div>
                              <div>
                                <Skeleton width={150} height={10} />
                              </div>
                            </div>
                          </div>
                          <div className="brandDiv1">
                            <Skeleton width={100} height={20} />
                          </div>
                          <div className="campaignsDiv1">
                            <Skeleton width={10} height={20} />
                          </div>
                        </div>
                      );
                    })
                : filteredproductLineData?.map((e, i) => {
                    return (
                      <div className="eachData" key={i}>
                        <div className="userDiv1">
                          <div>
                            <img src={e?.icon} alt="" />
                          </div>
                          <div>
                            <div
                              style={{ fontSize: '0.9rem', fontWeight: '600' }}
                            >
                              {e?.name}
                            </div>
                            <div style={{ fontSize: '0.8rem' }}>
                              {e?.productLine_id}
                            </div>
                          </div>
                        </div>
                        <div className="brandDiv1">{e?.brand_code}</div>
                        <div className="campaignsDiv1">0</div>
                      </div>
                    );
                  })}
            </div>
          </div>
        ) : commercialTab === 'Fixed Payout Campaigns' ? (
          <div className="commercial-content2">
            {videCardLoading
              ? Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="videoCards" key={index}>
                        <Skeleton className="thumbnailPic" />
                        <div className="cardHeader">
                          <Skeleton width={'100%'} height={35} />
                        </div>
                        <div className="cardContent">
                          <Skeleton width={'100%'} height={25} />
                          <Skeleton width={'100%'} height={25} />
                          <Skeleton width={'100%'} height={25} />
                          <Skeleton width={'100%'} height={25} />
                        </div>
                      </div>
                    );
                  })
              : filteredpayoutCampaignsData?.map((e, i) => {
                  return (
                    <div
                      className="videoCards"
                      key={i}
                      style={{
                        opacity: !campaignSelected
                          ? '1'
                          : campaignSelected &&
                            selectedCampaignData?._id === e?._id
                          ? '1'
                          : '0.25',
                      }}
                    >
                      <div className="thumbnailPic">
                        <img src={e?.video_thumbnail} alt="" />
                      </div>
                      <div className="cardHeader">{e?.video_title}</div>
                      <div className="cardContent">{e?.video_description}</div>
                      <div className="btnsDiv1">
                        <div style={{ background: '#7899CF' }}>Details</div>
                        <div
                          style={{ background: '#18191D' }}
                          onClick={() => {
                            setCampaignSelected(true);
                            setSelectedCampaignData(e);
                            setCampaignAction(true);
                          }}
                        >
                          Actions
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        ) : (
          ''
        )}

        {campaignAction && (
          <div className="popularC">
            <div className="head-txt">
              <div>Campaign Actions</div>
              <div
                onClick={() => {
                  setCampaignAction(false);
                  setCampaignSelected(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div className="overall-div">
              <div
                className="each-action1"
                // onClick={() => {
                //   setProductAction(false);
                // }}
              >
                <div>Edit Campaign</div>
              </div>
              <div
                className="each-action1"
                onClick={() => {
                  setCampaignAction(false);
                  setDeleteCampaign(true);
                }}
              >
                <div>Delete Campaign</div>
              </div>
            </div>
          </div>
        )}

        {deleteCampaign && (
          <div className="popularC">
            {deleteCampaignStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Delete Campaign</div>
                  <div
                    onClick={() => {
                      setDeleteCampaign(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="each-action1"
                    onClick={() => {
                      deleteACampaign();
                    }}
                  >
                    Confirm & Delete
                  </div>
                  <div
                    className="each-action1"
                    onClick={() => {
                      setDeleteCampaign(false);
                      setCampaignAction(true);
                    }}
                  >
                    Never Mind
                  </div>
                </div>
              </>
            )}

            {deletingCampaign && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}

            {deleteCampaignStep === 2 && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setDeleteCampaign(false);
                      setDeleteCampaignStep(1);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Deleted{' '}
                    {selectedCampaignData?.video_title}
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{
                      background: '#7899CF',
                      width: '100%',
                    }}
                    onClick={() => {
                      setDeleteCampaign(false);
                      setDeleteCampaignStep(1);
                    }}
                  >
                    See Updated List
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {popularActionEnabled && (
          <div className="popularC">
            <>
              <div className="head-txt">
                <div>Popular Actions</div>
                <div
                  onClick={() => {
                    setPopularActionEnabled(false);
                  }}
                  className="close-div"
                >
                  <img src={close} alt="" />
                </div>
              </div>
              <div className="overall-div">
                <div
                  className="each-action1"
                  onClick={() => {
                    setPopularActionEnabled(false);
                    setBecomeAdvertiser(true);
                  }}
                >
                  <div>Become A Viral Advertiser</div>
                </div>
                <div
                  className="each-action1"
                  onClick={() => {
                    setPopularActionEnabled(false);
                    setCreateProductLine(true);
                  }}
                >
                  <div>Create A Product Line To Advertise</div>
                </div>
                <div
                  className="each-action1"
                  onClick={() => {
                    setPopularActionEnabled(false);
                    setCreateFPCampaign(true);
                    lineData();
                  }}
                >
                  <div>Create A Fixed Payout Campaign</div>
                </div>
              </div>
            </>
          </div>
        )}

        {createFPCampaign && (
          <div className="popularC" style={{ padding: '1rem 2rem' }}>
            {createFPCampaignStep === 'step1' && (
              <>
                <div className="head-txt">
                  <div>New Fixed Payout Campaign</div>
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="subbTxt"
                    style={{ marginTop: '1rem', fontWeight: '500' }}
                  >
                    Select Product Line
                  </div>
                  {gettingLineData
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : productLineData1?.map((each, i) => {
                        return (
                          <div
                            className="each-action1"
                            key={i}
                            onClick={() => {
                              setSelectedLine(each?.productLine_id);
                              setCreateFPCampaignStep('step2');
                            }}
                          >
                            <div>
                              <img src={each?.icon} alt="" />
                            </div>
                            <div>{each?.name}</div>
                          </div>
                        );
                      })}
                </div>
              </>
            )}

            {createFPCampaignStep === 'step2' && (
              <>
                <div className="head-txt">
                  <div>New Fixed Payout Campaign</div>
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                      setCreateFPCampaignStep('step1');
                      setSelectedLine('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="subbTxt"
                    style={{
                      marginTop: '1rem',
                      fontWeight: '400',
                      fontSize: '0.97rem',
                    }}
                  >
                    What is the name of the campaign?
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Name..."
                      onChange={(e) => {
                        setcampaignName(e.target.value);
                      }}
                    />
                  </div>
                  <div
                    className="subbTxt"
                    style={{
                      marginTop: '1rem',
                      fontWeight: '400',
                      fontSize: '0.97rem',
                    }}
                  >
                    Create a nickname for the campaign
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Nickname..."
                      onChange={(e) => {
                        setcampaignNickName(e.target.value);
                      }}
                    />
                  </div>
                  <div
                    className="subbTxt"
                    style={{
                      marginTop: '1rem',
                      fontWeight: '400',
                      fontSize: '0.97rem',
                    }}
                  >
                    Create campaign description
                  </div>
                  <div className="each-action1" style={{ minHeight: '10rem' }}>
                    <input
                      type="text"
                      placeholder="Description..."
                      onChange={(e) => {
                        setcampaignDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#7899CF' }}
                    onClick={() => {
                      setCreateFPCampaignStep('step1');
                      setcampaignName('');
                      setcampaignNickName('');
                      setcampaignDescription('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        campaignName.length > 1 &&
                        campaignNickName.length > 1 &&
                        campaignDescription.length > 1
                          ? '1'
                          : '0.25',
                      cursor:
                        campaignName.length > 1 &&
                        campaignNickName.length > 1 &&
                        campaignDescription.length > 1
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        campaignName.length > 1 &&
                        campaignNickName.length > 1 &&
                        campaignDescription.length > 1
                      ) {
                        setCreateFPCampaignStep('step3');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {createFPCampaignStep === 'step3' && (
              <>
                <div className="head-txt">
                  <div>New Fixed Payout Campaign</div>
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                      setCreateFPCampaignStep('step1');
                      setSelectedLine('');
                      setcampaignName('');
                      setcampaignNickName('');
                      setcampaignDescription('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <ImageUploadDiv1
                    heading="Choose campaign cover photo"
                    setFunc={setCampaignThumbnail}
                    funcValue={campaignThumbnail}
                  />
                  <div
                    className="subbTxt"
                    style={{
                      marginTop: '1rem',
                      fontWeight: '400',
                      fontSize: '0.97rem',
                    }}
                  >
                    Enter campaign video
                  </div>
                  <div className="each-action1">
                    <input
                      type="text"
                      placeholder="Link"
                      onChange={(e) => {
                        setCampaignVideoLink(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#7899CF' }}
                    onClick={() => {
                      setCreateFPCampaignStep('step2');
                      setCampaignVideoLink('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: campaignVideoLink.length > 1 ? '1' : '0.25',
                      cursor:
                        campaignVideoLink.length > 1
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (campaignVideoLink.length > 1) {
                        setCreateFPCampaignStep('step4');
                        getVaultCurrency();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {createFPCampaignStep === 'step4' && (
              <>
                <div className="head-txt">
                  <div>New Fixed Payout Campaign</div>
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                      setCreateFPCampaignStep('step1');
                      setSelectedLine('');
                      setcampaignName('');
                      setcampaignNickName('');
                      setcampaignDescription('');
                      setCampaignVideoLink('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="subbTxt"
                    style={{ marginTop: '1rem', fontWeight: '500' }}
                  >
                    Select Payout Currency
                  </div>
                  {gettingLineData
                    ? Array(10)
                        .fill(' ')
                        .map((item, index) => {
                          return (
                            <div className="each-action1" key={index}>
                              <Skeleton width={150} height={30} />
                            </div>
                          );
                        })
                    : payoutCurrencydata?.map((e, i) => {
                        return (
                          <div
                            className="each-action1"
                            key={i}
                            onClick={() => {
                              setSelectedCurrency(e?.coinSymbol);
                              setCreateFPCampaignStep('step5');
                            }}
                          >
                            <div>
                              <img src={e?.coinImage} alt="" />
                            </div>
                            <div>{e?.coinSymbol}</div>
                          </div>
                        );
                      })}
                </div>
              </>
            )}

            {createFPCampaignStep === 'step5' && (
              <>
                <div className="head-txt">
                  <div>New Fixed Payout Campaign</div>
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                      setCreateFPCampaignStep('step1');
                      setSelectedLine('');
                      setcampaignName('');
                      setcampaignNickName('');
                      setcampaignDescription('');
                      setCampaignVideoLink('');
                      setSelectedCurrency('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="subbTxt"
                    style={{
                      marginTop: '1rem',
                      fontWeight: '400',
                      fontSize: '0.97rem',
                    }}
                  >
                    What is the payout amount?
                  </div>
                  <div className="each-action1">
                    <input
                      type="number"
                      placeholder="0.00"
                      onChange={(e) => setPayoutAmount(e.target.value)}
                    />
                    <div>{selectedCurrency}</div>
                  </div>
                  <div
                    className="subbTxt"
                    style={{
                      marginTop: '1rem',
                      fontWeight: '400',
                      fontSize: '0.97rem',
                    }}
                  >
                    How many people can get paid out?
                  </div>
                  <div className="each-action1">
                    <input
                      type="number"
                      placeholder="0"
                      onChange={(e) => setPeopleCount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#7899CF' }}
                    onClick={() => {
                      setCreateFPCampaignStep('step4');
                      setPayoutAmount('');
                      setPeopleCount('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity: peopleCount && payoutAmount ? '1' : '0.25',
                      cursor:
                        peopleCount && payoutAmount ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() => {
                      if (peopleCount && payoutAmount) {
                        setCreateFPCampaignStep('step6');
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {createFPCampaignStep === 'step6' && (
              <>
                <div className="head-txt">
                  <div>New Fixed Payout Campaign</div>
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                      setCreateFPCampaignStep('step1');
                      setSelectedLine('');
                      setcampaignName('');
                      setcampaignNickName('');
                      setcampaignDescription('');
                      setCampaignVideoLink('');
                      setSelectedCurrency('');
                      setPayoutAmount('');
                      setPeopleCount('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <div
                    className="subbTxt"
                    style={{ marginTop: '1rem', fontWeight: '500' }}
                  >
                    Select additional distribution platforms
                  </div>
                  <div
                    className="each-action1"
                    style={{ flexDirection: 'column' }}
                    onClick={() => setIgClicked(!igClicked)}
                  >
                    <div className="separatorDiv">
                      <div style={{ fontWeight: '600', fontSize: '1.35rem' }}>
                        Instagram
                      </div>
                      <div>
                        <img
                          src={igClicked ? up : down}
                          alt=""
                          style={{ width: '10px', height: '10px' }}
                        />
                      </div>
                    </div>
                    <div
                      className="hiddenDIv"
                      style={{ display: igClicked ? 'flex' : 'none' }}
                    >
                      <div>Enter campaign video for Instagram</div>
                      <div
                        style={{
                          marginTop: '1rem',
                          height: '3.5rem',
                          borderRadius: '10px',
                          border: '0.5px solid #E5E5E5',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Link..."
                          style={{
                            width: '100%',
                            borderRadius: '10px',
                            paddingLeft: '1rem',
                          }}
                          onChange={(e) => setIgLink(e.target.value)}
                        />
                      </div>
                      <div style={{ marginTop: '1.5rem' }}>
                        Enter payout for Instagram
                      </div>
                      <div
                        style={{
                          marginTop: '1rem',
                          height: '3.5rem',
                          display: 'flex',
                          borderRadius: '10px',
                          border: '0.5px solid #E5E5E5',
                          paddingRight: '1rem',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="number"
                          placeholder="0.00"
                          style={{
                            width: '90%',
                            borderRadius: '10px',
                            paddingLeft: '1rem',
                          }}
                          onChange={(e) => setIgAmount(e.target.value)}
                        />
                        <div>{selectedCurrency ? selectedCurrency : 'USD'}</div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="each-action1"
                    style={{ flexDirection: 'column' }}
                    onClick={() => setUtubeClicked(!utubeClicked)}
                  >
                    <div className="separatorDiv">
                      <div style={{ fontWeight: '600', fontSize: '1.35rem' }}>
                        Youtube
                      </div>
                      <div>
                        <img
                          src={utubeClicked ? up : down}
                          alt=""
                          style={{ width: '10px', height: '10px' }}
                        />
                      </div>
                    </div>
                    <div
                      className="hiddenDIv"
                      style={{ display: utubeClicked ? 'flex' : 'none' }}
                    >
                      <div>Enter campaign video for Youtube</div>
                      <div
                        style={{
                          marginTop: '1rem',
                          height: '3.5rem',
                          borderRadius: '10px',
                          border: '0.5px solid #E5E5E5',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Link..."
                          style={{
                            width: '100%',
                            borderRadius: '10px',
                            paddingLeft: '1rem',
                          }}
                          onChange={(e) => setUtubeLink(e.target.value)}
                        />
                      </div>
                      <div style={{ marginTop: '1.5rem' }}>
                        Enter payout for Youtube
                      </div>
                      <div
                        style={{
                          marginTop: '1rem',
                          height: '3.5rem',
                          display: 'flex',
                          borderRadius: '10px',
                          border: '0.5px solid #E5E5E5',
                          paddingRight: '1rem',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="number"
                          placeholder="0.00"
                          style={{
                            width: '90%',
                            borderRadius: '10px',
                            paddingLeft: '1rem',
                          }}
                          onChange={(e) => setUtubeAmount(e.target.value)}
                        />
                        <div>{selectedCurrency ? selectedCurrency : 'USD'}</div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="each-action1"
                    style={{ flexDirection: 'column' }}
                    onClick={() => setFbClicked(!fbClicked)}
                  >
                    <div className="separatorDiv">
                      <div style={{ fontWeight: '600', fontSize: '1.35rem' }}>
                        Facebook
                      </div>
                      <div>
                        <img
                          src={fbClicked ? up : down}
                          alt=""
                          style={{ width: '10px', height: '10px' }}
                        />
                      </div>
                    </div>
                    <div
                      className="hiddenDIv"
                      style={{ display: fbClicked ? 'flex' : 'none' }}
                    >
                      <div>Enter campaign video for Facebook</div>
                      <div
                        style={{
                          marginTop: '1rem',
                          height: '3.5rem',
                          borderRadius: '10px',
                          border: '0.5px solid #E5E5E5',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Link..."
                          style={{
                            width: '100%',
                            borderRadius: '10px',
                            paddingLeft: '1rem',
                          }}
                          onChange={(e) => setFbLink(e.target.value)}
                        />
                      </div>
                      <div style={{ marginTop: '1.5rem' }}>
                        Enter payout for Facebook
                      </div>
                      <div
                        style={{
                          marginTop: '1rem',
                          height: '3.5rem',
                          display: 'flex',
                          borderRadius: '10px',
                          border: '0.5px solid #E5E5E5',
                          paddingRight: '1rem',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <input
                          type="number"
                          placeholder="0.00"
                          style={{
                            width: '90%',
                            borderRadius: '10px',
                            paddingLeft: '1rem',
                          }}
                          onChange={(e) => setFbAmount(e.target.value)}
                        />
                        <div>{selectedCurrency ? selectedCurrency : 'USD'}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#7899CF' }}
                    onClick={() => {
                      setCreateFPCampaignStep('step5');
                      setIgLink('');
                      setIgAmount('');
                      setIgClicked(false);
                      setFbClicked(false);
                      setFbAmount('');
                      setFbLink('');
                      setUtubeClicked(false);
                      setUtubeAmount('');
                      setUtubeLink('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      opacity:
                        igAmount &&
                        igLink &&
                        fbAmount &&
                        fbLink &&
                        utubeAmount &&
                        utubeLink
                          ? '1'
                          : '0.25',
                      cursor:
                        igAmount &&
                        igLink &&
                        fbAmount &&
                        fbLink &&
                        utubeAmount &&
                        utubeLink
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (
                        igAmount &&
                        igLink &&
                        fbAmount &&
                        fbLink &&
                        utubeAmount &&
                        utubeLink
                      ) {
                        createCampaign();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {creating && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}

            {createFPCampaignStep === 'step7' && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setCreateFPCampaign(false);
                      setCreateFPCampaignStep('step1');
                      setSelectedLine('');
                      setcampaignName('');
                      setcampaignNickName('');
                      setcampaignDescription('');
                      setCampaignVideoLink('');
                      setSelectedCurrency('');
                      setPayoutAmount('');
                      setPeopleCount('');
                      setIgClicked(false);
                      setFbClicked(false);
                      setUtubeClicked(false);
                      setIgAmount('');
                      setIgLink('');
                      setFbAmount('');
                      setFbLink('');
                      setUtubeAmount('');
                      setUtubeLink('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Created A Video Campaign
                  </div>
                </div>

                <div className="stepBtns">
                  <div
                    style={{
                      background: '#7899CF',
                      width: '100%',
                    }}
                  >
                    See Campaign Details
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {becomeAdvertiser && (
          <div className="popularC">
            {becomeAdvertiserStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Become An Advertiser</div>
                  <div
                    onClick={() => {
                      setBecomeAdvertiser(false);
                      setProfileImgWhitee('');
                      setProfileImgColor('');
                      setColorCode('');
                      setProfileVideoLink('');
                      setButtonLink('');
                      setButtonText('');
                      setWebLink('');
                      setBrandAddress('');
                      setBrandName('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div
                  className="overall-div"
                  style={{ height: 'calc(100% - 6rem)' }}
                >
                  <InputDivsWithMT
                    heading="What is the name of your brand?"
                    placeholderText="Name..."
                    setFunc={setBrandName}
                    funcValue={brandName}
                  />
                  <InputDivsWithMT
                    heading="What is your brand’s address?"
                    placeholderText="Address..."
                    setFunc={setBrandAddress}
                    funcValue={brandAddress}
                  />
                  <InputDivsWithMT
                    heading="What is your website link?"
                    placeholderText="Url..."
                    setFunc={setWebLink}
                    funcValue={webLink}
                  />
                  <InputDivsWithMT
                    heading="Call to action button text"
                    placeholderText="Button text..."
                    setFunc={setButtonText}
                    funcValue={buttonText}
                  />
                  <InputDivsWithMT
                    heading="Call to action button link?"
                    placeholderText="Url..."
                    setFunc={setButtonLink}
                    funcValue={buttonLink}
                  />
                  <InputDivsWithMT
                    heading="What is your profile video link?"
                    placeholderText="Url..."
                    setFunc={setProfileVideoLink}
                    funcValue={profileVideoLink}
                  />
                  <InputDivsWithMT
                    heading="What is your colour code?"
                    placeholderText="#000000"
                    setFunc={setColorCode}
                    funcValue={colorCode}
                  />
                  <ImageUploadDiv
                    heading="Brand profile image"
                    setFunc={setProfileImgColor}
                    funcValue={profileImgColor}
                  />
                  <ImageUploadDiv2
                    heading="Brand profile image white"
                    setFunc={setProfileImgWhitee}
                    funcValue={profileImgWhitee}
                    imgLoading={imgLoading}
                    setImgLoading={setImgLoading}
                  />
                  <div className="stepBtns" style={{ marginTop: '2rem' }}>
                    <div
                      style={{ background: '#7899CF', height: '4rem' }}
                      onClick={() => {
                        setProfileImgWhitee('');
                        setProfileImgColor('');
                        setColorCode('');
                        setProfileVideoLink('');
                        setButtonLink('');
                        setButtonText('');
                        setWebLink('');
                        setBrandAddress('');
                        setBrandName('');
                        setBecomeAdvertiser(false);
                        setPopularActionEnabled(true);
                      }}
                    >
                      Go Back
                    </div>
                    <div
                      style={{
                        background: '#18191d',
                        height: '4rem',
                        opacity:
                          profileImgWhitee &&
                          profileImgColor &&
                          colorCode &&
                          profileVideoLink &&
                          buttonLink &&
                          buttonText &&
                          webLink &&
                          brandAddress &&
                          brandName
                            ? '1'
                            : '0.25',
                        cursor:
                          profileImgWhitee &&
                          profileImgColor &&
                          colorCode &&
                          profileVideoLink &&
                          buttonLink &&
                          buttonText &&
                          webLink &&
                          brandAddress &&
                          brandName
                            ? 'pointer'
                            : 'not-allowed',
                      }}
                      onClick={() => {
                        if (
                          profileImgWhitee &&
                          profileImgColor &&
                          colorCode &&
                          profileVideoLink &&
                          buttonLink &&
                          buttonText &&
                          webLink &&
                          brandAddress &&
                          brandName
                        ) {
                          advertiserApiCall();
                        }
                      }}
                    >
                      Next Step
                    </div>
                  </div>
                </div>
              </>
            )}

            {creating && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}

            {becomeAdvertiserStep === 2 && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setBecomeAdvertiser(false);
                      setProfileImgWhitee('');
                      setProfileImgColor('');
                      setColorCode('');
                      setProfileVideoLink('');
                      setButtonLink('');
                      setButtonText('');
                      setWebLink('');
                      setBrandAddress('');
                      setBrandName('');
                      setBecomeAdvertiserStep(1);
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Became An Advertiser
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {createProductLine && (
          <div className="popularC">
            {createProductLineStep === 1 && (
              <>
                <div className="head-txt">
                  <div>Create Product Line</div>
                  <div
                    onClick={() => {
                      setCreateProductLine(false);
                      setProductLineImg('');
                      setBrandCode('');
                      setProductLineName('');
                      setSelectedBrand('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
                <div className="overall-div">
                  <InputDivsWithMT
                    heading="What is the name of the product line?"
                    placeholderText="Name..."
                    setFunc={setProductLineName}
                    funcValue={productLineName}
                  />
                  <InputDropdown1
                    heading="Select a brand code"
                    setFunc={setBrandCode}
                    mapValue={brandCodeData}
                    funcValue={brandCode}
                    dropdown={brandCodedropDown}
                    dropdownFunc={setBrandCodedropDown}
                    setBrand={setSelectedBrand}
                    brand={selectedBrand}
                  />
                  <ImageUploadDiv2
                    heading="Brand profile image"
                    setFunc={setProductLineImg}
                    funcValue={productLineImg}
                    imgLoading={imgLoading1}
                    setImgLoading={setImgLoading1}
                  />
                </div>
                <div className="stepBtns">
                  <div
                    style={{ background: '#7899CF', height: '4rem' }}
                    onClick={() => {
                      setCreateProductLine(false);
                      setProductLineImg('');
                      setBrandCode('');
                      setProductLineName('');
                      setPopularActionEnabled(true);
                      setSelectedBrand('');
                    }}
                  >
                    Go Back
                  </div>
                  <div
                    style={{
                      background: '#18191d',
                      height: '4rem',
                      opacity:
                        productLineImg && brandCode && productLineName
                          ? '1'
                          : '0.25',
                      cursor:
                        productLineImg && brandCode && productLineName
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={() => {
                      if (productLineImg && brandCode && productLineName) {
                        createProductLineApiCall();
                      }
                    }}
                  >
                    Next Step
                  </div>
                </div>
              </>
            )}

            {creating && (
              <div
                className="loading-component"
                style={{ top: '0', left: '0', width: '100%' }}
              >
                <LoadingAnimation icon={appLogo} width={200} />
              </div>
            )}

            {createProductLineStep === 2 && (
              <>
                <div
                  className="head-txt"
                  style={{ justifyContent: 'flex-end' }}
                >
                  <div
                    onClick={() => {
                      setCreateProductLine(false);
                      setCreateProductLineStep(1);
                      setProductLineName('');
                      setBrandCode('');
                      setProductLineImg('');
                      setBrandCodeData('');
                      setBrandCodedropDown(false);
                      setSelectedBrand('');
                    }}
                    className="close-div"
                  >
                    <img src={close} alt="" />
                  </div>
                </div>

                <div className="overall-div">
                  <div className="successMsg">
                    You Have Successfully Created A Product Line
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CommercialPage;
