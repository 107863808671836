import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import '../AffiliatePage/affiliate.scss';
import {
  affiliateBalances,
  affiliateId,
  affiliateStats,
  allUsers,
  levelBasedData,
  uplineData,
  stakingContractHolders,
  usersWithTrade,
} from '../AffiliatePage/api';
import { useAppContextDetails } from '../../context/AppContext';
import { BankContext } from '../../context/Context';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import AddUser from '../AffiliatePage/AddUser';
import Invite from '../AffiliatePage/Invite';
import Withdraw from '../AffiliatePage/Withdraw';

import search from '../../static/images/malls/search.svg';
import fulllogo from '../../static/images/crm/fullLogo.svg';
import guest from '../../static/images/guest.jpg';
import ablogo from '../../static/images/affiliate/ablogo.svg';
import copy from '../../static/images/affiliate/copy.svg';
import close from './close.svg';
import edit from './edit.svg';
import plus from './plus.svg';
import heart from './heart.svg';
import withdraw from './withdraw.svg';
import profile from './profile.svg';
import vvLogo from '../../static/images/logos/vv.svg';

const CRMPage = () => {
  const {
    levelData,
    setLevelData,
    balances,
    setBalances,
    showleveldata,
    setShowLevelData,
    adduser,
    setAdduser,
    adduserStep,
    setAdduserStep,
    affiliateid,
    setAffiliateId,
    allaffiliatedata,
    setAllaffiliatedata,
    chainuserData,
    setchainUserData,
    appColorCode,
    affStats,
    setAffStats,
    appName,
    upline,
    setUpline,
    invite,
    setInvite,
    setuserfilteredAff,
    iswithdraw,
    setIswithdraw,
    //update user name
    newUserNameSidebar,
    setnewUserNameSidebar,
    newUserName,
    setnewUserName,
    usernameExist,
    setusernameExist,
    popular,
    setPopular,
    updatingUsername,
    setupdatingUsername,
  } = useAppContextDetails();

  const {
    email,
    token,
    username,
    name,
    profileImg,
    copyToClipboard,
    linkname,
    setLinkname,
    crmTab,
    setCrmTab,
  } = useContext(BankContext);

  const history = useHistory();
  const [isloading, setisloading] = useState(true);
  const [statLoading, setStatLoading] = useState(true);
  const [fetchingHolders, setFetchingHolders] = useState(true);
  const [holdersData, setHoldersData] = useState([]);
  const [filteredHoldersData, setFilteredHoldersData] = useState([]);
  const [appsSidebar, setAppsSidebar] = useState(false);
  const [appsData, setAppsData] = useState([]);
  const [gettingApps, setGettingApps] = useState(true);
  const [userSelectedApp, setUserSelectedApp] = useState([]);
  const [appselected, setAppselected] = useState(false);
  const [registrationLink, setRegistrationLink] = useState();
  const [copied, setCopied] = useState(false);
  const [gettingexchangeData, setgettingexchangeData] = useState(true);
  const [exchangeData, setExchangeData] = useState([]);
  const [filteredExchangeData, setFilteredExchangeData] = useState([]);

  const tabs = ['All', 
  // 'Platinum', 'CEO', 'Platinum + IB', 'CEO + IB'
];

  if (!email) {
    history.push('/');
  }

  useEffect(() => {
    let mailId = email;
    levelBasedData(mailId).then((response) => {
      let result = response?.data?.levelsData;
      // console.log(result, 'levelbased data');
      setLevelData(result);
      setisloading(false);
    });

    affiliateBalances(mailId).then((response) => {
      let result = response?.data;
      setBalances(result);
    });

    affiliateId(mailId).then((response) => {
      let result =
        response?.data?.usersData?.[0]?.hardCoded?.[0]?.data?.affiliate_id;
      // console.log(result, 'affiliate id');
      setAffiliateId(result);
    });

    uplineData(mailId).then((response) => {
      let result = response?.data?.uplines?.[0];
      setUpline(result);
      let username = response?.data?.user?.username;
      setLinkname(username);
    });

    stakingContractHolders(mailId).then((response) => {
      let result = response?.data?.users;
      // console.log(result, 'stakingContractHolders result');
      setHoldersData(result);
      setFilteredHoldersData(result);
      setFetchingHolders(false);
    });

    usersWithTrade(mailId).then((response) => {
      let result = response?.data?.userStats;
      // console.log(result, 'usersWithTrade result');
      setExchangeData(result);
      setFilteredExchangeData(result);
      setgettingexchangeData(false);
    });
  }, [email]);

  useEffect(() => {
    if (affiliateid.length > 5) {
      let id = affiliateid;
      affiliateStats(id).then((response) => {
        let result = response?.data;
        setAffStats(result);
        setStatLoading(false);
      });
    }
  }, [affiliateid, email]);

  const affiliateData = () => {
    let obj = {
      affiliate_id: affiliateid,
    };
    allUsers(obj).then((response) => {
      let result = response?.data;
      setAllaffiliatedata(result);
      setuserfilteredAff(result);
    });
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      // console.log('Search term:', newUserName);
      if (newUserName) {
        usernameExistCheck(newUserName);
      } else {
        setusernameExist(false);
      }
      // Perform search or any other action with the debounced value of newUserName
    }, 50);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [newUserName]);

  const handleChange = (event) => {
    setnewUserName(event.target.value);
  };

  function usernameExistCheck(username) {
    setusernameExist(false);
    axios
      .get(
        `https://comms.globalxchange.io/user/profile/data/get?username=${username}`
      )
      .then((response) => {
        if (!response?.data?.status || !response?.data?.count) {
          setusernameExist(true);
        } else {
          setusernameExist(false);
        }
        // console.log('username exist', response);
      })
      .catch((error) => {
        console.log('error checking username', error?.message);
      });
  }

  function usernameUpdate() {
    setupdatingUsername(true);
    axios
      .post('https://comms.globalxchange.io/user/details/update', {
        email: email,
        token: token,
        username: newUserName,
      })
      .then((response) => {
        // console.log(response, 'updated username');
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log('error checking username', error?.message);
        setupdatingUsername(false);
        setnewUserNameSidebar(false);
        window.location.reload(false);
      });
  }

  function filterItem(text) {
    let filterItem = holdersData?.filter((eachitem) => {
      return eachitem?.userData?.name
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilteredHoldersData(filterItem);
  }

  const getApps = () => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
      )
      .then((response) => {
        let result = response?.data?.userApps;
        // console.log(result);
        setAppsData(result);
        setGettingApps(false);
      })
      .catch((error) => {
        console.log(error, 'getApps error');
      });
  };

  const getAppLink = () => {
    let appCode = userSelectedApp?.app_code;
    axios
      .get(`https://comms.globalxchange.io/gxb/apps/get?app_code=${appCode}`)
      .then((response) => {
        let result = response?.data?.apps?.[0]?.registration_link;
        if (result?.length > 0) {
          setRegistrationLink(result);
        } else {
          setRegistrationLink('blank');
        }
      });
  };

  function copyDivValue() {
    // Get the div element
    var divElement = document.getElementById('myDiv');

    // Create a temporary input element
    var tempInput = document.createElement('input');

    // Set the value of the input element to the value of the div element
    tempInput.setAttribute('value', divElement.textContent);

    // Append the input element to the body of the document
    document.body.appendChild(tempInput);

    // Select the value of the input element
    tempInput.select();

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Remove the temporary input element from the document
    document.body.removeChild(tempInput);
  }

  return (
    <Layout active="CRM" className="affiliate" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="affiliate-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="affiliate-icon" style={{ width: '18%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-affiliate" style={{ width: '77%' }}>
              <div className="im">
                <input
                  type="text"
                  placeholder="Im Looking For..."
                  onChange={(event) => {
                    if (crmTab === 'Staking') {
                      filterItem(event.target.value);
                    }
                  }}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="right-topa">
            <div
              style={{ borderRight: '1px solid #e7e7e7', paddingRight: '2rem' }}
              className="divv"
            >
              <img
                src={upline?.profile_img ? upline?.profile_img : guest}
                alt=""
                style={{ height: '40px', borderRadius: '50%' }}
              />
              <div>
                <p style={{ opacity: '0.25' }}>Your Upline</p>
                <p>{upline?.name}</p>
              </div>
            </div>
            <div
              style={{ paddingLeft: '2rem', width: 'auto' }}
              className="divv"
            >
              <img src={ablogo} alt="" style={{ height: '40px' }} />
              <div>
                <p style={{ opacity: '0.25' }}>AfilliateBank Balance</p>
                <p>
                  $
                  {balances?.gxbroker_balance
                    ? balances?.gxbroker_balance?.toFixed(2)
                    : '0.00'}
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-btn"
            style={{ background: '#7899CF' }}
            onClick={() => {
              setPopular(!popular);
            }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '100%' }}>
            <div
              className="optionss-divider"
              style={{ justifyContent: 'unset', gap: '4rem' }}
            >
              {tabs.map((item) => (
                <div
                  className={`each-option ${
                    crmTab === item ? 'each-option-selected' : ''
                  }`}
                  style={{
                    fontWeight: crmTab === item ? '600' : '',
                  }}
                  // onClick={() => setCrmTab(item)}
                >
                  <div>
                    <p>{item}</p>
                  </div>
                </div>
              ))}
              {/* <div
                className={`each-option ${
                  crmTab === 'Overview' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: crmTab === 'Overview' ? '600' : '',
                }}
                onClick={() => setCrmTab('Overview')}
              >
                <div>
                  <p>Overview</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  crmTab === 'Staking' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: crmTab === 'Staking' ? '600' : '',
                }}
                onClick={() => setCrmTab('Staking')}
              >
                <div>
                  <p>Staking</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  crmTab === 'Exchange' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: crmTab === 'Exchange' ? '600' : '',
                }}
                onClick={() => setCrmTab('Exchange')}
              >
                <div>
                  <p>Exchange</p>
                </div>
              </div> */}

              {/* <div
                className={`each-option ${
                  crmTab === 'MoneyMarkets' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: crmTab === 'MoneyMarkets' ? '600' : '',
                }}
                // onClick={() => setCrmTab('MoneyMarkets')}
              >
                <div>
                  <p>MoneyMarkets</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  crmTab === 'Tokens' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: crmTab === 'Tokens' ? '600' : '',
                }}
                // onClick={() => setCrmTab('Tokens')}
              >
                <div>
                  <p>Tokens</p>
                </div>
              </div>
              <div
                className={`each-option ${
                  crmTab === 'Withdrawals' ? 'each-option-selected' : ''
                }`}
                style={{
                  fontWeight: crmTab === 'Withdrawals' ? '600' : '',
                }}
                // onClick={() => setCrmTab('Withdrawals')}
              >
                <div>
                  <p>Withdrawals</p>
                </div>
              </div> */}
            </div>
            {/* <div className="last-option">Products Per Brand</div> */}
          </div>
        </div>

        {crmTab === 'All' ? (
          <div className="affiliate-content" style={{ padding: '2.5rem 4% 0' }}>
            <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
              <div className="header-contt">
                <div className="text-contt">
                  <div className="left-contt" style={{ width: '100%' }}>
                    <p
                      style={{
                        color: '#18191d',
                        fontWeight: '550',
                        fontSize: '1.75rem',
                        marginBottom: '0.25rem',
                      }}
                    >
                      Welcome {name ? name : username}
                    </p>
                    <div className="linkstyle">
                      {/* <div
                        className="btnDivs"
                        onClick={() => {
                          setAppsSidebar(true);
                          getApps();
                        }}
                        style={{
                          gap: appselected ? '10px' : '',
                          whiteSpace: appselected ? 'nowrap' : '',
                        }}
                      >
                        <img
                          src={appselected ? userSelectedApp?.app_icon : ''}
                          alt=""
                          style={{
                            display: appselected ? 'flex' : 'none',
                            width: appselected ? '25px' : '',
                          }}
                        />
                        {appselected ? userSelectedApp?.app_name : 'Select App'}
                      </div>
                      <div
                        className="btnDivs"
                        style={{
                          background: '#18191d',
                          color: '#ffffff',
                          width: '20%',
                          opacity: appselected ? '1' : '0.5',
                        }}
                        onClick={() => {
                          if (registrationLink && appselected) {
                            setAppselected(false);
                            setRegistrationLink();
                            setCopied(false);
                          } else if (appselected) {
                            getAppLink();
                          }
                        }}
                      >
                        {registrationLink ? 'Clear' : 'Generate Link'}
                      </div> */}
                      <div className="linkField" id="myDiv">
                        {/* {registrationLink
                          ? registrationLink + '/' + linkname
                          : 'Select App To Get Your Link'} */}
                        {`https://app.manifest.family/register/affiliate/${linkname}`}
                      </div>
                      <div
                        className="btnDivs"
                        style={{
                          background: '#7899CF',
                          color: '#ffffff',
                          opacity: 1,
                        }}
                        onClick={() => {
                          if (registrationLink) {
                            copyDivValue();
                            setCopied(true);
                          }
                        }}
                      >
                        {copied ? 'Copied' : 'Copy Link'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="small-boxes">
                  <div className="sbox">
                    <div style={{ width: '100%', height: '40%' }}>
                      <p className="headd" style={{ marginBottom: '0.25rem' }}>
                      Personal Signups
                      </p>
                      {statLoading ? (
                        <div className="ttl">
                          <Skeleton width={75} height={25} />
                        </div>
                      ) : (
                        <div className="ttl">{affStats?.direct?.length}</div>
                      )}
                    </div>
                    <div className="act-inact">
                      <div className="actt">
                        <p className="countt">--</p>
                        <div>Active</div>
                      </div>
                      <div className="inactt">
                        <p className="countt">--</p>
                        <div>Inactive</div>
                      </div>
                    </div>
                  </div>
                  <div className="sbox">
                    <div style={{ width: '100%', height: '40%' }}>
                      <p className="headd" style={{ marginBottom: '0.25rem' }}>
                      Downline
                      </p>
                      {statLoading ? (
                        <div className="ttl">
                          <Skeleton width={75} height={25} />
                        </div>
                      ) : (
                        <div className="ttl">{affStats?.indirect?.length}</div>
                      )}
                    </div>
                    <div className="act-inact">
                      <div className="actt">
                        <p className="countt">--</p>
                        <div>Active</div>
                      </div>
                      <div className="inactt">
                        <p className="countt">--</p>
                        <div>Inactive</div>
                      </div>
                    </div>
                  </div>
                  <div className="sbox">
                    <div style={{ width: '100%', height: '40%' }}>
                      <p className="headd" style={{ marginBottom: '0.25rem' }}>
                        Total Users
                      </p>
                      {statLoading ? (
                        <div className="ttl">
                          <Skeleton width={75} height={25} />
                        </div>
                      ) : (
                        <div className="ttl">{affStats?.all?.length}</div>
                      )}
                    </div>
                    <div className="act-inact">
                      <div className="actt">
                        <p className="countt">--</p>
                        <div>Active</div>
                      </div>
                      <div className="inactt">
                        <p className="countt">--</p>
                        <div>Inactive</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="statistics-cont">
                <div className="overallcontt">
                  <div className="statt-div">
                    <div>Level</div>
                    <div>Users</div>
                    <div>Active</div>
                    <div>Inactive</div>
                    <div>Volume</div>
                    <div>Commissions</div>
                  </div>
                  <div className="contentt-div">
                    {isloading
                      ? Array(10)
                          .fill(' ')
                          .map((item, index) => {
                            return (
                              <div className="each-contentt" key={index}>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={50} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={100} height={25} />
                                </div>
                                <div>
                                  <Skeleton width={100} height={25} />
                                </div>
                              </div>
                            );
                          })
                      : levelData?.map((e, i) => {
                          return (
                            <div
                              className="each-contentt"
                              onClick={() => {
                                localStorage.setItem(
                                  'leveldata',
                                  JSON.stringify(e?.users)
                                );
                                localStorage.setItem(
                                  'level',
                                  JSON.stringify(e)
                                );
                                history.push('/crm/levels');
                              }}
                              key={i}
                            >
                              <div>{e?.level}</div>
                              <div>{e?.count}</div>
                              <div>--</div>
                              <div>--</div>
                              <div>${e?.total_revenue?.toFixed(2)}</div>
                              <div>${e?.total_earnings?.toFixed(2)}</div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : crmTab === 'Staking' ? (
          <div className="affiliate-content1">
            <div className="staking-header">
              <div style={{ width: '25%' }}>User</div>
              <div style={{ width: '29%' }}>Contracts</div>
            </div>
            <div className="staking-content">
              {fetchingHolders
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="each-holder" key={index}>
                          <div className="user-div">
                            <div>
                              <Skeleton
                                width={40}
                                height={40}
                                borderRadius={50}
                              />
                            </div>
                            <div>
                              <div style={{ fontWeight: '600' }}>
                                <Skeleton width={75} height={20} />
                              </div>
                              <div>
                                <Skeleton width={120} height={20} />
                              </div>
                            </div>
                          </div>
                          <div className="contract-div">
                            <div style={{ fontWeight: '600' }}>
                              <Skeleton width={75} height={20} />
                            </div>
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : filteredHoldersData?.map((e, i) => {
                    return (
                      <div className="each-holder" key={i}>
                        <div className="user-div">
                          <div>
                            <img
                              src={
                                e?.userData?.profile_img
                                  ? e?.userData?.profile_img
                                  : profile
                              }
                              alt="profile"
                            />
                          </div>
                          <div>
                            <div style={{ fontWeight: '600' }}>
                              {e?.userData?.name}
                            </div>
                            <div>{e?.userData?.email}</div>
                          </div>
                        </div>
                        <div className="contract-div">
                          <div style={{ fontWeight: '600' }}>
                            {e?.bondsCount} Contracts
                          </div>
                          <div>{e?.bondTxnsCount} Purchases</div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        ) : (
          <div className="affiliate-content1">
            <div className="staking-header">
              <div style={{ width: '30%' }}>User</div>
              <div style={{ width: '25%' }}>Volume</div>
              <div style={{ width: '20%' }}>Transactions</div>
              <div style={{ width: '25%' }}>Revenue</div>
            </div>
            <div className="staking-content">
              {gettingexchangeData
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="each-holder" key={index}>
                          <div className="user-div" style={{ width: '30%' }}>
                            <div>
                              <Skeleton
                                width={40}
                                height={40}
                                borderRadius={50}
                              />
                            </div>
                            <div>
                              <div>
                                <Skeleton width={75} height={20} />
                              </div>
                              <div>
                                <Skeleton width={120} height={20} />
                              </div>
                            </div>
                          </div>
                          <div className="contract-div1">
                            <div>
                              <Skeleton width={100} height={20} />
                            </div>
                          </div>
                          <div
                            className="contract-div1"
                            style={{ width: '20%' }}
                          >
                            <div>
                              <Skeleton width={75} height={20} />
                            </div>
                          </div>
                          <div className="contract-div1">
                            <div>
                              <Skeleton width={120} height={20} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : filteredExchangeData?.map((e, i) => {
                    return (
                      <div className="each-holder" key={i}>
                        <div className="user-div" style={{ width: '30%' }}>
                          <div>
                            <img
                              src={e?.profile_img ? e?.profile_img : profile}
                              alt=""
                            />
                          </div>
                          <div>
                            <div style={{ fontWeight: '600' }}>{e?.name}</div>
                            <div>{e?.email}</div>
                          </div>
                        </div>
                        <div className="contract-div1">
                          <div style={{ fontWeight: '600' }}>
                            $ {e?.volume?.toFixed(2)}
                          </div>
                        </div>
                        <div className="contract-div1" style={{ width: '20%' }}>
                          <div style={{ fontWeight: '600' }}>{e?.txns}</div>
                        </div>
                        <div className="contract-div1">
                          <div style={{ fontWeight: '600' }}>
                            $ {e?.revenue?.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}

        <div
          className="adduserSidebar"
          style={{ right: adduser ? '0' : '-100%' }}
        >
          <AddUser />
        </div>

        <div
          className="adduserSidebar"
          style={{ right: invite ? '0' : '-100%' }}
        >
          <Invite />
        </div>

        {popular && (
          <div className="popular">
            <div className="head-txt">
              <div>Popular Actions</div>
              <div
                onClick={() => {
                  setPopular(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>
            <div
              className="each-action"
              style={{ marginTop: '2.75rem' }}
              onClick={() => {
                setPopular(false);
                setIswithdraw(true);
              }}
            >
              <div>
                <img src={withdraw} alt="" />
              </div>
              <div>Withdraw Commissions</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopular(false);
                setnewUserNameSidebar(true);
              }}
            >
              <div>
                <img src={edit} alt="" />
              </div>
              <div>Update Username</div>
            </div>
            <div
              className="each-action"
              onClick={() => {
                setPopular(false);
                setAdduser(true);
                if (affiliateid) {
                  affiliateData();
                }
              }}
            >
              <div>
                <img src={plus} alt="" />
              </div>
              <div>Pre Register A Prospect</div>
            </div>
            {/* <div
              className="each-action"
              onClick={() => {
                setPopular(false);
                setInvite(true);
              }}
            >
              <div>
                <img src={heart} alt="" />
              </div>
              <div>Pre Register With A Gift</div>
            </div>
            <div
              className="each-action"
              // onClick={() => {
              //   setPopular(false);
              //   setInvite(true);
              // }}
            >
              <div>
                <img src={withdraw} alt="" style={{ rotate: '270deg' }} />
              </div>
              <div>Move User Within My Downline</div>
            </div> */}
          </div>
        )}

        {appsSidebar && (
          <div className="popular">
            <div
              className="head-txt"
              style={{ marginBottom: '1.75rem', height: '3rem' }}
            >
              <div>Registered Apps</div>
              <div
                onClick={() => {
                  setAppsSidebar(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div className="overallAction">
              {gettingApps
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div className="each-actionn" key={index}>
                          <div>
                            <Skeleton
                              width={40}
                              height={40}
                              borderRadius={50}
                            />
                          </div>
                          <div>
                            <Skeleton width={120} height={25} />
                          </div>
                        </div>
                      );
                    })
                : appsData?.map((e, i) => {
                    return (
                      <div
                        className="each-actionn"
                        onClick={() => {
                          setAppsSidebar(false);
                          setUserSelectedApp(e);
                          setAppselected(true);
                        }}
                        key={i}
                      >
                        <div>
                          <img
                            src={e?.app_icon}
                            alt=""
                            style={{ width: '30px', height: '30px' }}
                          />
                        </div>
                        <div>{e?.app_name}</div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}

        {updatingUsername ? (
          <div
            className="editUsername usernameLoading"
            style={{ right: newUserNameSidebar ? '' : '-150%' }}
          >
            <img src={vvLogo} alt="Logo" className="affiliateLogo" />
            <div className="updatingName">
              <span>Updating Username To</span>
              <span>{'  '} </span>
              <span> {newUserName}</span>
            </div>
          </div>
        ) : (
          <div
            className="editUsername"
            style={{ right: newUserNameSidebar ? '' : '-150%' }}
          >
            <div className="heading">
              <span>Update Username</span>
              <div onClick={() => setnewUserNameSidebar(false)}>X</div>
            </div>
            <div className="otherFields">
              <div>Current Username</div>
              <div>
                <input
                  value={linkname ? linkname : ''}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            </div>
            <div className="otherFields">
              <div>New Username</div>
              <div>
                <input
                  placeholder="Enter Username"
                  type="text"
                  value={newUserName}
                  onChange={handleChange}
                />
                <div
                  className="usernameIndicator"
                  style={{ background: usernameExist ? '#57D6A1' : '' }}
                ></div>
              </div>
            </div>
            <div
              className="confirmBtn"
              style={{
                pointerEvents: usernameExist ? '' : 'none',
                opacity: usernameExist ? '' : '0.4',
              }}
              onClick={usernameUpdate}
            >
              Confirm Update
            </div>
          </div>
        )}

        <div
          className="adduserSidebar"
          style={{ right: iswithdraw ? '0' : '-100%' }}
        >
          <Withdraw />
        </div>
      </div>
    </Layout>
  );
};

export default CRMPage;
