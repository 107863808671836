import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Layout/Layout';
import axios from 'axios';
import { BankContext } from '../../context/Context';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { uploadImageFunc } from '../../utils/imageUpload';
import { planBContext } from '../../context/PlanBContext';
import { LoadingAnimation } from '../../components/LoadingAnimation';

import fulllogo from '../../static/images/engagement/fulllogo.svg';
import search from '../../static/images/malls/search.svg';
import close from '../CRMPage/close.svg';
import close1 from '../RetailerPage/close.svg';
import hamburger from '../RetailerPage/hamburger.svg';
import plus from '../CRMPage/plus.svg';
import add from '../RetailerPage/addcompPlan.svg';
import del from '../RetailerPage/delete.svg';
import dots from '../RetailerPage/dots.svg';
import dummy from '../RetailerPage/dummy.svg';
import editIcon from '../RetailerPage/editIcon.svg';
import './engagement.scss';
import uploading from '../../static/images/uploading.svg';
import loadingLogo from '../../static/images/logos/vv.svg';

const EngagementPage = () => {
  const { email, token, engagementTab, setEngagementTab } =
    useContext(BankContext);

  const history = useHistory();

  if (!email) {
    history.push('/');
  }

  const [hamSelected, setHamSelected] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [productSelected, setProductSelected] = useState(false);
  const [productData, setProductData] = useState([]);
  const [productAction, setProductAction] = useState(false);
  const [withoutCompPlanData, setWithoutCompPlanData] = useState([]);
  const [filteredWoData, setFilteredWoData] = useState([]);

  useEffect(() => {
    axios
      .post('https://comms.globalxchange.io/gxb/product/price/with/fees/get', {
        product_creator: email,
      })
      .then((response) => {
        if (response?.data?.products?.length > 0) {
          let result = response?.data?.products;
          // console.log(
          //   response?.data?.products,
          //   'engagement products with compplan'
          // );
          setWithoutCompPlanData(result);
          setFilteredWoData(result);
          setIsloading(false);
        } else {
          setIsloading(false);
        }
      })
      .catch((error) => {
        console.log(error, 'engagement products with compplan error');
      });
  }, []);

  function filterItem1(text) {
    let filterItem = withoutCompPlanData?.filter((eachitem) => {
      return eachitem?.product?.product_name
        ?.toLowerCase()
        ?.includes(text?.toLowerCase());
    });
    setFilteredWoData(filterItem);
  }

  return (
    <Layout active="Engagement" className="engagement" hideFooter>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div className="engagement-navbar">
          <div
            style={{
              height: '100%',
              width: '45%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              className="engagement-hamburger"
              // onClick={() => {
              //   if (engagementTab === 'Offerings') {
              //     {
              //       setHamSelected(!hamSelected);
              //     }
              //   }
              // }}
            >
              <img src={hamSelected ? close1 : hamburger} alt="" />
            </div>
            <div className="engagement-icon" style={{ width: '35%' }}>
              <img src={fulllogo} alt="" />
            </div>
            <div className="search-engagement">
              <div className="im">
                <input
                  type="text"
                  placeholder="Search Anything"
                  onChange={(event) => {
                    if (engagementTab === 'Offerings') {
                      filterItem1(event.target.value);
                    }
                  }}
                />
                <div className="arroww" style={{ cursor: 'pointer' }}>
                  <img src={search} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-btn"
            // onClick={() => {
            //   setPopularAction(!popularAction);
            // }}
          >
            Popular Actions
          </div>
        </div>

        <div
          className="w-space"
          style={{ borderBottom: '0.5px solid #e5e5e5' }}
        >
          <div className="optionss" style={{ width: '55%' }}>
            <div
              className={`each-option ${
                engagementTab === 'Offerings' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: engagementTab === 'Offerings' ? '600' : '',
              }}
              onClick={() => setEngagementTab('Offerings')}
            >
              <div>
                <p>Offerings</p>
              </div>
            </div>
            <div
              className={`each-option ${
                engagementTab === 'Affiliates' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: engagementTab === 'Affiliates' ? '600' : '',
              }}
              //   onClick={() => setEngagementTab('Affiliates')}
            >
              <div>
                <p>Affiliates</p>
              </div>
            </div>
            <div
              className={`each-option ${
                engagementTab === 'Influencers' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: engagementTab === 'Influencers' ? '600' : '',
              }}
              // onClick={() => setEngagementTab('Influencers')}
            >
              <div>
                <p>Influencers</p>
              </div>
            </div>
            <div
              className={`each-option ${
                engagementTab === 'CompPlans' ? 'each-option-selected' : ''
              }`}
              style={{
                fontWeight: engagementTab === 'CompPlans' ? '600' : '',
              }}
              // onClick={() => setEngagementTab('CompPlans')}
            >
              <div>
                <p>CompPlans</p>
              </div>
            </div>
            <div
              className={`each-option ${
                engagementTab === 'Affiliate Licenses'
                  ? 'each-option-selected'
                  : ''
              }`}
              style={{
                fontWeight: engagementTab === 'Affiliate Licenses' ? '600' : '',
              }}
              // onClick={() => setEngagementTab('Affiliate Licenses')}
            >
              <div>
                <p>Affiliate Licenses</p>
              </div>
            </div>
          </div>
        </div>

        <div className="engagement-content">
          <div
            style={{
              width: hamSelected ? '70%' : '100%',
              height: '100%',
              overflowY: 'scroll',
              padding: '2% 4% 0',
            }}
          >
            <div className="bs-products">
              {isloading
                ? Array(10)
                    .fill(' ')
                    .map((item, index) => {
                      return (
                        <div
                          className="each-productt"
                          key={index}
                          style={{ width: hamSelected ? '48%' : '31.5%' }}
                        >
                          <div className="top-sec">
                            <div style={{ width: '50%' }}>
                              <Skeleton
                                width={100}
                                height={100}
                                borderRadius={50}
                              />
                            </div>
                            <Skeleton width={100} height={35} />
                          </div>
                          <div className="mid-sec">
                            <Skeleton width={150} height={35} />
                          </div>
                          <div className="bottom-sec">
                            <div>
                              <div className="amnt">
                                <Skeleton width={50} height={35} />
                              </div>
                              <div className="first">
                                <Skeleton width={100} height={35} />
                              </div>
                            </div>
                            <div>
                              <div className="amnt">
                                <Skeleton width={50} height={35} />
                              </div>
                              <div className="first">
                                <Skeleton width={100} height={35} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : engagementTab === 'Offerings' && filteredWoData?.length > 0
                ? filteredWoData?.map((e, i) => {
                    return (
                      <div
                        className="each-productt"
                        key={i}
                        onClick={() => {
                          history.push(`/engagement/${e?.product?.product_code}`);
                          localStorage.setItem(
                            'retailerProduct',
                            JSON.stringify(e?.product)
                          );
                        }}
                        style={{
                          opacity: !productSelected
                            ? '1'
                            : productSelected && productData?.product?._id === e?.product?._id
                            ? '1'
                            : '0.25',
                          width: hamSelected ? '48%' : '31.5%',
                        }}
                      >
                        <div className="top-sec">
                          <div style={{ width: '50%' }}>
                            <img src={e?.product?.product_icon} alt="" />
                          </div>
                          <button
                            onClick={(event) => {
                              event.stopPropagation();
                              setProductAction(!productAction);
                              setProductData(e);
                              setProductSelected(true);
                            }}
                          >
                            <img src={dots} alt="" />
                          </button>
                        </div>
                        <div className="mid-sec">{e?.product?.product_name}</div>
                        <div className="bottom-sec">
                          <div>
                            <div className="amnt">
                              {e?.product?.pricesWithAppFees[0]?.price?.toFixed(2) ||
                                '0.00'}{' '}
                              &nbsp;
                              {e?.product?.pricesWithAppFees[0]?.coin || ''}
                            </div>
                            <div className="first">First Month</div>
                          </div>
                          <div>
                            <div className="amnt">
                              {e?.product?.pricesWithAppFees[1]?.price?.toFixed(2) ||
                                '0.00'}
                              &nbsp;
                              {e?.product?.pricesWithAppFees[0]?.coin || ''}
                            </div>
                            <div className="first">Monthly</div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
        </div>

        {productAction && (
          <div className="popularE">
            <div className="head-txt">
              <div>Product Actions</div>
              <div
                onClick={() => {
                  setProductAction(false);
                  setProductSelected(false);
                }}
                className="close-div"
              >
                <img src={close} alt="" />
              </div>
            </div>

            <div
              className="each-action"
              // onClick={() => {
              //   setProductAction(false);
              // }}
            >
              <div>
                <img src={editIcon} alt="" />
              </div>
              <div>Edit CompPlan</div>
            </div>

            <div
              className="each-action"
              // onClick={() => {
              //   setProductAction(false);
              //   if (engagementTab === 'Offerings') {
              //     setDeleteProduct(true);
              //   }
              // }}
            >
              <div>
                <img src={del} alt="" />
              </div>
              <div>Delete CompPlan</div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default EngagementPage;
